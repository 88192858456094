import React from 'react';
import { IconButton, Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { IEmail } from './types';
import { EditOutlined } from '@mui/icons-material';
import { REQUEST_TYPE } from '../config';


interface IProps{
  emails: IEmail[],
  onEdit:(email:IEmail) => void;
}

export default function EmailsList(props:IProps) {

  if(!props.emails.length){
    return (
      <Typography color="textSecondary" align="center">Адреси відсутні</Typography>
    )
  }

  return (
    <List>
        { props.emails.map((email:IEmail) => {
          const type = (email.type || []).map((i)=>REQUEST_TYPE[i])
          return (
            <ListItem key={email.id}>
              <ListItemText
                primary={email.email}
                secondary={type.length && type.length !== Object.keys(REQUEST_TYPE).length ? type.join(", ") : "Всі запити"}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                  onClick={(e)=>{
                    e.preventDefault();
                    props.onEdit(email);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
    </List>
  );
}