import { addDays, differenceInYears } from "date-fns";

interface IINN {
  inn: string;
  date_of_birth: Date;
  age: number;
}

export const parseINN = (inn:string):IINN|null => {
  if(inn.length !== 10) return null;
  let days = Number(inn.slice(0, 5));
  const start_date = new Date(1899, 11, 31);
  let date_of_birth = addDays(start_date, days);
  let age = differenceInYears(new Date(), date_of_birth);
  return {
    inn: inn,
    date_of_birth: date_of_birth,
    age: age
  } as IINN;
}