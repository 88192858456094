import { TextField, TextFieldProps } from "@mui/material";
import InputMask from 'react-input-mask';

type MaskedFieldProps = TextFieldProps & {
  mask: string;
  value: string;
}

export function MaskedField(props:MaskedFieldProps){
  const {
    mask,
    value,
    onChange,
    ...textFieldProps
  } = props
  return (
    <InputMask mask={mask}
      value={value}
      onChange={onChange}
    >
      {(inputProps:TextFieldProps) => 
        <TextField
          {...inputProps}
          {...textFieldProps}
        />
      }
    </InputMask>
  )
}