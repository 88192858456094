import { Icon } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';



interface IProps {
  text: string|number;
  forceAddCopyStyle?: boolean;
  children?: React.ReactNode;
}

export default function CopyText(props: IProps) {
  const { enqueueSnackbar } = useSnackbar();
  
  const onCopy = () => {
    enqueueSnackbar((
      <div>
        <div><b>Скопійовано в буфер обміну</b></div>
        <div>{props.text}</div>
      </div>
    ), {variant: 'info'});
  }

  const renderChildren = () => {
    if(!props.forceAddCopyStyle && React.isValidElement(props.children)){
      return props.children;
    }
    return (
      <span style={{cursor: 'copy'}}>
        {props.children}&nbsp;<sup><Icon style={{fontSize: '0.9em'}} color='disabled'>file_copy</Icon></sup>
      </span>
      )
  }

  return (
      <span
        style={{display: 'inline'}}
        onClick={(e)=>{
          e.stopPropagation();
        }}
      >
        <CopyToClipboard
          text={props.text.toString()}
          onCopy={onCopy}
        >
          {renderChildren()}
        </CopyToClipboard>
      </span>
  );
}