import React from "react";
import { Icon } from "@mui/material";
import NumberFormat from "react-number-format";
import { FilteredTableColumn } from "components/FilteredTable/types";
import { IExtendData, IDriverRequest } from "./types";
import LightTooltip from "components/LightTooltip";
import Moment from "react-moment";
import { REQUEST_TYPE } from "./config";

const columns: FilteredTableColumn[] = [
  {
    title: 'Дата створення',
    items: [
      {
        title: 'Дата створення',
        render: (row:IDriverRequest) => (
          <Moment
            date={row.created}
            format="DD.MM.YY HH:mm"
          />
        ),
        style: {
          whiteSpace: 'nowrap'
        },
      }
    ]
  },
  {
    title: 'ПІБ',
    items: [
      {
        title: 'ПІБ',
        render: (row:IDriverRequest) => row.full_name,
      }
    ]
  },
  {
    title: 'Телефон',
    items: [
      {
        title: 'Телефон',
        render: (row:IDriverRequest) => {
          if(!row.phone) return null;
          return (
            <NumberFormat value={row.phone} displayType={'text'} format="+## (###) ### ## ##" />
          )
        },
        style: {
          whiteSpace: 'nowrap'
        },
      }
    ]
  },
  {
    title: 'Тип',
    items: [
      {
        title: 'Тип',
        render: (row:IDriverRequest) => REQUEST_TYPE[row.type] || row.type || "-",
      }
    ]
  },
  {
    title: 'Статус',
    items: [
      {
        title: 'Статус',
        render: (row:IDriverRequest, extendData?:IExtendData) => {
          const statusDict = extendData?.statusDict
          if(row.last_status_request === null || statusDict === undefined) return null;

          return (
            <div>
              {statusDict[row.last_status_request?.status || 0]?.name || ''}
              {(row.last_status_request?.message) ? (
                <LightTooltip
                  title={(
                    <div style={{whiteSpace: 'pre-wrap'}}>{row.last_status_request?.message}</div>
                  )}
                >
                  <span>
                    &nbsp;&nbsp;
                    <Icon style={{
                      fontSize: '1em',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }} color='disabled'>chat_bubble_outline</Icon>
                  </span>
                </LightTooltip>
              ):null}
            </div>
          )
        }
      }
    ]
  },
  {
    title: 'Джерело',
    items: [
      {
        title: 'Джерело',
        render: (row:IDriverRequest) => row.source || null,
      }
    ]
  },
  {
    title: 'utm_source',
    items: [
      {
        title: 'utm_source',
        render: (row:IDriverRequest) => row.utm_source || null,
      }
    ]
  },
];

export default columns;