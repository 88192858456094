import React, { useState, useEffect, useCallback } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import requests from 'api/requests';
import { useRouteMatch } from 'react-router-dom';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import TableOffset from './tableOffset';
import Moment from 'react-moment';



export default function Offset() {
  const match:any = useRouteMatch();
  
  const [cars, setCars] = useState(null as any)
  const [periods, setPeriods] = useState(null as any)
  const [period, setPeriod] = useState(null as any)
  const [carIDs, setCarIDs] = useState([] as any)

  const loadCars = useCallback(() => {
    setPeriods(null);
    setPeriod(null);
    requests.getAll(`/car/car/`, {
      atp: match.params.id,
    }).then((r) => {
      setCars(r.body.results);
    })
  }, [match.params.id])

  const loadPeriods = useCallback(() => {
    if(!carIDs.length) return;

    requests.get('/atp/offset/period/', {
      car_id__in: carIDs,
      limit: 5
    }).then((r) => {
      setPeriods(r.body.results);
      if(r.body.results.length){
        setPeriod(r.body.results[0]);
      }
    })
  }, [carIDs])

  useEffect(() => {
    if(!cars) return;
    const car_ids = cars.map((i:any) => i.id);
    setCarIDs(car_ids);
  }, [cars]);
  
  useEffect(() => loadCars(), [loadCars]);
  useEffect(() => loadPeriods(), [loadPeriods]);

  if(cars === null || periods === null){
    return null;
  }

  if(!carIDs.length){
    return (
      <div>
        <Typography variant="h1" color="textSecondary" align="center">
          <ErrorOutlineOutlined fontSize="large" />
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          АТП не має жодного позивного
        </Typography>
      </div>
    )
  }

  if(period === null){
    return (
      <div>
        <Typography variant="h1" color="textSecondary" align="center">
          <ErrorOutlineOutlined fontSize="large" />
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          Відсутні розрахункові періоди
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <FormControl fullWidth>
            <InputLabel>Період</InputLabel>
            <Select
              value={period}
              displayEmpty
              onChange={(e: any, q: any) => {
                setPeriod(e.target.value)
              }}
            >
              {periods.map((i:any, index:number) => (
                <MenuItem key={index} value={i}>
                  <Moment
                    date={i.date_from}
                    format="DD.MM"
                  />
                  -
                  <Moment
                    date={i.date_to}
                    format="DD.MM/YYYY"
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br/><br/>

      <TableOffset
        carIDs={carIDs}
        periodID={period.id}
      />
    </div>
  );
}