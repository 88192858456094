import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import NumberFormat from 'react-number-format';
import { ICompany } from './types';
import { BalabceTableCell } from './styles';


interface CompanyTableRowProps {
  item: ICompany;
  onClick:(item:ICompany) => void;

  showCompanyColumn?:boolean;
}

export default function CompanyTableRow(props:CompanyTableRowProps) {
  return (
    <TableRow
      hover
      style={{cursor: 'pointer'}}
      onClick={() => {
        props.onClick(props.item);
      }}
    >
      <TableCell>{props.item.code}</TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{props.item.name}</TableCell>
      <TableCell>{props.item.provider ? props.item.provider.name : null}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={props.item.lock_balance} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></TableCell>
      <BalabceTableCell
        align="right"
        style={{whiteSpace: 'nowrap'}}
        error={props.item.lock}
        alarm={props.item.alarm}
      ><NumberFormat value={props.item.balance} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></BalabceTableCell>
    </TableRow>
  );
}