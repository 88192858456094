import React, { useEffect, useState } from 'react';
import { Dialog, Typography, DialogContent, DialogActions, Button, FormControl, TextField, Box } from '@mui/material';
import requests from 'api/requests';
import { IItem } from './types';
import columns from './columns';
import moment from 'moment';
import ConfirmIconButton from 'components/confirmIconButton';
import { DeleteOutlined } from '@mui/icons-material';

interface IProps {
  instance?: IItem|null;
  onSaved: ()=>void;
  onClose: ()=>void;
  onDeleteClick: (item:IItem)=>void;
}

export default function InstanceModal(props:IProps) {

  const {
    onSaved,
    onClose,
    onDeleteClick,
    instance = null
  } = props
  
  const [orderID, setOrderID] = useState<number|null>(null);
  const [item, setItem] = useState<IItem|null>(instance);
  const [note, setNote] = useState<string>(instance?.note || "");

  useEffect(()=>{
    if(!!instance){
      setItem(instance);
      setNote(instance.note || "");
      return;
    }
    
    const abortController = new AbortController();
    let timeout:any = 0;
    if(orderID===null){
      setItem(null);
    }else{
      timeout = setTimeout(()=>{
        requests.get(
          `/admin/black-list/driver/order-info/${orderID}/`,
          {},
          abortController.signal
        ).then((r)=>{
          setItem(r.body);
        }).catch(()=>{
          setItem(null);
        })
      }, 500)
    }

    return () => {
      abortController.abort();
      clearTimeout(timeout);
    };
  }, [orderID, instance])

  const save = () => {
    if(!!instance?.id){
      requests.patch(`/admin/black-list/driver/${instance?.id}/`, {
        note: note
      }).then(onSaved)
    }else{
      requests.post('/admin/black-list/driver/', {
        orderid: orderID,
        note: note
      }).then(onSaved)
    }
  }
  

  return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={onClose}
      >
        <DialogContent>

          {!instance&&(
            <FormControl fullWidth sx={{my: 2}}>
              <TextField
                  label="ID замовлення"
                  value={orderID || ""}
                  type="number"
                  onChange={(event) => {
                    setOrderID(event.target.value?parseInt(event.target.value):null);
                  }}
                />
            </FormControl>
          )}

          {!!instance && (instance?.created || instance?.creator) && (
            <Typography
              variant="caption"
              component="div"
              sx={{
                pb: 2,
                lineHeight: 1.2
              }}
            >
              {!!instance?.created && (<div>Створено: {moment(instance?.created).format("DD.MM.YY HH:mm")}</div>)}
              {!!instance?.creator && (<div>Створив: {instance?.creator}</div>)}
            </Typography>
          )}
          
          {item!==null?(
            <Box sx={{my: -2}}>
              {columns.map((c, index)=>(
                <Box key={index} sx={{my: 2}}>
                  <Typography>{c.title}</Typography>
                  {c.items.map((i)=>(
                    <Typography
                      key={i.title}
                      variant="caption"
                      component="div"
                    ><em>{i.title}:</em> {i.render(item)}</Typography>
                  ))}
                </Box>
              ))}
            </Box>
          ):null}

          <FormControl fullWidth sx={{my: 2}}>
            <TextField
                label="Коментар"
                value={note || ""}
                type="note"
                multiline
                maxRows={3}
                onChange={(event) => {
                  setNote(event.target.value);
                }}
              />
          </FormControl>
          
        </DialogContent>
        <DialogActions>
          {!!instance?.id && (
            <ConfirmIconButton
              onConfirm={() => {
                onDeleteClick(instance)
              }}
              color="secondary"
              style={{marginRight: 'auto'}}
            >
              <DeleteOutlined />
            </ConfirmIconButton>
          )}
          <Button
            onClick={onClose}
          >
            Відмінити
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={item===null}
            onClick={save}
          >
            Зберегти
          </Button>
        </DialogActions>
      </Dialog>
  );
}