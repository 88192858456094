import BaseModel from "./BaseModel";
import requests, { IResponse } from "api/requests";
import { start_routes } from "routes";
import { IStartRoute } from "routes/types";

class _AuthModel extends BaseModel {
  key = 'Session';
  private _info:any = null;
  private _permissions:string[] = [];
  private _base_permissions:string[] = [];
  private _if_auth_check = false;
  private _pushToken:string|null = null;

  public firstch_check = ():void => {
    if(!this.if_auth()){
      this._if_auth_check = true;
      this.updateViews();
    }else{
      Promise.all([
        requests.get('/auth/info/'),
        requests.get('/auth/permission/')
      ]).then(values => {
        const [info, permissions] = values;
        
        this._info = info.body;
        this._permissions = permissions.body.all;
        this._base_permissions = permissions.body.base;
        
        this._if_auth_check = true;
        this.updateViews();
      })
      
    }
  }

  get info():any {
    return this._info;
  }

  get permissions():string[] {
    return this._permissions;
  }

  get base_permissions():string[] {
    return this._base_permissions;
  }

  get start_routes():IStartRoute[] {
    return start_routes.filter((r)=>this.check_all_permissions(r.permissions));
  }

  public _check_all_permissions = (permissions_list: string[], codes: string[]):boolean => {
    // Проверяет все запрашиваемые права
    let filtered_codes = codes.filter((c)=>permissions_list.indexOf(c) > -1);
    return codes.length === filtered_codes.length;
  }

  public _check_any_permissions = (permissions_list: string[], codes: string[]):boolean => {
    // Проверяет хотя бы одно право
    let filtered_codes = codes.filter((c)=>permissions_list.indexOf(c) > -1);
    return filtered_codes.length > 0;
  }

  public check_all_permissions = (codes: string[]):boolean => {
    return this._check_all_permissions(this.permissions, codes);
  }

  public check_any_permissions = (codes: string[]):boolean => {
    return this._check_any_permissions(this.permissions, codes);
  }

  public check_all_base_permissions = (codes: string[]):boolean => {
    return this._check_all_permissions(this.base_permissions, codes);
  }

  public check_any_base_permissions = (codes: string[]):boolean => {
    return this._check_any_permissions(this.base_permissions, codes);
  }

  public if_first_check = ():boolean => {
    return this._if_auth_check;
  }
  public if_auth = ():boolean => {
    return !!this.session()
  }
  public login = (session:string):void => {
    localStorage.setItem(this.key, session);
    this.firstch_check();
  }
  public session = ():string|null => {
    return localStorage.getItem(this.key);
  }
  public logout = ():Promise<any> => {
    localStorage.removeItem(this.key);
    this.updateViews();
    return requests.post('/auth/logout/');
  }

  public auth = (email:string, password:string):Promise<IResponse> => {
    return requests.post('/auth/session/', {
      email: email,
      password: password,
      push_token: this.pushToken
    }).then((r) => {
      this.login(r.body?.session);
      return r;
    })
  }

  get pushToken():string|null {
    return this._pushToken;
  }

  public setPushToken = (token:string|null) => {
    this._pushToken = token;
    if(token!==null && this.if_auth()){
      requests.post('/auth/pushtoken/', {
        token: token
      })
    }
  }
}


const AuthModel = new _AuthModel();
export default AuthModel;