import { ListItem, ListItemProps, Paper, styled } from "@mui/material";


export const PaperBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));


interface DraggableListItemProps extends ListItemProps {
  draggable?: boolean;
}

export const DraggableListItem = styled(ListItem, {
  shouldForwardProp: (prop) => !['draggable'].includes(prop.toString()),
})<DraggableListItemProps>(({ theme, draggable }) => ({
  ...(draggable && {
    background: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius
  }),
}));