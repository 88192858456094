import React, { useState } from 'react';
import { Button, FormControl, Dialog, DialogContent, DialogActions, IconButton, Popover, Grid } from '@mui/material';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import { IDriverRequest } from '../types';
import { FORM } from '../config';
import { FIELDS } from '../fields';
import { DeleteOutlined } from '@mui/icons-material';
import InstanceModalInfo from './info';
import RequestStatus from './status';
import { IDriverRequestStatus } from './status/types';

interface InstanceModalProps {
  instance: IDriverRequest;
  onClose?: ()=>void;
  onSave?: (instance:IDriverRequest)=>void;
  onDelete?: (instance:IDriverRequest)=>void;
  onStatusChange?: ()=>void;
}

export default function InstanceModal(props:InstanceModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [instance, setInstance] = useState({...props.instance});
  const [newStatusInstance, setNewStatusInstance] = useState<IDriverRequestStatus|null>(null);
  const [newStatusDialog, setNewStatusDialog] = useState(false);
  const [ deleteConfirmEl, setDeleteConfirmEl] = React.useState<HTMLButtonElement | null>(null);

  const {
    onSave,
    onDelete,
    onClose,
    onStatusChange
  } = props;

  const handleChange = (name:keyof IDriverRequest) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:keyof IDriverRequest) => (value:any) => {
    instance[name] = value as never;
    setInstance({...instance});
  }

  const remove = () => {
    requests.delete(`/admin/driver/request/${instance.id}/`).then((r) => {
      enqueueSnackbar("Видалено", {variant: 'success'});
      if(onDelete){
        onDelete(instance);
      }
    })
  }

  const update = (forceNewStatusInstance?:IDriverRequestStatus|null) => {
    let _newStatusInstance = forceNewStatusInstance;
    if(_newStatusInstance === undefined){
      _newStatusInstance = newStatusInstance;
    }

    if(instance.id && forceNewStatusInstance === undefined && _newStatusInstance !== null){
      setNewStatusDialog(true);
      return;
    }
    let _data:IDriverRequest = {
      type: instance.type,
      full_name: instance.full_name,
      phone: instance.phone,
    };
    FORM[instance.type].fields.map((field_name) => {
      _data[field_name] = instance[field_name] as never;
      return field_name;
    });
    
    let url = `/admin/driver/request/`;
    var method = requests.post;
    if(instance.id){
      url = `/admin/driver/request/${instance.id}/`;
      method = requests.patch;
    }else{
      _data.source = window.location.hostname;
    }

    method(url, _data).then((r) => {
      const data = r.body;
      const _onSave = () => {
        enqueueSnackbar("Збережено", {variant: 'success'});
        if(onSave){
          onSave(data);
        }
      }

      if(!!_newStatusInstance){
        addNewStatus({
          ..._newStatusInstance,
          request: data.id
        }).then(()=>{
          setNewStatusInstance(null);
          _onSave();
        })
      }else{
        _onSave()
      }
    });
    
  }

  const addNewStatus = (statusInstance:IDriverRequestStatus):Promise<any> => {
    return requests.post('/admin/driver/request/status/', statusInstance).then((r) => {
      enqueueSnackbar("Статус додано", {variant: 'success'});
      return r.body;
    })
  }

  return (
    <React.Fragment>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="sm"
        onClose={onClose}
        
      >
        <DialogContent
          dividers={true}
        >

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {FIELDS.type.field(instance.type, handleChange('type'))}
              </FormControl>
            </Grid>
            {FORM[instance.type].fields.map((field_name) => {
              const field = FIELDS[field_name].field(instance[field_name], handleChange(field_name));
              if(!field) return null
              return (
                <Grid key={field_name} item xs={12} md={6}>
                  <FormControl fullWidth key={field_name}>
                    {field}
                  </FormControl>
                </Grid>
              )
            })}
          </Grid>

          {instance.id ? (
            <InstanceModalInfo
              instance={instance}
            />
          ):null}

          <RequestStatus
            requestID={instance.id}
            onStatusChange={()=>{
              if(onStatusChange){
                onStatusChange()
              }
            }}

            newStatusInstance={newStatusInstance}
            onChangeNewStatusInstance={setNewStatusInstance}
            addNewStatus={addNewStatus}
          />
          
        </DialogContent>
        <DialogActions>
          {instance.id ? (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setDeleteConfirmEl(event.currentTarget);
              }}
              color="secondary"
              style={{marginRight: 'auto'}}
            >
              <DeleteOutlined />
            </IconButton>
          ):null}
          <Button
            onClick={onClose}
          >
            Відмінити
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e:any) => {
              e.preventDefault();
              update();
            }}
          >
            Зберегти
          </Button>
        </DialogActions>
        
        <Popover
          open={!!deleteConfirmEl}
          anchorEl={deleteConfirmEl}
          onClose={()=>setDeleteConfirmEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Button
            color="primary"
            onClick={()=>{
              remove();
              setDeleteConfirmEl(null);
            }}
          >Підтвердити</Button>
        </Popover>
      </Dialog>

      <Dialog
        open={newStatusDialog}
        maxWidth="xs"
        onClose={()=>{setNewStatusDialog(false)}}
        
      >
        <DialogContent>
          Ви змінили статус але не додали його. Додати вказаний статус до даного запиту?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e:any) => {
              e.preventDefault();
              setNewStatusDialog(false);
            }}
          >
            Відмінити
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e:any) => {
              e.preventDefault();
              setNewStatusDialog(false);
              update(null);
            }}
          >
            Ні
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e:any) => {
              e.preventDefault();
              setNewStatusDialog(false);

              if(newStatusInstance !== null){
                update(newStatusInstance);
              }
            }}
          >
            Так
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}