import { Stack, styled, Switch, switchClasses, Typography } from "@mui/material";
import { FilteredTableFilterProps } from "components/FilteredTable/types";

const StyledSwitch = styled(Switch)((theme)=>({
  [`& .${switchClasses.track}`]: {
    opacity: '0.3 !important'
  }
}))

export default function ArchiveFilter(props:FilteredTableFilterProps){
  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      <Typography>Активні</Typography>
      <StyledSwitch
        color="default"
        onChange={(e)=>{
          props.onChange(e.target.checked);
        }}
        checked={!!props.value}
      />
      <Typography>Архів</Typography>
    </Stack>
  )
}