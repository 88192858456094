import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { ExitToAppOutlined, PersonOutlineOutlined, LockOpenOutlined, WidgetsOutlined } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import AuthModel from 'models/AuthModel';
import { Menu, MenuItem } from '@mui/material';
import PasswordDialog from './PasswordDialod';
import { Link } from 'react-router-dom';

export default function ToolbarMenu(props:any) {
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [anchorAccountMenu, setAnchorAccountMenu] = React.useState<null | HTMLElement>(null);
  const [anchorServicesMenu, setAnchorServicesMenu] = React.useState<null | HTMLElement>(null);

  return (
    <div>
      { AuthModel.start_routes.length > 1 && (
        <IconButton
          color="inherit"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            setAnchorServicesMenu(e.currentTarget);
          }}
        >
          <WidgetsOutlined />
        </IconButton>
      )}

      <IconButton
        color="inherit"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setAnchorAccountMenu(e.currentTarget);
        }}
      >
        <PersonOutlineOutlined />
      </IconButton>
      
      { AuthModel.start_routes.length > 1 && (
        <Menu
          anchorEl={anchorServicesMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          open={Boolean(anchorServicesMenu)}
          onClose={() => {
            setAnchorServicesMenu(null);
          }}
        >
          {AuthModel.start_routes.map((start_route, index) => {
            if(start_route.firstPage === undefined){
              return "";
            }
            return (
                <MenuItem
                  key={`start_route-${index}`}
                  component={Link}
                  to={start_route.firstPage.path}
                >
                  <ListItemIcon>
                    <start_route.icon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">{start_route.name}</Typography>
                </MenuItem>
            )
          })}
        </Menu>
      )}

      <Menu
        anchorEl={anchorAccountMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorAccountMenu)}
        onClose={() => {
          setAnchorAccountMenu(null);
        }}
      >
          <MenuItem
            onClick={()=>{
              setPasswordDialog(true);
              setAnchorAccountMenu(null);
            }}
          >
            <ListItemIcon>
              <LockOpenOutlined fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Змінити пароль</Typography>
          </MenuItem>
          <MenuItem
            onClick={()=>{
              AuthModel.logout();
              setAnchorAccountMenu(null);
            }}
          >
            <ListItemIcon>
              <ExitToAppOutlined fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Вийти</Typography>
          </MenuItem>
      </Menu>
      
      { passwordDialog && (
        <PasswordDialog
          onClose={()=>{ setPasswordDialog(false) }}
          onDone={()=>{ setPasswordDialog(false) }}
        />
      )}
    </div>
  );
}