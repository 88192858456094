import React, { useMemo } from 'react';
import { Divider, Grid, styled, Typography } from '@mui/material';
import { ICompanyRequest } from '../types';
import { FieldList } from '../config';
import { FIELDS } from '../fields';


const SpacingGrid = styled(Grid)(({ theme }) => ({
  marginTop: '0 !important',
  marginBottom: '0 !important',
  "& >div": {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  }
}));

const DividerBox = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
}));

interface IProps {
  instance: ICompanyRequest;
}

const fields:FieldList = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'referrer',
  'source'
]

export default function InstanceModalInfo(props:IProps) {
  const {
    instance
  } = props;

  const values = useMemo(()=>{
    let v = fields.map((field_name) => {
      const value = instance[field_name];
      return {
        title: FIELDS[field_name].title,
        value: value || null
      };
    })
    return v.filter((value)=>value.value!==null)
  }, [instance])

  if(!values.length) return null;

  return (
    <React.Fragment>
      <DividerBox/>
      <SpacingGrid container spacing={3}>
        {values.map((value, index)=>{
          return (
            <Grid key={index} item xs={12} md={6}>
              <Typography variant="caption" color="textSecondary">{value.title}:</Typography> {value.value}
            </Grid>
          )
        })}
      </SpacingGrid>
    </React.Fragment>
  );
}