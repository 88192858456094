import { TextField } from "@mui/material";
import { MaskedField } from "components/fields";
import React from "react";
import { ICompanyRequest } from "./types";

type IFields = { [type in (keyof ICompanyRequest)] : {
  title: string;
  field: (v:any, onChange:(v:any)=>void)=>JSX.Element|null
}}

export const FIELDS:IFields = {
  id: {
    title: 'ID',
    field: (v, onChange) => null
  },
  created: {
    title: 'Дата створення',
    field: (v, onChange) => null
  },
  updated: {
    title: 'Дата останнього оновлення',
    field: (v, onChange) => null
  },
  name: {
    title: 'Назва',
    field: (v, onChange) => (
      <TextField
      label={`${FIELDS.name.title}*`}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  phone: {
    title: 'Телефон',
    field: (v, onChange) => (
      <MaskedField
        label={`${FIELDS.phone.title}`}
        mask="+38 (099) 999 99 99"
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  email: {
    title: 'E-mail',
    field: (v, onChange) => (
      <TextField
      label={FIELDS.email.title}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  message: {
    title: 'Повідомлення',
    field: (v, onChange) => (
      <TextField
        label={FIELDS.message.title}
        multiline
        maxRows={3}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  utm_source: {
    title: 'utm_source',
    field: (v, onChange) => null
  },
  utm_medium: {
    title: 'utm_medium',
    field: (v, onChange) => null
  },
  utm_campaign: {
    title: 'utm_campaign',
    field: (v, onChange) => null
  },
  referrer: {
    title: 'referrer',
    field: (v, onChange) => null
  },
  source: {
    title: 'Джерело',
    field: (v, onChange) => null
  },
}