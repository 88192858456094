import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { DefinedRange, DateRange } from "../types";
import { isSameRange } from "../utils";

type DefinedRangesProps = {
	setRange: (range: DateRange) => void;
	selectedRange: DateRange;
	ranges: DefinedRange[];
};

const DefinedRanges: React.FunctionComponent<DefinedRangesProps> = props => {
	return (
		<List>
			{props.ranges.map((range, idx) => (
				<ListItem button key={idx} onClick={() => props.setRange(range)}>
					<ListItemText
						primaryTypographyProps={{
							variant: "body2",
							style: {
								fontWeight: isSameRange(range, props.selectedRange)
									? "bold"
									: "normal"
							}
						}}>
						{range.label}
					</ListItemText>
				</ListItem>
			))}
		</List>
	);
};

export default DefinedRanges;
