import React, { useState, useCallback, useEffect } from 'react';
import requests from 'api/requests';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Hidden, TextField, InputAdornment, TablePagination, FormControl, FormControlLabel, Switch, Select, MenuItem } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { IMapPOI } from './types';
import Row from './row';
import { TableCellBorderRight } from './styles';
import { PaginationGridBox, TablePaperBox } from 'components/styled';

export default function MapPOI() {
  const [regionList, setRetionList] = useState([]);
  const [dictLoaded, setDictLoaded] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [problemFilter, setProblemFilter] = useState(false);
  const [regionFilter, setRegionFilter] = useState('');
  const [page, setPage] = useState({
    items: [] as IMapPOI[],
    items_count: 0,
    page: 0,
    limit: 10,
  })

  const filters = useCallback(() => {
    let new_filter:any = {};
    if(searchString){
      new_filter.search = searchString;
    }
    if(problemFilter){
      new_filter.problem = true;
    }
    if(regionFilter){
      new_filter.region = regionFilter;
    }
    return new_filter;
  }, [searchString, problemFilter, regionFilter])

  const load = useCallback(() => {
    const abortController = new AbortController();
    let timer = setTimeout(() => {
      window.scrollTo(0, 0);

      requests.get('/settings/map/poi/', {
        ...filters(),
        limit: page.limit,
        offset: page.page * page.limit,
      }, abortController.signal).then((r) => {
        setPage(page => ({
          ...page,
          items: r.body.results,
          items_count: r.body.count
        }));
      })
    }, 500)

    

    return () => {
      clearTimeout(timer);
      abortController.abort();
    };
  }, [page.page, page.limit, filters])
  useEffect(() => load(), [load]);

  const dict_load = useCallback(() => {
    requests.get('/settings/map/dict/region/').then((r) => {
      setRetionList(r.body);
      setDictLoaded(true);
    })
  }, [])
  useEffect(() => dict_load(), [dict_load]);

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  if(!dictLoaded){
    return null;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} xl={6}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  placeholder="Пошук"
                  value={ searchString }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchString(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined color={'action'} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <Select
                  value={regionFilter}
                  displayEmpty
                  onChange={(e: any) => {
                    setRegionFilter(e.target.value);
                  }}
                >
                  <MenuItem value="">Всі регіони</MenuItem>
                  {regionList.map((i:any) => (
                    <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={problemFilter}
                    color="primary"
                    onChange={(e:any) => {
                      setProblemFilter(e.target.checked)
                    }}
                  />
                )}
                label="Проблемні"
              />
            </Grid>
          </Grid>
          
        </Grid>
        <Hidden mdDown>
          <PaginationGridBox item sm={6} md={4} xl={6}>
            { renderPagination() }
          </PaginationGridBox>
        </Hidden>
      </Grid>
      <TablePaperBox>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{width: 1}}></TableCell>
              <TableCellBorderRight colSpan={3} align='center'>Назва</TableCellBorderRight>
              <TableCell colSpan={3} align='center'>Коментар</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Українська</TableCell>
              <TableCell>Русский</TableCell>
              <TableCellBorderRight>English</TableCellBorderRight>
              <TableCell>Українська</TableCell>
              <TableCell>Русский</TableCell>
              <TableCell>English</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.items.map((item, index) => (
              <Row
                key={item.id}
                item={item}
              />
            ))}
          </TableBody>
        </Table>
      </TablePaperBox>
      { renderPagination() }
    </div>
  );
}