import React from 'react';
import { AddressListOL } from './styles';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { PAYMENT_TYPE } from 'config';

interface IProps{
  items: any;
  hiddenCommissionDiscount:boolean;
}

export default function PrintTable(props: IProps) {
  const {
    items,
    hiddenCommissionDiscount
  } = props;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>№ Зам.</th>
            <th>Дата</th>
            <th>Час</th>
            <th>Позивний</th>
            <th>Клас</th>
            <th>Тариф, грн</th>
            <th>Джокер, грн</th>
            <th>Очік., грн</th>
            <th>Інші посл., грн</th>
            <th>Чайові, грн</th>
            <th>Комісія, грн</th>
            {!hiddenCommissionDiscount ? (
              <th>Знижка за брендування, грн</th>
            ):null}
            <th>Всього, грн</th>
            <th>Оплата</th>
            <th>Адреса</th>
            <th>Замітки</th>
          </tr>
        </thead>
        <tbody>
          { items.map((item:any, index:number) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>
                <Moment
                  date={item.date}
                  format="DD.MM.YY"
                />
              </td>
              <td>
                <Moment
                  date={item.date}
                  format="HH:mm"
                />
              </td>
              <td>{item.callsign}</td>
              <td>{item.tariff_class}</td>
              <td align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={' '} /></td>
              <td align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.joker} displayType={'text'} thousandSeparator={' '} /></td>
              <td align="right" style={{whiteSpace: 'nowrap'}}>
                {item.wait_minutes_price ? (
                  <NumberFormat value={item.wait_minutes_price} displayType={'text'} thousandSeparator={' '} />
                ) : ''}
              </td>
              <td align="right">
                {item.additions_price ? (
                  <NumberFormat value={item.additions_price} displayType={'text'} thousandSeparator={' '} />
                ) : ''}
              </td>
              <td align="right">
                {item.tip ? (
                  <NumberFormat value={item.tip} displayType={'text'} thousandSeparator={' '} />
                ) : ''}
              </td>
              <td><NumberFormat value={item.commission} displayType={'text'} thousandSeparator={' '} /></td>

              {!hiddenCommissionDiscount ? (
                <td><NumberFormat value={item.commission_discount} displayType={'text'} thousandSeparator={' '} /></td>
              ):null}
              <td><NumberFormat value={item.price + (item.joker || 0)} displayType={'text'} thousandSeparator={' '} /></td>
              <td>{PAYMENT_TYPE[item.payment_type as keyof typeof PAYMENT_TYPE]}</td>
              <td>
                <AddressListOL>
                  {item.addresses.map((address:string, index:number) => (
                    <li key={index}>{address}</li>
                  ))}
                </AddressListOL>
              </td>
              <td>{item.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}