import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import requests from 'api/requests';
import { AddOutlined } from '@mui/icons-material';
import InstanceModal from './instanceModal';
import { IDriverRequest, IExtendData } from './types';
import { IStatus } from 'pages/settings/statuses/base/types';
import FilteredTable from 'components/FilteredTable';
import columns from './columns';
import filters from './filters';


const DEFAULT_INSTANCE:IDriverRequest = {
  type: "OWN_CAR",
  full_name: "",
  phone: "",
}

export default function DriverRequest() {
  const tableRef = useRef(null)
  const [instance, setInstance] = useState<null|IDriverRequest>(null);
  const [extendData, setExtendData] = useState<IExtendData|undefined>(undefined);

  useEffect(()=>{
    Promise.all([
      requests.get('/settings/statuses/driver-request/').then((r) => r.body),
    ]).then((r)=>{
      const _statuses = r[0];
      let _statusDict = {} as any;
      _statuses.map((status:IStatus)=>{
        if(status.id !== undefined){
          _statusDict[status.id] = status;
        }
        return status;
      })
      
      setExtendData({
        statuses: _statuses,
        statusDict: _statusDict,
      })
    })
    
  }, [])

  return (
    <div>
      <FilteredTable
        dataURL="/admin/driver/request/"
        columns={columns}
        filters={filters}
        userSettingsKey="driver-requests"
        onRowClick={setInstance}
        extendData={extendData}
        tableRef={tableRef}
        leftSide={(
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddOutlined />}
            onClick={()=>{
              setInstance(DEFAULT_INSTANCE)
            }}
          >
            Додати
          </Button>
        )}
      />
      { instance !== null ? (
        <InstanceModal
          instance={instance}
          onClose={()=>setInstance(null)}
          onDelete={()=>{
            setInstance(null);
            (tableRef.current as any).reload();
          }}
          onSave={()=>{
            setInstance(null);
            (tableRef.current as any).reload();
          }}
          onStatusChange={()=>{
            (tableRef.current as any).reload();
          }}
        />
      ): null }
    </div>
  )
}