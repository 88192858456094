
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import FinedOrderDriver from 'components/finedOrderDriver';


export default function FinedOrders() {
  const match:any = useRouteMatch();
  const driver = match.params.id;

  return (
    <FinedOrderDriver
      listURL="/driver/fined-order/"
      identificatorID={driver}
      identificatorName="driver"
    />
  )
}