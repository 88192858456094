import React from 'react';
import BaseStatusDict from './base';

export default function WebcabRatingStatus() {

  return (
    <BaseStatusDict
      apiUrl="/settings/statuses/webcab-rating/"
      extraFields={[
        {
          title: "За замовченням для рейтингів",
          helpText: "Через кому. Застосовується виключно у випадку якщо вудгук порожній",
          field_name: "default_for_rating",
          setValueHook: (value)=>value || null,
        }
      ]}
    />
  );
}