import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import requests from 'api/requests';
import { IMainLayoutProps } from 'layout/main/types';
import { LockOutlined } from '@mui/icons-material';
import AuthModel from 'models/AuthModel';
import { Box, Button } from '@mui/material';


export default function ATPRedirect(props:IMainLayoutProps) {
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  
  const load_items = useCallback(() => {
    requests.get('/atp/atp/', {limit: 1, removed: false}).then((r) => {
      const items = r.body;
      if(items.count){
        const new_url = props.startRoute.start_path.replace("/:id", `/${items.results[0].id}`);
        history.push(`${new_url}/`);
      }else{
        setLoading(false);
      }
    })
  }, [history, props.startRoute.start_path])

  useEffect(() => load_items(), [load_items]);

  if(loading){
    return "";
  }

  return (
    <Box textAlign="center">
      <Typography variant="h1" color="textSecondary">
        <LockOutlined fontSize="large" />
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Не має доступу до кабінету АТП
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {AuthModel.info.email}
      </Typography>
      <Button
        onClick={()=>{
          AuthModel.logout();
        }}
      >Вийти</Button>
    </Box>
  );
}
