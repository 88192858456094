import { ReceiptOutlined, BusinessOutlined, CompareArrowsOutlined, NewReleasesOutlined } from '@mui/icons-material';
import CollateBalance from 'pages/adminCorp/collate/collateBalance';
import CollateOrder from 'pages/adminCorp/collate/collateOrder';
import Companies from 'pages/adminCorp/companies';
import CompanyRequest from 'pages/adminCorp/companyRequest';
import CompanyRequestEmails from 'pages/adminCorp/companyRequest/companyRequestEmails';
import Invoices from 'pages/adminCorp/invoices';
import { IMenuCollapse, IMenuRoute } from './types';

export const routes__corp_admin = {
  invoices: "/",
  companies: "/companies/",
  collate_order: "/collate/order/",
  collate_balance: "/collate/balance/",
  company_request: "/company-request/",
  company_request_emails: "/company-request/emails/",
}

export const menu_routes__corp_admin:(IMenuRoute|IMenuCollapse)[] = [
  {
    path: routes__corp_admin.invoices,
    name: "Рахунки всіх компаній",
    component: Invoices,
    icon: ReceiptOutlined,
  },
  {
    path: routes__corp_admin.companies,
    name: "Компанії",
    component: Companies,
    icon: BusinessOutlined,
  },

  {
    name: "Звірка",
    icon: CompareArrowsOutlined,
    items: [
      {
        path: routes__corp_admin.collate_order,
        name: "По замовленнях",
        component: CollateOrder,
      },
      {
        path: routes__corp_admin.collate_balance,
        name: "По балансу",
        component: CollateBalance,
      },
    ]
  } as IMenuCollapse,
  {
    name: "Заявки компаній",
    icon: NewReleasesOutlined,
    items: [
      {
        path: routes__corp_admin.company_request,
        name: "Заявки",
        component: CompanyRequest,
      },
      {
        path: routes__corp_admin.company_request_emails,
        name: "E-mail для повідомлень",
        component: CompanyRequestEmails,
      },
    ]
  } as IMenuCollapse,
];