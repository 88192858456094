import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { IATPRequest } from './types';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

interface IInstanceRow {
  instance: IATPRequest;
  onClick: () => void;
}

export default function InstanceRow(props:IInstanceRow) {

  const {
    instance,
    onClick
  } = props

  const renderPhone = (number:string|null) => {
    if(!number) return "";

    return (
      <NumberFormat value={number} displayType={'text'} format="+## (###) ### ## ##" />
    )
  }

  return (
    <TableRow hover onClick={onClick} sx={{cursor: 'pointer'}}>
      <TableCell style={{whiteSpace: 'nowrap', width: 1}}>
        <Moment
          date={instance.created}
          format="DD.MM.YY HH:mm"
        />
      </TableCell>
      <TableCell>{instance.full_name}</TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{renderPhone(instance.phone)}</TableCell>
      <TableCell>{instance.car_count}</TableCell>
      <TableCell>{instance.source}</TableCell>
      <TableCell>{instance.utm_source}</TableCell>
    </TableRow>
  );
}