import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, FormControl, TextField, Typography } from '@mui/material';
import CurrencyFormatToField from 'components/CurrencyFormatToField';
import requests from 'api/requests';
import { IInvoice } from 'pages/adminCorp/invoices/types';
import NumberFormat from 'react-number-format';
import { ICompany } from './types';
import { useProgress } from 'components/progress';

interface ICreateInvoiceComponent {
  company: ICompany;
  onClose: () => void;
  onCreated: (data:IInvoice) => void;
}

export default function CreateInvoice(props:ICreateInvoiceComponent) {
  const { isProgress } = useProgress();
  const [cost, setCost] = useState(1000);

  const Create = () => {
    requests.post(`/corp/invoice/`, {
        "cost": cost,
        "company": props.company.id
    }).then((r) => {
      props.onCreated(r.body);
    })
  }

  const number_cost = ((cost || 0) - 0)
  const dopsum = number_cost*props.company.real_percent/100.0;
  const total = number_cost + dopsum;

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="xs"
      scroll={'paper'}
      onClose={props.onClose}
    >
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          Create();
        }}
      >
        <DialogContent dividers={true}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              label="Сумма, грн."
              margin="normal"
              value={cost}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputComponent: CurrencyFormatToField as any,
              }}
              onChange={(e:any) => {
                setCost(e.target.value);
              }}
            />
            {dopsum > 0 && (
              <Typography variant="caption">
                Послуги з організації перевезень:&nbsp;
                <NumberFormat
                  value={dopsum}
                  displayType={'text'}
                  thousandSeparator={' '}
                  suffix=" грн."
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              </Typography>
            )}
            {dopsum > 0 && (
              <Typography variant="caption"><b>
                Всього:&nbsp;
                <NumberFormat
                  value={total}
                  displayType={'text'}
                  thousandSeparator={' '}
                  suffix=" грн."
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              </b></Typography>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
              onClick={props.onClose}
              disabled={isProgress}
            >
            Відмінити
          </Button>
          <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!cost || cost <= 0 || isProgress}
            >
            Створити
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}