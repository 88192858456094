
import OnlineTotalBillBase from "./base";

export default function OnlineTotalBill(){
  

  return (
    <OnlineTotalBillBase
      apiURL="/finance/online-total-bill/"
      dateFilters={[
        {
          title: "Перше закриття замовлення",
          field: "getresultdatetime",
        },
        {
          title: "З урахуванням редагування",
          field: "lastfolderchangedatetime",
        },
      ]}
    />
  )
}