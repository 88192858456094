import { IMenuCollapse, IMenuRoute } from './types';
import { AccountBoxOutlined, DirectionsCarOutlined, MapOutlined } from '@mui/icons-material';
import MapPage from 'pages/admin/mapPage';
import Drivers from 'pages/admin/drivers';
import Cars from 'pages/admin/cars';

export const routes__lawyer = {
  map: "/",
  drivers: "/drivers/",
  cars: "/cars/"
}

export const menu_routes__lawyer:(IMenuRoute|IMenuCollapse)[] = [
  {
    path: routes__lawyer.map,
    name: "Карта",
    component: MapPage,
    icon: MapOutlined,
  },
  {
    path: routes__lawyer.drivers,
    name: "Водії",
    component: Drivers,
    icon: AccountBoxOutlined,
  },
  {
    path: routes__lawyer.cars,
    name: "Автопарк",
    component: Cars,
    icon: DirectionsCarOutlined,
  },
];