import React, { useState, useCallback, useEffect } from 'react';
import { Grid, TextField, InputAdornment, Hidden, FormControl, Select, MenuItem } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import requests from 'api/requests';
import CompaniesTable from './table';
import CompaniesTablePagination from './pagination';
import { ICompany } from './types';
import Company from './company';
import { PaginationGridBox, TablePaperBox } from 'components/styled';


export default function Companies() {
  const [searchString, setSearchString] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [company, setCompany] = useState<ICompany|null>(null);
  const [page, setPage] = useState({
    first_loaded: false,
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })

  const filters = useCallback(() => {
    let new_filter:any = {
    };
    if(searchString){
      new_filter.search = searchString;
    }
    if(statusFilter === 'alarm'){
      new_filter.alarm=true;
    }
    if(statusFilter === 'lock'){
      new_filter.lock=true;
    }
    return new_filter;
  }, [searchString, statusFilter])

  const load = useCallback(() => {
    window.scrollTo(0, 0);
    
    requests.get('/corp/company/', {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit
    }).then((r) => {
      setPage(page => ({
        ...page,
        first_loaded: true,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [page.page, page.limit, filters])
  useEffect(() => load(), [load]);

  return (
    <div>
      { page.first_loaded && (
        <div>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <TextField
                    fullWidth
                    placeholder="Пошук"
                    value={ searchString }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined color={'action'} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormControl fullWidth>
                    <Select
                      value={statusFilter}
                      displayEmpty
                      onChange={(e: any) => {
                        setStatusFilter(e.target.value);
                      }}
                    >
                      <MenuItem value={''}>Всі</MenuItem>
                      <MenuItem value={'alarm'}>Попередження</MenuItem>
                      <MenuItem value={'lock'}>Заблоковані</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <PaginationGridBox item sm={4}>
                <CompaniesTablePagination
                  count={page.items_count}
                  limit={page.limit}
                  page={page.page}
                  onChange={(page_number:number) => {
                    setPage({
                      ...page,
                      page: page_number
                    });
                  }}
                />
              </PaginationGridBox>
            </Hidden>
          </Grid>
          <TablePaperBox>
            <CompaniesTable
              items={page.items}
              onClick={(item:ICompany) => {
                setCompany(item);
              }}
              showCompanyColumn
            />
          </TablePaperBox>
          <CompaniesTablePagination
            count={page.items_count}
            limit={page.limit}
            page={page.page}
            onChange={(page_number:number) => {
              setPage({
                ...page,
                page: page_number
              });
            }}
          />
        </div>
      )}

      { company && (
        <Company
          company={company}
          onSave={() => {
            setCompany(null);
            load();
          }}
          onClose={() => {
            setCompany(null);
          }}
        />
      )}
    </div>
  );
}