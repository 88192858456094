import { containerClasses, DialogTitle, paperClasses, styled } from "@mui/material";
import AdminPage from "components/adminPage";

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export const StyledAdminPage = styled(AdminPage)(({ theme }) => ({
  [`& .${containerClasses.root}`]: {
    maxWidth: 'none',
    padding: 0
  },
  [`& .${paperClasses.root}`]: {
    padding: 0,
    boxShadow: 'none'
  }
}));