export default class BaseModel {
  protected views: React.Component[] = [];

  subscribe(view: React.Component) {
      this.views.push(view);
      view.forceUpdate();
  }

  unsubscribe(view: React.Component) {
      this.views = this.views.filter((item: React.Component) => item !== view);
  }

  protected updateViews() {
      this.views.forEach((view: React.Component) => view.forceUpdate())
  }
}