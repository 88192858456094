import React from "react";
import moment from 'moment';
import { Icon, Link, Typography } from "@mui/material";
import { FilteredTableColumn } from "components/FilteredTable/types";
import { IParking } from "./types";
import NumberFormat from "react-number-format";
import OrderIDLink from "components/OrderIDLink";

const columns: FilteredTableColumn[] = [
  {
    title: 'Авто',
    items: [
      {
        title: 'Авто',
        render: (row:IParking) => row.car.label,
        style: {
          whiteSpace: 'nowrap'
        },
      }
    ]
  },
  {
    title: 'Час',
    items: [
      {
        title: 'Початок',
        render: (row:IParking) => (
          <Typography variant='caption' color='textSecondary'>
            {moment(row.start).format("DD.MM.YY HH:mm")}
          </Typography>
        ),
        style: {
          whiteSpace: 'nowrap'
        }
      },
      {
        title: 'Кінець',
        render: (row:IParking) => (
          <Typography variant='caption' color='textSecondary'>
            {moment(row.end).format("DD.MM.YY HH:mm")}
          </Typography>
        ),
        style: {
          whiteSpace: 'nowrap'
        }
      },
    ]
  },
  {
    title: 'Тривалість',
    items: [
      {
        title: 'Тривалість',
        render: (row:IParking) => row.duration
      }
    ]
  },
  {
    title: 'Адреса',
    items: [
      {
        title: 'Адреса',
        render: (row:IParking) => (
          <Link
            href={`https://www.google.com/maps/?q=${row.point.coordinates[0]},${row.point.coordinates[1]}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.address}
            <sup><Icon fontSize="inherit">open_in_new</Icon></sup>
          </Link>
        )
      }
    ]
  },
  {
    title: 'Замовлення',
    items: [
      {
        title: 'Замовлення',
        render: (row:IParking) => (
          <div>
            {row.orders.map((i)=>(
              <div key={i}>
                <Typography variant='caption' color='textSecondary'>
                  <OrderIDLink OrderID={i} />
                </Typography>
              </div>
            ))}
          </div>
        )
      }
    ]
  },
  {
    title: 'Замовлення поряд',
    items: [
      {
        title: 'Замовлення поряд',
        render: (row:IParking) => (
          <div>
            {row.near_orders.map((i)=>(
              <div key={i}>
                <Typography variant='caption' color='textSecondary'>
                  <OrderIDLink OrderID={i} />
                </Typography>
              </div>
            ))}
          </div>
        )
      }
    ]
  },
  {
    title: 'На зміні',
    items: [
      {
        title: 'На зміні',
        render: (row:IParking) => {
          if(row.working_percent === 0) return null;
          
          return (
            <div>
              {row.working_time}&nbsp;
              <Typography variant='caption' color='textSecondary'>
                ({row.working_percent}%)
              </Typography>
            </div>
          )
        }
      }
    ]
  },
  {
    title: 'Км. після',
    items: [
      {
        title: 'Км. після',
        align: 'right',
        render: (row:IParking) => {
          if(row.distance_after === 0) return null;
          return (
            <NumberFormat value={row.distance_after} displayType={'text'} thousandSeparator={' '} decimalScale={3} fixedDecimalScale={true} />
          )
        }
      }
    ]
  },
];

export default columns;