import React, { useState, useCallback } from 'react';
import { LinearProgress, styled } from '@mui/material';
import ProgressContext from './ProgressContext';
import { ProviderContext } from './types';

const LoadingBox = styled("div")(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: theme.zIndex.drawer * theme.zIndex.drawer,
}));

interface ProgressProviderProps{
  children: React.ReactNode;
}

export default function ProgressProvider(props: ProgressProviderProps) {

  const [showProgress, setShowProgress] = useState(false);
  
  const show = useCallback(() => {
    setShowProgress(true);
  }, [])
  const hide = useCallback(() => {
    setShowProgress(false);
  }, [])

  const context:ProviderContext = {
    showProgress: show,
    hideProgress: hide,
    setProgress: setShowProgress,
    isProgress: showProgress
  }

  return (
    <ProgressContext.Provider value={context}>
        {props.children}
        {showProgress && (
          <LoadingBox>
            <LinearProgress />
          </LoadingBox>
        )}
    </ProgressContext.Provider>
  );
}