const THEME_NAME = process.env['REACT_APP_THEME'] as string;

let _FIREBASE_CONFIG = {
    config: {
        apiKey: "AIzaSyAC7l6xCZ9DOKsKxdaYUK7V7Dmr_ra48Pw",
        authDomain: "my-elit-taxi.firebaseapp.com",
        projectId: "my-elit-taxi",
        storageBucket: "my-elit-taxi.appspot.com",
        messagingSenderId: "634384106657",
        appId: "1:634384106657:web:b47ee74aeddab762ba9ce0"
    },
    vapidKey: 'BPHose0h8hrZ7Q9YuxV8GXBluWKfWaYAX1-uFQ-i4BeOiIyT-W0bJ49roXszrmYAp6kp3AovDcy5dK9701_1Ngg'
}
if(THEME_NAME==='459'){
    _FIREBASE_CONFIG = {
        config: {
            apiKey: "AIzaSyAC7l6xCZ9DOKsKxdaYUK7V7Dmr_ra48Pw",
            authDomain: "my-elit-taxi.firebaseapp.com",
            projectId: "my-elit-taxi",
            storageBucket: "my-elit-taxi.appspot.com",
            messagingSenderId: "634384106657",
            appId: "1:634384106657:web:feb60f12413e47ecba9ce0"
          },
        vapidKey: 'BPHose0h8hrZ7Q9YuxV8GXBluWKfWaYAX1-uFQ-i4BeOiIyT-W0bJ49roXszrmYAp6kp3AovDcy5dK9701_1Ngg'
    }
}
export const FIREBASE_CONFIG = _FIREBASE_CONFIG;