import { IMenuRoute } from './types';
import { ContactlessOutlined, MonetizationOnOutlined, ReceiptOutlined } from '@mui/icons-material';
import Offset from 'pages/finance/offset';
import CAOrders from 'pages/finance/caOrders';
import OnlineTotalBill from 'pages/finance/onlineTotalBill';

export const routes__finance = {
  offset: "/",
  ca_orders: "/ca-orders",
  online_total_bill: "/online-total-bill",
}

export const menu_routes__finance:IMenuRoute[] = [
  {
    path: routes__finance.offset,
    name: "Розрахунок зі службою",
    component: Offset,
    icon: MonetizationOnOutlined,
  },
  {
    path: routes__finance.ca_orders,
    name: "Замовлення з оплатою на Р/Р",
    component: CAOrders,
    icon: ReceiptOutlined,
  },
  {
    path: routes__finance.online_total_bill,
    name: "Онлайн оплата загальний рах.",
    component: OnlineTotalBill,
    icon: ContactlessOutlined,
  },
];