import React, { useEffect, useRef, useState } from 'react';
import FilteredTable from 'components/FilteredTable';
import columns from './columns';
import { IExtendData } from './types';
import requests from 'api/requests';
import { FormControlLabel, Switch } from '@mui/material';



export default function PaymentErrorDirectory() {
  const tableRef = useRef(null)
  const [extendData, setExtendData] = useState<IExtendData|undefined>(undefined);

  useEffect(()=>{
    Promise.all([
      requests.get('/settings/dict/payment-error/side/').then((r) => r.body),
    ]).then((r)=>{
      const side = r[0]
      let sideDict = {} as any;
      side.map((i:any)=>{
        sideDict[i.id] = i;
        return i;
      })
      
      setExtendData({
        side: side,
        sideDict: sideDict,
        tableRef: tableRef,
        editMode: false
      })
    })
    
  }, [])

  return (
    <div>
      <FilteredTable
        dataURL="/settings/dict/payment-error/"
        columns={columns}
        userSettingsKey="directory-payment-error"
        extendData={extendData}
        tableRef={tableRef}
        leftSide={(
          <FormControlLabel
            control={
              <Switch
                checked={extendData?.editMode}
                onChange={(e)=>{
                  setExtendData((d)=>{
                    if(d === undefined) return d;
                    d.editMode = e.target.checked
                    return {...d}
                  })
                }}
                name="editMode"
              />
            }
            label="Режим редагування"
          />
        )}
      />
    </div>
  )
}