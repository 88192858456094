
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import FinedOrderDriver from 'components/finedOrderDriver';


export default function FinedOrders() {
  const match:any = useRouteMatch();
  const atp = match.params.id;

  return (
    <FinedOrderDriver
      listURL="/atp/fined-order/"
      identificatorID={atp}
      identificatorName="atp"
    />
  )
}