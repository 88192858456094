import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import OrdersDriver from 'components/orderDriver';
import requests from 'api/requests';


export default function Orders() {
  const match:any = useRouteMatch();
  const [driver, setDriver] = useState(null as any);

  const load = useCallback(() => {
    requests.getAll(`/base/driver/${match.params.id}/`).then((r) => {
      setDriver(r.body);
    })
  }, [match.params.id])

  useEffect(() => load(), [load]);

  if(driver === null){
    return null;
  }

  return (
    <OrdersDriver
      listURL="/driver/order/"
      identificatorID={match.params.id}
      identificatorName="driver"
      hiddenCommissionDiscount={!driver.call_signs.length}
    />
  )
}