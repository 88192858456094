import React from 'react';
import { Card, Typography, Divider, Box, styled } from '@mui/material';
import { auth_routes } from 'routes';
import { Route } from 'react-router-dom';

const LogoContainer = styled("div")(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(4, 0),
  "img": {
    height: 100,
    maxWidth: 200,
  }
}))

const CardBox = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: "0 auto",
  padding: theme.spacing(2)
}))

interface IProps {
  children: JSX.Element;
}

export default function AuthLayout(props:IProps){
  return (
    <Box sx={{px: 1}}>
      <LogoContainer>
        <img src={ process.env.PUBLIC_URL + '/img/logo.svg' } alt="logo" />
      </LogoContainer>
      <CardBox variant="outlined">
        <Typography variant="h5" component="h2">
          {auth_routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                exact={true}
                render={() => (
                    <div>{ item.name }</div>
                )}
              />
          ))}
        </Typography>
        <Divider sx={{ mx: -2, mt: 2 }} />
        
        {props.children}
      </CardBox>
    </Box>
  );
}