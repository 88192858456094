import { Checkbox, FormControl, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { SelectFilterProps } from "./SelectFilter";

export default function CheckboxListFilter(props:SelectFilterProps){
  const {
    items = []
  } = props

  const handleToggle = (value: number|string) => () => {
    const currentIndex = (props.value || []).indexOf(value);
    const newChecked = [...(props.value || [])];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    props.onChange(newChecked);;
  };
  
  return (
    <FormControl fullWidth>
      <Typography variant='caption' color='textSecondary'>{props.label}</Typography>

      <List>
        {items.map((i) => {
          const labelId = `checkbox-list-label-${i.value}`;

          return (
            <ListItem
              key={i.value}
              role={undefined}
              dense
              button
              onClick={handleToggle(i.value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={(props.value || []).indexOf(i.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={i.name} />
            </ListItem>
          );
        })}
      </List>
    </FormControl>
  )
}