import { Alert, Button, Checkbox, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import requests from "api/requests";
import { CarServicesSerializer } from "cards/car/types";
import { DriverServicesSerializer } from "cards/driver/types";
import { ICar } from "components/car/types";
import { IDriver } from "components/driver/types";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo } from "react";
import { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom";

interface IService{
  id: number;
  name: number;
  autouses: boolean;
  driveruses: boolean;
}

type ICarServices = {[car_id:number]:CarServicesSerializer};

export default function Services(){
  const match:any = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const [services, setServices] = useState<IService[]|null>(null);
  const [driver, setDriver] = useState<IDriver|null>(null);
  const [cars, setCars] = useState<ICar[]>([]);
  const [driverServices, setDriverServices] = useState<DriverServicesSerializer|undefined>(undefined);
  const [carServices, setCarServices] = useState<ICarServices|null|undefined>(undefined);
  const [initDriverServices, setInitDriverServices] = useState<DriverServicesSerializer|undefined>(undefined);
  const [initCarServices, setInitCarServices] = useState<ICarServices|null|undefined>(undefined);
  const [disable, setDisable] = useState(false);
  const [differentExists, setDifferentExists] = useState(false);

  useEffect(()=>{
    setDriver(null);
    setCars([]);
    setDriverServices(undefined);
    setCarServices(undefined);
    setInitDriverServices(undefined);
    setInitCarServices(undefined);

    requests.get(`/base/driver/${match.params.id}/`).then((r) => setDriver(r.body))
  }, [match.params.id])

  const mergeServices = useCallback((s:CarServicesSerializer|DriverServicesSerializer):CarServicesSerializer|DriverServicesSerializer=>{
    if(s.request_services !== null && s.request_services !== undefined){
      s.services = s.request_services
    }
    return JSON.parse(JSON.stringify(s))
  }, [])

  useEffect(()=>{
    if(driver){
      requests.get(`/base/driver-card/${driver.id}/services/`).then((r) => {
        setDriverServices(mergeServices(r.body));
        setInitDriverServices(mergeServices(r.body));
      })
      if(driver.call_signs.length){
        let car_ids = driver.call_signs.map((i)=>i.car_id);
        requests.getAll(`/base/car/`, {
          id__in: car_ids.join(",")
        }).then((r) => {
          setCars(r.body.results);
          let promises:any[] = [];
          (r.body.results as ICar[]).forEach((i)=>{
            promises.push(requests.get(`/base/car-card/${i.id}/services/`).then((r)=>({
              car_id: i.id,
              services: r.body
            })))
          })

          Promise.all(promises).then((r)=>{
            let services:ICarServices = {};
            r.forEach((i)=>{
              services[i.car_id] = mergeServices(i.services);
            })
            setCarServices(services)
            setInitCarServices(JSON.parse(JSON.stringify(services)))
          })
        })
      }else{
        setCarServices(null);
        setInitCarServices(null);
      }
    }else{
      setDriverServices(undefined);
      setInitCarServices(undefined);
    }
  }, [driver, mergeServices])

  useEffect(()=>{
    requests.get('/base/service/', {
      autosearchuse: true
    }).then((r) => setServices(r.body))
  }, [])

  const equals = useCallback((a:any[], b:any[]) =>{
    return a.length === b.length && !Boolean(a.filter((v) => !b.includes(v)).length)
  }, [])

  useEffect(()=>{
    var _differentExists = false;
    if(!equals(driverServices?.services || [], initDriverServices?.services || [])){
      _differentExists = true;
    }
    Object.keys(carServices || {}).forEach((key:any) => {
      if(!(initCarServices || {})[key] || !equals((carServices || {})[key]?.services || [], (initCarServices || {})[key]?.services || [])){
        _differentExists = true;
      }
    });
    setDifferentExists(_differentExists)
  }, [
    carServices,
    initCarServices,
    driverServices,
    initDriverServices,
    equals
  ])

  const save = () => {
    setDisable(true);
    let promises:any[] = [];

    if(!equals(driverServices?.services || [], initDriverServices?.services || [])){
      promises.push(requests.put(`/base/driver-card/${driver?.id}/services/`, {
        "request_services": driverServices?.services || []
      }).then((r)=>{
        setDriverServices(mergeServices(r.body));
        setInitDriverServices(mergeServices(r.body));
        return r;
      }));
    }
    Object.keys(carServices || {}).forEach((key:any) => {
      if(!(initCarServices || {})[key] || !equals((carServices || {})[key]?.services || [], (initCarServices || {})[key]?.services || [])){
        promises.push(requests.put(`/base/car-card/${key}/services/`, {
          "request_services": (carServices || {})[key]?.services || []
        }).then((r)=>{
          let services:ICarServices = {...carServices};
          services[key] = mergeServices(r.body);
          setCarServices(JSON.parse(JSON.stringify(services)))
          setInitCarServices(JSON.parse(JSON.stringify(services)))
          return r;
        }))
      }
    });

    Promise.all(promises).then((r)=>{
      let is_save = r.filter((_r)=>_r.body.request_services !== null).length === 0;
      if(is_save){
        enqueueSnackbar("Збережено", {variant: 'success'});
      }else{
        enqueueSnackbar("Заявку на зміни надіслано", {variant: 'success'});
      }
    }).finally(()=>{
      setDisable(false);
    })
  }


  const carServicesList = useMemo(()=>{
    return (services || []).filter((i)=>i.autouses)
  }, [services])

  const driverServicesList = useMemo(()=>{
    return (services || []).filter((i)=>i.driveruses)
  }, [services])

  const requestExists = useMemo(()=>{
    let exists = false;

    if(!!(driverServices?.request_services || []).length){
      exists = true;
    }
    Object.keys(carServices || {}).forEach((key:any) => {
      if(!!((carServices || {})[key]?.request_services || []).length){
        exists = true;
      }
    });

    return exists;
  }, [
    carServices,
    driverServices,
  ])

  if(
    services===null ||
    driver===null ||
    driverServices===undefined ||
    carServices===undefined){
    return null;
  }

  return (
    <Grid container spacing={4}>
      
      {cars.map((car)=>(
        <Grid key={car.id} item xs={6}>
          <Typography variant="h6" color={(disable || requestExists)?"text.disabled":undefined}>{car.call_sign}: {car.brand.name} ({car.state_number})</Typography>
          <List
            sx={{
              mx: -2
            }}
          >
            {carServicesList.map((service) => {
              if(!carServices || !carServices[car.id]){
                return null;
              }
              return (
                <ListItemButton
                  key={service.id}
                  dense
                  onClick={()=>{
                    var index = carServices[car.id].services.indexOf(service.id);
                    if(index===-1){
                      carServices[car.id].services.push(service.id)
                    }else{
                      carServices[car.id].services.splice(index, 1);
                    }
                    setCarServices({...carServices})
                  }}
                  disabled={disable || requestExists}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={carServices[car.id].services.includes(service.id)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={service.name} />
                </ListItemButton>
              );
            })}
          </List>
        </Grid>
      ))}

      <Grid item xs={6}>
        <Typography variant="h6" color={(disable || requestExists)?"text.disabled":undefined}>Водій: {[
                          driver.lastname,
                          driver.firstname,
                          driver.middlename,
                        ].filter((i)=>!!i).join(" ")}</Typography>
        <List
          sx={{
            mx: -2
          }}
        >
          {driverServicesList.map((service) => {
            return (
              <ListItemButton
                key={service.id}
                dense
                onClick={()=>{
                  var index = driverServices.services.indexOf(service.id);
                  if(index===-1){
                    driverServices.services.push(service.id)
                  }else{
                    driverServices.services.splice(index, 1);
                  }
                  setDriverServices({...driverServices})
                }}
                disabled={disable || requestExists}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={driverServices.services.includes(service.id)}
                  />
                </ListItemIcon>
                <ListItemText primary={service.name} />
              </ListItemButton>
            );
          })}
        </List>
      </Grid>

      {requestExists ? (
        <Grid item xs={12}>
          <Alert severity="info">
            <b>Ваша заявка в черзі на опрацювання</b><br/>
            Ви не можете вносити зміни доки не буде опряцьована попередня заявка
          </Alert>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>save()}
            disabled={disable || !differentExists || requestExists}
          >Зберегти</Button>
        </Grid>
      )}

    </Grid>
  )
}