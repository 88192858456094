import { OpenInNewOutlined } from "@mui/icons-material";
import { IconButton, Rating } from "@mui/material";
import CopyText from "components/copyText";
import { FilteredTableColumn } from "components/FilteredTable/types";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Rank from "../rank";
import { IDriver } from "../types";

interface DriverFilteredTableColumn extends FilteredTableColumn{
  disable_for_atp?: boolean
}

const columns: DriverFilteredTableColumn[] = [
  {
    title: 'ID',
    items: [
      {
        title: 'ID',
        style: {
          whiteSpace: "nowrap"
        },
        render: (row:IDriver) => (
          <CopyText text={row.id}>{row.id}</CopyText>
        )
      }
    ],
    width: 1
  },
  {
    title: 'ФИО',
    items: [
      {
        title: 'Прізвище',
        render: (row:IDriver) => row.lastname
      },
      {
        title: 'Ім\'я',
        render: (row:IDriver) => row.firstname,
      },
      {
        title: 'По батькові',
        render: (row:IDriver) => row.middlename
      }
    ]
  },
  {
    title: 'Рейтинг',
    width: 90,
    items: [
      {
        title: 'Рейтинг',
        render: (row:IDriver) => <Rank value={row.rank}/>,
      },
    ],
    // disable_for_atp: true
  },
  {
    title: 'Середня оцінка',
    width: 300,
    items: [
      {
        title: 'Оцінка',
        render: (row:IDriver) => (
          <div style={{marginLeft: -5}}>
            <Rating
              name="read-only"
              value={row.rating}
              readOnly
              size='small'
              precision={0.5}
              sx={{
                verticalAlign: 'middle'
              }}
            />
            &nbsp;(<NumberFormat value={row.rating} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />)
          </div>
        ),
      },
    ]
  },
  {
    title: 'АТП',
    items: [
      {
        title: 'АТП',
        render: (row:IDriver) => row.auto_group?.name,
      },
    ],
    disable_for_atp: true
  },
  {
    title: 'Позивний',
    items: [
      {
        title: 'Позивний',
        render: (row:IDriver) => row.call_signs.map((i)=>i.call_sign).join(", "),
      },
    ],
    disable_for_atp: true
  },
  {
    title: 'Кабінет',
    items: [
      {
        title: 'Кабінет',
        render: (row:IDriver) => (
          <IconButton
            component={Link}
            to={`/driver/${row.id}/`}
            onClick={(e:any)=>e.stopPropagation()}
          ><OpenInNewOutlined fontSize="small"/></IconButton>
        ),
      },
    ],
    width: 1
  }
];

export default columns;