import { Box, CircularProgress, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import requests from "api/requests";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Deserializer } from "ts-serializer-core";
import { OrderHistorySerializer } from "./types";

interface IProps{
  id: number;
}

export default function OrderHistory(props:IProps){
  const {
    id,
  } = props
  const [ rows, setRows ] = useState<OrderHistorySerializer[]|null>(null);

  useEffect(()=>{
    const abortController = new AbortController();

    if(!id){
      abortController.abort();
    }else{
      setRows(null);
      requests.get(`/base/order-card/${id}/history/`, undefined, abortController.signal).then((r)=>{
        const deserializer = new Deserializer();
        const dataList = (r.body || []).map((i:any)=>deserializer.deserialize(OrderHistorySerializer, i));
        setRows(dataList);
      })
    }

    return () => {
      abortController.abort();
    };
  }, [id])

  if(!rows){
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  if(!rows.length){
    return (
      <Typography variant="h6" color="textSecondary" align="center" sx={{py: 3}}>
        Історія відсутня
      </Typography>
    )
  }

  return (
    <Table>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow
            key={index}
          >
            <TableCell sx={{whiteSpace: 'nowrap'}}>
              <Moment
                date={row.date}
                format="DD.MM.YYYY HH:mm:ss"
              />
            </TableCell>
            <TableCell>{row.user}</TableCell>
            <TableCell sx={{whiteSpace: 'pre-wrap'}}>{row.changes}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}