import React, { useState, useCallback, useEffect } from 'react';
import Balance from './balance';
import { Root } from './styles';
import { useRouteMatch } from 'react-router-dom';
import requests from 'api/requests';
import { ICompany } from './types';
import { IInvoice } from 'pages/adminCorp/invoices/types';
import { Container, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, InputAdornment } from '@mui/material';
import { ExpandMore, SearchOutlined } from '@mui/icons-material';
import { DateRangePicker, IDateRange } from 'components/daterange';
import InvoicesTable from 'pages/adminCorp/invoices/table';
import { format } from 'date-fns';
import InvoicesTablePagination from 'pages/adminCorp/invoices/pagination';
import Invoice from 'pages/adminCorp/invoices/invoice';


export default function Payment() {
  const match:any = useRouteMatch();
  const company_id = match.params.company;
  
  const [company, setCompany] = useState(null as ICompany|null);
  const [lastInvoice, setLastInvoice] = useState(null as IInvoice|null);

  const [invoice, setInvoice] = useState<IInvoice|null>(null);
  const [archiveSearchString, setArchiveSearchString] = useState('');
  const [archiveDateRange, setArchiveDateRange] = useState<IDateRange>({
    startDate: undefined,
    endDate: undefined
  });
  const [archivePage, setArchivePage] = useState({
    items: [] as IInvoice[],
    all_count: null as number|null,
    items_count: 0,
    page: 0,
    limit: 5,
  })

  const load = useCallback(() => {
    const promiceCompany = requests.get(`/corp/company/${company_id}/`);
    const promiceInvoice = requests.get(`/corp/invoice/`, {
      company: company_id,
      limit: 1
    });

    Promise.all([
      promiceCompany,
      promiceInvoice,
    ]).then(values => {
      const [
        responseCompany,
        responseInvoice,
      ] = values;

      setCompany(responseCompany.body);
      if(responseInvoice.body.results){
        setLastInvoice(responseInvoice.body.results[0]);
      }
    })
  }, [company_id])


  const filtersArchive = useCallback(() => {
    let new_filter:any = {
    };
    if(archiveDateRange.startDate){
      new_filter.date__gte = format(archiveDateRange.startDate as Date, "yyyy-MM-dd");
    }
    if(archiveDateRange.endDate){
      new_filter.date__lte = format(archiveDateRange.endDate as Date, "yyyy-MM-dd");
    }
    if(archiveSearchString){
      new_filter.search = archiveSearchString;
    }
    return new_filter;
  }, [archiveDateRange, archiveSearchString])

  const loadArchive = useCallback(() => {
    requests.get(`/corp/invoice/`, {
      ...filtersArchive(),
      company: company_id,
      limit: archivePage.limit,
      offset: archivePage.page * archivePage.limit
    }).then(r => {

      setArchivePage(page => ({
        ...page,
        all_count: page.all_count !== null ? page.all_count : r.body.count,
        loading: false,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [archivePage.page, archivePage.limit, company_id, filtersArchive])

  useEffect(() => loadArchive(), [loadArchive]);
  useEffect(() => load(), [load]);

  const archive = (
    <Container maxWidth="sm">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Typography>Архів рахунків</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: "100%"}}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DateRangePicker
                  dateRange={archiveDateRange}
                  onChange={(range) => setArchiveDateRange(range)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  placeholder="Пошук"
                  value={ archiveSearchString }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setArchiveSearchString(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined color={'action'} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            
            <br/><br/>
            <InvoicesTable
              items={archivePage.items}
              onClick={(item:IInvoice) => {
                setInvoice(item);
              }}
            />
            <InvoicesTablePagination
              count={archivePage.items_count}
              limit={archivePage.limit}
              page={archivePage.page}
              onChange={(page_number:number) => {
                setArchivePage({
                  ...archivePage,
                  page: page_number
                });
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Container>
  );

  return (
    <Root>
      { company && (
        <div>
          <Balance
            company={company}
            lastInvoice={lastInvoice}
            onInvoiceCreated={(data:IInvoice) => {
              setLastInvoice(data);
              loadArchive();
            }}
          />

          <br/><br/>
          {archivePage.all_count ? archive : null}
          
        </div>
      )}

      { invoice && (
        <Invoice
          invoice={invoice}
          stamp={true}
          onClose={() => {
            setInvoice(null);
          }}
        />
      )}
    </Root>
  );
}