import React from 'react';
import NumberFormat from 'react-number-format';
import { TableRow, TableCell } from '@mui/material';
import Moment from 'react-moment';

export default function Row(props: { item: any}) {
  const { item } = props;

  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          <NumberFormat value={item.id} displayType={'text'} thousandSeparator={' '} />
        </TableCell>
        <TableCell>{item.addresses.length ? item.addresses[0] : null}</TableCell>
        <TableCell>
          <Moment
            date={item.date}
            format="DD.MM.YY"
          />
        </TableCell>
        <TableCell>
          <Moment
            date={item.date}
            format="HH:mm"
          />
        </TableCell>
        <TableCell>{item.tariff_class}</TableCell>
        <TableCell>{item.call_sign}</TableCell>
        <TableCell>{item.driver}</TableCell>
        {/* <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></TableCell> */}
      </TableRow>
    </React.Fragment>
  );
}