import { ExpandMore } from "@mui/icons-material";
import { Accordion, accordionClasses, AccordionDetails, AccordionSummary, styled, Typography } from "@mui/material";
import React from "react";
import ExcludeCheckboxListFilter, { ExcludeCheckboxListFilterProps } from "./ExcludeCheckboxListFilter";

const StyledAccordion = styled(Accordion)((theme) => ({
  [`&.${accordionClasses.root}`]: {
    borderRadius: 0
  }
}))

export default function AccordionExcludeCheckboxListFilter(props:ExcludeCheckboxListFilterProps){
  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
      >
        <Typography>{props.label}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{p: 0}}>
        <ExcludeCheckboxListFilter {...props} hideLabel={true}/>
      </AccordionDetails>
    </StyledAccordion>
  )
}