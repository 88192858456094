import React, { useState, useEffect, useCallback } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Switch, IconButton, Button, Grid, Hidden, FormControl, Dialog, DialogContent, TextField, DialogActions, FormControlLabel, InputAdornment, Menu, MenuItem, ListItemIcon, Typography, Fab } from '@mui/material';
import requests from 'api/requests';
import { EditOutlined, DeleteOutlined, SearchOutlined, MoreVertOutlined, PublishOutlined, GetAppOutlined, AddOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useRouteMatch } from 'react-router-dom';
import ImportDialog from './ImportDialog';
import { PaginationGridBox, TablePaperBox } from 'components/styled';
import { MaskedField } from 'components/fields';



export default function Staff() {
  const match:any = useRouteMatch();
  const company = match.params.company;
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [instance, setInstance] = useState(null as any);
  const [searchString, setSearchString] = useState('');
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [page, setPage] = useState({
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })

  const load = useCallback(() => {
    window.scrollTo(0, 0);
    requests.get('/corp/staff/', {
      limit: page.limit,
      offset: page.page * page.limit,
      company: company,
      search: searchString
    }).then((r) => {
      setPage(page => ({
        ...page,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [page.page, page.limit, company, searchString])

  useEffect(() => load(), [load]);

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeChecked = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.checked);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    instance[name] = value;
    setInstance({...instance});
  }

  const toggleActive = (item:any) => {
    item.active = !item.active;
    setPage({
      ...page,
      items: page.items
    })
    requests.patch(`/corp/staff/${item.id}/`, {
      active: item.active
    }).then((r) => {
      enqueueSnackbar(item.active ? "Активовано" : "Деактивовано", {variant: 'success'});
    });
  }

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  const renderItems = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ПІБ</TableCell>
              <TableCell>Телефон&nbsp;1</TableCell>
              <TableCell>Телефон&nbsp;2</TableCell>
              <TableCell style={{width: 1}}>Активний</TableCell>
              <TableCell style={{width: 1}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { (page.items).map((item:any) => (
              <TableRow hover key={item.id}>
                <TableCell>{item.fio}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.phone2}</TableCell>
                <TableCell>
                  <Switch
                    checked={item.active}
                    onChange={(e, value) => {
                      toggleActive(item);
                    }}
                    color="primary"
                  />
                </TableCell>
                <TableCell style={{whiteSpace: 'nowrap'}}>
                  <IconButton
                    color="default"
                    onClick={()=>{
                      const _item = JSON.stringify(item);
                      const __item = JSON.parse(_item);
                      setInstance(__item)
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    color="default"
                    onClick={()=>{
                      confirm({
                        title: "Ви впевнені?",
                        description: 'Видалення - незворотний процес!',
                        cancellationText: 'Ні',
                        confirmationText: 'Так'
                      })
                        .then(() => {
                          remove(item);
                        });
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const remove = (item:any) => {
    requests.delete(`/corp/staff/${item.id}/`).then((r) => {
      load();
      enqueueSnackbar("Видалено", {variant: 'success'});
    })
  }

  const update = (item:any) => {
    let url = `/corp/staff/`;
    var method = requests.post;
    if(item.id){
      url = `/corp/staff/${item.id}/`;
      method = requests.put;
    }
    item.company = company;

    method(url, item).then((r) => {
      load();
      setInstance(null);
      enqueueSnackbar("Збережено", {variant: 'success'});
    });
    
  }

  const staff_export = () => {
    requests.download('/corp/staff/export/', {
      company: company
    })
    setAnchorMenu(null);
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => {
          setInstance(null);
        }}
      >
        <form
          onSubmit={(e:any) => {
            e.preventDefault();
            update(instance);
          }}
        >
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                autoFocus
                label="ПІБ"
                margin="normal"
                value={instance.fio || ''}
                onChange={handleChange('fio')}
              />
              <MaskedField
                label="Телефон 1"
                mask="+38 (099) 999 99 99"
                margin="normal"
                value={instance.phone || ''}
                onChange={handleChange('phone')}
              />
              <MaskedField
                label="Телефон 2"
                mask="+38 (099) 999 99 99"
                margin="normal"
                value={instance.phone2 || ''}
                onChange={handleChange('phone2')}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={instance.active}
                    onChange={handleChangeChecked('active')}
                    color="primary"
                  />
                }
                label="Активний"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setInstance(null)
              }}
            >
              Відмінити
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
            >
              Зберегти
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3} alignItems="center" justifyContent="flex-start" direction="row" wrap="nowrap">
            <Grid item>
              <Hidden mdDown>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddOutlined />}
                  onClick={()=>{
                    setInstance({
                      active: true
                    })
                  }}
                >
                  Додати
                </Button>
              </Hidden>

              <Hidden mdUp>
                <Fab
                  size="small"
                  color="secondary"
                  onClick={()=>{
                    setInstance({
                      active: true
                    })
                  }}
                >
                  <AddOutlined />
                </Fab>
              </Hidden>
            </Grid>
            <Grid item>
              <TextField
                placeholder="Пошук"
                value={ searchString }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchString(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined color={'action'} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  setAnchorMenu(e.currentTarget);
                }}
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu
                anchorEl={anchorMenu}
                keepMounted
                open={Boolean(anchorMenu)}
                onClose={() => {
                  setAnchorMenu(null);
                }}
              >
                  <MenuItem
                    onClick={() => {
                      setImportDialogOpen(true);
                      setAnchorMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      <GetAppOutlined fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Імпорт (Excel)</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={staff_export}
                  >
                    <ListItemIcon>
                      <PublishOutlined fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Експорт (Excel)</Typography>
                  </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <PaginationGridBox item sm={4}>
            { renderPagination() }
          </PaginationGridBox>
        </Hidden>
      </Grid>
      <TablePaperBox>
        {renderItems()}
      </TablePaperBox>
      {renderPagination()}

      { instance !== null && renderDialog() }

      { importDialogOpen && (
        <ImportDialog
          company={company}
          onClose={() => setImportDialogOpen(false)}
          onDone={()=>{
            enqueueSnackbar("Імпорт завершено", {variant: 'success'});
            setImportDialogOpen(false);
            load();
          }}
        />
      )}
    </div>
  );
}