import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import OrderIDLink from "components/OrderIDLink";
import { useMemo } from "react";
import NumberFormat from "react-number-format";
import { ICAOrder } from "./types";

interface IProps{
  items: ICAOrder[];
}

interface ITotalProps{
  total: number;
}

function Total(props:ITotalProps){
  const {
    total
  } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell>Всього</TableCell>
        <TableCell align="right">
          <NumberFormat
            value={total}
            displayType={'text'}
            thousandSeparator={' '}
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default function TableItems(props:IProps){
  const {
    items
  } = props

  const total:number|null = useMemo(()=>{
    if(!items.length){
      return null;
    }
    return items.map((i)=>i.price).reduce((a,b)=>a+b);
  }, [items])

  if(total === null){
    return (
      <Typography variant="h6" color="textSecondary" align="center" sx={{py: 3}}>
        Замовлення відсутні
      </Typography>
    )
  }

  return (
    <TableContainer sx={{mt: 3}}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Замовлення</TableCell>
            <TableCell align="right">Вартість</TableCell>
            <TableCell width="100%">Клієнт</TableCell>
            <TableCell>Позивний</TableCell>
          </TableRow>
        </TableHead>
        <Total total={total || 0}/>
        <TableBody>
          {items.map((item) => (
            <TableRow hover key={item.id}>
              <TableCell sx={{whiteSpace: "nowrap"}}><OrderIDLink OrderID={item.id} /></TableCell>
              <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                <NumberFormat
                  value={item.price}
                  displayType={'text'}
                  thousandSeparator={' '}
                />
              </TableCell>
              <TableCell>{item.client.code}: {item.client.name}</TableCell>
              <TableCell sx={{whiteSpace: "nowrap"}}>{item.callsign.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <Total total={total || 0}/>
      </Table>
    </TableContainer>
  )
}