import { styled } from '@mui/material';
import { WEB_STAT_URL } from 'config';
import React from 'react';


const Root = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-3),
  right: theme.spacing(-3),
  left: theme.spacing(-3),
  bottom: theme.spacing(-3),
  '& iframe': {
    border: 0,
    width: '100%',
    height: '100%',
  }
}));

export default function Stat() {

  return (
    <Root>
      <iframe
        src={WEB_STAT_URL}
        title="stat"
      ></iframe>
    </Root>
  );
}