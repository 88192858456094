import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Link, Box } from '@mui/material';

import { InsertInvitationOutlined } from '@mui/icons-material';
import { BalanceGrid, PaperBox, TextTypography } from './styles';
import NumberFormat from 'react-number-format';
import { ICompany } from './types';
import Moment from 'react-moment';
import Invoice from '../../adminCorp/invoices/invoice';
import CreateInvoice from './create_invoice';
import { IInvoice } from 'pages/adminCorp/invoices/types';


interface IBalance {
  company: ICompany;
  lastInvoice:IInvoice|null;
  onInvoiceCreated:(data:IInvoice) => void;
}

export default function Balance(props:IBalance) {
  const [createInvoiceDialog, setCreateInvoiceDialog] = useState(false);
  const [showLastInvoice, setShowLastInvoice] = useState(false);
  const [lastInvoice, setLastInvoice] = useState(null as IInvoice|null);


  return (
    <Container maxWidth="sm">
      <PaperBox>
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <Typography variant="h4" color="textSecondary">
              Баланс
            </Typography>
          </Grid>
          <BalanceGrid item sm={8} xs={12}>
            <Typography variant="h4">
              <NumberFormat
                value={props.company.balance}
                displayType={'text'}
                thousandSeparator={' '}
                suffix=" грн."
              />
            </Typography>
          </BalanceGrid>
        </Grid>

        <TextTypography>
          Будь ласка, слідкуйте за залишком на рахунку та своєчасно поповнюйте його для безперебійного доступу Ваших співробітників до послуг компанії.
        </TextTypography>

        { props.company.provider && (
          <Box sx={{marginTop: 3}}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e:any) => {
                  e.preventDefault();
                  setCreateInvoiceDialog(true);
              }}
            >
              Замовити рахунок
            </Button>
          </Box>
        )}

        { props.company.provider && props.lastInvoice && (
          <TextTypography>
            <InsertInvitationOutlined color="action" sx={{marginRight: 1}} />
            <span>
              Останній рахунок № {props.lastInvoice.number} був сформований <span>
                <Moment format="DD.MM.YYYY" parse="YYYY-MM-DD">
                  {props.lastInvoice.date}
                </Moment>
              </span> на сумму <span>
                <NumberFormat
                  value={props.lastInvoice.cost}
                  displayType={'text'}
                  thousandSeparator={' '}
                  suffix=" грн."
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              </span>
              <br/>
              <Link href="#" onClick={(e:any) => {
                  e.preventDefault();
                  setShowLastInvoice(true);
              }}>
                Переглянути
              </Link>
            </span>
          </TextTypography>
        )}
      </PaperBox>
      { (lastInvoice || props.lastInvoice) && showLastInvoice && (
        <Invoice
          invoice={(lastInvoice || props.lastInvoice) as IInvoice}
          stamp={true}
          onClose={() => {
            setShowLastInvoice(false);
          }}
        />
      )}

      { createInvoiceDialog && (
        <CreateInvoice
          company={props.company}
          onClose={()=>{
            setCreateInvoiceDialog(false);
          }}
          onCreated={(data:IInvoice)=>{
            setCreateInvoiceDialog(false);
            props.onInvoiceCreated(data);

            setLastInvoice(data);
            setShowLastInvoice(true);
          }}
        />
      )}
    </Container>
  );
}