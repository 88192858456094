import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, IconButton, Popover,  Checkbox, FormControl, TextField, FormControlLabel } from '@mui/material';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import { ExtraField, IStatus } from './types';
import { DeleteOutlined } from '@mui/icons-material';

export interface InstanceModalProps {
  apiUrl: string;
  extraFields?: ExtraField[];
  instance: IStatus;
  onClose?: ()=>void;
  onSave?: (instance:IStatus)=>void;
  onDelete?: (instance:IStatus)=>void;
}

export default function InstanceModal(props:InstanceModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [ deleteConfirmEl, setDeleteConfirmEl] = React.useState<HTMLButtonElement | null>(null);
  const [instance, setInstance] = useState({...props.instance});

  const {
    onSave,
    onClose,
    onDelete
  } = props;

  const remove = () => {
    requests.delete(`${props.apiUrl}${instance.id}/`).then((r) => {
      enqueueSnackbar("Видалено", {variant: 'success'});
      if(onDelete){
        onDelete(instance);
      }
    })
  }

  const update = () => {
    requests.patch(`${props.apiUrl}${instance.id}/`, instance).then((r) => {
      enqueueSnackbar("Збережено", {variant: 'success'});
      if(onSave){
        onSave(r.body);
      }
    });
    
  }

  const handleChange = (name:(keyof IStatus)) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:(keyof IStatus)) => (value:any) => {
    let _inputs = {...instance} as any;
    _inputs[name] = value;
    setInstance({..._inputs});
  }

  const handleChangeCheckbox = (name:(keyof IStatus)) => (event:any) => {
    handleChangeValue(name)(event.target.checked);
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
    >
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          update();
        }}
      >
        <DialogContent>

          <FormControl fullWidth>
            <TextField
              autoFocus
              label="Назва"
              margin="normal"
              value={instance.name || ''}
              onChange={handleChange('name')}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={instance.is_final}
                onChange={handleChangeCheckbox('is_final')}
              />
            }
            label="Кінцевий статус"
          />

          {(props.extraFields || []).map((extra_field) => (
            <FormControl fullWidth>
              <TextField
                label={extra_field.title}
                helperText={extra_field.helpText}
                margin="normal"
                value={(instance as any)[extra_field.field_name] || ''}
                onChange={(event)=>{
                  let value = event.target.value;
                  if(extra_field.setValueHook !== undefined){
                    value = extra_field.setValueHook(value);
                  }
                  handleChangeValue(extra_field.field_name as any)(value);
                }}
              />
            </FormControl>
          ))}
          
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setDeleteConfirmEl(event.currentTarget);
            }}
            color="secondary"
            style={{marginRight: 'auto'}}
          >
            <DeleteOutlined />
          </IconButton>
          <Button
            onClick={onClose}
          >
            Відмінити
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            Зберегти
          </Button>
        </DialogActions>
      </form>

      <Popover
        open={!!deleteConfirmEl}
        anchorEl={deleteConfirmEl}
        onClose={()=>setDeleteConfirmEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={()=>{
            remove();
            setDeleteConfirmEl(null);
          }}
        >Підтвердити</Button>
      </Popover>
    </Dialog>
  );
}