import requests from "api/requests";
import { useCard } from "cards/provider";
import FilteredTable from "components/FilteredTable";
import { useEffect, useMemo, useRef, useState } from "react";
import { IDriver } from "../types";
import columns from "./columns";
import filters from "./filters";
import { IExtendData } from "./types";

interface IProps{
  userSettingsKey: string;
  atp?: number;
}

export default function DriverList(props:IProps){
  const {
    userSettingsKey,
    atp
  } = props;
  const { openCard } = useCard();
  const [extendData, setExtendData] = useState<IExtendData|undefined>(undefined);
  const tableRef = useRef(null)

  const table_columns = useMemo(()=>{
    if(!atp) return columns
    return columns.filter((i)=>!i.disable_for_atp)
  }, [atp])

  const table_filters = useMemo(()=>{
    if(!atp) return filters
    return filters.filter((i)=>!i.disable_for_atp)
  }, [atp])

  const defaultFilters = useMemo(()=>{
    const _filters:any = {
      removed: false
    }
    if(!!atp){
      _filters.auto_group__id = atp;
    }
    return _filters
  }, [atp])

  useEffect(()=>{
    let promises:any = [];
    if(!atp){
      promises.push(
        requests.getAll('/atp/atp/').then((r) => r.body.results)
      )
    }else{
      promises.push(Promise.resolve([]))
    }
    Promise.all(promises).then((r:any[])=>{
      const _atps:any[] = r[0];

      setExtendData({
        atp: _atps.map((i)=>({
          name: i.name,
          value: i.id
        }))
      })
    })
    
  }, [atp])

  return (
    <div>
      <FilteredTable
        dataURL="/base/driver/"
        columns={table_columns}
        defaultFilters={defaultFilters}
        filters={table_filters}
        extendData={extendData}
        userSettingsKey={userSettingsKey}
        onRowClick={(driver:IDriver)=>{openCard("driver", driver.id)}}
        tableRef={tableRef}
      />
    </div>
  )
}