import AdminPage from 'components/adminPage';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export default function Users() {
  const match:any = useRouteMatch();

  return (
    <AdminPage
      apiUrl="/atp/atp-permission/"
      parentID={match.params.id}
      parentFieldName="atp"
      inputNames={{
        driver: 'АТП'
      }}
    />
  );
}