import React, { useCallback, useEffect } from 'react';
import RequestStatusForm from './form';
import requests from 'api/requests';
import { IRatingStatus } from './types';
import StatusList from './statusList';
import { IStatus } from 'pages/settings/statuses/base/types';

interface IProps {
  ratingID?: number;
  statusTypes: IStatus[];
  onStatusChange: ()=>void;
  addNewStatus: (statusInstance:IRatingStatus)=>Promise<any>;

  newStatusInstance: IRatingStatus|null;
  onChangeNewStatusInstance: (statusInstance:IRatingStatus|null)=>void;
}

export default function RequestStatus(props:IProps) {
  const [ statuses, setStatuses] = React.useState<IRatingStatus[]|null>(null);

  const load = useCallback(() => {
    if(!props.ratingID) return;

    requests.getAll('/admin/webcab/rating/status/', {
      ratingid: props.ratingID
    }).then((r) => {
      setStatuses(r.body.results);
    })
    
  }, [props.ratingID])

  useEffect(() => load(), [load]);

  return (
    <div>

      <RequestStatusForm
        ratingID={props.ratingID}
        statusTypes={props.statusTypes}
        onCreated={()=>{
          load()
          props.onStatusChange();
        }}

        newStatusInstance={props.newStatusInstance}
        onChangeNewStatusInstance={props.onChangeNewStatusInstance}
        addNewStatus={props.addNewStatus}
      />

      {props.ratingID ? (
        <StatusList
          statusTypes={props.statusTypes}
          statuses={statuses}
        />
      ):null}
    </div>
  );
}
