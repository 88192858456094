import { Typography } from '@mui/material';
import React from 'react';
import OrderGroup from './orderGroup';


export default function OrderByDriver() {

  return (
    <OrderGroup
      apiURL="/atp/order/total-driver/"
      renderGroupValue={(item) => {
        if(!item.driver){
          return (<Typography variant="inherit" color="textSecondary">N/A</Typography>);
        }
        let name = [
          item.driver.lastname,
          item.driver.firstname,
          item.driver.middlename,
        ];
        return name.join(" ");
      }}
      groupName="Водій"
    />
  );
}