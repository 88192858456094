import { styled } from "@mui/material";


export const PrintBox = styled("div")(({ theme }) => ({
  width: '100%',
  '& table': {
    width: '100%',
    fontSize: 10,
    fontFamily: 'Arial, sans-serif',
    borderCollapse: 'collapse',
    border: '1px solid black',
    textAlign: 'left'
  },
  '& th': {
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '3px 5px'
  },
  '& td': {
    border: '1px solid black',
    padding: '3px 5px',
    verticalAlign: 'top'
  }
}));