import requests from "api/requests";
import { useCard } from "cards/provider";
import FilteredTable from "components/FilteredTable";
import AuthModel from "models/AuthModel";
import { useEffect, useMemo, useRef, useState } from "react";
import { ICar } from "../types";
import columns from "./columns";
import filters from "./filters";
import { IExtendData } from "./types";

interface IProps{
  userSettingsKey: string;
  atp?: number;
}

export default function CarList(props:IProps){
  const {
    userSettingsKey,
    atp
  } = props;
  const { openCard } = useCard();
  const [extendData, setExtendData] = useState<IExtendData|undefined>(undefined);
  const tableRef = useRef(null)

  const table_columns = useMemo(()=>{
    if(!atp) return columns
    return columns.filter((i)=>!i.disable_for_atp)
  }, [atp])

  const table_filters = useMemo(()=>{
    if(!atp) return filters
    return filters.filter((i)=>!i.disable_for_atp)
  }, [atp])

  const defaultFilters = useMemo(()=>{
    const _filters:any = {}
    if(!!atp){
      _filters.auto_group__id = atp;
    }
    return _filters
  }, [atp])

  useEffect(()=>{
    let services_filter:any = {
      autouses: true,
    }
    if(!AuthModel.permissions.includes("admin")){
      services_filter.autosearchuse = true;
    }

    const apt_promise = !atp ? (
      requests.getAll('/atp/atp/').then((r) => r.body.results)
    ): Promise.resolve([])
    
    const promises:any = [
      requests.get('/car/class/').then((r) => r.body),
      requests.get('/base/service/', services_filter).then((r) => r.body),
      requests.get('/base/document-type/').then((r) => r.body),
      apt_promise
    ];
    
    Promise.all(promises).then((r:any[])=>{
      const _ratiff_class:any[] = r[0];
      const _services:any[] = r[1];
      const _document_types:any[] = r[2];
      const _atps:any[] = r[3];

      setExtendData({
        atp: _atps.map((i)=>({
          name: i.name,
          value: i.id
        })),
        tariff_class: _ratiff_class.map((i)=>({
          name: i.name,
          value: i.id
        })),
        services:  _services.map((i)=>({
          name: i.name,
          value: i.id
        })),
        document_types:  _document_types.map((i)=>({
          name: i.name,
          value: i.id
        })),
      })
    })
    
  }, [atp])

  return (
    <div>
      <FilteredTable
        dataURL="/base/car/"
        exportURL="/base/car/export/"
        columns={table_columns}
        defaultFilters={defaultFilters}
        filters={table_filters}
        extendData={extendData}
        userSettingsKey={userSettingsKey}
        onRowClick={(car:ICar)=>{openCard("car", car.id)}}
        tableRef={tableRef}
      />
    </div>
  )
}