import { Button, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import { AddOutlined, CachedOutlined, PrintOutlined } from "@mui/icons-material";
import requests from "api/requests";
import { TariffClass } from "components/map/types";
import MultipleSelect from "components/multipleSelect";
import { format, setDay, setMinutes, startOfDay } from "date-fns";
import addDays from "date-fns/addDays";
import { useConfirm } from "material-ui-confirm";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useReactToPrint } from "react-to-print";
import AddRouteDialog from "./addRouteDialog";
import DataTable from "./dataTable";
import { PaperBox } from "./styles";
import { IData, IRoute, IWeekDay } from "./types";


const makeDateTimeByWeek = (weekDay:number) => {
  let dateTime = new Date();
  dateTime = addDays(dateTime, 7);
  dateTime = startOfDay(dateTime);
  dateTime = setDay(dateTime, weekDay, {weekStartsOn: 1});
  return dateTime;
}

const _weekDays:IWeekDay[] = [
  {
    title: "Понеділок",
    dateTime: makeDateTimeByWeek(1),
  },
  {
    title: "Вівторок",
    dateTime: makeDateTimeByWeek(2),
  },
  {
    title: "Середа",
    dateTime: makeDateTimeByWeek(3),
  },
  {
    title: "Четвер",
    dateTime: makeDateTimeByWeek(4),
  },
  {
    title: "П'ятниця",
    dateTime: makeDateTimeByWeek(5),
  },
  {
    title: "Субота",
    dateTime: makeDateTimeByWeek(6),
  },
  {
    title: "Неділя",
    dateTime: makeDateTimeByWeek(0),
  },
]

const minutes_in_day = 60 * 24;
const minutes_step = 30;

const getMinutes = () => {
  let arr:number[] = [];
  let minute = 0;
  while(minute < minutes_in_day){
    arr.push(minute);
    minute += minutes_step;
  }
  return arr;
}

const _minutes = getMinutes();


const timeItems = _minutes.map((i)=>({
  id: i,
  name: format(setMinutes(_weekDays[0].dateTime, i), "HH:mm")
}))

const weekDayItems = _weekDays.map((i)=>({
  id: Math.floor(i.dateTime.getTime() / 1000),
  name: i.title
}))


export default function TariffCheck(){
  const confirm = useConfirm();

  const [carClasses, setCarClasses] = useState<TariffClass[]>([]);
  const [routes, setRoutes] = useState<IRoute[]>([])
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [data, setData] = useState<IData>(null);
  const [loadingProgress, setLoadingProgress] = useState<null|number>(null);

  const [timeChoice, setTimeChoice] = useState<any[]>([]);
  const [weekDayChoice, setWeekDayChoice] = useState<any[]>([]);

  let destroyed = useRef(false);

  const printRef:any = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: landscape;
        padding: 25px;
      }
      *{
        line-height: 1 !important;
      }

      td, th {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
      }

      .printHide{
        display: none !important;
      }
      .route    { page-break-inside:avoid; page-break-after:auto }
      .priceCell{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      .priceCell>p{
        font-size: 12px !important;
      }

      .stepper{
        padding-top: 0px !important;
      }
      .stepLabel{
        margin-top: -15px !important;
      }
      .stepLabel * {
        line-height: 1 !important;
      }
    `,
  });

  const weekDays = useMemo(()=>{
    if(!weekDayChoice.length) return _weekDays;
    return _weekDays.filter((i)=>{
      return weekDayChoice.includes(Math.floor(i.dateTime.getTime() / 1000));
    })
  }, [weekDayChoice])

  const minutes = useMemo(()=>{
    if(!timeChoice.length) return _minutes;
    return _minutes.filter((i)=>{
      return timeChoice.includes(i);
    })
  }, [timeChoice])
  
  const load = useCallback(()=>{
    requests.getAll("/admin/tariff/check/route/").then((r)=>{
      setRoutes(r.body.results)
    })
  }, [])

  const loadCarClasses = useCallback(()=>{
    requests.get("/car/class/", {
      ignored_tariff: false
    }).then((r)=>{
      setCarClasses(r.body)
    })
  }, [])

  const getDataKey = useCallback((data:Date) => {
    return format(data, "yyyy-MM-dd HH:mm:ss")
  }, [])

  const doRequest = (newData:IData, requestsData:any[], requestsCount:number, withProgress=true) => {
    if(destroyed.current){
      return;
    }

    if(!requestsData.length){
      setLoadingProgress(null);
      setData(newData);
      return;
    }
    const requestData = requestsData.shift();

    Promise.all([
      requests.withoutErrorMessage().post("/base/price/calculation/", {
        ...requestData,
        server: "prod"
      }),
      requests.withoutErrorMessage().post("/base/price/calculation/", {
        ...requestData,
        server: "rezerv"
      }),
    ]).then((r)=>{

      if(!newData){
        newData = {};
      }
      if(!newData?.[requestData.routeID]){
        newData[requestData.routeID] = {};
      }
      newData[requestData.routeID][requestData.date] = {
        prod: r[0].body.class_prices,
        rezerv: r[1].body.class_prices,
      };
      if(withProgress){
        setLoadingProgress(100-requestsData.length*100/requestsCount)
      }
      doRequest(newData, requestsData, requestsCount)
    }).catch(()=>{
      setTimeout(()=>{
        requestsData.unshift(requestData)
        doRequest(newData, requestsData, requestsCount)
      }, 1000)
    })
  }

  const makeRequestData = (route:IRoute, key:string) => ({
    routeID: route.id,
    date: key,
    routepoints: route.routepoints
  })

  const loadData = ()=>{
    setLoadingProgress(0);
    setData(null);
    let requestsData:any[] = [];
    routes.forEach((route)=>{
      minutes.forEach((minute)=>{
        weekDays.forEach((weekDay)=>{
          const dateTime = setMinutes(weekDay.dateTime, minute);
          const dateKey = getDataKey(dateTime);
          requestsData.push(makeRequestData(route, dateKey))
        })
      })
    })

    doRequest({}, requestsData, requestsData.length);

  }

  useEffect(load, [load])
  useEffect(loadCarClasses, [loadCarClasses])
  useEffect(()=>{
    return ()=>{
      destroyed.current = true;
    }
  }, [])

  const deleteRoute = useCallback((route:IRoute) => {
    confirm({
      title: "Ви впевнені?",
      description: 'Ви дійсно бажаєте видалити маршрут?',
      cancellationText: 'Ні',
      confirmationText: 'Так'
    }).then(() => {
      requests.delete(`/admin/tariff/check/route/${route.id}/`).then(load)
    });
  }, [confirm, load])

  if(!carClasses.length){
    return null;
  }

  if(loadingProgress!==null){
    return (
      <div className="progress">
        <Typography variant="h5"><NumberFormat value={loadingProgress || 0} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />%</Typography>
        <LinearProgress variant="determinate" value={loadingProgress || 0} />
      </div>
    )
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddOutlined />}
            onClick={()=>{
              setShowAddDialog(true);
            }}
          >
            Додати маршрут
          </Button>
        </Grid>
        {routes.length?(
          <React.Fragment>
            <Grid item>
              <IconButton
                onClick={()=>{
                  loadData();
                }}
              >
                <CachedOutlined/>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handlePrint}
              >
                <PrintOutlined/>
              </IconButton>
            </Grid>
          </React.Fragment>
        ):null}
        <Grid item>
          <MultipleSelect
            placeholder="Час"
            value={timeChoice}
            items={timeItems}
            onChange={setTimeChoice}
          />
        </Grid>
        <Grid item>
          <MultipleSelect
            placeholder="Дні тижня"
            value={weekDayChoice}
            items={weekDayItems}
            onChange={setWeekDayChoice}
          />
        </Grid>
      </Grid>
      
      
      
      <PaperBox>
        <div ref={printRef}>
          <DataTable
            data={data}
            carClasses={carClasses}
            minutes={minutes}
            weekDays={weekDays}
            routes={routes}
            getDataKey={getDataKey}
            onDeleteRoute={deleteRoute}
            onPriceCellClick={(route, key)=>{
              const newData = {...data};
              doRequest(newData, [makeRequestData(route, key)], 1, false)
            }}
          />
        </div>
      </PaperBox>

      {showAddDialog ? (
        <AddRouteDialog
          onClose={()=>{
            setShowAddDialog(false);
          }}
          onDone={()=>{
            load();
            setShowAddDialog(false);
          }}
        />
      ):null}
    </div>
  )
}