import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Grid, Hidden, TextField, InputAdornment, IconButton, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import requests from 'api/requests';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { format, addDays, startOfWeek, endOfWeek } from 'date-fns';
import Row from './row';
import { PrintBox } from './styles';
import { MoreVertOutlined, PrintOutlined, PublishOutlined, SearchOutlined } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import PrintTable from './printTable';
import TotalTiles from 'components/totalTiles';
import { PaginationGridBox, TablePaperBox } from 'components/styled';

interface IFinedOrderDriverProps {
  listURL:string;
  identificatorID:number;
  identificatorName:string;
}

export default function FinedOrderDriver(props:IFinedOrderDriverProps) {
  const printRef:any = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => setAnchorMenu(null),
    onBeforeGetContent: () => {
      return new Promise<void>((resolve, reject) => {
        requests.getAll(props.listURL, {
          ...filters(),
          ordering: 'date',
        }).then((r) => {
          setPrintItems(r.body.results);
          resolve();
        })
      });
    },
    pageStyle: 'padding: 25px'
  });
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [searchString, setSearchString] = useState('');
  const [printItems, setPrintItems] = useState([])
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date())
  });
  const [page, setPage] = useState({
    first_loaded: false,
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })
  const [total, setTotal] = useState({} as any)

  const filters = useCallback(() => {
    let new_filter:any = {};
    new_filter[props.identificatorName] = props.identificatorID;
    if(dateRange.startDate){
      new_filter.date__gte = format(dateRange.startDate as Date, "yyyy-MM-dd");
    }
    if(dateRange.endDate){
      new_filter.date__lte = format(addDays(dateRange.endDate as Date, 1), "yyyy-MM-dd");
    }
    if(searchString){
      new_filter.search = searchString;
    }
    return new_filter;
  }, [props.identificatorName, props.identificatorID, dateRange, searchString])

  const load = useCallback(() => {
    window.scrollTo(0, 0);
    
    requests.get(props.listURL, {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit,
    }).then((r) => {
      setPage(page => ({
        ...page,
        first_loaded: true,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [props.listURL, page.page, page.limit, filters])

  const loadTotal = useCallback(() => {
    requests.get(`${props.listURL}total/`, filters()).then((r) => {
      setTotal(r.body);
    })
  }, [props.listURL, filters])

  useEffect(() => load(), [load]);
  useEffect(() => loadTotal(), [loadTotal]);

  const order_export = () => {
    requests.download(`${props.listURL}export/`, {
      ...filters(),
      ordering: 'date',
    })
    setAnchorMenu(null);
  }

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  const renderItems = () => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№ замовлення</TableCell>
              <TableCell>Адреса</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Час</TableCell>
              <TableCell>Клас</TableCell>
              <TableCell>Позивний</TableCell>
              <TableCell>Водій</TableCell>
              {/* <TableCell align="right">Штраф, грн</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            { page.items.map((item:any, index:number) => (
              <Row key={index} item={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div>
      { page.first_loaded && (
        <div>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5} lg={4} xl={3}>
                <DateRangePicker
                  dateRange={dateRange}
                  onChange={(range) => setDateRange(range)}
                />
              </Grid>
              <Grid item xs={9} sm={5} lg={4} xl={3}>
                <TextField
                  fullWidth
                  placeholder="Пошук"
                  value={ searchString }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchString(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined color={'action'} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
          <Grid item xs={3} sm={2}>
            <IconButton
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setAnchorMenu(e.currentTarget);
              }}
            >
              <MoreVertOutlined />
            </IconButton>
            <Menu
              anchorEl={anchorMenu}
              keepMounted
              open={Boolean(anchorMenu)}
              onClose={() => {
                setAnchorMenu(null);
              }}
            >
                <MenuItem
                  disabled={ !page.items || !page.items.length }
                  onClick={handlePrint}
                >
                  <ListItemIcon>
                    <PrintOutlined fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Друкувати</Typography>
                </MenuItem>
                <MenuItem
                  disabled={ !page.items || !page.items.length }
                  onClick={order_export}
                >
                  <ListItemIcon>
                    <PublishOutlined fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Експорт (Excel)</Typography>
                </MenuItem>
            </Menu>
          </Grid>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <PaginationGridBox item sm={4}>
              { renderPagination() }
            </PaginationGridBox>
          </Hidden>
        </Grid>
        
        <br/>
        <TotalTiles items={[
            {
              title: 'Замовлень',
              value: total.count,
              badge: null,
              number_format: false,
            },
            // {
            //   title: 'Всього',
            //   value: total.price,
            //   badge: null,
            //   number_format: true,
            // },
          ]}
        />

        <TablePaperBox>
          {renderItems()}
        </TablePaperBox>
        {renderPagination()}
      </div>
      )}

      <div style={{display: 'none'}}>
        <PrintBox ref={printRef}>
          <PrintTable
            items={printItems}
          />
        </PrintBox>
      </div>
    </div>
  );
}