import { CommentBankOutlined, EmojiPeopleOutlined, InfoOutlined, MapOutlined, MonetizationOnOutlined, SettingsOutlined } from '@mui/icons-material';
import FinedOrders from 'pages/driver/finedOrders';
import Info from 'pages/driver/info';
import MapPage from 'pages/driver/mapPage';
import Offset from 'pages/driver/offset';
import Orders from 'pages/driver/orders';
import RatingDriverPage from 'pages/driver/rating';
import Services from 'pages/driver/services';
import Users from 'pages/driver/users';
import { IMenuCollapse, IMenuRoute } from './types';

export const routes__driver = {
  map: "/",
  order: "/orders/",
  fined_orders: "/orders/fined/",
  offset: "/offset/",
  info: "/info/",
  rating: "/rating/",
  serrings_users: "/settings/users/",
  serrings_services: "/settings/services/",
  online_total_bill: "/online-total-bill/",
}

export const menu_routes__driver: (IMenuRoute | IMenuCollapse)[] = [
  {
    path: routes__driver.map,
    name: "Карта",
    component: MapPage,
    icon: MapOutlined,
  },
  {
    name: "Замовлення",
    icon: EmojiPeopleOutlined,
    items: [
      {
        path: routes__driver.order,
        name: "Виконані",
        component: Orders,
      },
      {
        path: routes__driver.fined_orders,
        name: "Проігноровані",
        component: FinedOrders,
      },
    ]
  } as IMenuCollapse,
  {
    path: routes__driver.offset,
    name: "Розрахунок зі службою",
    component: Offset,
    icon: MonetizationOnOutlined,
    isHide: (driver: any): boolean => {
      return !driver.call_signs.length
    },
  },
  {
    path: routes__driver.rating,
    name: "Отзывы",
    component: RatingDriverPage,
    icon: CommentBankOutlined,
  },
  {
    name: "Налаштування",
    icon: SettingsOutlined,
    items: [
      {
        path: routes__driver.serrings_services,
        name: "Мої послуги",
        component: Services,
      },
      {
        path: routes__driver.serrings_users,
        name: "Адміністратори",
        component: Users,
      },
    ]
  } as IMenuCollapse,
  {
    path: routes__driver.info,
    name: "Інформація для водія",
    component: Info,
    icon: InfoOutlined,
  }
];