import * as React from "react";
import {
	addMonths,
	isSameDay,
	isWithinInterval,
	isAfter,
	isBefore,
	isSameMonth,
	addYears,
	max,
	min,
	format,
	endOfDay,
	startOfDay,
	setHours,
	setMinutes,
	setSeconds,
	getHours,
	getMinutes,
	addSeconds,
	addDays
} from "date-fns";
import { DateRange, NavigationAction, DefinedRange } from "./types";
import Menu from "./components/Menu";
import { defaultRanges, TEXTS } from "./defaults";
import { optionalDate, isSameRange, getDateRangeWithTimeText } from "./utils";
import { Popover, FormControl, Input, InputAdornment } from "@mui/material";
import { DateRangeOutlined } from '@mui/icons-material';

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
	FIRST_MONTH: Symbol("firstMonth"),
	SECOND_MONTH: Symbol("secondMonth")
};

const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date) => {
	let { startDate, endDate } = range;
	if (startDate && endDate) {
		const newStart = max([startDate, minDate]);
		const newEnd = min([endDate, maxDate]);

		return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
	} else {
		return [startDate, endDate];
	}
};

interface DateRangePickerProps {
	dateRange: DateRange;
	definedRanges?: DefinedRange[];
	minDate?: Date;
	maxDate?: Date;
	withTime?: boolean;
	startTime?: Date;
	onChange: (dateRange: DateRange) => void;
}

const DateRangePickerImpl: React.FunctionComponent<DateRangePickerProps> = props => {
	const today = new Date();

	const {
		onChange,
		dateRange,
		minDate,
		maxDate,
		withTime,
		startTime,
		definedRanges = defaultRanges
	} = props;

	const minDateValid = optionalDate(minDate, addYears(today, -10));
	const maxDateValid = optionalDate(maxDate, addDays(today, 1));
	const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(
		dateRange || {},
		minDateValid,
		maxDateValid
	);

	const [hoverDay, setHoverDay] = React.useState<Date>();
	const [firstMonth, setFirstMonth] = React.useState<Date>(intialFirstMonth || today);
	const [secondMonth, setSecondMonth] = React.useState<Date>(
		initialSecondMonth || addMonths(firstMonth, 1)
	);
	const [anchorElDateRange, setAnchorElDateRange] = React.useState<HTMLDivElement | null>(null);

	const { startDate, endDate } = dateRange;

	// handlers
	const setFirstMonthValidated = (date: Date) => {
		if (isBefore(date, secondMonth)) {
			setFirstMonth(date);
		}
	};

	const setSecondMonthValidated = (date: Date) => {
		if (isAfter(date, firstMonth)) {
			setSecondMonth(date);
		}
	};

	const setDateRangeValidated = (range: DateRange) => {
		let { startDate: newStart, endDate: newEnd } = range;
		if (newStart && newEnd) {
			range.startDate = newStart = min([newEnd, max([newStart, minDateValid])]);
			range.endDate = newEnd = min([newEnd, maxDateValid]);
			onChange(range);
			setFirstMonth(newStart);
			setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
		}else{
			onChange(range);
		}
	};

	const onDayClick = (day: Date) => {
		let _startDate = startOfDay(day);
		if(startTime){
			_startDate = setHours(_startDate, getHours(startTime))
			_startDate = setMinutes(_startDate, getMinutes(startTime))
			_startDate = setSeconds(_startDate, 0)
		}
		let newRange = { startDate: _startDate, endDate: undefined as Date|undefined };
		if (startDate && !endDate && !isBefore(_startDate, startDate)) {
			let _endDate = endOfDay(day);
			_startDate = startDate;
			if(startTime){
				_endDate = setHours(_endDate, getHours(startTime))
				_endDate = setMinutes(_endDate, getMinutes(startTime))
				_endDate = setSeconds(_endDate, 0)
				_endDate = addSeconds(_endDate, -1)
				if(startOfDay(day) > startOfDay(_endDate)){
					_endDate = addDays(_endDate, 1)
				}
				if(startDate > _endDate){
					_startDate = startOfDay(startDate)
					_endDate = endOfDay(startDate)
				}
			}
			newRange = { startDate: _startDate, endDate: _endDate };
		}
		onChange(newRange);
		setHoverDay(day);
	};

	const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
		if (marker === MARKERS.FIRST_MONTH) {
			const firstNew = addMonths(firstMonth, action);
			if (isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
		} else {
			const secondNew = addMonths(secondMonth, action);
			if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
		}
	};

	const onDayHover = (date: Date) => {
		if (startDate && !endDate) {
			if (!hoverDay || !isSameDay(date, hoverDay)) {
				setHoverDay(date);
			}
		}
	};

	// helpers
	const inHoverRange = (day: Date) => {
		return (startDate &&
			!endDate &&
			hoverDay &&
			isAfter(hoverDay, startDate) &&
			isWithinInterval(day, {start: startDate, end: hoverDay})) as boolean;
	};

	const helpers = {
		inHoverRange
	};

	const handlers = {
		onDayClick,
		onDayHover,
		onMonthNavigate
	};


  const openDateRange = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElDateRange(event.currentTarget);
  };

  const closeDateRange = () => {
    setAnchorElDateRange(null);
  };
	
	const rangeText = ():string => {
		const yStart = Boolean(dateRange.startDate);
		const yEnd = Boolean(dateRange.endDate);
		
		for(var i=0; i<definedRanges.length; i++){
			if(isSameRange(definedRanges[i], dateRange)){
				return definedRanges[i].label;
			}
		}

		if(yStart && yEnd){
			if(isSameDay(dateRange.startDate as Date, dateRange.endDate as Date)){
				return format(dateRange.startDate as Date, "dd.MM.yyyy");
			}

			return format(dateRange.startDate as Date, "dd.MM.yyyy") + " - " + format(dateRange.endDate as Date, "dd.MM.yyyy");
		}
		
		if(!yStart && yEnd){
			return TEXTS.to + " " + format(dateRange.endDate as Date, "dd.MM.yyyy");
		}
		if(yStart && !yEnd){
			return TEXTS.from + " " + format(dateRange.startDate as Date, "dd.MM.yyyy");
		}

		return TEXTS.no_range;
	}
	const dateRangeWithTimeText = ():string => {
		return getDateRangeWithTimeText(dateRange);
	}

  const open = Boolean(anchorElDateRange);
	const id = open ? 'daterange-popover' : undefined;

	return (
		<div>
			<FormControl fullWidth>
        <Input
					value={rangeText()}
					onClick={openDateRange}
					readOnly={true}
          startAdornment={
            <InputAdornment position="start">
              <DateRangeOutlined color={'action'} />
            </InputAdornment>
          }
        />
				{ withTime ? (
					<small>{dateRangeWithTimeText()}</small>
				):null}
      </FormControl>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorElDateRange}
				onClose={closeDateRange}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Menu
					dateRange={dateRange}
					minDate={minDateValid}
					maxDate={maxDateValid}
					withTime={!!withTime}
					ranges={definedRanges}
					firstMonth={firstMonth}
					secondMonth={secondMonth}
					setFirstMonth={setFirstMonthValidated}
					setSecondMonth={setSecondMonthValidated}
					setDateRange={setDateRangeValidated}
					closeDateRange={closeDateRange}
					helpers={helpers}
					handlers={handlers}
				/>
			</Popover>
		</div>
		
	);
};

export type IDateRange = DateRange;
export type IDefinedRange = DefinedRange;
export const DateRangePicker = DateRangePickerImpl;
