import { Point } from "./types";


export default class CurrentLocationModel {
  subscribers:((point:Point)=>void)[];
  point:Point|null;

  constructor() {
    this.point = null;
    this.subscribers = [];

    if(!!navigator.geolocation) {
      navigator.geolocation.watchPosition(this.watchGeoPosition, (err)=>{
        console.warn('ERROR(' + err.code + '): ' + err.message);
      }, {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
      });
    }
  }

  subscribe = (callback:(point:Point)=>void) => {
    this.subscribers.push(callback);
  }
  unsubscribe = (callback:(point:Point)=>void) => {
    this.subscribers = this.subscribers.filter((c)=>c!==callback);
  }

  protected watchGeoPosition = (pos:GeolocationPosition) => {
    this.point = {
      type: "Point",
      coordinates: [pos.coords.latitude, pos.coords.longitude]
    }
    this.subscribers.map((callback)=>{
      if(this.point){
        callback(this.point);
      }
      return callback;
    });
  }
}