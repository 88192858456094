import { Divider, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import requests from 'api/requests';
import CarLink from 'components/carLink';
import CopyOrderID from 'components/copyClick/CopyOrderID';
import DriverLink from 'components/driverLink';
import OrderIDLink from 'components/OrderIDLink';
import AuthModel from 'models/AuthModel';
import { Feature } from 'ol';
import React, { useEffect, useState } from 'react';
import { CallSignInfo } from './types';

interface IProps {
  icon:Feature<any>;
  tariffClassesDict:{[id:number]: string};
}

export default function PopUp(props:IProps) {
  const {
    icon,
    tariffClassesDict
  } = props;

  const [info, setInfo] = useState<CallSignInfo|null>(null)
  const callSign = icon.getProperties().info

  const getTariffClassName = (id:number):string => {
    return tariffClassesDict[id] || "-";
  }

  useEffect(()=>{
    if(!callSign?.call_sign) return;

    setInfo(null);
    requests.get(`/car/shift/${callSign.call_sign}/`).then((r)=>{
      icon.setProperties({
        full_info: r.body
      })
      setInfo(r.body)
    })

  }, [icon, callSign.call_sign])

  if(!callSign) return null;

  return (
    <div style={{minWidth: 150}}>
      <Typography variant="caption" component="div">
        <b>
        {AuthModel.permissions.includes("admin") ? (
          <CarLink
            carID={info?.car.id}
            color="inherit"
          >{callSign.call_sign}</CarLink>
        ):callSign.call_sign}</b>
      </Typography>
      <Typography variant="caption" component="div">
        {info === null ? (<Skeleton />) : info.state_number}
      </Typography>
      <Typography variant="caption" component="div">
        {info === null ? (<Skeleton />) : getTariffClassName(info.car.tariff_class)}
      </Typography>
      <Typography variant="caption" component="div">
        {info === null ? (<Skeleton />) : `${info.car.brand} ${info.car.color}`}
      </Typography>
      {info !== null && info.driver ? (
        <Typography variant="caption" component="div">
          {AuthModel.permissions.includes("admin") ? (
            <DriverLink
              driverID={info.driver.id}
              color="inherit"
            >{info.driver.name}</DriverLink>
          ):info.driver.name}
        </Typography>
      ):null}
      {!!(info?.orders && info?.orders.length) && (
        <React.Fragment>
          <Divider sx={{my: 1}}/>
          <Typography variant="caption" component="div">
            {info.orders.map((i, index) => (
              <div key={i.id}>
                {AuthModel.permissions.includes("admin") ? (
                  <OrderIDLink OrderID={i.id}/>
                ):(
                  <CopyOrderID OrderID={i.id}/>
                )}
              </div>
            ))}
          </Typography>
        </React.Fragment>
      )}
    </div>
  );
}