import React, { useEffect, useReducer, Reducer, useMemo, useCallback } from 'react';
import RequestsContext, { ProviderContext } from './RequestsContext';
import { useProgress } from 'components/progress';
import { IResponse } from './';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import Emoji from 'components/Emoji';

interface RequestsProviderProps{
  children: React.ReactNode;
}

interface IErrorMessage{
  title: string;
  error: string;
}

function reducer(state:Promise<IResponse>[], q?:Promise<IResponse>) {
  let _state = state.filter((s:any) => !s.is_finally);
  if(q){
    _state.push(q);
  }
  return _state;
}

export default function RequestsProvider(props: RequestsProviderProps) {
  const { showProgress, hideProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  
  const [ activeRequests, setActiveRequests ] = useReducer<Reducer<any, any>>(reducer, [] as any);

  const errorMessages = useCallback((body:any):IErrorMessage[] => {
    let errors:IErrorMessage[] = [];

    const { labels, ..._errors } = body;
    Object.keys(_errors).map((_key:string) => {
      if(Array.isArray(_errors[_key])){
        if(typeof _errors[_key][0] == 'string'){
          errors = errors.concat(_errors[_key].map((_e:string)=>{
            return {
              title: labels[_key],
              error: _e
            }
          }));
        }
      }else if(typeof _errors[_key] == 'object'){
        errors = errors.concat(errorMessages({
          labels: labels[_key],
          ..._errors[_key]
        }))
      }
      return null;
    })
    return errors
  }, []);
  
  useEffect(() => {
    function handleStartRequest(r:CustomEventInit<{response:Promise<IResponse>, requests:any}>) {
      setActiveRequests(r.detail);

      if(r.detail?.requests?._with_error_message){
        r.detail?.response?.catch((response)=>{
          if(response === null) return;

          if(response.status >= 500 && response.status < 600){
            enqueueSnackbar((
              <div>
                <Typography>Щось сталося на сервері <Emoji symbol="🤷‍♀️"/></Typography>
                <span>Ми вже отримали звіт та працюємо над виправленням помилки.</span><br/>
                <span>Спробуйте пізніше.</span>
              </div>
            ), {variant: 'error'})
          }else if(response.status >= 400 && response.status < 500){
            if(response.body?.detail){
              enqueueSnackbar(response.body?.detail, {variant: 'error'})
            }else if(response.body){
              let messages = errorMessages(response.body);
              if(messages){
                enqueueSnackbar((
                  <div>
                    {messages.map((i, index)=>(
                      <div key={index}>
                        {!!i.title?(
                          <React.Fragment>
                            <b>{i.title}</b>:&nbsp;
                          </React.Fragment>
                        ):null}
                        {i.error}
                      </div>
                    ))}
                  </div>
                ), {variant: 'error'})
              }else{
                enqueueSnackbar((
                  <div>Невідома помилка в запиті <Emoji symbol="🤷‍♀️"/></div>
                ), {variant: 'error'})
              }
            }else{
              enqueueSnackbar((
                <div>Невідома помилка в запиті <Emoji symbol="🤷‍♀️"/></div>
              ), {variant: 'error'})
            }
          }
        })
      }
      
      r.detail?.response?.catch(()=>{
        
      }).finally(()=>{
        // @ts-ignore
        r.detail.is_finally = true;
        setActiveRequests(undefined);
      })
    }

    window.addEventListener("StartRequest", handleStartRequest, false);
    return () => {
      window.removeEventListener("StartRequest", handleStartRequest);
    };
  }, [enqueueSnackbar, errorMessages]);

  const requestsWithLoader = useMemo(()=>{
    return activeRequests.filter((i:any)=>i.requests._with_loader)
  }, [activeRequests])
  

  useEffect(()=>{
    const _isRequest = !!requestsWithLoader.length;
    if(_isRequest){
      showProgress();
    }else{
      setTimeout(()=>{
        if(!requestsWithLoader.length){
          hideProgress();
        }
      }, 500)
    }
  }, [requestsWithLoader, showProgress, hideProgress])

  const context:ProviderContext = {
    isRequest: !!activeRequests.length,
  }

  return (
    <RequestsContext.Provider value={context}>
        {props.children}
    </RequestsContext.Provider>
  );
}