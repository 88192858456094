import React, { useState } from 'react';
import requests from 'api/requests';
import { ICompany } from './types';
import { Dialog, DialogContent, DialogActions, Button, FormControl, TextField } from '@mui/material';
import { useProgress } from 'components/progress';
import { useSnackbar } from 'notistack';
import CurrencyFormatToField from 'components/CurrencyFormatToField';


interface ICompanyComponent {
  company:ICompany;
  onSave: () => void;
  onClose: () => void;
}

export default function Company(props:ICompanyComponent) {
  const { isProgress } = useProgress();
  const [instance, setInstance] = useState({...props.company} as any);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    instance[name] = value;
    setInstance({...instance});
  }

  const update = (instance:any) => {
    requests.patch(`/corp/company/${instance.id}/`, {
      lock_balance: instance.lock_balance
    }).then((r) => {
      props.onSave();
      setInstance(null);
      enqueueSnackbar("Збережено", {variant: 'success'});
    })
  }

  return (
    <Dialog
      open={true}
      maxWidth="md"
      scroll={'paper'}
      onClose={()=>{
        !isProgress && props.onClose();
      }}
    >

      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          if(!isProgress){
            update(instance);
          }
        }}
      >
        <DialogContent dividers={true}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              label="Блокування"
              margin="normal"
              value={instance.lock_balance || 0}
              disabled={isProgress}
              onChange={handleChange('lock_balance')}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputComponent: CurrencyFormatToField as any,
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
            disabled={isProgress}
          >
            Відмінити
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isProgress}
          >
            Зберегти
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}