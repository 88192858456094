
import { Alert, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import requests from "api/requests";
import { addDays, addMinutes, format, startOfDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import TableItems from "./tableItems";
import { ICAOrder } from "./types";

const MAX_DIFF = 1000 * 60 * 60 * 24 * 30

export default function CAOrders() {
  const now = startOfDay(new Date());
  now.setHours(10)

  const [end, setEnd] = useState<Date | null>(addMinutes(now, -1));
  const [start, setStart] = useState<Date | null>(addDays(now, -7));
  const [errorEnd, setErrorEnd] = useState(false);
  const [errorStart, setErrorStart] = useState(false);
  const [items, setItems] = useState<ICAOrder[] | null>(null)

  const diff = useMemo(() => {
    if (!start || !end) {
      return 0
    } else {
      return end.getTime() - start.getTime()
    }
  }, [start, end])

  useEffect(() => {
    const abortController = new AbortController();

    let timer = setTimeout(() => {
      if (!start || !end || diff > MAX_DIFF) {
        abortController.abort();
        setItems([]);
      } else {
        requests.get("/finance/ca-orders/", {
          start: format(start, "yyyy-MM-dd HH:mm:00"),
          end: format(end, "yyyy-MM-dd HH:mm:59"),
        }, abortController.signal).then((r) => {
          setItems(r.body)
        }).catch(() => { })
      }
    }, 500)
    return () => {
      clearTimeout(timer);
      abortController.abort();
    };
  }, [start, end, diff])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} error={errorStart} />}
            label="Початок"
            value={start}
            onChange={(newValue) => {
              if (newValue) {
                if (!isNaN(newValue.getTime())) {
                  setStart(newValue);
                  setErrorStart(false);
                } else {
                  setErrorStart(true);
                }
              } else {
                setStart(newValue);
                setErrorStart(false);
              }
            }}
          />
        </Grid>
        <Grid item>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} error={errorEnd} />}
            label="Кінець"
            value={end}
            onChange={(newValue) => {
              if (newValue) {
                if (!isNaN(newValue.getTime())) {
                  setEnd(newValue);
                  setErrorEnd(false);
                } else {
                  setErrorEnd(true);
                }
              } else {
                setEnd(newValue);
                setErrorEnd(false);
              }
            }}
          />
        </Grid>
      </Grid>

      {(diff > MAX_DIFF) ? (
        <Alert severity="warning" sx={{ my: 3, mx: 'auto', maxWidth: 400 }}>
          Обрано дуже великий період<br />Максимальна кількість: 30 днів
        </Alert>
      ) : (
        <>
          {items !== null && (
            <TableItems
              items={items}
            />
          )}
        </>
      )}

    </div>
  )
}