import React from 'react';
import { IconButton, Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { IUserPermission } from './types';
import Moment from 'react-moment';
import AuthModel from 'models/AuthModel';


interface UsersListProps{
  users: IUserPermission[],
  onDelete:(user:IUserPermission) => void;
}

export default function UsersList(props:UsersListProps) {

  if(!props.users.length){
    return (
      <Typography color="textSecondary" align="center">Користувачі відсутні</Typography>
    )
  }

  return (
    <List>
        { props.users.map((user:IUserPermission) => {
          let last_auth = (<span>Остання авторизація: {
            user.user.last_auth ? (
              <Moment format="DD.MM.YYYY HH:mm:ss">
                {user.user.last_auth}
              </Moment>
            ) : "відсутня"
          }</span>);

          let email = user.user.email + "";
          if(AuthModel.info.email === user.user.email){
            email += " (Я)"
          }

          return (
            <ListItem key={user.id}>
              <ListItemText
                primary={email}
                secondary={last_auth}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                  onClick={(e)=>{
                    e.preventDefault();
                    props.onDelete(user);
                  }}
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
    </List>
  );
}