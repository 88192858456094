import React from 'react';
import { TableRow, TableCell, Typography, Chip } from '@mui/material';
import { IUser } from './types';
import Moment from 'react-moment';
import { PermIdentityOutlined } from '@mui/icons-material';


interface IRowProps{
  item:IUser,
  permissionsDict:any;
  onClick:(item:IUser) => void;
}

export default function Row(props: IRowProps) {
  const { item } = props;

  return (
    <TableRow hover sx={{cursor: 'pointer'}} onClick={() => {
      props.onClick(props.item);
    }}>
      <TableCell>{item.email}</TableCell>
      <TableCell>
        {item.last_auth ? (
            <Moment format="DD.MM.YYYY HH:mm:ss">
              {item.last_auth}
            </Moment>
          ) : (<Typography variant="caption" color="textSecondary">відсутня</Typography>)}  
      </TableCell>
      { item.is_superuser ? (
        <TableCell>
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            icon={<PermIdentityOutlined />}
            label="Суперкористувач"
          />
        </TableCell>
      ):(
        <TableCell>
          {item.permissions.map((p) => {
            const permission_title = props.permissionsDict[p.permission];
            if(!permission_title) return null;

            return (
              <Chip
                key={p.permission}
                variant="outlined"
                size="small"
                label={permission_title}
                sx={{mr: 1}}
              />
            )
          })}
        </TableCell>
      )}
    </TableRow>
  );
}