import { Grid, Hidden, InputAdornment, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import requests from 'api/requests';
import CustomTablePagination from 'components/customTablePagination';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { dataRangeItem, defaultRanges } from 'components/daterange/defaults';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import DetailOrders from './detailOrders';
import ItemsTable from './itemsTable';
import { ICollateOrder } from './types';
import { PaginationGridBox, TablePaperBox } from 'components/styled';


export default function CollateOrder() {
  const [detailClientID, setDetailClientID] = useState<number|null>(null);
  const [searchString, setSearchString] = useState('');
  const [ dateRange, setDateRange ] = useState<IDateRange>(defaultRanges[2]);
  const [page, setPage] = useState({
    first_loaded: false,
    items: null as ICollateOrder[]|null,
    items_count: 0,
    page: 0,
    limit: 30,
  })
  const dateDefinedRanges = [...defaultRanges, dataRangeItem.all(new Date())]

  const filters = useCallback(() => {
    let new_filter:any = {
    };
    if(searchString){
      new_filter.search = searchString;
    }
    if(dateRange.startDate){
      new_filter.date__gte = format(dateRange.startDate, "yyyy-MM-dd HH:mm:00");
    }
    if(dateRange.endDate){
      new_filter.date__lte = format(dateRange.endDate, "yyyy-MM-dd HH:mm:59");
    }
    return new_filter;
  }, [searchString, dateRange])

  const load = useCallback(() => {
    const abortController = new AbortController();
    window.scrollTo(0, 0);
    
    requests.get('/corp-admin/collate/order/', {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit
    }, abortController.signal).then((r) => {
      setPage(page => ({
        ...page,
        first_loaded: true,
        items: r.body.results,
        items_count: r.body.count
      }));
    })

    return () => {
      abortController.abort();
    };
  }, [page.page, page.limit, filters])
  useEffect(() => load(), [load]);

  if(page.items === null){
    return null;
  }

  return (
    <div>
      { page.first_loaded && (
        <div>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <DateRangePicker
                    dateRange={dateRange}
                    onChange={(range) => setDateRange(range)}
                    definedRanges={dateDefinedRanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <TextField
                    fullWidth
                    placeholder="Пошук"
                    value={ searchString }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value);
                      setPage({
                        ...page,
                        page: 0,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined color={'action'} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <PaginationGridBox item sm={4}>
                <CustomTablePagination
                  count={page.items_count}
                  limit={page.limit}
                  page={page.page}
                  onChange={(page_number:number) => {
                    setPage({
                      ...page,
                      page: page_number
                    });
                  }}
                />
              </PaginationGridBox>
            </Hidden>
          </Grid>
          <TablePaperBox>
            <ItemsTable
              items={page.items}
              itemClick={(item)=>{
                setDetailClientID(item.company.id);
              }}
            />
          </TablePaperBox>
          <CustomTablePagination
            count={page.items_count}
            limit={page.limit}
            page={page.page}
            onChange={(page_number:number) => {
              setPage({
                ...page,
                page: page_number
              });
            }}
          />
        </div>
      )}

      {detailClientID!==null ? (
        <DetailOrders
          clientID={detailClientID}
          dateRange={dateRange}
          onClose={()=>{
            setDetailClientID(null);
          }}
        />
      ):null}
    </div>
  );
}