import React, { useState, useCallback, useEffect } from 'react';
import requests from 'api/requests';
import { LinearProgress, Typography, Chip, styled } from '@mui/material';


const Root = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3)
}))
const ChipBox = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(),
  marginBottom: theme.spacing(),
}))

interface ISubPermissionsProps {
  userID:number;
}

export default function SubPermissions(props:ISubPermissionsProps) {
  const [ loading, setLoading ] = useState(true);
  const [ companyPermissions, setCompanyPermissions ] = useState([]);
  const [ driverPermissions, setDriverPermissions ] = useState([]);
  const [ atpPermissions, setAtpPermissions ] = useState([]);


  const load = useCallback(() => {
    setLoading(true);
    Promise.all([
      requests.getAll('/corp/company-permission/', {user: props.userID}),
      requests.getAll('/driver/driver-permission/', {user: props.userID}),
      requests.getAll('/atp/atp-permission/', {user: props.userID})
    ]).then(values => {
      const [
        responseCompany,
        responseDriver,
        responseATP
      ] = values;

      let company_ids:number[] = responseCompany.body.results.map((i:any)=>i.company);
      let driver_ids:number[] = responseDriver.body.results.map((i:any)=>i.driver);
      let atp_ids:number[] = responseATP.body.results.map((i:any)=>i.atp);

      let promises = [];
      if(company_ids.length){
        let company_promice = requests.getAll('/corp/company/', {id__in: company_ids.join(",")}).then((r) => {
          setCompanyPermissions(r.body.results)
        })
        promises.push(company_promice)
      }
      if(driver_ids.length){
        let driver_promice = requests.getAll('/base/driver/', {id__in: driver_ids.join(",")}).then((r) => {
          setDriverPermissions(r.body.results)
        })
        promises.push(driver_promice)
      }
      if(atp_ids.length){
        let atp_promice = requests.getAll('/atp/atp/', {id__in: atp_ids.join(",")}).then((r) => {
          setAtpPermissions(r.body.results)
        })
        promises.push(atp_promice)
      }

      Promise.all(promises).then(values => {
        setLoading(false);
      })
    })
  }, [props.userID])
  useEffect(() => load(), [load]);

  if(loading){
    return (<Root><LinearProgress sx={{mt: -1}} /></Root>);
  }

  return (
    <div>
      {!!companyPermissions.length && (
        <Root>
          <Typography variant="subtitle1" color="textSecondary">
            Доступ до корпоративних кабінетів
          </Typography>
          {companyPermissions.map((company:any) => (
            <ChipBox
              key={company.id}
              variant="outlined"
              size="small"
              label={company.name}
            />
          ))}
        </Root>
      )}
      {!!atpPermissions.length && (
        <Root>
          <Typography variant="subtitle1" color="textSecondary">
            Доступ до кабінетів АТП
          </Typography>
          {atpPermissions.map((atp:any) => (
            <ChipBox
              key={atp.id}
              variant="outlined"
              size="small"
              label={atp.name}
            />
          ))}
        </Root>
      )}
      {!!driverPermissions.length && (
        <Root>
          <Typography variant="subtitle1" color="textSecondary">
            Доступ до кабінетів водія
          </Typography>
          {driverPermissions.map((driver:any) => {
            let vars = [
              driver.lastname,
              driver.firstname ? (driver.firstname[0].toUpperCase() + ".") : null,
              driver.middlename ? (driver.middlename[0].toUpperCase() + ".") : null,
            ];
            vars = vars.filter(v => !!v);
            return(
              <ChipBox
                key={driver.id}
                variant="outlined"
                size="small"
                label={vars.join(" ")}
              />
            )
          })}
        </Root>
      )}
    </div>
  );
}