import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { Divider, Drawer, DrawerProps, IconButton, styled } from '@mui/material';

const drawerWidth = 260;


const Root = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 1000,
  marginLeft: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
  ...(!open && {
    marginLeft: -drawerWidth,
    pointerEvents: 'none',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

interface IProps{
  open: boolean;
  onClose: ()=>void;
  variant?: DrawerProps['variant'];

  children: JSX.Element|JSX.Element[];
}

export default function FilterDrawer(props:IProps) {

  return (
    <Root
      variant={props.variant}
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      ModalProps={{
        keepMounted: true
      }}
    >
      <div style={{flex: '0 0 auto'}}>
        <DrawerHeader>
          <IconButton onClick={props.onClose}>
            <ChevronRight />
          </IconButton>
        </DrawerHeader>
        <Divider />
      </div>
      {React.Children.toArray(props.children).map((c) => c)}
    </Root>
  );
}