import React, { useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, Box, Divider, IconButton } from '@mui/material';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, ChevronRightOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { IService, IUpdateRequest } from './types';
import columns from './columns';

interface InstanceModalProps {
  instance: IUpdateRequest;
  services: IService[];
  onClose: ()=>void;
  onApprove: (ignore: number[])=>void;
  onReject: ()=>void;
}

export default function InstanceModal(props:InstanceModalProps) {

  const {
    instance,
    services,
    onClose,
    onApprove,
    onReject,
  } = props;

  const [removeItems, setRemoveItems] = useState<number[]>([]);

  const changeExists:boolean = useMemo(()=>{
    let json1 = JSON.stringify(instance.ids.sort());
    let json2 = JSON.stringify(instance.current_ids.sort());
    return json1 !== json2;
  }, [instance])

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      
    >
      <DialogContent
        dividers={true}
      >

        {columns.map((c)=>c.items.map((i, index) => (
          <Box key={`${i.title}-${index}`}>
            <b>{i.title}:</b> {i.render(instance)}
          </Box>
        )))}
        <Divider sx={{my: 2, mx: -3}}/>
        {changeExists?(
          <Box>
            {services.map((i)=>{
              let includes = [
                instance.current_ids.includes(i.id),
                instance.ids.includes(i.id),
              ];
              return (
                <React.Fragment key={i.id}>
                  {includes.filter((i)=>i).length === 1 ? (
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        ...(removeItems.includes(i.id) && {
                          opacity: 0.3,
                          "& .name": {
                            textDecoration: 'line-through',
                          }
                        })
                      }}
                    >
                      <Box
                        sx={{
                          lineHeight: 1,
                          flexGrow: 0,
                          pr: 2
                        }}
                      >
                        {includes.map((check, index)=>(
                          <React.Fragment key={index}>
                            {!!index && (
                              <ChevronRightOutlined color="disabled"/>
                            )}
                            {check ? (
                              <CheckBoxOutlined/>
                            ):(
                              <CheckBoxOutlineBlankOutlined/>
                            )}
                          </React.Fragment>
                        ))}
                      </Box>
                      <Box className='name'>{i.name}</Box>
                      <Box sx={{
                        marginLeft: 'auto',
                      }}>
                        <IconButton
                          size='small'
                          onClick={()=>{
                            setRemoveItems((o)=>{
                              if(o.includes(i.id)){
                                o = o.filter((_i)=>_i!==i.id)
                              }else{
                                o.push(i.id);
                              }
                              return [...o];
                            })
                          }}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                      </Box>
                    </Box>
                  ):null}
                </React.Fragment>
              )
            })}
          </Box>
        ):"Зміни відсутні"}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{marginRight: 'auto'}}
        >
          Закрити
        </Button>
        <Button
          variant="contained"
          onClick={onReject}
          color="error"
        >
          Відхилити
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={()=>{
            onApprove([...removeItems]);
          }}
        >
          Прийняти
        </Button>
      </DialogActions>
      
    </Dialog>
  );
}