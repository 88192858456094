import { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextField } from "@mui/material";
import requests from "api/requests";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

interface IBaseProps{
  url: string;
  field: string;
  onSaved?: (value:unknown)=>void;
}

interface IStandardTextFieldProps extends StandardTextFieldProps, IBaseProps{}
interface IFilledTextFieldProps extends FilledTextFieldProps, IBaseProps{}
interface IOutlinedTextFieldProps extends OutlinedTextFieldProps, IBaseProps{}

type IProps = IStandardTextFieldProps | IFilledTextFieldProps | IOutlinedTextFieldProps;

export default function TextFieldBlurPatch(props:IProps){
  const { enqueueSnackbar } = useSnackbar();

  const [v, setV] = useState(props.value || "");

  const {
    label,
    url,
    field,
    value="",
    onSaved=()=>{}
  } = props

  useEffect(()=>{
    setV((value || "") as string);
  }, [value])

  const save = () => {
    if(value !== v){
      requests.patch(url, {
        [field]: v
      }).then(()=>{
        enqueueSnackbar("Збережено", {variant: 'success'});
        onSaved(v);
      })
    }
  }

  return (
    <TextField
      {...props}
      label={label}
      value={v}
      onChange={(e)=>setV(e.target.value)}
      onBlur={save}
    />
  )
}