import { DefinedRange } from "./types";

import {
	addDays,
	startOfWeek,
	endOfWeek,
	addWeeks,
	startOfMonth,
	endOfMonth,
	addMonths,
	endOfDay,
	startOfDay
} from "date-fns";

export const dataRangeItem = {
	'today': (date: Date) => {
		return {
			label: "Сьогодні",
			startDate: startOfDay(date),
			endDate: undefined
		}
	},
	'yesterday': (date: Date) => {
		return {
			label: "Учора",
			startDate: startOfDay(addDays(date, -1)),
			endDate: endOfDay(addDays(date, -1))
		}
	},
	'prev_7_days': (date: Date) => {
		return {
			label: "Попередні 7 днів",
			startDate: startOfDay(addDays(date, -7)),
			endDate: undefined
		}
	},
	'prev_30_days': (date: Date) => {
		return {
			label: "Попередні 30 днів",
			startDate: startOfDay(addDays(date, -30)),
			endDate: undefined
		}
	},
	'current_week': (date: Date) => {
		return {
			label: "Поточний тиждень",
			startDate: startOfDay(startOfWeek(date)),
			endDate: undefined
		}
	},
	'prev_week': (date: Date) => {
		return {
			label: "Попередній тиждень",
			startDate: startOfDay(startOfWeek(addWeeks(date, -1))),
			endDate: endOfDay(endOfWeek(addWeeks(date, -1)))
		}
	},
	'current_month': (date: Date) => {
		return {
			label: "Поточний місяць",
			startDate: startOfDay(startOfMonth(date)),
			endDate: undefined
		}
	},
	'prev_month': (date: Date) => {
		return {
			label: "Попередній місяць",
			startDate: startOfDay(startOfMonth(addMonths(date, -1))),
			endDate: endOfDay(endOfMonth(addMonths(date, -1)))
		}
	},
	'all': (date: Date) => {
		return {
			label: "За весь час",
			startDate: undefined,
			endDate: undefined
		}
	},
}

const getDefaultRanges = (date: Date): DefinedRange[] => [
	dataRangeItem.today(date),
	dataRangeItem.yesterday(date),
	dataRangeItem.prev_7_days(date),
	dataRangeItem.prev_30_days(date),
	dataRangeItem.current_week(date),
	dataRangeItem.prev_week(date),
	dataRangeItem.current_month(date),
	dataRangeItem.prev_month(date),
];

const getDefaultRangesWithAll = (date: Date): DefinedRange[] => {
	let items = getDefaultRanges(date);
	items.push(dataRangeItem.all(date));
	return items;
}


export const defaultRanges = getDefaultRanges(new Date());
export const defaultRangesWithAll = getDefaultRangesWithAll(new Date());
export const WEEK_DAYS = [
	"Нд",
	"Пн",
	"Вт",
	"Ср",
	"Чт",
	"Пт",
	"Сб"
];
export const MONTHS = [
	"Січень",
	"Лютий",
	"Березень",
	"Квітень",
	"Травень",
	"Червень",
	"Липень",
	"Серпень",
	"Вересень",
	"Жовтень",
	"Листопад",
	"Грудень"
];
export const TEXTS = {
	'start': "Початок",
	'end': "Кінець",
	'no_range': "Оберіть дату",
	'from': 'від',
	'to': 'до',
}