import React, { useCallback, useEffect } from 'react';
import RequestStatusForm from './form';
import requests from 'api/requests';
import { IDriverRequestStatus } from './types';
import StatusList from './statusList';
import { IStatus } from 'pages/settings/statuses/base/types';

interface IProps {
  requestID?: number;
  onStatusChange: ()=>void;

  newStatusInstance: IDriverRequestStatus|null;
  onChangeNewStatusInstance: (statusInstance:IDriverRequestStatus|null)=>void;
  addNewStatus: (statusInstance:IDriverRequestStatus)=>Promise<any>;
}

export default function RequestStatus(props:IProps) {
  const [ statuses, setStatuses] = React.useState<IDriverRequestStatus[]|null>(null);
  const [ statusTypes, setStatusTypes] = React.useState<IStatus[]>([]);

  const load = useCallback(() => {
    if(!props.requestID) return;

    requests.getAll('/admin/driver/request/status/', {
      request: props.requestID
    }).then((r) => {
      setStatuses(r.body.results);
    })
    
  }, [props.requestID])
  
  const loadStatusTypes = useCallback(() => {
    requests.get('/settings/statuses/driver-request/').then((r) => {
      setStatusTypes(r.body);
    })
  }, [])

  useEffect(() => load(), [load]);
  useEffect(() => loadStatusTypes(), [loadStatusTypes]);

  return (
    <div>

      <RequestStatusForm
        requestID={props.requestID}
        statusTypes={statusTypes}
        onCreated={()=>{
          load()
          props.onStatusChange();
        }}

        newStatusInstance={props.newStatusInstance}
        onChangeNewStatusInstance={props.onChangeNewStatusInstance}
        addNewStatus={props.addNewStatus}
      />

      {props.requestID ? (
        <StatusList
          statusTypes={statusTypes}
          statuses={statuses}
        />
      ):null}
    </div>
  );
}