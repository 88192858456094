import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IDateRange } from 'components/daterange';
import { format } from 'date-fns';
import requests from 'api/requests';
import NumberFormat from 'react-number-format';

interface IProps {
  clientID: number;
  dateRange: IDateRange;
  onClose?: ()=>void;
}

interface IDetailOrders{
  id: number;
  sum: number;
  history_sum: number;
  diff_sum: number;
}

export default function DetailOrders(props:IProps) {
  const [items, setItems] = useState<IDetailOrders[]|null>(null);

  const {
    clientID,
    dateRange,
    onClose
  } = props;

  const filters = useCallback(() => {
    let new_filter:any = {
      clientid: clientID
    };
    if(dateRange.startDate){
      new_filter.date__gte = format(dateRange.startDate, "yyyy-MM-dd HH:mm:00");
    }
    if(dateRange.endDate){
      new_filter.date__lte = format(dateRange.endDate, "yyyy-MM-dd HH:mm:59");
    }
    return new_filter;
  }, [clientID, dateRange])

  const load = useCallback(() => {
    const abortController = new AbortController();
    window.scrollTo(0, 0);
    
    requests.getAll('/corp-admin/collate/order/orders/', {
      ...filters(),
    }, abortController.signal).then((r) => {
      setItems(r.body.results)
    })

    return () => {
      abortController.abort();
    };
  }, [filters])
  useEffect(() => load(), [load]);

  const money = (value:number) => (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
  );

  if(items === null){
    return null
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      
    >
      <DialogContent>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID Замовлення</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Фактичка ціна</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Списано</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Різниця</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { items.map((item, index) => (
                <TableRow
                  hover
                  key={index}
                >
                  <TableCell>{item.id}</TableCell>
                  <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.sum)}</TableCell>
                  <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.history_sum)}</TableCell>
                  <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.diff_sum)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </DialogContent>
      <DialogActions>
          <Button
            onClick={onClose}
          >
            Закрити
          </Button>
        </DialogActions>
    </Dialog>
  );
}