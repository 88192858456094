import { Box, Divider, Paper, styled } from "@mui/material";


export const FooterDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(),
  marginLeft: theme.spacing(-1),
  marginRight: theme.spacing(-1),
}));

export const RootBox = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
  paddingTop: '2px',
}));

export const PaperBox = styled(Paper)(({ theme }) => ({
  overflowX: 'auto',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: 0,
  WebkitOverflowScrolling: 'touch',
}));


export const FilterContainer = styled("div")(({ theme }) => ({
  flex: '1 1 auto',
  padding: theme.spacing(2),
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
}));

export const FilterControls = styled("div")(({ theme }) => ({
  flex: '0 0 auto',
  marginLeft: theme.spacing(),
  marginRight: theme.spacing(),
  marginBottom: theme.spacing(),
}));

export const FilterItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));