
import { useRouteMatch } from 'react-router-dom';
import CarList from 'components/car/carList';


export default function Cars() {
  const match:any = useRouteMatch();
  const atp = match.params.id;

  return (
    <CarList
      userSettingsKey="atp-cars"
      atp={atp}
    />
  )
}