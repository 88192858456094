import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import InvoicesTableRow from './tableRow';
import { IInvoice } from './types';

interface InvoicesTableProps {
  items: IInvoice[];
  onClick:(item:IInvoice) => void;

  showCompanyColumn?:boolean;
}

export default function InvoicesTable(props:InvoicesTableProps) {
  return (
    <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{whiteSpace: 'nowrap'}}>№ рахунку</TableCell>
              <TableCell>Дата</TableCell>
              {props.showCompanyColumn &&(
                <TableCell style={{width: "100%"}}>Кліент</TableCell>
              )}
              <TableCell style={{whiteSpace: 'nowrap'}} align="right">Сума, грн.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { props.items.map((item:any) => (
              <InvoicesTableRow
                key={item.id}
                item={item}
                onClick={props.onClick}
                showCompanyColumn={props.showCompanyColumn}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}