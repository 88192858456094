import { Grid } from "@mui/material";
import requests from "api/requests";
import { TariffClass } from "components/map/types";
import React, { useCallback, useEffect, useState } from "react";
import LoyaltyControl from "./loyaltyControl";
import LoyaltyTimeTable from "./loyaltyTime";
import { ILoyalty, ILoyaltyTime } from "./types";



export default function TariffLoyaltyTime(){
  const [loyalty, setLoyalty] = useState<ILoyalty[]>([]);
  const [loyaltyTime, setLoyaltyTime] = useState<ILoyaltyTime[]>([]);
  const [carClasses, setCarClasses] = useState<TariffClass[]>([]);

  const loadLoyalty = useCallback(()=>{
    requests.get("/admin/tariff/loyalty/").then((r)=>{
      setLoyalty(r.body)
    })
  }, [])

  const loadLoyaltyTime = useCallback(()=>{
    requests.get("/admin/tariff/loyalty-time/", {
      loyaltyname__turnon: 1
    }).then((r)=>{
      setLoyaltyTime(r.body)
    })
  }, [])

  const loadCarClasses = useCallback(()=>{
    requests.get("/car/class/").then((r)=>{
      setCarClasses(r.body)
    })
  }, [])

  useEffect(loadLoyalty, [loadLoyalty])
  useEffect(loadLoyaltyTime, [loadLoyaltyTime])
  useEffect(loadCarClasses, [loadCarClasses])


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <LoyaltyTimeTable
            carClasses={carClasses}
            loyalty={loyalty}
            loyaltyTime={loyaltyTime}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LoyaltyControl
            carClasses={carClasses}
            loyalty={loyalty}
            onUpdated={()=>{
              loadLoyalty()
              loadLoyaltyTime()
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}