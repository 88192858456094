import React from 'react';
import { FormControl, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { ATP, Status, TariffClass } from './types';
import MultipleSelect from 'components/multipleSelect';

interface IControlsProps {
  atp?:ATP|undefined;
  
  availableFilteringOrders:boolean;
  tariffClasses:TariffClass[];
  statuses:Status[];
  
  tariffClassesCheck:number[];
  statusesCheck:number[];
  onlyATP:boolean;
  filteringOrders:boolean;
  callsign:string;

  onChangeTariffClasses:(value:any[])=>void;
  onChangeStatuses:(value:any[])=>void;
  onChangeCallsign:(value:string)=>void;
  onChangeOnlyATP:(value:boolean)=>void;
  onChangeFilteringOrders:(value:boolean)=>void;
}

export default function Controls(props:IControlsProps) {

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={"auto"}>
        <MultipleSelect
          placeholder="Клас замовлення"
          value={props.tariffClassesCheck}
          items={props.tariffClasses}
          onChange={props.onChangeTariffClasses}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <MultipleSelect
          placeholder="Статус"
          value={props.statusesCheck}
          items={props.statuses}
          onChange={props.onChangeStatuses}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <FormControl fullWidth sx={{minWidth: 120}}>
          <TextField
            placeholder="Позивний"
            value={props.callsign}
            onChange={(e:any) => {
              props.onChangeCallsign(e.target.value)
            }}
          />
        </FormControl>
      </Grid>
      {props.atp && (
        <Grid item xs={12} sm={"auto"}>
          <FormControlLabel
            control={(
              <Switch
                checked={props.onlyATP}
                color="primary"
                onChange={(e:any) => {
                  props.onChangeOnlyATP(e.target.checked)
                }}
              />
            )}
            label={props.atp.name}
          />
        </Grid>
      )}
      {props.availableFilteringOrders && (
        <Grid item xs={12} sm={"auto"}>
          <FormControlLabel
            control={(
              <Switch
                checked={props.filteringOrders}
                color="primary"
                onChange={(e:any) => {
                  props.onChangeFilteringOrders(e.target.checked)
                }}
              />
            )}
            label="Фільтрують замовлення"
          />
        </Grid>
      )}
    </Grid>
  );
}