import { Typography } from "@mui/material";
import { FilteredTableColumn } from "components/FilteredTable/types";
import { IUpdateRequest } from "./types";
import Moment from "react-moment";

const columns: FilteredTableColumn[] = [
  {
    title: 'Дата створення',
    items: [
      {
        title: 'Дата створення',
        render: (row:IUpdateRequest) => (
          <Moment
            date={row.created}
            format="DD.MM.YY HH:mm"
          />
        ),
        style: {
          whiteSpace: 'nowrap'
        },
      }
    ]
  },
  {
    title: 'Позивний',
    items: [
      {
        title: 'Позивний',
        render: (row:IUpdateRequest) => {
          let call_sign:any = null;
          if(row.obj_type === "car"){
            call_sign = row.car?.call_sign;
          }else if(row.obj_type === "driver"){
            call_sign = row.driver?.call_sign;
          }
          return call_sign || null
        },
      },
    ]
  },
  {
    title: 'Зміни для',
    items: [
      {
        title: 'Зміни для',
        render: (row:IUpdateRequest) => {
          if(row.obj_type === "car"){
            return `${row.car?.mark} (${row.car?.statenum})`
          }else if(row.obj_type === "driver"){
            let name = [
              row.driver?.lastname,
              row.driver?.firstname,
              row.driver?.middlename,
            ];
            return name.join(" ");
          }
          return null
        },
      }
    ]
  },
  {
    title: 'АТП',
    items: [
      {
        title: 'АТП',
        render: (row:IUpdateRequest) => {
          let atp:any = null;
          if(row.obj_type === "car"){
            atp = row.car?.atp?.name;
          }else if(row.obj_type === "driver"){
            atp = row.driver?.atp?.name;
          }
          if(!!atp){
            return (
              <Typography variant='caption' color='textSecondary'>{atp}</Typography>
            )
          }
          return null
        },
      },
    ]
  },
];

export default columns;