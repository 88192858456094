import React from "react";
import moment from 'moment';
import { Divider, Icon, Typography } from "@mui/material";
import { FilteredTableColumn } from "components/FilteredTable/types";
import { IExtendData, IRating } from "./types";
import LightTooltip from "components/LightTooltip";
import { Rating } from "@mui/material";
import CopyPhone from "components/copyClick/CopyPhone";
import OrderIDLink from "components/OrderIDLink";
import DriverLink from "components/driverLink";

const columns: FilteredTableColumn[] = [
  {
    title: 'Відгук',
    width: 300,
    items: [
      {
        title: 'Дата відгука',
        render: (row:IRating) => (
          <Typography variant='caption' color='textSecondary'>
            {moment(row.created).format("DD.MM.YY HH:mm")}
          </Typography>
        ),
        style: {
          whiteSpace: 'nowrap'
        },
      },
      {
        title: 'Оцінка',
        render: (row:IRating) => (
          <div style={{marginLeft: -5}}>
            <Rating name="read-only" value={row.rating} readOnly size='small' />
          </div>
        ),
      },
      {
        title: 'Відгук',
        render: (row:IRating) => {
          const comment = row.comment;
          
          const _params = (row.items || []) as string[];
          const count = _params.length;
          if(count === 0 && !comment) return (<div></div>);
          
          const tooltip = (
            <React.Fragment>
              {!!comment ? (
                <Typography variant="caption" component="p" style={{whiteSpace: 'pre-wrap'}}>{comment}</Typography>
              ):null}
    
              {!!comment && count>1 ? ( <Divider style={{margin: '5px 0'}} /> ):null}
    
              {count>0 ? (
                <React.Fragment>
                  {_params.map((i, index)=>(
                    <Typography key={index} variant="caption" component="p">{i}</Typography>
                  ))}
                </React.Fragment>
              ):null}
            </React.Fragment>
          );
          
          return (
            <LightTooltip
              title={tooltip}
            >
              <span style={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                maxWidth: 300,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {(Number(!!comment) + _params.length) > 1 ? (
                  <span>
                    <Icon style={{
                      fontSize: '0.9em',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }} color='disabled'>chat_bubble_outline</Icon>
                    &nbsp;
                  </span>
                ) : null}
                {!!comment ? comment : _params[0]}
              </span>
            </LightTooltip>
          )
        }
      },
    ]
  },
  {
    title: 'Статус',
    items: [
      {
        title: 'Статус',
        render: (row:IRating, extendData?:IExtendData) => {
          const statusDict = extendData?.statusDict
          if(row.laststatus === null || statusDict === undefined) return null;

          return (
            <div>
              {statusDict[row.laststatus?.status || 0]?.name || ''}
              {(row.laststatus?.message) ? (
                <LightTooltip
                  title={(
                    <div style={{whiteSpace: 'pre-wrap'}}>{row.laststatus?.message}</div>
                  )}
                >
                  <span>
                    &nbsp;&nbsp;
                    <Icon style={{
                      fontSize: '1em',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }} color='disabled'>chat_bubble_outline</Icon>
                  </span>
                </LightTooltip>
              ):null}
            </div>
          )
        }
      }
    ]
  },
  {
    title: 'Замовлення',
    items: [
      {
        title: '№ замовлення',
        render: (row:IRating) => {
          if(row.orderid!==null){
            return (
              <OrderIDLink OrderID={row.orderid} />
            )
          }
          return null;
        },
        style: {
          whiteSpace: 'nowrap'
        }
      },
      {
        title: 'Клас замовлення',
        render: (row:IRating) => row.order?.tariff_class,
      },
      {
        title: 'Дата закриття замовлення',
        render: (row:IRating) => {
          if(row.order!==null){
            return (
              <Typography variant='caption' color='textSecondary'>
                {moment(row.order?.getresultdatetime).format("DD.MM.YY HH:mm")}
              </Typography>
            )
          }
          return null;
        },
        style: {
          whiteSpace: 'nowrap'
        }
      },
    ]
  },
  {
    title: 'Позивний',
    items: [
      {
        title: 'Позивний',
        render: (row:IRating) => (
          <React.Fragment>
            {row.call_sign}
            {row.car?.isarchive?(
              <Typography variant='caption' color='textSecondary'>
                &nbsp;(Архів)
              </Typography>
            ):null}
          </React.Fragment>
        )
      },
      {
        title: 'Клас авто',
        render: (row:IRating) => row.car?.tariff_class,
      },
      {
        title: 'Марка авто',
        render: (row:IRating) => row.car?.mark
      }
    ]
  },
  {
    title: 'Водій/АТП',
    items: [
      {
        title: 'Водій',
        render: (row:IRating) => {
          let names = [
            row.driver?.lastname,
            row.driver?.firstname,
            row.driver?.middlename,
          ]
          names = names.filter((i)=>!!i)
          return <DriverLink
            driverID={row.driver?.id}
            color="inherit"
          >{names.join(" ")}</DriverLink>
        }
      },
      {
        title: 'АТП',
        render: (row:IRating) => {
          if(!row.car?.atp) return null;
          return (
            <Typography variant='caption' color='textSecondary'>
              {row.car.atp.name}
            </Typography>
          );
        }
      }
    ]
  },
  {
    title: 'Пасажир',
    items: [
      {
        title: 'Имя пасажира',
        render: (row:IRating) => row.order?.fio
      },
      {
        title: 'Телефон пасажира',
        render: (row:IRating) => {
          if(row.order!==null && row.order.phone!==null){
            return (
              <CopyPhone phone={row.order.phone.number} />
            )
          }
          return null;
        },
        style: {
          whiteSpace: 'nowrap'
        }
      },
      {
        title: 'Кількість поїздок пасажира',
        render: (row:IRating) => {
          if(row.order?.phone!==null){
            return `Поїздок: ${row.order?.phone.numbertrips}`;
          }
          return null;
        }
      }
    ]
  },
];

export default columns;