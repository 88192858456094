import { Tooltip } from "@mui/material";
import { TariffClass } from "components/map/types"
import React, { useMemo } from "react"
import NumberFormat from "react-number-format";
import { ColorTypography, PriceTypography, TableCellBorder } from "./styles";
import { IClassPrice } from "./types";

interface IProps{
  prices?: {
    prod: IClassPrice[],
    rezerv: IClassPrice[],
  };
  carClasses: TariffClass[];
  onClick: ()=>void;
}

export default function ClassPrices(props:IProps){
  const {
    prices,
    carClasses,
    onClick
  } = props;

  const prodPriceByID = useMemo(()=>{
    let res:{[id: number]:IClassPrice} = {};
    (prices?.prod || []).forEach((i)=>{
      res[i.id] = i;
    })
    return res;
  }, [prices])

  const rezervPriceByID = useMemo(()=>{
    let res:{[id: number]:IClassPrice} = {};
    (prices?.rezerv || []).forEach((i)=>{
      res[i.id] = i;
    })
    return res;
  }, [prices])

  const diff = (a:number, b:number):number => {
    if(a===0 || b===0) return 0;

    if(a>b){
      return a/b
    }
    return b/a
  }

  return (
    <React.Fragment>
      {carClasses.map((car_class, car_class_index)=>{
        const pPrice = prodPriceByID[car_class.id]?.price || "";
        const rPrice = rezervPriceByID[car_class.id]?.price || "";
        return (
          <TableCellBorder
            BRight={car_class_index===(carClasses.length-1)}
            key={car_class.id}
            onClick={onClick}
          >
            <PriceTypography diffPrice={pPrice!==rPrice}>
              <Tooltip title="Prod"><span>{pPrice}</span></Tooltip><br/>
              <Tooltip title="Rezerv"><small>{rPrice}</small></Tooltip>
              {pPrice!==rPrice?(
                <ColorTypography
                  variant="caption"
                  red={pPrice>rPrice}
                  green={pPrice<rPrice}
                > (<NumberFormat
                  value={diff(pPrice || 0, rPrice || 0)}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  />)</ColorTypography>
              ):null}
            </PriceTypography>
          </TableCellBorder>
      )
      })}
    </React.Fragment>
  )
}