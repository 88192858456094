import React, { useEffect, useState } from 'react';
import { Dialog, Grid, Typography, List, ListItem, Icon } from '@mui/material';
import { IChoice, IRating } from '../types';
import RequestStatus from './status';
import { IRatingStatus } from './status/types';
import moment from 'moment';
import { Rating } from '@mui/material';
import CopyText from 'components/copyText';
import { IStatus } from 'pages/settings/statuses/base/types';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import RatingForm from './ratingForm';
import CopyPhone from 'components/copyClick/CopyPhone';
import CopyOrderID from 'components/copyClick/CopyOrderID';
import Rank from 'components/driver/rank';

interface InstanceModalProps {
  instance: IRating;
  statusTypes: IStatus[];
  ratingChoices: IChoice[];
  onAdded?: ()=>void;
  onClose?: ()=>void;
  onStatusChange?: ()=>void;
}

export default function InstanceModal(props:InstanceModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [newStatusInstance, setNewStatusInstance] = useState<IRatingStatus|null>(null);
  const [instance, setInstance] = useState<IRating>(props.instance);

  const {
    statusTypes
  } = props

  useEffect(()=>{
    setInstance({...props.instance})
  }, [props.instance])

  const {
    ratingChoices,
    onAdded,
    onClose,
    onStatusChange
  } = props;

  const addNewStatus = (statusInstance:IRatingStatus):Promise<any> => {
    if(statusInstance.rating){
      return requests.post('/admin/webcab/rating/status/', statusInstance).then((r) => {
        enqueueSnackbar("Статус додано", {variant: 'success'});
        return r.body;
      })
    }
    return new Promise((resolve, reject) => {
      requests.post('/admin/webcab/rating/', instance).then((r) => {
        enqueueSnackbar("Відгук додано", {variant: 'success'});
        if(statusInstance.status){
          const rating:IRating = r.body;
          const status:IRatingStatus = {
            ...statusInstance,
            rating: rating.id!
          }
          requests.post('/admin/webcab/rating/status/', status).then((r) => {
            enqueueSnackbar("Статус додано", {variant: 'success'});
            resolve(r.body);
          }).catch(()=>{
            resolve(null);
          })
        }else{
          resolve(null);
        }
      }).catch(reject);
    }).then((r)=>{
      if(!!onAdded){
        onAdded();
      }
      return r;
    });
  }

  return (
    <React.Fragment>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="sm"
        onClose={onClose}
        
      >
          <List>
            <ListItem divider>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Rating
                    name="read-only"
                    value={instance.rating}
                    readOnly={!!instance.id}
                    onChange={(e, value)=>{
                      setInstance((i)=>{
                        i.rating = value || 0
                        return {...i}
                      })
                    }}
                  />
                </Grid>
                <Grid>
                  {!!instance.created?(
                    <Typography color='textSecondary'>
                      {moment(instance.created).format("DD.MM.YY HH:mm")}
                    </Typography>
                  ):null}
                </Grid>
              </Grid>
            </ListItem>
            
            {!instance.id?(
              <ListItem divider>
                <RatingForm
                  instance={instance}
                  ratingChoices={ratingChoices}
                  onCommentChange={(v)=>setInstance((i)=>({
                    ...i,
                    comment: v
                  }))}
                  onItemsChange={(v)=>setInstance((i)=>({
                    ...i,
                    items: v
                  }))}
                  onOrderIDChange={(v)=>{
                    
                    setInstance((i)=>({
                      ...i,
                      orderid: v
                    }));

                    if(v){
                      requests.withoutErrorMessage().get(`/admin/webcab/rating/order-info/${v}/`).then((r)=>{
                        setInstance((i)=>({
                          ...i,
                          ...r.body
                        }));
                      })
                    }
                  }}
                />
              </ListItem>
            ):null}

            {!!instance.id && (instance.comment || !!instance.items.length || !!instance.creator)?(
              <ListItem divider>
                <div>
                  {instance.comment ? (<Typography variant='body2' style={{whiteSpace: 'pre-wrap'}}>{instance.comment}</Typography>) : null}
                  {instance.items.length ? (
                    <Typography variant='body2' component='div'>
                      {instance.items.map((i, index)=>(
                        <div key={index}>
                          <Icon
                            sx={{
                              fontSize: '1em',
                              verticalAlign: 'middle',
                            }}
                            color='disabled'
                          >check</Icon>&nbsp;&nbsp;{i}
                        </div>
                      ))}
                    </Typography>
                  ):null}
                  {instance.creator ? (<Typography variant='caption' color="textSecondary"><i>Створив: {instance.creator}</i></Typography>) : null}
                </div>
              </ListItem>
            ):null}

            {instance.order !== null && instance.orderid !== null?(
              <ListItem divider>
                <div style={{width: '100%'}}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid>
                      <Typography variant='subtitle1' gutterBottom>Замовлення №&nbsp;
                      <CopyOrderID OrderID={instance.orderid} />
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant='subtitle1' color='textSecondary' gutterBottom>
                        {moment(instance.order.getresultdatetime).format("DD.MM.YY HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  <Typography variant='body2'>
                    <Typography variant='caption' color='textSecondary'>Клас:&nbsp;</Typography>
                    {instance.order?.tariff_class}
                  </Typography>
                  {instance.order.fio?(
                    <Typography variant='body2'>
                      <Typography variant='caption' color='textSecondary'>І'мя:&nbsp;</Typography>
                      {instance.order.fio}
                    </Typography>
                  ):null}
                  {!!instance.order.phone?(
                    <React.Fragment>
                      <Typography variant='body2'>
                        <Typography variant='caption' color='textSecondary'>Телефон:&nbsp;</Typography>
                        <CopyPhone phone={instance.order.phone.number} />
                      </Typography>
                      <Typography variant='body2'>
                        <Typography variant='caption' color='textSecondary'>Кількість поїздок:&nbsp;</Typography>
                        {instance.order.phone.numbertrips}
                      </Typography>
                    </React.Fragment>
                  ):null}
                </div>
              </ListItem>
            ):null}
            {instance.car !== null ? (
              <ListItem divider>
                <div style={{width: '100%'}}>
                  <Typography variant='subtitle1' gutterBottom>Позивний&nbsp;
                    {instance.call_sign?(
                      <CopyText text={instance.call_sign} forceAddCopyStyle>{instance.call_sign}</CopyText>
                    ):"-"}

                    {instance.car.isarchive?(
                      <Typography variant='caption' color='textSecondary'>
                        &nbsp;(Архів)
                      </Typography>
                    ):null}
                  </Typography>
                  
                  <Typography variant='body2'>
                    <Typography variant='caption' color='textSecondary'>Клас:&nbsp;</Typography>
                    {instance.car.tariff_class}
                  </Typography>
                  <Typography variant='body2'>
                    <Typography variant='caption' color='textSecondary'>Марка:&nbsp;</Typography>
                    {instance.car.mark}
                  </Typography>
                  <Typography variant='body2'>
                    <Typography variant='caption' color='textSecondary'>Гос. номер:&nbsp;</Typography>
                    {instance.car.statenum}
                  </Typography>
                  <Typography variant='body2'>
                    <Typography variant='caption' color='textSecondary'>Водій:&nbsp;</Typography>
                    {[
                      instance.driver?.lastname,
                      instance.driver?.firstname,
                      instance.driver?.middlename,
                    ].filter((i)=>!!i).join(" ")}
                    {!!instance.driver?(
                      <sup>
                        &nbsp;<Rank value={instance.driver.rank}/>
                      </sup>
                    ):null}
                  </Typography>
                  <Typography variant='body2'>
                    <Typography variant='caption' color='textSecondary'>АТП:&nbsp;</Typography>
                    {instance.car.atp?.name}
                  </Typography>
                </div>
              </ListItem>
            ):null}

            <ListItem>
              <div style={{width: '100%'}}>
                <RequestStatus
                  ratingID={instance.id}
                  statusTypes={statusTypes}
                  onStatusChange={()=>{
                    if(onStatusChange){
                      onStatusChange()
                    }
                  }}
                  addNewStatus={addNewStatus}

                  newStatusInstance={newStatusInstance}
                  onChangeNewStatusInstance={setNewStatusInstance}
                />
              </div>
            </ListItem>
          </List>
      </Dialog>
    </React.Fragment>
  );
}