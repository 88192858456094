
import DriverList from 'components/driver/driverList';


export default function Drivers() {
  return (
    <DriverList
      userSettingsKey="admin-drivers"
    />
  )
}