import { Card, Checkbox, List, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import React from "react";
import { IChoice, IRating } from "../types";

interface IProps{
  instance: IRating;
  ratingChoices: IChoice[];

  onOrderIDChange: (value:number|null)=>void;
  onCommentChange: (value:string)=>void;
  onItemsChange: (value:string[])=>void;
}

export default function RatingForm(props:IProps){

  const {
    instance,
    ratingChoices,
    onOrderIDChange,
    onCommentChange,
    onItemsChange
  } = props

  const toggleChoice = (choice:IChoice) => () => {
    const index = instance.items.indexOf(choice.name);
    let items = [...instance.items];
    if(index > -1){
      items.splice(index, 1);
    }else{
      items.push(choice.name);
    }
    onItemsChange(items);
  }

  return (
    <div style={{width: '100%'}}>
      <TextField
        label="ID Замовлення"
        fullWidth
        type="number"
        value={instance.orderid || ""}
        onChange={(e)=>onOrderIDChange(((e.target.value as any)-0)||null)}
        margin="dense"
      />

      <TextField
        label="Відгук"
        fullWidth
        multiline
        value={instance.comment || ""}
        onChange={(e)=>onCommentChange(e.target.value)}
        margin="dense"
      /><br/><br/>

      <Card variant="outlined">
        <List dense component="div" role="list">
          {ratingChoices.map((choice) => {
            const labelId = `list-item-${choice.id}-label`;

            return (
              <ListItem
                key={choice.id}
                role="listitem"
                button
                onClick={toggleChoice(choice)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={instance.items.indexOf(choice.name) > -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={choice.name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    </div>
  )
}