import React, { useState, useCallback, useEffect, useMemo } from 'react';
import requests from 'api/requests';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Hidden, Button, Fab, TextField, InputAdornment, TablePagination, FormControl, Select, MenuItem } from '@mui/material';
import Row from './row';
import { IPermission, IUser } from './types';
import { AddOutlined, SearchOutlined } from '@mui/icons-material';
import UserDialog from './dialog';
import { PaginationGridBox, TablePaperBox } from 'components/styled';

export default function SettingsUser() {
  const [permissionsList, setPermissionsList] = useState([]);
  const [instance, setInstance] = useState<IUser|null|undefined>(undefined);
  const [dictLoaded, setDictLoaded] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [permissionFilter, setPermissionFilter] = useState('');
  const [page, setPage] = useState({
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })

  const filters = useCallback(() => {
    let new_filter:any = {};
    if(searchString){
      new_filter.search = searchString;
    }
    if(permissionFilter){
      if(permissionFilter === 'superuser'){
        new_filter.is_superuser = 'true';
      }else{
        new_filter.permission = permissionFilter;
        new_filter.is_superuser = 'false';
      }
    }
    return new_filter;
  }, [searchString, permissionFilter])

  const load = useCallback(() => {
    const abortController = new AbortController();

    window.scrollTo(0, 0);

    requests.get('/settings/user/', {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit,
    }, abortController.signal).then((r) => {
      const users = r.body.results;
      const user_count = r.body.count;
      const user_ids = users.map((u:IUser) => u.id);

      if(user_ids.length){
        requests.getAll('/settings/permission/', {
          user__in: user_ids.join(",")
        }, abortController.signal).then((r) => {
          let user_permissions = {} as any;
          r.body.results.map((p:IPermission) => {
            if(user_permissions[p.user] === undefined){
              user_permissions[p.user] = [];
            }
            user_permissions[p.user].push(p);
            return p;
          })
          users.map((u:IUser) => {
            u.permissions = user_permissions[u.id] || [];
            return u;
          });
          setPage(page => ({
            ...page,
            items: users,
            items_count: user_count
          }));
        })
      }else{
        setPage(page => ({
          ...page,
          items: users,
          items_count: user_count
        }));
      }
    })

    return () => {
      abortController.abort();
    };
  }, [page.page, page.limit, filters])
  useEffect(() => load(), [load]);

  const dict_load = useCallback(() => {
    requests.get('/settings/dict/permission/').then((r) => {
      setPermissionsList(r.body);
      setDictLoaded(true);
    })
  }, [])
  useEffect(() => dict_load(), [dict_load]);

  const permissionsDict = useMemo(()=>{
    let permissions_dict = {} as any;
    permissionsList.map((p:any) => {
      permissions_dict[p.id] = p.value;
      return p;
    })
    return permissions_dict
  },[permissionsList])

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )
  
  if(!dictLoaded){
    return null;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} xl={6}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3} justifyContent="flex-start" direction="row" wrap="nowrap">
                <Grid item>
                  <Hidden lgDown>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddOutlined />}
                      onClick={()=>{ setInstance(null) }}
                    >
                      Додати
                    </Button>
                  </Hidden>

                  <Hidden lgUp>
                    <Fab
                      size="small"
                      color="secondary"
                      onClick={()=>{ setInstance(null) }}
                    >
                      <AddOutlined />
                    </Fab>
                  </Hidden>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Пошук"
                      value={ searchString }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchString(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined color={'action'} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  value={permissionFilter}
                  displayEmpty
                  onChange={(e: any) => {
                    setPermissionFilter(e.target.value);
                  }}
                >
                  <MenuItem value="">Всі права доступу</MenuItem>
                  <MenuItem value={'superuser'}>Суперкористувач</MenuItem>
                  {permissionsList.map((i:any) => (
                    <MenuItem key={i.id} value={i.id}>{i.value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          
        </Grid>
        <Hidden mdDown>
          <PaginationGridBox item sm={6} md={4} xl={6}>
            { renderPagination() }
          </PaginationGridBox>
        </Hidden>
      </Grid>
      <TablePaperBox>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>E-mail</TableCell>
              <TableCell>Остання авторизація</TableCell>
              <TableCell>Права</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.items.map((item, index) => (
              <Row
                key={index}
                item={item}
                permissionsDict={permissionsDict}
                onClick={(i:IUser) => { setInstance(i) }}
              />
            ))}
          </TableBody>
        </Table>
      </TablePaperBox>
      { renderPagination() }
      
      { instance !== undefined && (
        <UserDialog
          item={instance}
          permissionsDict={permissionsDict}
          onClose={()=>{ setInstance(undefined) }}
          onDone={()=>{
            setInstance(undefined);
            load();
          }}
        />
      )}
    </div>
  );
}