import React from 'react';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { StyledTableCell, StyledTableRow, TableBox } from './styles';
import TableRowOffset from './tableRow';


interface ITableOffsetComponent {
  items:any[];
  total:any;
  dotted_lines:boolean;
  not_found_line:string|null;
}

export default function TableOffset(props:ITableOffsetComponent) {

  return (
    <TableContainer>
        <TableBox size="small">
          <TableHead>
            <TableRow>
              <TableCell>Контрагент</TableCell>
              <TableCell align="right">Кількість виконаних замовлень (всього)</TableCell>
              <TableCell align="right">Сума за готівковими замовленнями (Нал)</TableCell>
              <TableCell align="right">Сума за безготівковими замовленнями (кеш)</TableCell>
              <TableCell align="right">Сума за безготівковими замовленнями (р/р)</TableCell>
              <TableCell align="right">Джокер</TableCell>
              <TableCell align="right">Загальна сума по замовленнях</TableCell>
              <TableCell align="right">Бонуси</TableCell>
              <TableCell align="right">Комісія (Всього за замовлення)</TableCell>
              <TableCell align="right">Борг (на поч)</TableCell>
              <TableCell align="right">Оренда р/р</TableCell>
              <TableCell align="right">Телефон (Тримоб)</TableCell>
              <TableCell align="right">Штраф за замовлення</TableCell>
              <TableCell align="right">Інші нарахування</TableCell>
              <TableCell align="right">Від клієнта (Передачки)</TableCell>
              <StyledTableCell dark align="right">Всього на здачу</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <StyledTableRow dark nowrap hover>
              <TableRowOffset
                name="Всього"
                item={props.total}
              />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!!props.dotted_lines ? (
              <TableRow>
                <TableCell colSpan={16}>...</TableCell>
              </TableRow>
            ) : null}
            {props.items.map((item:any, index:number) => (
              <StyledTableRow key={index} nowrap hover>
                <TableRowOffset
                  name={item.call_sign}
                  item={item}
                />
              </StyledTableRow>
            ))}

            {!!props.dotted_lines ? (
              <TableRow>
                <TableCell colSpan={16}>...</TableCell>
              </TableRow>
            ) : null}

            {!!props.not_found_line ? (
              <TableRow>
                <TableCell colSpan={16}>{props.not_found_line}</TableCell>
              </TableRow>
            ) : null}

          </TableBody>
          <TableHead>
              <StyledTableRow dark nowrap hover>
                <TableRowOffset
                  name="Всього"
                  item={props.total}
                />
              </StyledTableRow>
          </TableHead>
        </TableBox>
      </TableContainer>
  );
}