import React from 'react';
import List from '@mui/material/List';
import { IMainLayoutProps } from '../types';
import { LogoBox } from '../styles';
import MenuItems from './menuItems';
import { IMenuRoute } from 'routes/types';



export default function BaseLayoutMenu(props:IMainLayoutProps) {

  return (
    <div>
      <LogoBox className={"MuiPaper-elevation4"}>
        <img src={ process.env.PUBLIC_URL + '/img/logoHead.svg' } alt="logo" />
      </LogoBox>
      
      <List>
        <MenuItems
          items={props.startRoute.children}
          makePath={(item:IMenuRoute) => (props.startRoute.start_path + item.path)}
          makeUrl={(item:IMenuRoute) => (props.startRoute.start_path + item.path)}
          onClick={() => { props.closeMobileMenu() }}
        />
      </List>
    </div>
  );
}