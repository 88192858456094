export interface Point{
  type:"Point";
  coordinates:[number, number];
}

export interface TariffClass{
  id:number;
  name:string;
  available: number[];
}

export interface Status{
  id:number;
  name:string;
}

export interface ATP{
  id:number;
  name:string;
}

export interface ICarLocation{
  cs: string; // Позывной(str)
  p: [number, number]; // [lat, lon]
  s: number; // Статус(int)
  c: number; //	Класс(int)
  ac: number[]; //	ID классов которые может брать позывной(int[])
}

interface ICallSignDriver{
  id: number;
  name: string;
}

interface ICallSignCar{
  id: number;
  tariff_class: number;
  brand: string;
  color: string;
}

interface ICallSignOrder{
  id: number;
}

export interface CallSignInfo {
  state_number: string;
  driver?: ICallSignDriver;
  car: ICallSignCar;
  orders?: ICallSignOrder[];
}

export class CarLocation{
  data: ICarLocation;

  constructor(data: ICarLocation) {
    this.data = data;
  }

  public get call_sign():string { return this.data.cs }
  public get coordinates():[number, number] { return this.data.p }
  public get status():number { return this.data.s }
  public get class():number { return this.data.c }
  public get available_classes():number[] { return this.data.ac }
}