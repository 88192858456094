import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CompanyTableRow from './tableRow';
import { ICompany } from './types';

interface CompaniesTableProps {
  items: ICompany[];
  onClick:(item:ICompany) => void;

  showCompanyColumn?:boolean;
}

export default function CompaniesTable(props:CompaniesTableProps) {
  return (
    <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Код</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>Назва</TableCell>
              <TableCell>Поставщик</TableCell>
              <TableCell>Блокування, грн.</TableCell>
              <TableCell>Залишок, грн.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { props.items.map((item:any) => (
              <CompanyTableRow
                key={item.id}
                item={item}
                onClick={props.onClick}
                showCompanyColumn={props.showCompanyColumn}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}