import React, { useState } from 'react';
import { Dialog, DialogContent, FormControl, FormControlLabel, DialogActions, Button, Checkbox, styled } from '@mui/material';
import requests from 'api/requests';
import { useProgress } from 'components/progress';

interface IImportDialogProps {
  company: number;
  onClose: () => void;
  onDone: () => void;
}

const FileContainer = styled("label")(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center'
}));

const FileName = styled("span")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export default function ImportDialog(props:IImportDialogProps) {
  const { isProgress } = useProgress();
  const [ inputs, setInputs ] = useState({
    add: true,
    update: true,
    delete: false,
    file: null,
    company: props.company
  } as any);

  const handleChangeChecked = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.checked);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    inputs[name] = value;
    setInputs({...inputs});
  }

  return (
    <div>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="xs"
        onClose={()=>{
          if(isProgress) return;
          props.onClose();
        }}
      >
        <form
          onSubmit={(e:any) => {
            e.preventDefault();
            if(isProgress) return;

            requests.form('/corp/staff/import/', inputs).then((r) => {
              props.onDone()
            })
          }}
        >
          <DialogContent>

            <FormControl fullWidth>
              <input
                accept=".xlsx,.xls"
                style={{display: 'none'}}
                id="contained-button-file"
                type="file"
                onChange={(e:any) => {
                  handleChangeValue('file')(e.target.files[0]);
                }}
              />
              <FileContainer htmlFor={isProgress ? "" : "contained-button-file"}>
                <Button variant="contained" color="primary" component="span" style={{minWidth: 130}} disabled={isProgress}>
                  Обрати файл
                </Button>
                
                { inputs.file ?
                  (<FileName>{inputs.file.name}</FileName>)
                  : (<FileName style={{opacity: 0.6}}>Файл не обрано</FileName>)
                }
                
              </FileContainer>
              
              <p>
                Excel-файл повинен бути у форматі xlsx та містити 4 колонки: ПІБ, Телефон 1, Телефон 2, Активний.<br/>
                У якості прикладу рекомендуємо експортувати поточні дані в Excel.
              </p>

              

              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputs.add}
                    onChange={handleChangeChecked('add')}
                    color="primary"
                    disabled={isProgress}
                  />
                }
                label="Додавати"
              />
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputs.update}
                    onChange={handleChangeChecked('update')}
                    color="primary"
                    disabled={isProgress}
                  />
                }
                label="Оновлювати"
              />
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputs.delete}
                    onChange={handleChangeChecked('delete')}
                    color="primary"
                    disabled={isProgress}
                  />
                }
                label="Видаляти"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onClose}
              disabled={isProgress}
            >
              Відмінити
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isProgress || (!inputs.add && !inputs.update && !inputs.delete) || !inputs.file}
            >
              Імпортувати
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}