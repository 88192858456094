import React from 'react';
import { TablePagination } from '@mui/material';


interface InvoicesTablePaginationProps {
  count: number;
  limit: number;
  page: number;
  onChange:(page:number) => void;
}

export default function InvoicesTablePagination(props:InvoicesTablePaginationProps) {

  return (
    <TablePagination
      component="div"
      count={props.count}
      rowsPerPage={props.limit}
      page={props.page}
      rowsPerPageOptions={[props.limit]}
      onPageChange={(e, page_number) => {
        props.onChange(page_number)
      }}
    />
  );
}