import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { ICollateOrder } from './types';
import NumberFormat from 'react-number-format';
import Emoji from 'components/Emoji';

interface CompaniesTableProps {
  items: ICollateOrder[];
  itemClick: (item:ICollateOrder)=>void;
}

export default function ItemsTable(props:CompaniesTableProps) {

  const money = (value:number) => (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
  );

  if(!props.items.length){
    return (
      <div>
        <br/>
        <br/>
        <Typography
          variant='h4'
          align='center'
          color='textSecondary'
        >
          <Emoji symbol="👍" /><br/>
          Все добре!
        </Typography>
        <br/>
        <br/>
      </div>
    )
  }
  
  return (
    <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Код</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>Назва</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Кількість факт. замовлень</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Сума по замовленніх згідно історії</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Сума по факт. замовлень</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Різниця</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { props.items.map((item, index) => (
              <TableRow
                hover
                key={index}
                onClick={()=>{
                  props.itemClick(item)
                }}
                style={{
                  cursor: 'pointer'
                }}
              >
                <TableCell>{item.company?.code || (<Typography variant="inherit" color="textSecondary">-</Typography>)}</TableCell>
                <TableCell style={{whiteSpace: 'nowrap'}}>{item.company?.name || (<Typography variant="inherit" color="textSecondary">-</Typography>)}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{item.order_count}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.history_sum)}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.order_sum)}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.diff_sum)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}