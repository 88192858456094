import { Drawer, DrawerProps, Grid, IconButton, styled } from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';

const drawerWidth = 270;


export const RootBox = styled("div")(({ theme }) => ({
  display: 'flex',
  height: '100%'
}));

export const NavBox = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  marginLeft: drawerWidth,
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${drawerWidth}px) !important`,
  }
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: 20,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
}));

export const TitleBox = styled("div")(({ theme }) => ({
  flexGrow: 1,
  width: 0,
  "& > h6": {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1,
    "& > span": {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1,
    }
  }
}));

interface DrawerBoxProps extends DrawerProps {
  mobile?: boolean;
}

export const DrawerBox = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'mobile',
})<DrawerBoxProps>(({ theme, mobile }) => ({
  "&:after": {
    ...theme.mixins.toolbar,
    content: "''",
    display: 'block',
    position: 'fixed',
    top: 0,
    left: drawerWidth-3,
    width: 6,
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer+1,
    boxShadow: "0 4px 2px -2px rgba(0,0,0,0.2)",
  },
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
  ...(mobile && {
    "&:after": {
      display: 'none'
    }
  }),
}));

export const MainBox = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  width: '100%',
  overflow: 'hidden'
}));

export const FakeToolBarBox = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar
}));

export const MainContentGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  alignItems: 'stretch',
  '& > *': {
    width: '100%',
    position: 'relative',
  }
}));

export const LogoBox = styled(FakeToolBarBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  '& img': {
    height: 40,
  }
}));

export const MenuLoadingBox = styled("div")(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(3)
}));