import React, { useState } from 'react';
import { Dialog, DialogContent, FormControl, DialogActions, Button, InputLabel, Input, InputAdornment, IconButton, Typography } from '@mui/material';
import requests from 'api/requests';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

interface IPasswordDialogProps {
  onClose: () => void;
  onDone: () => void;
}

export default function PasswordDialog(props:IPasswordDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [ inputs, setInputs ] = useState({
    current_password: '',
    password1: '',
    password2: ''
  } as any);
  const [ showPassword, setShowPassword ] = useState({
    current_password: false,
    password1: false,
    password2: false
  } as any);

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    inputs[name] = value;
    setInputs({...inputs});
  }

  const handleTogglePassword = (name:string) => () => {
    showPassword[name] = !showPassword[name];
    setShowPassword({...showPassword});
  }

  const passwordHelpText = ():string|null => {
    if(inputs.password1 && inputs.password2 && (inputs.password1 !== inputs.password2)){
      return "Паролі не співпадають";
    }
    return null
  }

  const Update = () => {
    requests.post('/auth/update-password/', {
      password: inputs.current_password,
      new_password: inputs.password1
    }).then((r) => {
      enqueueSnackbar("Пароль змінено", {variant: 'success'});
      props.onDone()
    });
  }
  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      onClose={props.onClose}
    >
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          Update();
        }}
      >
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="current-password">Поточний пароль</InputLabel>
            <Input
              id="current-password"
              type={showPassword.current_password ? 'text' : 'password'}
              value={inputs.current_password}
              onChange={handleChange('current_password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePassword('current_password')}
                  >
                    {showPassword.current_password ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="password1">Новий пароль</InputLabel>
            <Input
              id="password1"
              error={Boolean(passwordHelpText())}
              type={showPassword.password1 ? 'text' : 'password'}
              value={inputs.password1}
              onChange={handleChange('password1')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePassword('password1')}
                  >
                    {showPassword.password1 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              }
            />
            { passwordHelpText() && (<Typography variant="caption">{passwordHelpText()}</Typography>)}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="password2">Новий пароль ще раз</InputLabel>
            <Input
              id="password2"
              error={Boolean(passwordHelpText())}
              type={showPassword.password2 ? 'text' : 'password'}
              value={inputs.password2}
              onChange={handleChange('password2')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePassword('password2')}
                  >
                    {showPassword.password2 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              }
            />
            { passwordHelpText() && (<Typography variant="caption">{passwordHelpText()}</Typography>)}
          </FormControl><br/><br/>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
          >
            Відмінити
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!inputs.password1 || !inputs.password2 || (inputs.password1 !== inputs.password2)}
          >
            Зберегти
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}