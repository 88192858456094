import React, { useCallback, useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { columns, types } from './config';
import { IColumn, ITypeFolderDict, IItem } from './types';
import Row from './row';
import requests from 'api/requests';
import { useRouteMatch } from 'react-router-dom';
import { useInterval } from 'utils/interval';
import { addDays, format } from 'date-fns';
import { TablePaperBox } from 'components/styled';


export default function Monitoring() {
  const match:any = useRouteMatch();
  const company = match.params.company;
  
  const [lastUpdate, setLastUpdate] = React.useState(new Date());
  const [items, setItems] = React.useState({} as ITypeFolderDict);

  useInterval(() => {
    load()
  }, 20 * 1000)

  const load = useCallback(() => {
    requests.getAll('/corp/order/', {
      limit: 100,
      company: company,
      date__gte: format(addDays(new Date(), -1), "yyyy-MM-dd HH:mm:ss")
    }).then((r) => {
      let results = r.body.results;
      
      let items:ITypeFolderDict = {};
      results.map((i:IItem) => {
        if(items[i.folder] === undefined ){
          items[i.folder] = []
        }
        items[i.folder].push(i)
        return null;
      })
      setItems(items);
      setLastUpdate(new Date());
    });
  }, [company])

  useEffect(() => load(), [load]);

  return (
    <div>
      Час останнього оновлення: {format(lastUpdate, "HH:mm:ss")}
      <TablePaperBox>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column:IColumn, index:number) => (
                <TableCell key={index}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {types.map((type) => (
              <Row
                key={type.name}
                type={type}
                items={items}
              />
            ))}
          </TableBody>
        </Table>
      </TablePaperBox>
    </div>
  );
}