import { styled, Table, TableCell, tableCellClasses, TableCellProps, TableRow, TableRowProps } from "@mui/material";


export const TableBox = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.sizeSmall}`]: {
    fontSize: "0.8rem",
    lineHeight: 1.2,
    padding: "6px 10px"
  }
}));

interface StyledTableCellProps extends TableCellProps {
  success?: boolean;
  warning?: boolean;
  dark?: boolean;
}

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => !['success', 'warning', 'dark'].includes(prop.toString()),
})<StyledTableCellProps>(({ theme, success, warning, dark }) => ({
  ...(dark && {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  }),
  ...(success&&{
    color: theme.palette.mode === 'light' ? theme.palette.success.main : theme.palette.success.light
  }),
  ...(warning&&{
    color: theme.palette.mode === 'light' ? theme.palette.error.main : theme.palette.error.light
  }),
}));

interface StyledTableRowProps extends TableRowProps {
  dark?: boolean;
  nowrap?: boolean;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => !['dark', 'nowrap'].includes(prop.toString()),
})<StyledTableRowProps>(({ theme, dark, nowrap }) => ({
  ...(dark && {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  }),
  ...(nowrap && {
    "& td, & th": {
      whiteSpace: "nowrap"
    }
  }),
}));