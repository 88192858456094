import React, { useState } from 'react';
import { TableRow, TableCell, TextField, Typography, IconButton } from '@mui/material';
import { IMapPOI } from './types';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import { MapOutlined } from '@mui/icons-material';
import { TableCellBorderRight } from './styles';


interface IRowProps{
  item:IMapPOI,
}

export default function Row(props: IRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [prevItem, setPrevItem] = useState<IMapPOI>({...props.item})
  const [item, setItem] = useState<IMapPOI>({...props.item})

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    let _inputs = item as any;
    _inputs[name] = value;
    setItem({..._inputs});
  }

  const onUpdate = (itemName:string) => {
    if((prevItem as any)[itemName] === (item as any)[itemName]){
      return;
    }

    let params:any = {};
    params[itemName] = (item as any)[itemName];

    requests.patch(`/settings/map/poi/${item.id}/`, params).then((r)=>{
      const name_to_message = params[itemName] ? ` "${params[itemName]}"` : "";
      enqueueSnackbar(`Збережено${name_to_message}`, {variant: 'success'});

      let _inputs = prevItem as any;
      _inputs[itemName] = params[itemName];
      setPrevItem({..._inputs});
    })
  }

  const renderInput = (itemName:string) => {
    return (
      <TextField
        fullWidth
        variant="outlined"
        size='small'
        label={(item as any)[itemName] !== (prevItem as any)[itemName] ? (
          <Typography color='error' component='span'>*</Typography>
        ) : undefined}
        value={(item as any)[itemName] || ''}
        onChange={handleChange(itemName)}
        onBlur={()=>onUpdate(itemName)}
      />
    )
  }

  return (
    <TableRow hover>
      <TableCell>
        <IconButton
          href={`https://www.google.com/maps/?q=${item.point.coordinates[0]},${item.point.coordinates[1]}`}
          target="_blank"
          rel="noreferrer"
        >
          <MapOutlined />
        </IconButton>
      </TableCell>
      <TableCell>
        {renderInput('name_ua')}
      </TableCell>
      <TableCell>
        {renderInput('name_ru')}
      </TableCell>
      <TableCellBorderRight>
        {renderInput('name_en')}
      </TableCellBorderRight>

      <TableCell>
        {renderInput('comments_ua')}
      </TableCell>
      <TableCell>
        {renderInput('comments_ru')}
      </TableCell>
      <TableCell>
        {renderInput('comments_en')}
      </TableCell>
    </TableRow>
  );
}