import React, { useState } from 'react';
import { AddressListOL, CollapseGrid, TableRowBox } from './styles';
import NumberFormat from 'react-number-format';
import { TableRow, TableCell, Grid, IconButton, Collapse, Box } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Moment from 'react-moment';
import { PAYMENT_TYPE } from 'config';

interface IProps{
  item: any;
  hiddenCommissionDiscount:boolean;
}

export default function Row(props: IProps) {
  const {
    item,
    hiddenCommissionDiscount
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRowBox hover onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          <NumberFormat value={item.id} displayType={'text'} thousandSeparator={' '} />
        </TableCell>
        <TableCell>
          <Moment
            date={item.date}
            format="DD.MM.YY"
          />
        </TableCell>
        <TableCell>
          <Moment
            date={item.date}
            format="HH:mm"
          />
        </TableCell>
        <TableCell>{item.callsign}</TableCell>
        <TableCell>{item.tariff_class}</TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={' '} /></TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.joker} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{item.wait_minutes_price ? (
            <NumberFormat value={item.wait_minutes_price} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
          ) : ''}
        </TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{item.additions_price ? (
          <NumberFormat value={item.additions_price} displayType={'text'} thousandSeparator={' '} decimalScale={0} fixedDecimalScale={true} />
        ) : ''}</TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{item.tip ? (
          <NumberFormat value={item.tip} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
        ) : ''}</TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.commission} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></TableCell>
        {!hiddenCommissionDiscount ? (
          <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.commission_discount} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></TableCell>
        ):null}
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price + (item.joker || 0)} displayType={'text'} thousandSeparator={' '} /></TableCell>
        <TableCell>{PAYMENT_TYPE[item.payment_type as keyof typeof PAYMENT_TYPE]}</TableCell>
      </TableRowBox>
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <CollapseGrid container spacing={5} alignItems="flex-start" justifyContent="flex-start" direction="row" wrap="nowrap">

              {/* <Grid item>
                <div><b>Оплата:</b> </div>
              </Grid> */}

              <Grid item>
                <b>Адреса</b>
                <AddressListOL>
                  {item.addresses.map((address:string, index:number) => (
                    <li key={index}>{address}</li>
                  ))}
                </AddressListOL>
              </Grid>

              { item.comment && (
              <Grid item>
                <b>Замітки</b>
                <div>{item.comment}</div>
              </Grid>
              )}

            </CollapseGrid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
  );
}