import { DateConverter, JsonProperty } from "ts-serializer-core";


class IDNameSerializer{
  @JsonProperty('id')
  public id:number;

  @JsonProperty('name')
  public name:string;
}

export class CarSerializer {
  @JsonProperty('id')
  public id:number;

  @JsonProperty('call_sign')
  public call_sign?:string;

  @JsonProperty('tariff_class')
  public tariff_class?:IDNameSerializer;

  @JsonProperty({
    name: 'hiring_date',
    type: Date,
    customConverter: DateConverter
  })
  public hiring_date?:Date;

  @JsonProperty('year')
  public year?:number;

  @JsonProperty('state_number')
  public state_number?:string;

  @JsonProperty('auto_group')
  public auto_group?:IDNameSerializer;

  @JsonProperty('brand')
  public brand?:IDNameSerializer;

  @JsonProperty('body')
  public body?:IDNameSerializer;

  @JsonProperty('color')
  public color?:IDNameSerializer;

  @JsonProperty('note')
  public note?:string;

  @JsonProperty('phone')
  public phone?:string;

  @JsonProperty('removed')
  public removed?:boolean;
}

export class CarServicesSerializer {
  @JsonProperty('request_services')
  public request_services?:number[]|null;
  @JsonProperty('services')
  public services:number[];
}