import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Icon, Link, Typography } from "@mui/material";
import requests from "api/requests";
import React, { useEffect, useMemo, useState } from "react";
import { CardProps } from "../types";
import { StyledDialogTitle } from "./styles";
import { CarSerializer } from "./types";
import { Deserializer } from "ts-serializer-core";
import Moment from 'react-moment';
import NumberFormat from "react-number-format";
import Services from "./services";
import Documents from "./documents";

export default function CarCard(props:CardProps){
  const {
    id,
    onClose
  } = props
  const [ data, setData ] = useState<CarSerializer|null>(null);


  useEffect(()=>{
    const abortController = new AbortController();

    if(!id){
      abortController.abort();
    }else{
      setData(null);
      requests.get(`/base/car-card/${id}/`, {}, abortController.signal).then((r)=>{
        const deserializer = new Deserializer();
        const instance = deserializer.deserialize(CarSerializer, r.body);
        setData(instance);
      })
    }

    return () => {
      abortController.abort();
    };
  }, [id])

  const title = useMemo(()=>{
    let title_list = ["Авто"];
    if(data?.call_sign || data?.state_number){
      title_list.push(data?.call_sign || data?.state_number || "");
    }
    return (
      <React.Fragment>
        {title_list.join(": ")}
        {data?.removed?(
          <sup>
            <Typography variant='caption' color='textSecondary'>
                &nbsp;(Архів)
            </Typography>
          </sup>
        ):null}
      </React.Fragment>
    )
  }, [data])


  return (
    <Dialog
      open={!!id}
      onClose={onClose}
      maxWidth="md"
      fullWidth={!!data}
    >
      {!data ? (
        <DialogContent>
          <CircularProgress sx={{display: "block"}} />
        </DialogContent>
      ):(
        <React.Fragment>
          <StyledDialogTitle>{title}</StyledDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1} direction={{
              xs: "column-reverse",
              sm: "row"
            }}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography color="textSecondary" component="span">Позивний: </Typography>
                    {data.call_sign}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Телефон: </Typography>
                    {!!data.phone && (
                      <Link href={`tel:${data.phone}`} color="inherit">
                        <NumberFormat value={data.phone} displayType={'text'} format="+## (###) ### ## ##" />
                      </Link>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Клас авто: </Typography>
                    {data.tariff_class?.name}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Марка: </Typography>
                    {data.brand?.name}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Авто група: </Typography>
                    {data.auto_group?.name}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography color="textSecondary" component="span">Рік випуску: </Typography>
                    {data.year}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Кузов: </Typography>
                    {data.body?.name}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Колір: </Typography>
                    {data.color?.name}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Держ. номер: </Typography>
                    {data.state_number}
                    {!!data.state_number && (
                      <Typography variant="caption"><br/>
                        <Link
                          href={"https://ua.carplates.app/number/" + (data.state_number||'').replace(/ /g, "")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Перевірити на carplates.app <Icon fontSize="inherit">open_in_new</Icon>
                        </Link>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Прийнятий: </Typography>
                    {!!data.hiring_date && (
                      <Moment
                        date={data.hiring_date}
                        format="DD.MM.YYYY"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {data.note && (<Alert severity="info" sx={{mt: 2, whiteSpace: "pre-wrap"}}>{data.note}</Alert>)}

            <Box sx={{mt: 2, mb: 1}}>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Додаткові послуги</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Services
                    carID={data.id}
                    tariffClass={data.tariff_class?.id}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Документи</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Documents
                    carID={data.id}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Закрити</Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  )
}