import React from 'react';
import { TableCell } from '@mui/material';
import { StyledTableCell } from './styles';
import NumberFormat from 'react-number-format';


interface ITableRowOffsetComponent {
  name:string;
  item:any;
}

export default function TableRowOffset(props:ITableRowOffsetComponent) {

  const money = (value:number) => {
    return (
      <NumberFormat
        value={value || 0}
        displayType={'text'}
        thousandSeparator={' '}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    )
  }

  const order_sum = (
    props.item.order_sum_cash
    + props.item.order_sum_non_cash_rc
    + props.item.order_sum_non_cash_ch
    + props.item.order_sum_joker
  );

  return (
    <React.Fragment>
        <TableCell>{props.name}</TableCell>
        <TableCell align="right">{props.item.order_num || 0}</TableCell>
        <TableCell align="right">{money(props.item.order_sum_cash)}</TableCell>
        <TableCell align="right">{money(props.item.order_sum_non_cash_ch)}</TableCell>
        <TableCell align="right">{money(props.item.order_sum_non_cash_rc)}</TableCell>
        <TableCell align="right">{money(props.item.order_sum_joker)}</TableCell>
        <TableCell align="right">{money(order_sum)}</TableCell>
        <TableCell align="right">{money(props.item.bonus)}</TableCell>
        <TableCell align="right">{money(props.item.commission)}</TableCell>
        <TableCell align="right">{money(props.item.order_debt)}</TableCell>
        <TableCell align="right">{money(props.item.sum_radio)}</TableCell>
        <TableCell align="right">{money(props.item.sum_phone)}</TableCell>
        <TableCell align="right">{money(props.item.order_fine)}</TableCell>
        <TableCell align="right">{money(props.item.other)}</TableCell>
        <TableCell align="right">{money(props.item.order_sum_client)}</TableCell>
        <StyledTableCell
          align="right"
          dark
          success={props.item.remainder >= 0}
          warning={props.item.remainder < 0}
        >{money(props.item.remainder)}</StyledTableCell>
    </React.Fragment>
  );
}