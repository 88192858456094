import React, { useEffect, useState } from 'react';
import { Button, TextField, FormControl, InputAdornment, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@mui/material';
import { AlternateEmailOutlined, LockOpenOutlined } from '@mui/icons-material';
import AuthLayout from 'layout/auth';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes';
import requests from 'api/requests';
import AuthModel from 'models/AuthModel';
import { useSnackbar } from 'notistack';


export default function SetPassword(){
  
  const { enqueueSnackbar } = useSnackbar();
  const match:any = useRouteMatch();
  const hash = match.params.hash;

  const [ inputs, setInputs ] = useState({
    email: "",
    password: "",
    password2: ""
  });
  const [ done, setDone ] = useState(false);
  const [ isNewUser, setIsNewUser ] = useState(false);
  const [ redirectTo, setRedirectTo ] = useState<null|string>(null);

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    let _inputs = inputs as any;
    _inputs[name] = value;
    setInputs({..._inputs});
  }

  const onSumbut = (e:any) => {
    e.preventDefault();
    
    if(inputs.password !== inputs.password2){
      enqueueSnackbar("Паролі не співпадають", {variant: 'error'});
    }else{
      requests.post('/auth/set-password/', {
        hash: hash,
        password: inputs.password
      }).then((r) => {
        setDone(true)
      })
    }
  }

  useEffect(() => {
    requests.post('/auth/check-hash/', {
      hash: hash
    }).then((r) => {
      setInputs((v)=>({
        ...v,
        email: r.body.email
      }))
      setIsNewUser(r.body.is_new_user);
    }).catch(()=>{
      setRedirectTo("/")
    })
  }, [hash])

  if (redirectTo !== null) {
    return <Redirect to={redirectTo}/>;
  }

  if(!inputs.email) return null;

  return (
    <AuthLayout>
      <React.Fragment>
        <form onSubmit={onSumbut}>
          <FormControl fullWidth>
            <TextField
              placeholder="E-mail"
              value={inputs.email}
              type="email"
              disabled={true}
              onChange={handleChange('email')}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlined color={'action'} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder="Новий пароль"
              value={ inputs.password || '' }
              type="password"
              onChange={handleChange('password')}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOpenOutlined color={'action'} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder="Пароль ще раз"
              value={ inputs.password2 || '' }
              type="password"
              onChange={handleChange('password2')}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOpenOutlined color={'action'} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Grid container sx={{mt: 2}} justifyContent="space-between" alignItems="center">
            <Grid item>
              {!isNewUser ? (
                <Button
                  component={Link}
                  to={routes.login}
                  size="small"
                  color="primary"
                >
                  Вхід
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
              >
                {isNewUser ? "Встановити" : "Змінити"}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={done}
        >
          <DialogTitle>Зміна паролю</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ваш пароль успішно змінено.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={(e)=>{
                e.preventDefault();
                AuthModel.auth(inputs.email, inputs.password)
              }}
            >
              Вхід
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </AuthLayout>
  )
}