import { FilteredTableFilter } from "components/FilteredTable/types"
import { DateRangePickerFilter, TextFilter } from "components/FilteredTable/filters";
import { dataRangeItem } from "components/daterange/defaults";
import { IDateRange } from "components/daterange";
import { format } from 'date-fns';

const filters:FilteredTableFilter[] = [
  {
    title: 'Дата',
    key: 'created',
    defaultValue: dataRangeItem.all(new Date()),
    component: DateRangePickerFilter,
    filter: (value:IDateRange)=>{
      if(!value.startDate && !value.endDate){
        return null;
      }
      let filter:any = {};
      if(value.startDate){
        filter.created__gte = format(value.startDate, "yyyy-MM-dd");
      }
      if(value.endDate){
        filter.created__lte = format(value.endDate, "yyyy-MM-dd");
      }
      return filter
    },
  },
  {
    title: 'Позивний',
    key: 'call_sign',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        call_sign: value
      } : null
    ),
  },
  {
    title: 'Водій',
    key: 'driver__lastname__icontains',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        driver__lastname__icontains: value
      } : null
    ),
  },
  {
    title: 'Номер авто',
    key: 'car__statenum__icontains',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        car__statenum__icontains: value
      } : null
    ),
  },
]

export default filters;