import { Checkbox, Chip, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import requests from "api/requests";
import { TariffClass } from "components/map/types";
import { useConfirm } from "material-ui-confirm";
import React, { useMemo } from "react";
import { ICarClassesDict, ILoyalty } from "./types";

interface IProps{
  loyalty: ILoyalty[];
  carClasses: TariffClass[];
  onUpdated: ()=>void;
}

const ChipBox = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontSize: "0.8em"
}));

export default function LoyaltyControl(props:IProps){
  const confirm = useConfirm();
  
  const {
    loyalty,
    carClasses
  } = props;

  const carClassesDict:ICarClassesDict = useMemo(()=>{
    let dict:ICarClassesDict = {};
    carClasses.map((i)=>{
      dict[i.id] = i;
      return i;
    })
    return dict;
  }, [carClasses])

  const handleChange = (loyalty:ILoyalty) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    confirm({
      title: "❗ Ви впевнені?",
      description: (
        <React.Fragment>
          Ви плануєте <b>{checked?"увімкнути":"вимкнути"}</b> лояльність <b>"{loyalty.name}"</b><br/>
          Натискаючи так, ви підтверджуєте, що ви тверезі та в здоровому глузді. 😀
        </React.Fragment>
      ),
      cancellationText: 'Ні',
      confirmationText: 'Так'
    }).then(() => {
      requests.patch(`/admin/tariff/loyalty/${loyalty.id}/`, {
        turnon: checked
      }).then(props.onUpdated)
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Назва</TableCell>
            <TableCell>Класи</TableCell>
            <TableCell>Увімкнено?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loyalty.map((i)=>(
            <TableRow key={i.id}>
              <TableCell>{i.name}</TableCell>
              <TableCell>
                {i.tariff_classes.map((tariff_class) => {
                  if(!carClassesDict[tariff_class]) return null;
                  return (
                    <ChipBox
                      key={tariff_class}
                      variant="outlined"
                      size="small"
                      label={carClassesDict[tariff_class].name}
                    />
                  )
                })}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={i.turnon}
                  onChange={handleChange(i)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}