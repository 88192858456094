import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { TileCard, ValueTypography } from './styles';
import { Skeleton } from '@mui/material';
import NumberFormat from 'react-number-format';
import requests from 'api/requests';


interface ITilesComponent {
  carIDs:number[];
  periodID:number;
}

export default function Tiles(props:ITilesComponent) {
  const [data, setData] = useState(null as any)

  const load = useCallback(() => {
    setData(null);
    requests.getAll('/driver/offset/', {
      car_id__in: props.carIDs,
      period: props.periodID
    }).then((r) => {
      let data = {} as any;
      if(r.body.results.length){
        r.body.results.map((item:any) => {
          Object.keys(item).map((key:string) => {
            if(typeof item[key] == "number"){
              if(!data[key]){
                data[key] = 0;
              }
              data[key] += item[key];
            }else{
              data[key] = item[key];
            }
            return key;
          })
          return item;
        })
      }
      setData(data);
    })

  }, [props.carIDs, props.periodID])

  useEffect(() => load(), [load]);

  const items = [
    {
      title: 'Всього на здачу',
      value: data ? (data.remainder || 0) : undefined,
      money_format: true,
      props: data ? (data.remainder <= 0 ? {success: true} : {warning: true}) : {}
    },
    {
      title: 'Кіл-ть виконаних замовлень',
      value: data ? (data.order_num || 0) : undefined,
      money_format: false,
    },
    {
      title: 'Загальна сума по замовленнях',
      value: data ? (
        (data.order_sum_cash || 0)
        + (data.order_sum_non_cash_rc || 0)
        + (data.order_sum_non_cash_ch || 0)
        + (data.order_sum_joker || 0)
      ) : undefined,
      money_format: true,
    },
    {
      title: 'Бонуси',
      value: data ? (data.bonus || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Комісія',
      value: data ? (data.commission || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Сума за готівковими замовленнями',
      value: data ? (data.order_sum_cash || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Сума за безготівковими замовленнями (кеш)',
      value: data ? (data.order_sum_non_cash_ch || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Сума за безготівковими замовленнями (р/р)',
      value: data ? (data.order_sum_non_cash_rc || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Джокер',
      value: data ? (data.order_sum_joker || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Від клієнта',
      value: data ? (data.order_sum_client || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Борг',
      value: data ? (data.order_debt || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Оренда р/с',
      value: data ? (data.sum_radio || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Телефон(3Мob)',
      value: data ? (data.sum_phone || 0) : undefined,
      money_format: true,
    },
    {
      title: "Штрафи",
      value: data ? (data.order_fine || 0) : undefined,
      money_format: true,
    },
    {
      title: 'Інші нарахування',
      value: data ? (data.other || 0) : undefined,
      money_format: true,
    },
  ]

  return (
    <Grid container spacing={3} alignItems="stretch" justifyContent="flex-start" direction="row">
      {items.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
          <TileCard>
            <Grid container spacing={0} direction="column" style={{height: "100%"}}>
              <Grid item style={{flexGrow: 1}}>
                <Typography color="textSecondary" gutterBottom>
                  {item.title}
                </Typography>
              </Grid>
              <Grid item>
                <ValueTypography variant="h5" {...item.props}>
                  {item.value!==undefined ? (
                    <div>{item.money_format ? (
                      <NumberFormat value={item.value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} suffix=" грн." />
                    ) : item.value}</div>
                  ) : (
                    <Skeleton animation="wave" />
                  )}
                </ValueTypography>
              </Grid>
            </Grid>
          </TileCard>
        </Grid>
      ))}
    </Grid>
  );
}