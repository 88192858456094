import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import { TextField, CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import requests from 'api/requests';
import NumberFormat from 'react-number-format';
import { IMenuRoute } from 'routes/types';
import { LogoBox, MenuLoadingBox } from '../styles';
import { IMainLayoutProps } from '../types';
import MenuItems from './menuItems';



export default function CorpLayoutMenu(props:IMainLayoutProps) {
  const company_id = +props.startRouteProps.match.params.company;

  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [company, setComapny] = useState(null as any);
  const [companies, setCompanies] = useState([]);

  const makeUrl = (item:IMenuRoute) => {
    return (props.startRoute.start_path + item.path).replace(":company", `${company_id}`)
  }
  
  useEffect(() => {
    setLoading(true);
    requests.getAll('/corp/company/', {limit: 0}).then((r) => {
      setCompanies(r.body.results);
      setLoading(false);
    })
  }, [])
  
  useEffect(() => {
    if(companies.length){
      let company = null;
      if(company_id){
        companies.forEach((i:any) => {
          if(i.id === company_id){
            company = i;
          }
        });
      }
      if(company){
        setComapny(company);
      }else{
        const new_url = props.startRoute.start_path.replace("/:company", "/");
        history.push(new_url);
      }
    }
  }, [companies, company_id, history, props.startRoute.start_path])

  const goToCompany = (new_company_id:number) => {
    const new_url = props.startRouteProps.match.url.replace(`/${company_id}/`, `/${new_company_id}/`);
    history.push(new_url);
  }

  let list = null;

  if(!loading && company){
    list = (
      <List>
        { companies && companies[0] && (
          <ListItem>
            <div style={{width: '100%'}}>
              { (companies && companies.length > 1) ? (
                <Autocomplete
                  options={companies}
                  value={company}
                  fullWidth
                  getOptionLabel={(item:any) => item.name || '-/-'}
                  renderOption={((props, option)=>(
                    <li {...props} key={option.id}>{option.name || '-/-'}</li>
                  ))}
                  isOptionEqualToValue={(option, value) => {
                    return option && value && option.id === value.id;
                  }}
                  renderInput={(params:any) => <TextField {...params} label="Компанія" />}
                  disableClearable={true}
                  onChange={(e, company) => {
                    goToCompany(company.id);
                  }}
                />
              ) : (
                <Typography variant="body1">{(companies[0] as any).name || '-/-'}</Typography>
              )}
              <Typography variant="caption" color="primary">
                Баланс:&nbsp;
                <NumberFormat
                  value={company.balance}
                  displayType={'text'}
                  thousandSeparator={' '}
                  suffix=" грн."
                />
              </Typography>
            </div>
          </ListItem>
        )}
        <MenuItems
          items={props.startRoute.children}
          makePath={(item:IMenuRoute) => (props.startRoute.start_path + item.path)}
          makeUrl={makeUrl}
          onClick={() => { props.closeMobileMenu() }}
          isHideParam={company}
        />
      </List>
    );
  }

  return (
    <div>
      <LogoBox className={"MuiPaper-elevation4"}>
        <img src={ process.env.PUBLIC_URL + '/img/logoHead.svg' } alt="logo" />
      </LogoBox>
      
      { loading ? (
        <MenuLoadingBox>
          <CircularProgress />
        </MenuLoadingBox>
      ) : list }
    </div>
  );
}