import { Grid, Paper, styled } from "@mui/material";

export const TablePaperBox = styled(Paper)(({ theme }) => ({
  overflowX: 'auto',
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
  marginTop: theme.spacing(3),
  borderRadius: 0,
  '-webkit-overflow-scrolling': 'touch',
}));

export const PaginationGridBox = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(-3)
}));