import { DateConverter, JsonProperty } from "ts-serializer-core";

export const status = {
  0: "Работает",
  1: "Отстранен",
  2: "Уволен"
}
export type StatusValues = keyof typeof status;

class ATPSerializer{
  @JsonProperty('id')
  public id:number;

  @JsonProperty('name')
  public name:string;
}

export class DriverSerializer {
  @JsonProperty('id')
  public id:number;

  @JsonProperty('firstname')
  public firstname:string;

  @JsonProperty('lastname')
  public lastname:string;

  @JsonProperty('middlename')
  public middlename:string;

  @JsonProperty('phone')
  public phone:string;

  @JsonProperty('email')
  public email:string;

  @JsonProperty({
    name: 'birthday',
    type: Date,
    customConverter: DateConverter
  })
  public birthday:Date;

  @JsonProperty('living_address')
  public living_address:string;

  @JsonProperty('note')
  public note:string;

  @JsonProperty('status')
  public status:StatusValues;

  @JsonProperty('rank')
  public rank:number|null;

  public get status_value() {
    return status[this.status];
  }

  @JsonProperty({
    name: 'hiring_date',
    type: Date,
    customConverter: DateConverter
  })
  public hiring_date:Date;

  @JsonProperty('atp')
  public atp?:ATPSerializer;
}

export class DriverServicesSerializer {
  @JsonProperty('request_services')
  public request_services?:number[]|null;
  @JsonProperty('services')
  public services:number[];
}