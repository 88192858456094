import { RatingFilter, TextFilter } from "components/FilteredTable/filters";
import AutocompleteFilter, { AutocompleteFilterItemProps } from "components/FilteredTable/filters/AutocompleteFilter";
import BooleanFilter from "components/FilteredTable/filters/BooleanFilter";
import { FilteredTableFilter } from "components/FilteredTable/types"
import { IExtendData } from "./types";

interface DriverFilteredTableFilter extends FilteredTableFilter{
  disable_for_atp?: boolean
}

const filters:DriverFilteredTableFilter[] = [
  {
    title: 'Прізвище',
    key: 'lastname',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        lastname__icontains: value
      } : null
    ),
  },
  {
    title: 'АТП',
    key: 'auto_group',
    component: AutocompleteFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: "Без АТП",
          option: (<b>Без АТП</b>),
          value: 0
        } as AutocompleteFilterItemProps,
        ...(extendData?.atp || [])
      ]
    }),
    filter: (value:number)=>{
      if(value === 0) return {
        auto_group__isnull: true
      };
      return value ? {
        auto_group__id: value
      } : null
    },
    disable_for_atp: true,
  },
  {
    title: 'Позивний',
    key: 'call_sign',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        call_sign: value
      } : null
    ),
    disable_for_atp: true,
  },
  {
    title: 'Оцінка',
    key: 'rating',
    component: RatingFilter,
    filter: (value:number[])=>(
      value && value.length ? {
        rating_floor__in: value
      } : null
    ),
  },
  {
    title: 'Відсутній доступ до кабінету',
    key: 'without_driver_permission',
    component: BooleanFilter,
    filter: (value:boolean)=>(
      value ? {
        without_driver_permission: true
      } : null
    ),
  },
]

export default filters;