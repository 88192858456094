import { dataRangeItem } from "components/daterange/defaults";
import { DefinedRange } from "components/daterange/types";
import DriverRating from "components/driverRating";
import { addDays } from "date-fns";
import React from "react";
import { useRouteMatch } from "react-router-dom";

export default function ATPDriverRating(){
  const match:any = useRouteMatch();
  const atp = match.params.id;

  const date = new Date();
  const definedRanges:DefinedRange[] = [
    dataRangeItem.yesterday(date),
    dataRangeItem.prev_7_days(date),
    dataRangeItem.prev_30_days(date),
    dataRangeItem.current_week(date),
    dataRangeItem.prev_week(date),
    dataRangeItem.current_month(date),
    dataRangeItem.prev_month(date),
    dataRangeItem.all(date),
  ]
  
  return (
    <DriverRating
      hideSubtitle
      atp={atp}
      DateRangeProps={{
        definedRanges: definedRanges,
        maxDate: addDays(date, -1)
      }}
    />
  )
}