import { Button, Dialog, DialogActions, DialogContent, Fab, Hidden, Paper } from '@mui/material';
import { FilterListOutlined, MyLocationOutlined } from '@mui/icons-material';
import 'ol/ol.css';
import React, { ReactNode, useEffect, useState } from 'react';
import Info from './info';
import { MapClass } from './map';
import { ControlsBox, ControlsOnPageBox, InfoBox, MapBox, MyLocationButton, PaperBox, PopUpBox, Root } from './styles';
import { CarLocation, Point, Status, TariffClass } from './types';
import 'elm-pep';
import PopUp from './popUp';
import Feature from 'ol/Feature';
import CurrentLocationModel from './CurrentLocationModel';

interface IOLMapProps {
  call_signs:CarLocation[];
  tariff_classes:TariffClass[];
  statuses:Status[];
  forceBigMarkers:boolean;
  showCurrentLocation?:boolean;

  children:ReactNode;
}

export default function OLMap(props:IOLMapProps) {

  const {
    showCurrentLocation = true
  } = props

  const [showControlsDialog, setShowControlsDialog] = useState(false);

  const [map, setMap] = useState(null as MapClass|null);
  const [popupData, setPopupData] = useState(null as Feature<any>|null);
  const [ tariffClassesDict, setTariffClassesDict ] = useState({} as {[id:number]: string});
  const [ currentPoint, setCurrentPoint ] = useState<null|Point>(null);
  

  useEffect(() => {
    const _map = new MapClass('map', 'popup');
    _map.setPopUpCallBack(setPopupData)
    setMap(_map);
  }, []);
  
  useEffect(() => {
    if(!map) return;
    map.forceBigMarkers = props.forceBigMarkers;
    map.updateCallSigns((props.call_signs || []));
  }, [map, props.forceBigMarkers, props.call_signs]);
  
  useEffect(() => {
    let class_dict = {} as {[id:number]: string};
    props.tariff_classes.map((i:any) => {
      class_dict[i.id] = i.name;
      return i;
    })
    setTariffClassesDict(class_dict);
  }, [props.tariff_classes]);

  useEffect(() => {
    if(!map) return;
    if(showCurrentLocation){
      const locationModel = new CurrentLocationModel();
      locationModel.subscribe(setCurrentPoint);
      map.showCurrentLocation(locationModel);
      return ()=>{
        locationModel.unsubscribe(setCurrentPoint);
        map.hideCurrentLocation()
      }
    }else{
      map.hideCurrentLocation()
    }
  }, [map, showCurrentLocation])

  return (
    <Root>
      <MapBox>
        <div id="map"></div>
        {!!showCurrentLocation && currentPoint && (
          <MyLocationButton
            color="primary"
            onClick={()=>{
              if(!!currentPoint){
                map?.setCenter(currentPoint, 0.003)
              }
            }}
          >
            <MyLocationOutlined/>
          </MyLocationButton>
        )}
      </MapBox>
      
      <PopUpBox id="popup">
        {!!popupData && (
          <PopUp
            icon={popupData}
            tariffClassesDict={tariffClassesDict}
          />
        )}
      </PopUpBox>

      <Hidden smUp>
        <Paper>
          <Info
            tariffClasses={props.tariff_classes}
            statuses={props.statuses}
            callSigns={props.call_signs}
            className="mobileInfo"
          />
        </Paper>
      </Hidden>
      
      <InfoBox>
        <Hidden smDown>
          <PaperBox>
            <Info
              tariffClasses={props.tariff_classes}
              statuses={props.statuses}
              callSigns={props.call_signs}
            />
          </PaperBox>
        </Hidden>
      </InfoBox>

      <Hidden smUp>
        <ControlsBox>
          <Fab
            color="secondary"
            size="small"
            onClick={()=>{
              setShowControlsDialog(true);
            }}
          >
            <FilterListOutlined />
          </Fab>
        </ControlsBox>
      </Hidden>
      <Hidden smDown>
        <ControlsOnPageBox>
          <PaperBox>
            {props.children}
          </PaperBox>
        </ControlsOnPageBox>
      </Hidden>

      <Dialog
        open={showControlsDialog}
        fullWidth={true}
        maxWidth="xs"
        onClose={()=>{
          setShowControlsDialog(false);
        }}
      >
          <DialogContent>
            {props.children}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={()=>{
                setShowControlsDialog(false);
              }}
            >
              Закрити
            </Button>
          </DialogActions>
      </Dialog>
    </Root>
  );
}