import React from 'react';
import { Typography, Badge, TableRow, TableCell, IconButton, styled, badgeClasses } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IType, IColumn, ITypeFolderDict, IItem } from './types';
import { columns } from './config';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& > *': {
    borderBottom: 'unset',
  },
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    position: 'static',
    transform: 'none',
    transformOrigin: 'initial',
    top: 'auto',
    right: 'auto',
    marginLeft: theme.spacing()
  }
}))



export default function Row(props: { type:IType, items:ITypeFolderDict }) {
  const { type, items } = props;
  const [open, setOpen] = React.useState(type.initOpen);

  let orders:IItem[] = [];
  type.folders.map((folder:number) => {
    if(items[folder]){
      orders = orders.concat(items[folder])
    }
    return null;
  })
  

  const renderRows = () => {
    if(!orders.length){
      return (
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={columns.length}>
            <Typography color='textSecondary'>
              Відсутні
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <React.Fragment>
        {orders.map((order:IItem) => (
          <TableRow key={order.id}>
            <TableCell></TableCell>
            {columns.map((column:IColumn, index:number) => {
              let styles:any = {}
              if(column.nowrap){
                styles.whiteSpace = 'nowrap';
              }
              if(column.width){
                styles.width = column.width;
              }
              return (
                <TableCell
                  key={index}
                  align={column.align}
                  style={styles}
                >
                  {column.onRender(order)}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StyledTableRow hover onClick={() => setOpen(!open)}>
        <TableCell style={{width: 1}}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" colSpan={columns.length}>
          <StyledBadge badgeContent={orders.length} color="secondary">
            {type.name}
          </StyledBadge>
        </TableCell>
      </StyledTableRow>
      { open && renderRows()}
    </React.Fragment>
  );
}