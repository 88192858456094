import React, { useState, useEffect, useCallback } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Hidden } from '@mui/material';
import requests from 'api/requests';
import { IMessage } from './types';
import InstanceRow from './instanceRow';
import { TablePaperBox } from 'components/styled';


interface IPage{
  items: IMessage[];
  items_count: number;
  page: number;
  limit: number;
}

const DEFAULT_PAGE:IPage = {
  items: [],
  items_count: 0,
  page: 0,
  limit: 30,
}

export default function AppMessages() {
  const [page, setPage] = useState(DEFAULT_PAGE)

  const load = useCallback((scroll_up=true) => {
    if(scroll_up){
      window.scrollTo(0, 0);
    }
    requests.get('/admin/webcab/message/', {
      limit: page.limit,
      offset: page.page * page.limit,
    }).then((r) => {
      setPage(page => ({
        ...page,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [page.page, page.limit])

  useEffect(() => load(), [load]);

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  const renderItems = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Ім'я</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell style={{width: '100%'}}>Повідомлення</TableCell>
              <TableCell>OS</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { (page.items).map((item) => (
              <InstanceRow
                key={item.id}
                instance={item}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div>
      <Hidden lgDown>
        { renderPagination() }
      </Hidden>
      <TablePaperBox>
        {renderItems()}
      </TablePaperBox>
      {renderPagination()}
    </div>
  );
}