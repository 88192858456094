import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { IInvoice } from './types';


interface InvoicesTableRowProps {
  item: IInvoice;
  onClick:(item:IInvoice) => void;

  showCompanyColumn?:boolean;
}

export default function InvoicesTableRow(props:InvoicesTableRowProps) {
  return (
    <TableRow hover style={{cursor: 'pointer'}} onClick={() => {
      props.onClick(props.item);
    }}>
      <TableCell style={{whiteSpace: 'nowrap'}}>{props.item.number}</TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>
        <Moment
          date={props.item.date}
          format="DD.MM.YY"
        />
      </TableCell>
      {props.showCompanyColumn && (
        <TableCell>{props.item.company_data.name}</TableCell>
      )}
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
        <NumberFormat value={props.item.cost} displayType={'text'} thousandSeparator={' '} fixedDecimalScale={true} decimalScale={2} />
      </TableCell>
    </TableRow>
  );
}