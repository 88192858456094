import React, { useState, useCallback, useEffect } from 'react';
import { PaperBox } from './styles';
import requests from 'api/requests';
import { Container, Grid, FormControl, TextField, IconButton, Divider } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import UsersList from './users_list';
import { IUserPermission } from './types';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';


interface IAdminPageComponent {
  apiUrl:string;
  parentID:number;
  parentFieldName:string;
  inputNames:any;
  className?:string;
}

export default function AdminPage(props:IAdminPageComponent) {
  
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const load = useCallback(() => {
    let data = {} as any;
    data[props.parentFieldName] = props.parentID;
    requests.getAll(props.apiUrl, data).then((r:any) => {
      setUsers(r.body.results);
    })
  }, [props.apiUrl, props.parentID, props.parentFieldName])
  useEffect(() => load(), [load]);

  const Delete = (user:IUserPermission) => {
    confirm({
      title: "Ви впевнені?",
      description: 'Користувач більше не матиме доступ',
      cancellationText: 'Ні',
      confirmationText: 'Так'
    }).then(() => {
      requests.delete(`${props.apiUrl}${user.id}/`).then(()=>{
        load();
        enqueueSnackbar("Користувача видалено", {variant: 'success'});
      })
    });
  }

  const Add = () => {
    let data = {
      user: {
        email: email
      }
    } as any;
    data[props.parentFieldName] = props.parentID;
    requests.post(props.apiUrl, data).then((r:any) => {
      setEmail("");
      enqueueSnackbar("Користувача додано", {variant: 'success'});
      load();
    })
  }

  return (
    <div className={props.className}>
      <Container maxWidth="sm">
        <PaperBox>
          <form
            onSubmit={(e:any) => {
              e.preventDefault();
              Add();
            }}
          >
            <Grid container alignItems="flex-start" wrap="nowrap">
              <Grid item style={{flexGrow: 1}}>
                <FormControl fullWidth>
                  <TextField
                    label="E-mail"
                    placeholder="E-mail користувача"
                    helperText="Користувачу буде відправлено запрошення на вказаний e-mail"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton type="submit" color="secondary">
                  <AddOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </form>
          
          <br/>
          <Divider/>
          <br/>
          <UsersList
            users={users}
            onDelete={Delete}
          />

          
        </PaperBox>
      </Container>
    </div>
  );
}