import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthModel from "models/AuthModel";

function PrivateRoute(data:any) {
  const { render, ...rest } = data;

  return (
    <Route
      {...rest}
      render={props =>
        AuthModel.if_auth() ? (
          render(props)
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default PrivateRoute;