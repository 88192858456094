import React, { useState } from 'react';
import { Button, FormControl, Dialog, DialogContent, DialogActions, IconButton, Popover, Grid } from '@mui/material';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import { ICompanyRequest } from '../types';
import { FORM } from '../config';
import { FIELDS } from '../fields';
import { DeleteOutlined } from '@mui/icons-material';
import InstanceModalInfo from './info';

interface InstanceModalProps {
  instance: ICompanyRequest;
  onClose?: ()=>void;
  onSave?: (instance:ICompanyRequest)=>void;
  onDelete?: (instance:ICompanyRequest)=>void;
}

export default function InstanceModal(props:InstanceModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [instance, setInstance] = useState({...props.instance});
  const [ deleteConfirmEl, setDeleteConfirmEl] = React.useState<HTMLButtonElement | null>(null);

  const {
    onSave,
    onDelete,
    onClose
  } = props;

  const handleChange = (name:keyof ICompanyRequest) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:keyof ICompanyRequest) => (value:any) => {
    instance[name] = value as never;
    setInstance({...instance});
  }

  const remove = () => {
    requests.delete(`/corp-admin/company/request/${instance.id}/`).then((r) => {
      enqueueSnackbar("Видалено", {variant: 'success'});
      if(onDelete){
        onDelete(instance);
      }
    })
  }

  const update = () => {
    let _data:ICompanyRequest = {
      name: instance.name,
    };
    FORM.fields.map((field_name) => {
      _data[field_name] = instance[field_name] as never;
      return field_name;
    });
    
    let url = `/corp-admin/company/request/`;
    var method = requests.post;
    if(instance.id){
      url = `/corp-admin/company/request/${instance.id}/`;
      method = requests.patch;
    }else{
      _data.source = window.location.hostname;
    }

    method(url, _data).then((r) => {
      enqueueSnackbar("Збережено", {variant: 'success'});
      if(onSave){
        onSave(r.body);
      }
    });
    
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
    >
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          update();
        }}
      >
        <DialogContent>

        <Grid container spacing={3}>
          {FORM.fields.map((field_name) => {
            const field = FIELDS[field_name].field(instance[field_name], handleChange(field_name));
            if(!field) return null
            return (
              <Grid key={field_name} item xs={12} md={6}>
                <FormControl fullWidth key={field_name}>
                  {field}
                </FormControl>
              </Grid>
            )
          })}
        </Grid>

        {instance.id ? (
          <React.Fragment>
            <InstanceModalInfo
              instance={instance}
            />
          </React.Fragment>
        ):null}
          
        </DialogContent>
        <DialogActions>
          {instance.id ? (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setDeleteConfirmEl(event.currentTarget);
              }}
              color="secondary"
              style={{marginRight: 'auto'}}
            >
              <DeleteOutlined />
            </IconButton>
          ):null}
          <Button
            onClick={onClose}
          >
            Відмінити
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            Зберегти
          </Button>
        </DialogActions>
      </form>
      
      <Popover
        open={!!deleteConfirmEl}
        anchorEl={deleteConfirmEl}
        onClose={()=>setDeleteConfirmEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={()=>{
            remove();
            setDeleteConfirmEl(null);
          }}
        >Підтвердити</Button>
      </Popover>
    </Dialog>
  );
}