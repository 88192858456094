import { alpha, darken, lighten, styled, TableCell } from "@mui/material";


export const TableCellBorderRight = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid
    ${
      theme.palette.mode === 'light'
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
    }`
}));