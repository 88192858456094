import React, { useState } from 'react';
import { Button, TextField, FormControl, InputAdornment, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@mui/material';
import { AlternateEmailOutlined } from '@mui/icons-material';
import AuthLayout from 'layout/auth';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import requests from 'api/requests';


export default function Password(){

  const [ inputs, setInputs ] = useState({
    email: "",
  });
  const [ done, setDone ] = useState(false);

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    let _inputs = inputs as any;
    _inputs[name] = value;
    setInputs({..._inputs});
  }

  const onSumbut = (e:any) => {
    e.preventDefault();
    requests.post('/auth/password/', {
      email: inputs.email
    }).then((r) => {
      setDone(true);
    })
  }

  return (
    <AuthLayout>
      <React.Fragment>
        <form onSubmit={onSumbut}>
          <FormControl fullWidth>
            <TextField
              placeholder="E-mail"
              value={ inputs.email || '' }
              type="email"
              onChange={handleChange('email')}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlined color={'action'} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Grid container sx={{mt: 2}} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                component={Link}
                to={routes.login}
                size="small"
                color="primary"
              >
                Вхід
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
              >
                Відновити
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={done}
        >
          <DialogTitle>Відновлення пароля</DialogTitle>
          <DialogContent>
            <DialogContentText>
              На Вашу електронну адресу надіслано лист з інструкцією по відновленню пароля.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              component={Link}
              to={routes.login}
              color="primary"
            >
              Вхід
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </AuthLayout>
  )
}