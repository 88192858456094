import React from "react";
import moment from 'moment';
import { Typography } from "@mui/material";
import { FilteredTableColumn } from "components/FilteredTable/types";
import { IItem } from "./types";
import CopyPhone from "components/copyClick/CopyPhone";
import OrderIDLink from "components/OrderIDLink";
import DriverLink from "components/driverLink";


const columns: FilteredTableColumn[] = [
  {
    title: 'Водій/АТП',
    items: [
      {
        title: 'Водій',
        render: (row:IItem) => {
          
          
          let names = [
            row.driver?.lastname,
            row.driver?.firstname,
            row.driver?.middlename,
          ]
          names = names.filter((i)=>!!i)
          return <DriverLink
            driverID={row.driver?.id}
            color="inherit"
          >{names.join(" ")}</DriverLink>
        }
      },
      {
        title: 'АТП',
        render: (row:IItem) => {
          if(!row.car?.atp) return null;
          return (
            <Typography variant='caption' color='textSecondary'>
              {row.car.atp.name}
            </Typography>
          );
        }
      }
    ]
  },
  {
    title: 'Позивний',
    items: [
      {
        title: 'Позивний',
        render: (row:IItem) => (
          <React.Fragment>
            {row.callsign?.call_sign}
            {row.car?.isarchive?(
              <Typography variant='caption' color='textSecondary'>
                &nbsp;(Архів)
              </Typography>
            ):null}
          </React.Fragment>
        )
      },
      {
        title: 'Клас авто',
        render: (row:IItem) => row.car?.tariff_class,
      },
      {
        title: 'Марка авто',
        render: (row:IItem) => row.car?.mark
      }
    ]
  },
  {
    title: 'Замовлення',
    items: [
      {
        title: '№ замовлення',
        render: (row:IItem) => {
          if(row.orderid!==null){
            return (
              <OrderIDLink OrderID={row.orderid} />
            )
          }
          return null;
        },
        style: {
          whiteSpace: 'nowrap'
        }
      },
      {
        title: 'Клас замовлення',
        render: (row:IItem) => row.order?.tariff_class,
      },
      {
        title: 'Дата закриття замовлення',
        render: (row:IItem) => {
          if(row.order!==null){
            return (
              <Typography variant='caption' color='textSecondary'>
                {moment(row.order?.getresultdatetime).format("DD.MM.YY HH:mm")}
              </Typography>
            )
          }
          return null;
        },
        style: {
          whiteSpace: 'nowrap'
        }
      },
    ]
  },
  {
    title: 'Пасажир',
    items: [
      {
        title: 'Имя пасажира',
        render: (row:IItem) => row.order?.fio
      },
      {
        title: 'Телефон пасажира',
        render: (row:IItem) => {
          return (
            <CopyPhone phone={row.phone?.number} />
          )
        },
        style: {
          whiteSpace: 'nowrap'
        }
      },
      {
        title: 'Кількість поїздок пасажира',
        render: (row:IItem) => {
          if(row.order?.phone!==null){
            return `Поїздок: ${row.phone?.numbertrips}`;
          }
          return null;
        }
      }
    ]
  },
];

export default columns;