import { lighten, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";


export default styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === "light" ? theme.palette.background.paper : lighten(theme.palette.background.paper, 0.2),
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));