import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Grid, Hidden, IconButton, Collapse, Box, TextField, InputAdornment, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import requests from 'api/requests';
import { useRouteMatch } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { KeyboardArrowDown, KeyboardArrowUp, PublishOutlined, PrintOutlined, SearchOutlined, MoreVertOutlined } from '@mui/icons-material';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { ORDER_FOLDER } from 'config';
import { dataRangeItem } from 'components/daterange/defaults';
import { AddressListOL, CollapseGrid, PrintBox, TableRowBox } from './styles';
import { PaginationGridBox, TablePaperBox } from 'components/styled';


function Row(props: { item: any}) {
  const { item } = props;
  const [open, setOpen] = React.useState(false);

  const renderPhone = (number:string|null) => {
    if(!number) return "";

    return (
      <NumberFormat value={number} displayType={'text'} format="+## (###) ### ## ##" />
    )
  }

  return (
    <React.Fragment>
      <TableRowBox hover onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          <NumberFormat value={item.id} displayType={'text'} thousandSeparator={' '} />
        </TableCell>
        <TableCell>
          <Moment
            date={item.date}
            format="DD.MM.YY"
          />
        </TableCell>
        <TableCell>
          <Moment
            date={item.date}
            format="HH:mm"
          />
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          {renderPhone(item.phone)}
          { !!item.phone2 && (
            <div>{renderPhone(item.phone2)}</div>
          )}
        </TableCell>
        <TableCell>{item.fio}</TableCell>
        <TableCell>{item.tariff_class}</TableCell>
        <TableCell align="right">
          {item.wait_minutes || ''}
        </TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{item.additions_price ? (
          <NumberFormat value={item.additions_price} displayType={'text'} thousandSeparator={' '} />
        ) : ''}</TableCell>
        <TableCell align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={' '} /></TableCell>
      </TableRowBox>
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <CollapseGrid container spacing={5} alignItems="flex-start" justifyContent="flex-start" direction="row" wrap="nowrap">
              <Grid item>
                <b>Адреса</b>
                <AddressListOL>
                  {item.addresses.map((address:string, index:number) => (
                    <li key={index}>{address}</li>
                  ))}
                </AddressListOL>
              </Grid>

              { item.comment && (
              <Grid item>
                <b>Замітки</b>
                <div>{item.comment}</div>
              </Grid>
              )}

              <Grid item>
                <div><b>Позивний:</b> {item.callsign}</div>
                { !!item.hours_time && (
                  <div><b>Години:</b> {item.hours_time}</div>
                )}
              </Grid>

              { item.client_answer && (
                <Grid item>
                  <b>Відповідь кліента</b>
                  <div>{item.client_answer}</div>
                </Grid>
              )}
            </CollapseGrid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
  );
}

export default function Trips() {
  const match:any = useRouteMatch();
  const company = match.params.company;

  const printRef:any = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => setAnchorMenu(null),
    onBeforeGetContent: () => {
      return new Promise<void>((resolve, reject) => {
        requests.getAll('/corp/order/', {
          ...filters(),
          ordering: 'date',
        }).then((r) => {
          setPrintItems(r.body.results);
          resolve();
        })
      });
    },
    pageStyle: 'padding: 25px'
  });
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [searchString, setSearchString] = useState('');
  const [dateRange, setDateRange] = useState<IDateRange>(dataRangeItem.prev_7_days(new Date()));
  const [page, setPage] = useState({
    first_loaded: false,
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })
  const [printItems, setPrintItems] = useState([])
  const [total, setTotal] = useState({
    price: undefined,
    count: undefined,
  })
  const [companyData, setCompanyData] = useState(null as any)

  const filters = useCallback(() => {
    let new_filter:any = {
      company: company,
      folder: ORDER_FOLDER.FULFILLED
    };
    if(dateRange.startDate){
      new_filter.date__gte = format(dateRange.startDate as Date, "yyyy-MM-dd HH:mm:00");
    }
    if(dateRange.endDate){
      new_filter.date__lte = format(dateRange.endDate, "yyyy-MM-dd HH:mm:59");
    }
    if(searchString){
      new_filter.search = searchString;
    }
    return new_filter;
  }, [company, dateRange, searchString])

  const load = useCallback(() => {
    window.scrollTo(0, 0);
    
    requests.get('/corp/order/', {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit,
    }).then((r) => {
      setPage(page => ({
        ...page,
        first_loaded: true,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [page.page, page.limit, filters])

  const loadTotal = useCallback(() => {
    requests.get('/corp/order/total/', filters()).then((r) => {
      setTotal(r.body);
    })
  }, [filters])

  const loadCompanyData = useCallback(() => {
    requests.get(`/corp/company/${company}/`).then((r) => {
      setCompanyData(r.body);
    })
  }, [company])

  useEffect(() => load(), [load]);
  useEffect(() => loadTotal(), [loadTotal]);
  useEffect(() => loadCompanyData(), [loadCompanyData]);

  const order_export = () => {
    requests.download('/corp/order/export/', {
      ...filters(),
      ordering: 'date',
    })
    setAnchorMenu(null);
  }

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  const renderItems = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{width: 1}} />
              <TableCell>№ замовлення</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Час</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>ПІБ</TableCell>
              <TableCell>Клас</TableCell>
              <TableCell align="right">Очікування, хв.</TableCell>
              <TableCell align="right">Інші послуги, грн</TableCell>
              <TableCell align="right">Тариф, грн</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { page.items.map((item:any) => (
              <Row key={item.id} item={item} />
            ))}

            { total.price !== undefined && (
              <TableRow>
                <TableCell rowSpan={2} colSpan={7} />
                <TableCell colSpan={2}><b>Всього(грн.)</b></TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
                  <b><NumberFormat value={total.price || 0} displayType={'text'} thousandSeparator={' '} /></b>
                </TableCell>
              </TableRow>
            )}
            { total.count !== undefined && (
              <TableRow>
                <TableCell colSpan={2}><b>Всього поїздок</b></TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
                  <b><NumberFormat value={total.count} displayType={'text'} thousandSeparator={' '} /></b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderPrintTable = () => (
    <div>
      <table>
        <thead>
          <tr>
            <th>№ Зам.</th>
            <th>Дата</th>
            <th>Час</th>
            <th>Телефон</th>
            <th>Телефон 2</th>
            <th>Адреса</th>
            <th>ПІБ</th>
            <th>Клас</th>
            <th>Очік., хв.</th>
            <th>Інші посл., грн</th>
            <th>Години</th>
            <th>Позивний</th>
            <th>Тариф, грн</th>
            <th>Замітки</th>
            <th>Відповідь кліента</th>
          </tr>
        </thead>
        <tbody>
          { printItems.map((item:any, index:number) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>
                <Moment
                  date={item.date}
                  format="DD.MM.YY"
                />
              </td>
              <td>
                <Moment
                  date={item.date}
                  format="HH:mm"
                />
              </td>
              <td>{item.phone || ''}</td>
              <td>{item.phone2 || ''}</td>
              <td>
                <AddressListOL>
                  {item.addresses.map((address:string, index:number) => (
                    <li key={index}>{address}</li>
                  ))}
                </AddressListOL>
              </td>
              <td>{item.fio}</td>
              <td>{item.tariff_class}</td>
              <td align="right">{item.wait_minutes || ''}</td>
              <td align="right">
                {item.additions_price ? (
                  <NumberFormat value={item.additions_price} displayType={'text'} thousandSeparator={' '} />
                ) : ''}
              </td>
              <td align="right">{item.hours_time}</td>
              <td>{item.callsign}</td>
              <td align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={' '} /></td>
              <td>{item.comment}</td>
              <td>{item.client_answer}</td>
            </tr>
          ))}
          <tr>
            <th colSpan={12}>Всього (поіздок: <NumberFormat value={total.count} displayType={'text'} thousandSeparator={' '} />)</th>
            <th align="right" style={{whiteSpace: 'nowrap'}}>
              <NumberFormat
                value={total.price || 0}
                fixedDecimalScale={true}
                decimalScale={2}
                displayType={'text'}
                thousandSeparator={' '}
              />
            </th>
            <th colSpan={2}></th>
          </tr>
          {companyData && companyData.percent ? (
            <tr>
              <th colSpan={12}>{companyData.percent}%</th>
              <th align="right" style={{whiteSpace: 'nowrap'}}>
                <NumberFormat
                  value={(total.price || 0)*companyData.percent/100}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={' '}
                />
              </th>
              <th colSpan={2}></th>
            </tr>
          ) : null}
          {companyData && companyData.percent && companyData.real_percent ? (
            <tr>
              <th colSpan={12}>Разом</th>
              <th align="right" style={{whiteSpace: 'nowrap'}}>
                <NumberFormat
                  value={(total.price || 0)*companyData.percent/100 + (total.price || 0)}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={' '}
                /></th>
              <th colSpan={2}></th>
            </tr>
          ): null}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      { page.first_loaded && (
        <div>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5} lg={4} xl={3}>
                  <DateRangePicker
                    withTime={true}
                    dateRange={dateRange}
                    onChange={(range) => setDateRange(range)}
                  />
                </Grid>
                <Grid item xs={9} sm={5} lg={4} xl={3}>
                  <TextField
                    fullWidth
                    placeholder="Пошук"
                    value={ searchString }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined color={'action'} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
            <Grid item xs={3} sm={2}>
              <IconButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  setAnchorMenu(e.currentTarget);
                }}
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu
                anchorEl={anchorMenu}
                keepMounted
                open={Boolean(anchorMenu)}
                onClose={() => {
                  setAnchorMenu(null);
                }}
              >
                  <MenuItem
                    disabled={ !page.items || !page.items.length }
                    onClick={handlePrint}
                  >
                    <ListItemIcon>
                      <PrintOutlined fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Друкувати</Typography>
                  </MenuItem>
                  <MenuItem
                    disabled={ !page.items || !page.items.length }
                    onClick={order_export}
                  >
                    <ListItemIcon>
                      <PublishOutlined fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Експорт (Excel)</Typography>
                  </MenuItem>
              </Menu>
            </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <PaginationGridBox item sm={4}>
                { renderPagination() }
              </PaginationGridBox>
            </Hidden>
          </Grid>
          <TablePaperBox>
            {renderItems()}
          </TablePaperBox>
          {renderPagination()}
        </div>
      )}

      <div style={{display: 'none'}}>
        <PrintBox ref={printRef}>
          {renderPrintTable()}
        </PrintBox>
      </div>
    </div>
  );
}