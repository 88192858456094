import { Paper, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableRowProps, Tabs, Tooltip } from "@mui/material";
import { TariffClass } from "components/map/types";
import React, { useMemo, useState } from "react";
import { ILoyalty, ILoyaltyDict, ILoyaltyTime } from "./types";

interface IProps{
  loyalty: ILoyalty[];
  carClasses: TariffClass[];
  loyaltyTime: ILoyaltyTime[];
}

interface ITariffLoyalty{
  loyalties: ILoyalty[],
  coef: number;
}

interface ILoyaltiesByPoint{
  [point:number]: {
    [tariff_class: number]: ITariffLoyalty
  }
}

interface ILoyaltyItem{
  timefrom: number;
  timeto: number;
  classes: {[tariff_class: number]: ITariffLoyalty};
}

const TabsBox = styled(Tabs)(({ theme }) => ({
  minHeight: 0,
  "& button": {
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(0.5, 1)
  }
}));

interface CurrentRowProps extends TableRowProps {
  current?: boolean;
}
const CurrentRow = styled(TableRow, {
  shouldForwardProp: (prop) => !['current'].includes(prop.toString()),
})<CurrentRowProps>(({ theme, current }) => ({
  ...(current&&{
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  })
}));

interface BoldBoxProps {
  bold?: boolean;
}
const BoldBox = styled("span", {
  shouldForwardProp: (prop) => !['bold'].includes(prop.toString()),
})<BoldBoxProps>(({ theme, bold }) => ({
  ...(bold&&{
    fontWeight: 'bold'
  })
}));

export default function LoyaltyTimeTable(props:IProps){
  const {
    loyalty,
    carClasses,
    loyaltyTime
  } = props;

  const weekday = ((new Date()).getDay() + 6) % 7;
  const [activeTab, setActiveTab] = useState<number>(weekday);

  const getTodaysTotalMinutes = () => {
    const date = new Date();
    return (date.getHours() * 60) + date.getMinutes();
  }

  const loyaltyDict:ILoyaltyDict = useMemo(()=>{
    let dict:ILoyaltyDict = {};
    loyalty.map((i)=>{
      dict[i.id] = i;
      return i;
    })
    return dict;
  }, [loyalty])

  const itemsByWeekDay = useMemo(() => {
    return loyaltyTime.filter((i)=>i.weekday===activeTab)
  }, [loyaltyTime, activeTab])

  const timePoints = useMemo(()=>{
    let points:number[] = [];
    itemsByWeekDay.map((i)=>{
      if(!points.includes(i.timefrom)){
        points.push(i.timefrom)
      }
      if(!points.includes(i.timeto)){
        points.push(i.timeto)
      }
      return i;
    })
    points.sort((a, b) => a - b)

    return points;
  }, [itemsByWeekDay])

  const loyaltyByTimePoint = useMemo(()=>{
    let res:ILoyaltiesByPoint = {};
    itemsByWeekDay.map((item)=>{
      const startPoint = timePoints.indexOf(item.timefrom);
      for(let i=startPoint; timePoints[i]<item.timeto; i++){
        const point = timePoints[i];
        if(res[point] === undefined){
          res[point] = {}
          carClasses.forEach((i)=>{
            res[point][i.id] = {
              coef: 100,
              loyalties: []
            }
          })
        }
        const _loyalty = loyaltyDict[item.loyaltyname];
        if(_loyalty){
          _loyalty.tariff_classes.forEach((tariff_class)=>{
            if(res[point][tariff_class]){
              res[point][tariff_class].loyalties.push(_loyalty)
              res[point][tariff_class].coef += item.coef - 100;
              res[point][tariff_class].loyalties.sort((a, b) => a.id - b.id)
            }
          })
        }
      }
      return item;
    })
    return res;
  }, [itemsByWeekDay, timePoints, carClasses, loyaltyDict])

  const items = useMemo(()=>{
    const ifSome = (a:ILoyaltyItem, b:ILoyaltyItem):boolean => {
      if(!a.classes !== !b.classes) return false

      for(let i=0; i<carClasses.length; i++){
        const classID = carClasses[i].id;
        if(a.classes[classID].coef !== b.classes[classID].coef){
          return false;
        }
      }
      return true;
    }

    let res:ILoyaltyItem[] = [];
    for(let i=0; i<timePoints.length-1; i++){
      const val:ILoyaltyItem = {
        timefrom: timePoints[i],
        timeto: timePoints[i+1],
        classes: loyaltyByTimePoint[timePoints[i]],
      }
      if(res.length<1 || !ifSome(val, res[res.length-1])){
        res.push(val)
      }else{
        res[res.length-1].timeto = val.timeto;
      }
    }
    if(!res[0] || res[0].timefrom !== 0){
      res.splice(0, 0, {
        timefrom: 0,
        timeto: !res[0] ? 60*24 : res[0].timefrom,
        classes: {}
      });
    }
    if(res[res.length-1].timeto !== (60*24)){
      res.push({
        timefrom: res[res.length-1].timeto,
        timeto: 60*24,
        classes: {}
      });
    }
    return res
  }, [timePoints, loyaltyByTimePoint, carClasses])

  const printTime = (seconds:number):string => {
    const hours = Math.floor(seconds/60).toString().padStart(2, "0")
    const minutes = (seconds%60).toString().padStart(2, "0")
    return `${hours}:${minutes}`;
  }


  return (
    <div>
      <TabsBox
        variant="fullWidth"
        value={activeTab}
        onChange={(e, value)=>{
          setActiveTab(value)
        }}
      >
        <Tab label="ПН" value={0} />
        <Tab label="ВТ" value={1} />
        <Tab label="СР" value={2} />
        <Tab label="ЧТ" value={3} />
        <Tab label="ПТ" value={4} />
        <Tab label="СБ" value={5} />
        <Tab label="НД" value={6} />
      </TabsBox>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {carClasses.map((i)=>(
                <TableCell key={i.id} align="center">{i.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((t, index) => (
              <CurrentRow
                key={`${t.timefrom}-${t.timeto}-${index}`}
                current={weekday===activeTab && getTodaysTotalMinutes() >= t.timefrom && getTodaysTotalMinutes() <= t.timeto}
              >
                <TableCell component="th">
                  {printTime(t.timefrom)}-{printTime(t.timeto)}
                </TableCell>
                {carClasses.map((tariff)=>(
                  <TableCell key={tariff.id} align="center">
                    {t.classes && t.classes[tariff.id]?(
                      <Tooltip title={(t.classes[tariff.id]?.loyalties || []).map((i)=>i.name).join(", ")}>
                        <BoldBox
                          bold={(t.classes[tariff.id]?.loyalties || []).length > 1}
                        >{t.classes[tariff.id].coef}%</BoldBox>
                      </Tooltip>
                    ):null}
                  </TableCell>
                ))}
              </CurrentRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}