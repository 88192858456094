
import { CheckOutlined, ManageHistoryOutlined, MoreVertOutlined, PrintOutlined, PublishOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import requests from "api/requests";
import { addDays, addMinutes, format, startOfDay } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Deserializer } from "ts-serializer-core";
import TableItems from "./tableItems";
import { OnlineTotalBillSerializer } from "./types";
import { useReactToPrint } from "react-to-print";

interface DateFilter {
  title: string;
  field: string;
}

interface IProps {
  dateFilters: DateFilter[];
  apiURL: string;
  queryParams?: { [param: string]: string }
}

export default function OnlineTotalBillBase(props: IProps) {
  const {
    dateFilters,
    apiURL,
    queryParams,
  } = props;
  const now = startOfDay(new Date());
  now.setHours(21)

  const printRef: any = useRef();
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [end, setEnd] = useState<Date | null>(addMinutes(now, -1));
  const [start, setStart] = useState<Date | null>(addDays(now, -7));
  const [items, setItems] = useState<OnlineTotalBillSerializer[] | null>(null)
  const [anchorDateMenu, setAnchorDateMenu] = useState<null | HTMLElement>(null);
  const [dateFilterField, setDateFilterField] = useState(dateFilters[0].field)

  const filters = useMemo(()=>{
    if (!start || !end){
      return null;
    }
    return {
      ...queryParams,
      [`${dateFilterField}__gte`]: format(start, "yyyy-MM-dd HH:mm:00"),
      [`${dateFilterField}__lte`]: format(end, "yyyy-MM-dd HH:mm:59"),
    }
  }, [start, end, queryParams, dateFilterField])

  useEffect(() => {
    if (!filters) {
      setItems([]);
    } else {
      requests.get(apiURL, filters).then((r) => {
        const deserializer = new Deserializer();
        const items = deserializer.deserialize(OnlineTotalBillSerializer, r.body);
        setItems(items)
      })
    }
  }, [apiURL, filters])

  const handlePrint = useReactToPrint({
    content: () => {
      console.log(printRef.current)
      return printRef.current
    },
    onBeforePrint: () => setAnchorMenu(null),
    pageStyle: 'padding: 25px'
  });

  const handleExport = () => {
    if(!filters){
      return;
    }
    requests.download(`${apiURL}export/`, filters)
    setAnchorMenu(null);
  }

  return (
    <div>
      <Stack direction={"row"} alignItems={"end"} spacing={2}>
        <Box>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Початок"
            value={start}
            onChange={(newValue) => {
              setStart(newValue);
            }}
          />
        </Box>
        <Box>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Кінець"
            value={end}
            onChange={(newValue) => {
              setEnd(newValue);
            }}
          />
        </Box>
        <Box>
          <IconButton size="small" onClick={(e) => {
            setAnchorDateMenu(e.currentTarget);
          }}>
            <ManageHistoryOutlined />
          </IconButton>
          <Menu
            anchorEl={anchorDateMenu}
            open={!!anchorDateMenu}
            onClose={() => {
              setAnchorDateMenu(null);
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {dateFilters.map((i) => (
              <MenuItem
                key={i.field}
                onClick={() => {
                  setDateFilterField(i.field);
                  setAnchorDateMenu(null);
                }}
              >
                <ListItemIcon>
                  <CheckOutlined fontSize="small" color={i.field === dateFilterField ? 'success' : 'disabled'} />
                </ListItemIcon>
                <Typography variant="inherit">{i.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box>
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setAnchorMenu(e.currentTarget);
            }}
          >
            <MoreVertOutlined />
          </IconButton>
          <Menu
            anchorEl={anchorMenu}
            keepMounted
            open={Boolean(anchorMenu)}
            onClose={() => {
              setAnchorMenu(null);
            }}
          >
            <MenuItem
              disabled={!items || !items.length}
              onClick={handlePrint}
            >
              <ListItemIcon>
                <PrintOutlined fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Друкувати</Typography>
            </MenuItem>
            <MenuItem
              disabled={!items || !items.length || !filters}
              onClick={handleExport}
            >
              <ListItemIcon>
                <PublishOutlined fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Експорт (Excel)</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>

      {items !== null && (
        <TableItems
          ref={printRef}
          items={items}
        />
      )}
    </div>
  )
}