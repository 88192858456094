import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { CheckOutlined, ManageHistoryOutlined, MoreVertOutlined, PrintOutlined, PublishOutlined } from '@mui/icons-material';
import requests from 'api/requests';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { getDateRangeWithTimeText } from 'components/daterange/utils';
import { useProgress } from 'components/progress';
import { format, parse } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getDateDefinedRanges } from '../../utils';
import GroupTable from './groupTable';
import PrintTable from './printTable';
import { PrintBox } from './styles';
import { TablePaperBox } from 'components/styled';
import { dateFilters } from 'components/orderDriver';

interface IOrdersGroupProps {
  apiURL: string;
  groupName: string;
  renderGroupValue: (item: any) => any;
}

export default function OrderGroup(props: IOrdersGroupProps) {
  const {
    apiURL,
    groupName,
    renderGroupValue
  } = props

  const match: any = useRouteMatch();
  const atp_id = match.params.id;

  const { isProgress } = useProgress();
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [anchorDateMenu, setAnchorDateMenu] = React.useState<null | HTMLElement>(null);
  const [dateFilterField, setDateFilterField] = React.useState(dateFilters[0].field);
  const [atp, setAtp] = useState({} as any);
  const [dateRange, setDateRange] = useState<IDateRange>({} as IDateRange);
  const [items, setItems] = useState([] as any[]);

  const printRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => setAnchorMenu(null),
    pageStyle: 'padding: 25px'
  });

  const order_export = () => {
    requests.download(`${apiURL}export/`, {
      ...filters(),
    })
    setAnchorMenu(null);
  }

  const replacement_time = useMemo(() => {
    return parse(atp.replacement_time || '00:00:00', 'HH:mm:ss', new Date());
  }, [atp])
  const dateDefinedRanges = useMemo(() => {
    const date_range = getDateDefinedRanges(replacement_time);
    setDateRange(date_range[1]);
    return date_range;
  }, [replacement_time])

  const filters = useCallback(() => {
    let new_filter = {} as any;
    if (atp.id) {
      new_filter.atp = atp.id;
    }
    if (dateRange.startDate) {
      new_filter[`${dateFilterField}__gte`] = format(dateRange.startDate as Date, "yyyy-MM-dd HH:mm:00");
    }
    if (dateRange.endDate) {
      new_filter[`${dateFilterField}__lte`] = format(dateRange.endDate, "yyyy-MM-dd HH:mm:59");
    }
    return new_filter;
  }, [atp, dateRange, dateFilterField])

  const initLoad = useCallback(() => {
    requests.get(`/atp/atp/${atp_id}/`).then((r) => {
      setAtp(r.body);
    })
  }, [atp_id]);

  const load = useCallback(() => {
    if (!atp.id) return;

    requests.get(apiURL, filters()).then((r) => {
      setItems(r.body)
    })
  }, [apiURL, atp, filters]);

  useEffect(() => initLoad(), [initLoad]);
  useEffect(() => load(), [load]);

  if (!atp.id) {
    return null;
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} lg={4} xl={3}>
              <Stack direction={"row"} alignItems={"start"}>
                <Box sx={{ flexGrow: 1 }}>
                  <DateRangePicker
                    withTime={true}
                    startTime={replacement_time}
                    dateRange={dateRange}
                    onChange={(range) => setDateRange(range)}
                    definedRanges={dateDefinedRanges}
                  />
                </Box>
                <Box>
                  <IconButton onClick={(e) => {
                    setAnchorDateMenu(e.currentTarget);
                  }}>
                    <ManageHistoryOutlined />
                  </IconButton>
                  <Menu
                    anchorEl={anchorDateMenu}
                    open={!!anchorDateMenu}
                    onClose={() => {
                      setAnchorDateMenu(null);
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {dateFilters.map((i) => (
                      <MenuItem
                        key={i.field}
                        onClick={() => {
                          setDateFilterField(i.field);
                          setAnchorDateMenu(null);
                        }}
                      >
                        <ListItemIcon>
                          <CheckOutlined fontSize="small" color={i.field === dateFilterField ? 'success' : 'disabled'} />
                        </ListItemIcon>
                        <Typography variant="inherit">{i.title}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={3} sm={2}>
              <IconButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  setAnchorMenu(e.currentTarget);
                }}
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu
                anchorEl={anchorMenu}
                keepMounted
                open={Boolean(anchorMenu)}
                onClose={() => {
                  setAnchorMenu(null);
                }}
              >
                <MenuItem
                  disabled={isProgress || !items.length}
                  onClick={handlePrint}
                >
                  <ListItemIcon>
                    <PrintOutlined fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Друкувати</Typography>
                </MenuItem>
                <MenuItem
                  disabled={isProgress || !items.length}
                  onClick={order_export}
                >
                  <ListItemIcon>
                    <PublishOutlined fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Експорт (Excel)</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TablePaperBox>
        <GroupTable
          groupName={groupName}
          renderGroupValue={renderGroupValue}
          items={items}
        />
        {(!items.length && !isProgress) ? (
          <div>
            <br />
            <br />
            <Typography variant="h6" color="textSecondary" align="center">Дані за вказаний період відсутні</Typography>
            <br />
            <br />
          </div>
        ) : null}
      </TablePaperBox>

      <div style={{ display: 'none' }}>
        <PrintBox ref={printRef}>
          <div>Період: {getDateRangeWithTimeText(dateRange)}</div><br />
          <PrintTable
            groupName={groupName}
            renderGroupValue={renderGroupValue}
            items={items}
          />
        </PrintBox>
      </div>
    </div>
  );
}