import { FilteredTableFilter } from "components/FilteredTable/types"
import { DateRangePickerFilter, SelectFilter } from "components/FilteredTable/filters";
import { dataRangeItem } from "components/daterange/defaults";
import { IDateRange } from "components/daterange";
import { addDays, endOfDay, format, startOfDay } from 'date-fns';
import { IExtendData } from "./types";
import SliderFilter from "components/FilteredTable/filters/SliderFilter";

const filters:FilteredTableFilter[] = [
  {
    title: 'Дата зупинки',
    key: 'date',
    defaultValue: dataRangeItem.yesterday(new Date()),
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>{
      const date = new Date();
      return {
        definedRanges: [
          dataRangeItem.today(date),
          dataRangeItem.yesterday(date),
          {
            label: "Позавчора",
            startDate: startOfDay(addDays(date, -2)),
            endDate: endOfDay(addDays(date, -2))
          },
          dataRangeItem.prev_7_days(date),
          dataRangeItem.current_week(date),
          dataRangeItem.prev_week(date),
        ]
      }
    },
    component: DateRangePickerFilter,
    filter: (value:IDateRange)=>{
      if(!value.startDate && !value.endDate){
        return null;
      }
      let filter:any = {};
      if(value.startDate){
        filter.start = format(value.startDate, "yyyy-MM-dd 00:00:00");
      }
      if(value.endDate){
        filter.end = format(value.endDate, "yyyy-MM-dd 23:59:59");
      }
      return filter
    },
  },
  {
    title: 'Авто',
    key: 'car',
    component: SelectFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>{
      let items = ((extendData || {}).cars || []).map((i)=>({
        name: i.label,
        value: i.id
      }))
      items = [{
        name: 'Всі',
        value: "" as any,
      }].concat(items);
      return {
        items: items
      }
    },
    filter: (value:number)=>{
      if(!value) return null;
      return {
        car: value
      };
    },
  },
  {
    title: 'Під замовленням',
    key: 'with_order',
    component: SelectFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: 'Не важливо',
          value: "",
        },
        {
          name: 'Так',
          value: "yes",
        },
        {
          name: 'Ні',
          value: 'no',
        }
      ]
    }),
    filter: (value:string)=>{
      if(value === 'yes'){
        return {
          with_order: true
        };
      }
      if(value === 'no'){
        return {
          with_order: false
        };
      }
      return null;
    },
  },
  {
    title: 'Час на зміні(%)',
    key: 'working_percent',
    component: SliderFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      step: 5
    }),
    filter: (value:number[])=>{
      if(!value || (value[0] === 0 && value[1] === 100)) return null;
      return {
        working_percent__gte: value[0],
        working_percent__lte: value[1]
      };
    },
  },
]

export default filters;