import { Grid, InputAdornment, styled, TextField, TextFieldProps } from "@mui/material";
import React, { useMemo } from "react";
import { FilteredTableFilterProps } from "../types";

interface IProps extends FilteredTableFilterProps{
  value: [number|null, number|null]|undefined|null;
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& input": {
    MozAppearance: 'textfield',
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: 'none',
      margin: 0,
    },
  }
}));

export default function FromToFilter(props:IProps){

  const {
    value
  } = props;
  
  const fromValue = useMemo(()=>{
    if(!value || value.length < 2) return "";
    return value[0] === null ? "" : value[0]
  }, [value])
  
  const toValue = useMemo(()=>{
    if(!value || value.length < 2) return "";
    return value[1] === null ? "" : value[1]
  }, [value])

  const getValue = (v:string) => {
    if(v === "") return null;
    if(isNaN(v as any)) return null
    return Number(v);
  }

  return (
    <Grid container alignItems="flex-end">
      <Grid item xs={6}>
        <StyledTextField
          type="number"
          label={props.label}
          value={fromValue}
          onChange={(e)=>{
            props.onChange([getValue(e.target.value), toValue])
          }}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">від</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          type="number"
          value={toValue}
          onChange={(e)=>{
            props.onChange([fromValue, getValue(e.target.value)])
          }}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">до</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  )
}