import requests from "api/requests";
import FilteredTable from "components/FilteredTable"
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import columns from "./columns"
import filters from "./filters"
import InstanceModal from "./instanceModal";
import { IService, IUpdateRequest } from "./types";

export default function ServiceUpdateRequest(){
  const tableRef = useRef(null);
  const [instance, setInstance] = useState<null|IUpdateRequest>(null);
  const [services, setServices] = useState<IService[]>([]);

  useEffect(()=>{
    requests.get('/base/service/').then((r) => setServices(r.body))
  }, [])
  
  return (
    <React.Fragment>
      <FilteredTable
        dataURL="/admin/service-update-request/"
        columns={columns}
        filters={filters}
        userSettingsKey="service-update-requests"
        tableRef={tableRef}
        onRowClick={setInstance}
      />
      { instance !== null ? (
        <InstanceModal
          instance={instance}
          services={services}
          onClose={()=>setInstance(null)}
          onApprove={(ignore)=>{
            const instance_id = instance.id;
            setInstance(null);
            requests.post(`/admin/service-update-request/${instance_id}/approve/`, {
              ignore: ignore
            }).finally(()=>{
              (tableRef.current as any).reload();
            })
          }}
          onReject={()=>{
            const instance_id = instance.id;
            setInstance(null);
            requests.post(`/admin/service-update-request/${instance_id}/reject/`).finally(()=>{
              (tableRef.current as any).reload();
              setInstance(null);
            })
          }}
        />
      ): null }
    </React.Fragment>
  )
}