import { AccountBoxOutlined, DirectionsCarOutlined, EmojiPeopleOutlined, InfoOutlined, MapOutlined, MonetizationOnOutlined, SettingsOutlined, StarBorderOutlined, SupervisorAccountOutlined } from '@mui/icons-material';
import Cars from 'pages/atp/cars';
import Drivers from 'pages/atp/drivers';
import FinedOrders from 'pages/atp/finedOrders';
import Info from 'pages/atp/info';
import MapPage from 'pages/atp/mapPage';
import Offset from 'pages/atp/offset';
import OrderByDriver from 'pages/atp/orderGroups/orderByDriver';
import OrderByCallSign from 'pages/atp/orderGroups/orderBySallSign';
import Orders from 'pages/atp/orders';
import ATPDriverRating from 'pages/atp/rating/driverRating';
import Settings from 'pages/atp/settings';
import Users from 'pages/atp/users';
import { IMenuCollapse, IMenuRoute } from './types';

export const routes__atp = {
  map: "/",
  order: "/orders/",
  order_by_driver: "/orders/driver/",
  order_by_callsign: "/orders/callsign/",
  fined_orders: "/orders/fined/",
  offset: "/offset/",
  users: "/users/",
  info: "/info/",
  settings: "/settings/",
  rating_driver: "/rating/",
  drivers: "/drivers/",
  cars: "/cars/",
  online_total_bill: "/online-total-bill/",
}

export const menu_routes__atp:(IMenuRoute|IMenuCollapse)[] = [
  {
    path: routes__atp.map,
    name: "Карта",
    component: MapPage,
    icon: MapOutlined,
  },
  {
    name: "Замовлення",
    icon: EmojiPeopleOutlined,
    items: [
      {
        path: routes__atp.order,
        name: "Виконані",
        component: Orders,
      },
      {
        path: routes__atp.order_by_driver,
        name: "По водіям",
        component: OrderByDriver,
      },
      {
        path: routes__atp.order_by_callsign,
        name: "По позивним",
        component: OrderByCallSign,
      },
      {
        path: routes__atp.fined_orders,
        name: "Проігноровані",
        component: FinedOrders,
      },
    ]
  } as IMenuCollapse,
  {
    path: routes__atp.offset,
    name: "Розрахунок зі службою",
    component: Offset,
    icon: MonetizationOnOutlined,
  },
  {
    path: routes__atp.rating_driver,
    name: "Рейтинг",
    component: ATPDriverRating,
    icon: StarBorderOutlined,
  },
  {
    path: routes__atp.drivers,
    name: "Водії",
    component: Drivers,
    icon: AccountBoxOutlined,
  },
  {
    path: routes__atp.cars,
    name: "Автопарк",
    component: Cars,
    icon: DirectionsCarOutlined,
  },
  {
    path: routes__atp.users,
    name: "Адміністратори",
    component: Users,
    icon: SupervisorAccountOutlined,
  },
  {
    path: routes__atp.info,
    name: "Інформація для водія",
    component: Info,
    icon: InfoOutlined,
  },
  {
    path: routes__atp.settings,
    name: "Налаштування",
    component: Settings,
    icon: SettingsOutlined,
  }
];