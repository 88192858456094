import CopyText from "components/copyText";
import React from "react";
import NumberFormat from "react-number-format";

interface IProps{
  OrderID?: number|null;
}

export default function CopyOrderID(props:IProps){
  const {
    OrderID
  } = props

  if(!OrderID){
    return null;
  }
  
  return (
    <CopyText text={OrderID} forceAddCopyStyle>
      <NumberFormat value={OrderID} displayType={'text'} thousandSeparator={' '} />
    </CopyText>
  )
}