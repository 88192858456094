import { SaveAltOutlined } from '@mui/icons-material';
import { Button, Grid, Hidden, MenuItem, Select, TablePagination, TextField } from '@mui/material';
import requests from 'api/requests';
import { PaginationGridBox, TablePaperBox } from 'components/styled';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import TableOffset from './tableOffset';
import UploadDialog from './upload';



export default function Offset() {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [periods, setPeriods] = useState(null as any)
  const [period, setPeriod] = useState(null as any)
  const [searchCallSign, setSearchCallSign] = useState('');
  const [total, setTotal] = useState(null as any)
  const [page, setPage] = useState({
    first_loaded: false,
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })

  const loadPeriods = useCallback(() => {
    setPeriods(null);
    setPeriod(null);
    requests.get('/finance/offset/period/', {
      limit: 5
    }).then((r) => {
      setPeriods(r.body.results);
      if(r.body.results.length){
        setPeriod(r.body.results[0]);
      }
    })
  }, [])

  const load = useCallback(() => {
    if(!period) return;

    window.scrollTo(0, 0);
    
    requests.get('/finance/offset/', {
      period: period.id,
      call_sign: searchCallSign,
      limit: page.limit,
      offset: page.page * page.limit,
    }).then((r) => {
      setPage(page => ({
        ...page,
        first_loaded: true,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [page.page, page.limit, period, searchCallSign])

  const loadTotal = useCallback(() => {
    if(!period) return;

    setTotal(null);
    requests.get('/finance/offset/total/', {
      period: period.id
    }).then((r) => {
      setTotal(r.body);
    })
  }, [period])

  useEffect(() => loadPeriods(), [loadPeriods]);
  useEffect(() => loadTotal(), [loadTotal]);
  useEffect(() => load(), [load]);

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  if(periods === null || period === null || total === null){
    return null;
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <Select
                fullWidth
                value={period}
                displayEmpty
                onChange={(e: any, q: any) => {
                  setPeriod(e.target.value);
                  setPage(page => ({
                    ...page,
                    page: 0
                  }));
                }}
              >
                {periods.map((i:any, index:number) => (
                  <MenuItem key={index} value={i}>
                    <Moment
                      date={i.date_from}
                      format="DD.MM"
                    />
                    -
                    <Moment
                      date={i.date_to}
                      format="DD.MM/YYYY"
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <TextField
                fullWidth
                placeholder="Позивний"
                value={ searchCallSign }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchCallSign(e.target.value);
                  setPage(page => ({
                    ...page,
                    page: 0
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<SaveAltOutlined />}
                onClick={()=>setUploadDialogOpen(true)}
              >
                Завантажити
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <PaginationGridBox item sm={4}>
            { renderPagination() }
          </PaginationGridBox>
        </Hidden>
      </Grid>
      <br/>

      <TablePaperBox>
        <TableOffset
          items={page.items}
          total={total}
          dotted_lines={!!page.items.length && !!searchCallSign}
          not_found_line={(!page.items.length && !!searchCallSign) ? `"${searchCallSign}" не знайдено` : null}
        />
      </TablePaperBox>

      {renderPagination()}

      { uploadDialogOpen && (
        <UploadDialog
          initDateFrom={period.date_to}
          onClose={() => setUploadDialogOpen(false)}
          onDone={()=>{
            enqueueSnackbar("Завантажено", {variant: 'success'});
            setUploadDialogOpen(false);
            loadPeriods();
          }}
        />
      )}
    </div>
  );
}