import { FilteredTableFilter } from "components/FilteredTable/types"
import { DateRangePickerFilter, RatingFilter, TextFilter, SelectFilter } from "components/FilteredTable/filters";
import { dataRangeItem } from "components/daterange/defaults";
import { IDateRange } from "components/daterange";
import { format } from 'date-fns';
import { IExtendData } from "./types";
import CheckboxListFilter from "components/FilteredTable/filters/CheckboxListFilter";

const filters:FilteredTableFilter[] = [
  {
    title: 'Дата відгука',
    key: 'created',
    defaultValue: dataRangeItem.all(new Date()),
    component: DateRangePickerFilter,
    filter: (value:IDateRange)=>{
      if(!value.startDate && !value.endDate){
        return null;
      }
      let filter:any = {};
      if(value.startDate){
        filter.created__gte = format(value.startDate, "yyyy-MM-dd");
      }
      if(value.endDate){
        filter.created__lte = format(value.endDate, "yyyy-MM-dd");
      }
      return filter
    },
  },
  {
    title: 'Оцінка',
    key: 'rating',
    component: RatingFilter,
    filter: (value:number[])=>(
      value && value.length ? {
        rating__in: value
      } : null
    ),
  },
  {
    title: 'Стан',
    key: 'state',
    component: SelectFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: "Всі стани",
          value: "",
        },
        {
          name: 'Нові',
          value: 'new',
        },
        {
          name: 'В роботі',
          value: 'in_progress',
        },
        {
          name: 'Завершені',
          value: 'final',
        }
      ]
    }),
    filter: (value:string)=>{
      if(value === 'new'){
        return {
          laststatus__statusid__isnull: true
        };
      }
      if(value === 'in_progress'){
        return {
          laststatus__statusid__isfinal: false
        };
      }
      if(value === 'final'){
        return {
          laststatus__statusid__isfinal: true
        };
      }
      return null;
    },
  },
  {
    title: 'Статус',
    key: 'status',
    component: CheckboxListFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: ((extendData || {}).statuses || []).map((i)=>({
        name: i.name,
        value: i.id
      }))
    }),
    filter: (value:number[])=>{
      if(!value) return null;
      return {
        laststatus__statusid__in: value.join(",")
      };
    },
  },
  {
    title: 'Позивний',
    key: 'callsign',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        callsign: value
      } : null
    ),
  },
  {
    title: 'Водій',
    key: 'driver',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        driver: value
      } : null
    ),
  },
  {
    title: 'АТП',
    key: 'atp',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        atp: value
      } : null
    ),
  },
  {
    title: 'Телефон пасажира',
    key: 'client_phone',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        client_phone: value
      } : null
    ),
  },
  {
    title: 'Вибір користувача',
    key: 'шеуьы',
    component: CheckboxListFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: ((extendData || {}).choices || []).map((i)=>({
        name: i.name,
        value: i.id
      }))
    }),
    filter: (value:number[])=>{
      if(!value) return null;
      return {
        items__in: value.join(",")
      };
    },
  },
]

export default filters;