import React, { useCallback } from 'react';
import { DividerBox, InfoRoot, InfoTable } from './styles';
import { CarLocation, Status, TariffClass } from './types';

interface IInfoProps {
  tariffClasses:TariffClass[];
  statuses:Status[];
  callSigns:CarLocation[];
  className?:string;
}

export default function Info(props:IInfoProps) {

  const getClasses = useCallback(() => {
    let classIDs = props.callSigns.map((i:CarLocation) => i.class)
    classIDs = classIDs.filter((value, index, self) => self.indexOf(value) === index)

    return props.tariffClasses.filter((i:TariffClass) => classIDs.indexOf(i.id) > -1);
  }, [props.callSigns, props.tariffClasses])

  const getCountClasses = (class_id:number) => {
    return props.callSigns.filter((item:CarLocation) => {
      return item.class === class_id;
    }).length;
  }

  const getCountStatus = (status_id:number) => {
    return props.callSigns.filter((item:CarLocation) => {
      return (item.status.toString() === "0" && status_id.toString() === "0") ||
      (item.status.toString() !== "0" && status_id.toString() !== "0");
    }).length;
  }

  return (
    <InfoRoot className={props.className || ""}>
      {!!getClasses().length && (
        <div>
          <InfoTable>
            <tbody>
              {getClasses().map((i) => (
                <tr key={i.id}>
                  <td>{i.name}:</td>
                  <td>{getCountClasses(i.id)}</td>
                </tr>
              ))}
            </tbody>
          </InfoTable>
          <DividerBox />
        </div>
      )}
      <div>
        <InfoTable>
          <tbody>
            {props.statuses.map((i) => (
              <tr key={i.id}>
                <td>{i.name}:</td>
                <td>{getCountStatus(i.id)}</td>
              </tr>
            ))}
            <tr>
              <td>Всі:</td>
              <td>{props.statuses.map((i) => getCountStatus(i.id)).reduce((a:number,b:number) => a + b)}</td>
            </tr>
          </tbody>
        </InfoTable>
      </div>
    </InfoRoot>
  );
}