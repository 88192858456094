import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import requests from 'api/requests';
import { IMenuRoute } from 'routes/types';
import { LogoBox, MenuLoadingBox } from '../styles';
import { IMainLayoutProps } from '../types';
import { IATP } from 'pages/atp/types';
import MenuItems from './menuItems';



export default function ATPLayoutMenu(props:IMainLayoutProps) {
  const atp_id = +props.startRouteProps.match.params.id;

  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [atp, setAtp] = useState(null as any);
  const [atps, setAtps] = useState([]);

  const makeUrl = (item:IMenuRoute) => {
    return (props.startRoute.start_path + item.path).replace(":id", `${atp_id}`)
  }

  useEffect(() => {
    setLoading(true);
    requests.getAll('/atp/atp/', {limit: 0}).then((r) => {
      setAtps(r.body.results);
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    if(atps.length){
      let atp = null;
      if(atp_id){
        atps.forEach((i:any) => {
          if(i.id === atp_id){
            atp = i;
          }
        });
      }
      if(atp){
        setAtp(atp);
      }else{
        const new_url = props.startRoute.start_path.replace("/:id", "/");
        history.push(new_url);
      }
    }
  }, [atps, atp_id, history, props.startRoute.start_path]);

  const goToAtp = (new_atp_id:number) => {
    const new_url = props.startRouteProps.match.url.replace(`/${atp_id}/`, `/${new_atp_id}/`);
    history.push(new_url);
  }

  const atpName = (obj:IATP):string => {
    let vars = [
      obj.name
    ];
    vars = vars.filter(v => !!v);
    let name = vars.join(" ");
    return name ? name : '-/-'
  }

  const renderAutocompleteOption = (props:any, option:IATP) => {
    return (
      <div {...props} key={option.id}>
        <div>{atpName(option)}</div>
      </div>
    )
  }

  const filterAutocompleteOptions = createFilterOptions({
    stringify: (option:IATP) => [atpName(option)].join(" "),
  });

  let list = null;

  if(!loading && atp){
    list = (
      <List>
        { atps && atps[0] && (
          <ListItem>
            <div style={{width: '100%'}}>
              { (atps && atps.length > 1) ? (
                <Autocomplete
                  options={atps}
                  value={atp}
                  fullWidth
                  filterOptions={filterAutocompleteOptions}
                  renderOption={renderAutocompleteOption}
                  getOptionLabel={atpName}
                  isOptionEqualToValue={(option, value) => {
                    return option && value && option.id === value.id;
                  }}
                  renderInput={(params:any) => <TextField {...params} label="АТП" />}
                  disableClearable={true}
                  onChange={(e, atp) => {
                    goToAtp(atp.id);
                  }}
                />
              ) : (
                <Typography variant="body1">{atpName(atps[0])}</Typography>
              )}
            </div>
          </ListItem>
        )}
        <MenuItems
          items={props.startRoute.children}
          makePath={(item:IMenuRoute) => (props.startRoute.start_path + item.path)}
          makeUrl={makeUrl}
          onClick={() => { props.closeMobileMenu() }}
          isHideParam={atp}
        />
      </List>
    );
  }

  return (
    <div>
      <LogoBox className={"MuiPaper-elevation4"}>
        <img src={ process.env.PUBLIC_URL + '/img/logoHead.svg' } alt="logo" />
      </LogoBox>
      
      { loading ? (
        <MenuLoadingBox>
          <CircularProgress />
        </MenuLoadingBox>
      ) : list }
    </div>
  );
}