import { Alert, Box, Button, Checkbox, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import requests from "api/requests";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DriverServicesSerializer } from "./types";

interface IProps {
  driverID: number;
}

interface IService {
  id: number;
  name: number;
}

export default function Services(props: IProps) {
  const {
    driverID,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [services, setServices] = useState<IService[] | null>(null);
  const [driverServices, setDriverServices] = useState<DriverServicesSerializer | null>(null);
  const [initDriverServices, setInitDriverServices] = useState<DriverServicesSerializer | null>(null);
  const [disable, setDisable] = useState(false);

  const mergeServices = useCallback((s: DriverServicesSerializer): DriverServicesSerializer => {
    if (s.request_services !== null && s.request_services !== undefined) {
      s.services = s.request_services
    }
    return JSON.parse(JSON.stringify(s))
  }, [])

  useEffect(() => {
    requests.get('/base/service/', {
      driveruses: true,
      autosearchuse: true
    }).then((r) => setServices(r.body))
  }, [])

  useEffect(() => {
    requests.get(`/base/driver-card/${driverID}/services/`).then((r) => {
      setDriverServices(mergeServices(r.body))
      setInitDriverServices(mergeServices(r.body))
    })
  }, [driverID, mergeServices])

  const requestExists = useMemo(() => {
    if (!!(driverServices?.request_services || []).length) {
      return true
    }
    return false;
  }, [
    driverServices,
  ])

  const equals = useCallback((a: any[], b: any[]) => {
    return a.length === b.length && !Boolean(a.filter((v) => !b.includes(v)).length)
  }, [])

  const differentExists = useMemo(() => {
    if (!equals(driverServices?.services || [], initDriverServices?.services || [])) {
      return true;
    }
    return false;
  }, [
    driverServices,
    initDriverServices,
    equals
  ])

  const save = () => {
    setDisable(true);
    requests.put(`/base/driver-card/${driverID}/services/`, {
      "request_services": driverServices?.services || []
    }).then((r) => {
      setDriverServices(mergeServices(r.body));
      setInitDriverServices(mergeServices(r.body));
      if (r.body.request_services === null) {
        enqueueSnackbar("Збережено", { variant: 'success' });
      } else {
        enqueueSnackbar("Заявку на зміни надіслано", { variant: 'success' });
      }
    }).finally(() => {
      setDisable(false);
    })
  }

  if (services === null || driverServices === null) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <List
        disablePadding
        sx={{
          columns: {
            xs: 1,
            sm: 2,
            md: 3
          },
          ">div": {
            display: 'inline-flex',
            width: '100%',
          },
        }}
      >
        {services.map((service) => {
          return (
            <ListItemButton
              key={service.id}
              dense
              onClick={() => {
                var index = driverServices.services.indexOf(service.id);
                if (index === -1) {
                  driverServices.services.push(service.id)
                } else {
                  driverServices.services.splice(index, 1);
                }
                setDriverServices({ ...driverServices })
              }}
              disabled={disable || requestExists}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={driverServices.services.includes(service.id)}
                />
              </ListItemIcon>
              <ListItemText primary={service.name} />
            </ListItemButton>
          );
        })}
      </List>
      <Box sx={{
        mt: 2
      }}>
        {!!requestExists ? (
          <Alert severity="info">
            <b>Ваша заявка в черзі на опрацювання</b><br />
            Ви не можете вносити зміни доки не буде опряцьована попередня заявка
          </Alert>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={save}
            disabled={disable || !differentExists}
          >Зберегти</Button>
        )}
      </Box>
    </Box>
  )
}