import { Button, Dialog, DialogActions, DialogContent, FormControl, TextField, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import requests from 'api/requests';
import { useProgress } from 'components/progress';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { useState } from 'react';

interface IImportDialogProps {
  initDateFrom?: Date;
  initDateTo?: Date;
  onClose: () => void;
  onDone: () => void;
}

const FileContainer = styled("label")(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center'
}));

const FileName = styled("span")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export default function UploadDialog(props:IImportDialogProps) {
  const { isProgress } = useProgress();
  const [ inputs, setInputs ] = useState({
    file: null,
    date_from: props.initDateFrom ? new Date(props.initDateFrom) : new Date(),
    date_to: props.initDateTo ? new Date(props.initDateTo) : new Date(),
  } as any);



  const handleChangeValue = (name:string) => (value:any) => {
    inputs[name] = value;
    setInputs({...inputs});
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={uk}>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="xs"
        onClose={()=>{
          if(isProgress) return;
          props.onClose();
        }}
      >
        <form
          onSubmit={(e:any) => {
            e.preventDefault();
            if(isProgress) return;

            requests.form('/finance/offset/upload/', {
              'file': inputs.file,
              date_from: inputs.date_from ? format(inputs.date_from, 'yyyy-MM-dd') : null,
              date_to: inputs.date_to ? format(inputs.date_to, 'yyyy-MM-dd') : null,
            }).then((r) => {
              props.onDone()
            })
          }}
        >
          <DialogContent>

            <FormControl fullWidth>

              <DatePicker
                renderInput={(params) => <TextField variant="standard" margin="normal" {...params} />}
                inputFormat="dd.MM.yyyy"
                label="Початок періоду"
                value={inputs.date_from}
                onChange={handleChangeValue('date_from')}
              />

              <DatePicker
                renderInput={(params) => <TextField variant="standard" margin="normal" {...params} />}
                inputFormat="dd.MM.yyyy"
                label="Кінець періоду"
                value={inputs.date_to}
                onChange={handleChangeValue('date_to')}
              />
              <br/>

              <input
                accept=".xlsx,.xls"
                style={{display: 'none'}}
                id="contained-button-file"
                type="file"
                onChange={(e:any) => {
                  handleChangeValue('file')(e.target.files[0]);
                }}
              />
              <FileContainer htmlFor={isProgress ? "" : "contained-button-file"}>
                <Button variant="contained" color="primary" component="span" style={{minWidth: 130}} disabled={isProgress}>
                  Обрати файл
                </Button>
                
                { inputs.file ?
                  (<FileName>{inputs.file.name}</FileName>)
                  : (<FileName style={{opacity: 0.6}}>Файл не обрано</FileName>)
                }
                
              </FileContainer>
              
              <p>
                Excel-файл повинен бути у форматі xlsx
              </p>

            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onClose}
              disabled={isProgress}
            >
              Відмінити
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isProgress || !inputs.date_from || !inputs.date_to || !inputs.file}
            >
              Завантажити
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </LocalizationProvider>
  );
}