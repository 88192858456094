import React, { useState, useCallback } from 'react';
import { Backdrop, LinearProgress, styled, Typography } from '@mui/material';
import ProgressPercentContext from './ProgressPercentContext';
import { ProviderPercentContext } from './types';
import NumberFormat from 'react-number-format';

const ProgressBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
  "& .progress": {
    width: '90%'
  }
}));

interface ProgressPercentProviderProps{
  children: React.ReactNode;
}

export default function ProgressPercentProvider(props: ProgressPercentProviderProps) {

  const [progress, setProgress] = useState<null|number>(null);
  
  const show = useCallback(() => {
    setProgress(0);
  }, [])
  const hide = useCallback(() => {
    setProgress(null);
  }, [])

  const context:ProviderPercentContext = {
    showProgressPercent: show,
    hideProgressPercent: hide,
    setProgressPercent: setProgress,
    isProgressPercent: progress!==null
  }

  return (
    <ProgressPercentContext.Provider value={context}>
        {props.children}
        
        <ProgressBackdrop open={progress!==null}>
          <div className="progress">
            <Typography variant="h5"><NumberFormat value={progress || 0} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />%</Typography>
            <LinearProgress variant="determinate" value={progress || 0} />
          </div>
        </ProgressBackdrop>
    </ProgressPercentContext.Provider>
  );
}