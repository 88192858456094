import { FileCopyOutlined, HistoryOutlined } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { useCard } from "cards/provider";
import CopyText from "components/copyText";
import AuthModel from "models/AuthModel";
import NumberFormat from "react-number-format";
import CopyOrderID from "./copyClick/CopyOrderID";

interface IProps{
  OrderID?: number|null;
}

const StyledIconButton = styled(IconButton)((theme) => ({
  opacity: 0.6,
  fontSize: '1.2em',
  padding: '4px',
  verticalAlign: 'sub'
}))

export default function OrderIDLink(props:IProps){
  const {
    OrderID
  } = props

  const { openCard } = useCard();

  if(!OrderID){
    return null;
  }

  if(!AuthModel.permissions.includes("admin")){
    return <CopyOrderID OrderID={OrderID} />
  }
  
  return (
    <span>
      <NumberFormat value={OrderID} displayType={'text'} thousandSeparator={' '} />

      &nbsp;
      <StyledIconButton
        color="default"
        onClick={(e)=>{
          e.stopPropagation();
          openCard("orderHistory", OrderID);
        }}
      ><HistoryOutlined fontSize="inherit"/></StyledIconButton>
      <span
        onClick={(e)=>{
          e.stopPropagation();
        }}
      >
        <CopyText text={OrderID}>
          <StyledIconButton color="default"><FileCopyOutlined fontSize="inherit"/></StyledIconButton>
        </CopyText>
      </span>
    </span>
  )
}