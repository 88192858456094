import React, { useEffect, useState } from 'react';
import BaseStatusDict from './base';
import { InstanceModalProps } from './base/instanceModal';
import { IStatus } from './base/types';
import { Button, Dialog, DialogContent, DialogActions, IconButton, Popover,  Checkbox, FormControl, TextField, FormControlLabel, Divider, Typography, Box, LinearProgress } from '@mui/material';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import { DeleteOutlined } from '@mui/icons-material';
import { REQUEST_TYPE, REQUEST_TYPE_LIST } from 'pages/admin/driverRequest/config';

interface IAutoEmail{
  id?: number;
  status: number;
  type: keyof typeof REQUEST_TYPE;
  email: string;
}

type IAutoEmailDict = { [type in (keyof typeof REQUEST_TYPE)] : IAutoEmail}

function DriverRequestStatusModal(props:InstanceModalProps){
  const { enqueueSnackbar } = useSnackbar();
  const [ deleteConfirmEl, setDeleteConfirmEl] = React.useState<HTMLButtonElement | null>(null);
  const [instance, setInstance] = useState({...props.instance});
  const [ autoEmails, setAutoEmails ] = useState<IAutoEmailDict|null>(null);
  const [ autoEmailsFirstLoaded, setAutoEmailsFirstLoaded ] = useState<IAutoEmailDict|null>(null);


  const {
    onSave,
    onClose,
    onDelete
  } = props;

  useEffect(()=>{
    if(!props.instance.id){
      setAutoEmails(null);
      setAutoEmailsFirstLoaded(null);
    }else{
      requests.get(`${props.apiUrl}auto-email/`, {
        status: props.instance.id
      }).then((r)=>{
        const default_value:IAutoEmail = {
          email: "",
          status: props.instance.id || 0,
          type: "COMPANY_CAR"
        }
        let auto_emails:IAutoEmailDict = {
          COMPANY_CAR: {...default_value, type: "COMPANY_CAR"},
          LEASING: {...default_value, type: "LEASING"},
          OWN_CAR: {...default_value, type: "OWN_CAR"},
          RENT: {...default_value, type: "RENT"},
        };
        (r.body as IAutoEmail[]).forEach((i)=>{
          auto_emails[i.type] = i
        })
        setAutoEmails(auto_emails);
        setAutoEmailsFirstLoaded({...auto_emails});
      })
    }
  }, [props.apiUrl, props.instance.id])

  const remove = () => {
    requests.delete(`${props.apiUrl}${instance.id}/`).then((r) => {
      enqueueSnackbar("Видалено", {variant: 'success'});
      if(onDelete){
        onDelete(instance);
      }
    })
  }

  const update = () => {
    let promises:Promise<any>[] = [
      requests.patch(`${props.apiUrl}${instance.id}/`, instance)
    ];

    if(autoEmails !== null && autoEmailsFirstLoaded !== null){
      REQUEST_TYPE_LIST.forEach((i)=>{
        const new_obj = autoEmails[i.val];
        const old_obj = autoEmailsFirstLoaded[i.val];
        const new_value = (new_obj?.email || "").trim()
        if(!new_value){
          if(!!old_obj?.id){
            promises.push(
              requests.delete(`${props.apiUrl}auto-email/${old_obj?.id}/`)
            );
          }
        }else{
          if(!!old_obj?.id){
            requests.put(`${props.apiUrl}auto-email/${old_obj?.id}/`, new_obj)
          }else{
            requests.post(`${props.apiUrl}auto-email/`, new_obj)
          }
        }
      })
    }
    Promise.all(promises).then((r)=>{
      enqueueSnackbar("Збережено", {variant: 'success'});
      if(onSave){
        onSave(r[0].body);
      }
    })
  }

  const handleChange = (name:(keyof IStatus)) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:(keyof IStatus)) => (value:any) => {
    let _inputs = {...instance} as any;
    _inputs[name] = value;
    setInstance({..._inputs});
  }

  const handleChangeCheckbox = (name:(keyof IStatus)) => (event:any) => {
    handleChangeValue(name)(event.target.checked);
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="md"
      onClose={onClose}
    >
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          update();
        }}
      >
        <DialogContent>

          <FormControl fullWidth>
            <TextField
              autoFocus
              label="Назва"
              margin="normal"
              value={instance.name || ''}
              onChange={handleChange('name')}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={instance.is_final}
                onChange={handleChangeCheckbox('is_final')}
              />
            }
            label="Кінцевий статус"
          />

          <Divider sx={{ mx: -3, my: 3 }} />
          <Typography variant="h6">Автоматичні листи</Typography>

          {autoEmails === null ? (
            <Box sx={{ my: 1 }}>
              <LinearProgress />
            </Box>
          ):(
            <React.Fragment>
              <Typography variant="caption">{"{{ full_name }}"} - ФІО водія</Typography>
              {REQUEST_TYPE_LIST.map((i) => (
                <FormControl fullWidth key={i.val}>
                  <TextField
                    label={i.title}
                    margin="normal"
                    multiline
                    maxRows={10}
                    value={autoEmails[i.val]?.email}
                    onChange={(event)=>{
                      let value = event.target.value;
                      setAutoEmails((v)=>{
                        if(v===null || !v[i.val]) return v;
                        v[i.val].email = value;
                        return {...v};
                      })
                    }}
                  />
                </FormControl>
              ))}
            </React.Fragment>
          )}
          
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setDeleteConfirmEl(event.currentTarget);
            }}
            color="secondary"
            style={{marginRight: 'auto'}}
          >
            <DeleteOutlined />
          </IconButton>
          <Button
            onClick={onClose}
          >
            Відмінити
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            Зберегти
          </Button>
        </DialogActions>
      </form>

      <Popover
        open={!!deleteConfirmEl}
        anchorEl={deleteConfirmEl}
        onClose={()=>setDeleteConfirmEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={()=>{
            remove();
            setDeleteConfirmEl(null);
          }}
        >Підтвердити</Button>
      </Popover>
    </Dialog>
  );
}

export default function DriverRequestStatus() {

  return (
    <BaseStatusDict
      apiUrl="/settings/statuses/driver-request/"
      instanceModelComponent={DriverRequestStatusModal}
    />
  );
}