import React, { useMemo } from 'react';
import { CircularProgress, Divider, Grid, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { IDriverRequestStatus } from './types';
import Moment from 'react-moment';
import { IStatus } from 'pages/settings/statuses/base/types';

interface IProps {
  statuses: IDriverRequestStatus[] | null;
  statusTypes: IStatus[];
}

const DividerBox = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
}));


export default function StatusList(props:IProps) {
  const {
    statuses,
    statusTypes
  } = props;

  const statusDict = useMemo(()=>{
    let _statusDict = {} as any;
    statusTypes.map((status_type)=>{
      if(status_type.id !== undefined){
        _statusDict[status_type.id] = status_type;
      }
      return status_type;
    })
    return _statusDict;
  }, [statusTypes])

  const primaryText = (status:IDriverRequestStatus) => {
    let status_name = statusDict[status.status || 0]?.name;
    if(status_name === undefined){
      status_name = 'Не визначений';
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle2">{status_name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <Moment
              date={status.created}
              format="DD.MM.YY HH:mm"
            />
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const secondaryText = (status:IDriverRequestStatus) => {
    if(!status.message) return null;

    return (
      <span style={{whiteSpace: 'pre-wrap'}}>{status.message}</span>
    )
  }


  if(statuses === null){
    return (
      <Typography align="center" component="div">
        <CircularProgress />
      </Typography>
    )
  }

  if(!statuses.length){
    return null;
  }

  return (
    <React.Fragment>
      <DividerBox/>
      <List>
        {statuses.map((status) => (
          <ListItem
            key={status.id}
            disableGutters={true}
          >
            <ListItemText
              primary={primaryText(status)}
              secondary={secondaryText(status)}
            />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}