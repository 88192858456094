import CopyText from "components/copyText";
import React, { useMemo } from "react";
import NumberFormat from "react-number-format";

interface IProps{
  phone?: string|null;
}

export default function CopyPhone(props:IProps){
  const {
    phone
  } = props

  const phoneToCopy = useMemo(()=>{
    return phone?.replace(/^\+38/, "");
  }, [phone])

  if(!phone || !phoneToCopy){
    return null;
  }
  
  return (
    <CopyText text={phoneToCopy} forceAddCopyStyle>
      <NumberFormat value={phone} displayType={'text'} format="+## (###) ### ## ##" />
    </CopyText>
  )
}