import { FIELDS } from "./fields"

export enum REQUEST_TYPE {
  OWN_CAR = "На власному авто",
  COMPANY_CAR = "На авто компанії",
  RENT = "На авто компанії в оренду",
  LEASING = "Автомобіль в лізинг"
}

interface IRequestTypeListItem{
  val: keyof typeof REQUEST_TYPE,
  title: string;
}

export const REQUEST_TYPE_LIST:IRequestTypeListItem[] = [
  {
    val: "OWN_CAR",
    title: REQUEST_TYPE.OWN_CAR
  },
  {
    val: "COMPANY_CAR",
    title: REQUEST_TYPE.COMPANY_CAR
  },
  {
    val: "RENT",
    title: REQUEST_TYPE.RENT
  },
  {
    val: "LEASING",
    title: REQUEST_TYPE.LEASING
  },
]

export type FieldList = (keyof typeof FIELDS)[]
type IForm = { [type in (keyof typeof REQUEST_TYPE)] : {
  fields: FieldList
}}

export const FORM:IForm = {
  "OWN_CAR": {
    fields: [
      'full_name',
      'phone',
      'email',
      'inn',
      'driving_experience',
      'car_model',
      'car_state_number',
      'car_year'
    ]
  },
  "COMPANY_CAR": {
    fields: [
      'full_name',
      'phone',
      'email',
      'inn',
      'driving_experience'
    ]
  },
  "RENT": {
    fields: [
      'full_name',
      'phone',
      'email',
      'inn',
      'driving_experience'
    ]
  },
  "LEASING": {
    fields: [
      'full_name',
      'phone',
      'email',
      'inn',
      'driving_experience'
    ]
  }
}