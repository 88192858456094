import React, { useState, useEffect, useCallback } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Button, Grid, Hidden, TextField, InputAdornment, Fab, FormControl } from '@mui/material';
import requests from 'api/requests';
import { AddOutlined, SearchOutlined } from '@mui/icons-material';
import InstanceModal from './instanceModal';
import { ICompanyRequest } from './types';
import InstanceRow from './instanceRow';
import { FIELDS } from './fields';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { dataRangeItem, defaultRangesWithAll } from 'components/daterange/defaults';
import { format } from 'date-fns';
import { PaginationGridBox, TablePaperBox } from 'components/styled';



interface IPage{
  items: ICompanyRequest[];
  items_count: number;
  page: number;
  limit: number;
}

const DEFAULT_INSTANCE:ICompanyRequest = {
  name: "",
}

const DEFAULT_PAGE:IPage = {
  items: [],
  items_count: 0,
  page: 0,
  limit: 30,
}

export default function CompanyRequest() {
  const [instance, setInstance] = useState(null as (null|ICompanyRequest));
  const [searchString, setSearchString] = useState('');
  const [dateRange, setDateRange] = useState<IDateRange>(dataRangeItem.all(new Date()));
  const [page, setPage] = useState(DEFAULT_PAGE)

  const filters = useCallback(() => {
    let new_filter:any = {};
    if(dateRange.startDate){
      new_filter.created__gte = format(dateRange.startDate, "yyyy-MM-dd 00:00:00");
    }
    if(dateRange.endDate){
      new_filter.created__lte = format(dateRange.endDate, "yyyy-MM-dd 23:59:59");
    }
    if(searchString){
      new_filter.search = searchString;
    }
    return new_filter;
  }, [dateRange, searchString])

  const load = useCallback((scroll_up=true) => {
    if(scroll_up){
      window.scrollTo(0, 0);
    }
    requests.get('/corp-admin/company/request/', {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit,
    }).then((r) => {
      setPage(page => ({
        ...page,
        items: r.body.results,
        items_count: r.body.count
      }));
    })
  }, [page.page, page.limit, filters])

  useEffect(() => load(), [load]);

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.items_count}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  const renderItems = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{FIELDS.created.title}</TableCell>
              <TableCell>{FIELDS.name.title}</TableCell>
              <TableCell>{FIELDS.phone.title}</TableCell>
              <TableCell>{FIELDS.message.title}</TableCell>
              <TableCell>{FIELDS.source.title}</TableCell>
              <TableCell>{FIELDS.utm_source.title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { (page.items).map((item) => (
              <InstanceRow
                key={item.id}
                instance={item}
                onClick={()=>setInstance(item)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Grid container spacing={3} alignItems="flex-start" justifyContent="flex-start" direction="row" wrap="nowrap">
            <Grid item>
              <Hidden mdDown>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddOutlined />}
                  onClick={()=>{
                    setInstance(DEFAULT_INSTANCE)
                  }}
                >
                  Додати
                </Button>
              </Hidden>

              <Hidden mdUp>
                <Fab
                  size="small"
                  color="secondary"
                  onClick={()=>{
                    setInstance(DEFAULT_INSTANCE)
                  }}
                >
                  <AddOutlined />
                </Fab>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  placeholder="Пошук"
                  value={ searchString }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchString(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined color={'action'} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={3} xl={2}>
          <DateRangePicker
            dateRange={dateRange}
            onChange={(range) => setDateRange(range)}
            definedRanges={defaultRangesWithAll}
          />
        </Grid>
        
        <Hidden lgDown>
          <Grid item xs={12} sm={2} lg={2} xl={5}></Grid>
          <PaginationGridBox item sm={4} lg={3} xl={2}>
            { renderPagination() }
          </PaginationGridBox>
        </Hidden>
      </Grid>
      <TablePaperBox>
        {renderItems()}
      </TablePaperBox>
      {renderPagination()}

      { instance !== null ? (
        <InstanceModal
          instance={instance}
          onClose={()=>setInstance(null)}
          onDelete={()=>{
            setInstance(null);
            load(false);
          }}
          onSave={()=>{
            setInstance(null);
            load(false);
          }}
        />
      ): null }
    </div>
  );
}