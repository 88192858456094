import React from 'react';
import { FilteredTableColumn } from "components/FilteredTable/types";
import { IExtendData, IPaymentError } from "./types";
import TextFieldBlurPatch from 'components/textFieldBlurPatch';
import { Typography } from '@mui/material';

interface PaymentErrorTextFieldBlurPatchProps{
  row: IPaymentError;
  field: keyof IPaymentError;
  label: string;
  editMode: boolean;
}
function PaymentErrorTextFieldBlurPatch(props:PaymentErrorTextFieldBlurPatchProps){
  const {
    row,
    field,
    label,
    editMode
  } = props

  if(editMode){
    return (
      <TextFieldBlurPatch
        field={field}
        url={`/settings/dict/payment-error/${row.id}/`}
        label={label}
        value={row[field]}
        variant="outlined"
        size="small"
        margin="dense"
        style={{minWidth: 400}}
      />
    )
  }

  return (
    <React.Fragment>
      <Typography variant="caption" color="textSecondary">{label}:</Typography> {row[field]}
    </React.Fragment>
  )
}

const columns: FilteredTableColumn[] = [
  {
    title: 'Код',
    items: [
      {
        title: 'Код',
        render: (row:IPaymentError) => row.code,
      },
    ]
  },
  {
    title: 'Текст помилки',
    items: [
      {
        title: 'Текст помилки',
        render: (row:IPaymentError) => row.message,
      },
      {
        title: 'Роз\'яснення',
        render: (row:IPaymentError) => (<Typography variant="caption">{row.description}</Typography>),
      },
    ]
  },
  {
    title: 'Чия помилка?',
    items: [
      {
        title: 'Чия помилка?',
        render: (row:IPaymentError, extendData?:IExtendData) => extendData?.sideDict[row.paymenterrorside || 0]?.name,
      },
    ]
  },
  {
    title: 'Повідомлення для кліента',
    items: [
      {
        title: 'UK',
        render: (row:IPaymentError, extendData?:IExtendData) => {
          return (
            <PaymentErrorTextFieldBlurPatch
              row={row}
              field="clientmessageuk"
              label="UK"
              editMode={Boolean(extendData?.editMode)}
            />
          )
        },
      },
      {
        title: 'RU',
        render: (row:IPaymentError, extendData?:IExtendData) => {
          return (
            <PaymentErrorTextFieldBlurPatch
              row={row}
              field="clientmessageru"
              label="RU"
              editMode={Boolean(extendData?.editMode)}
            />
          )
        },
      },
      {
        title: 'EN',
        render: (row:IPaymentError, extendData?:IExtendData) => {
          return (
            <PaymentErrorTextFieldBlurPatch
              row={row}
              field="clientmessageen"
              label="EN"
              editMode={Boolean(extendData?.editMode)}
            />
          )
        },
      },
    ]
  },
  {
    title: 'Повідомлення для водія',
    items: [
      {
        title: 'UK',
        render: (row:IPaymentError, extendData?:IExtendData) => {
          return (
            <PaymentErrorTextFieldBlurPatch
              row={row}
              field="drivermessageuk"
              label="UK"
              editMode={Boolean(extendData?.editMode)}
            />
          )
        },
      },
      {
        title: 'RU',
        render: (row:IPaymentError, extendData?:IExtendData) => {
          return (
            <PaymentErrorTextFieldBlurPatch
              row={row}
              field="drivermessageru"
              label="RU"
              editMode={Boolean(extendData?.editMode)}
            />
          )
        },
      },
      {
        title: 'EN',
        render: (row:IPaymentError, extendData?:IExtendData) => {
          return (
            <PaymentErrorTextFieldBlurPatch
              row={row}
              field="drivermessageen"
              label="EN"
              editMode={Boolean(extendData?.editMode)}
            />
          )
        },
      },
    ]
  },
];

export default columns;