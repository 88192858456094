import React from 'react';
import OrderGroup from './orderGroup';


export default function OrderByCallSign() {
  return (
    <OrderGroup
      apiURL="/atp/order/total-callsign/"
      renderGroupValue={(item) => {
        return item.callsign;
      }}
      groupName="Позивний"
    />
  );
}