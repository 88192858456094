import BaseModel from "./BaseModel";

class _LastLocationModel extends BaseModel {
  key = 'LastLocation';

  public setPath = (path:string|null):void => {
    if(path === null){
      localStorage.removeItem(this.key)
    }else{
      localStorage.setItem(this.key, path);
    }
  }

  public getPath = ():string|null => {
    return localStorage.getItem(this.key);
  }
}


const LastLocationModel = new _LastLocationModel();
export default LastLocationModel;