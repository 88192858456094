import { dataRangeItem } from "components/daterange/defaults";
import { addDays, addSeconds, endOfDay, getHours, getMinutes, setHours, setMinutes, setSeconds, startOfDay } from "date-fns";

export const getDateDefinedRanges = (replacement_time:Date) => {
  let date = new Date();
  if(date < replacement_time){
    date = addDays(date, -1)
  }
  let ranges = [
    {
      label: "Поточна зміна",
      startDate: startOfDay(date),
      endDate: undefined
    },
    {
      label: "Попередня зміна",
      startDate: startOfDay(addDays(date, -1)),
      endDate: endOfDay(addDays(date, -1))
    },
    dataRangeItem.prev_7_days(date),
    dataRangeItem.prev_30_days(date),
    dataRangeItem.current_week(date),
    dataRangeItem.prev_week(date),
    dataRangeItem.current_month(date),
    dataRangeItem.prev_month(date),
  ]
  ranges = ranges.map((r) => {
    if(r.startDate){
      r.startDate = setHours(r.startDate, getHours(replacement_time))
      r.startDate = setMinutes(r.startDate, getMinutes(replacement_time))
      r.startDate = setSeconds(r.startDate, 0)
    }
    if(r.endDate){
      r.endDate = addDays(r.endDate, 1)
      r.endDate = setHours(r.endDate, getHours(replacement_time))
      r.endDate = setMinutes(r.endDate, getMinutes(replacement_time))
      r.endDate = setSeconds(r.endDate, 0)
      r.endDate = addSeconds(r.endDate, -1)
    }
    return r;
  })
  return ranges;
}