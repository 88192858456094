import {
	Grid,
	IconButton,
	Select,
	MenuItem,
	SelectChangeEvent,
	styled
} from "@mui/material";
import React from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { setMonth, getMonth, setYear, getYear } from "date-fns";
import { MONTHS } from "../defaults";

interface HeaderProps{
	date: Date;
	setDate: (date: Date) => void;
	nextDisabled: boolean;
	prevDisabled: boolean;
	onClickNext: () => void;
	onClickPrevious: () => void;
}


const IconContainer = styled(Grid)(({ theme }) => ({
	padding: 5
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	padding: 10,
	"&:hover": {
		background: "none"
	}
}))

const generateYears = (relativeTo: Date, count: number) => {
	const half = Math.floor(count / 2);
	return Array(count)
		.fill(0)
		.map((y, i) => relativeTo.getFullYear() - half + i); // TODO: make part of the state
};

export default function Header(props:HeaderProps){
	const {
		date,
		setDate,
		nextDisabled,
		prevDisabled,
		onClickNext,
		onClickPrevious
	} = props

	const handleMonthChange = (event: SelectChangeEvent<any>) => {
		setDate(setMonth(date, parseInt(event.target.value)));
	};

	const handleYearChange = (event: SelectChangeEvent<any>) => {
		setDate(setYear(date, parseInt(event.target.value)));
	};

	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<IconContainer item>
				<StyledIconButton
					disabled={prevDisabled}
					onClick={onClickPrevious}>
					<ChevronLeft color={prevDisabled ? "disabled" : "action"} />
				</StyledIconButton>
			</IconContainer>
			<Grid item>
				<Select
					value={getMonth(date)}
					onChange={handleMonthChange}
					MenuProps={{ disablePortal: true }}>
					{MONTHS.map((month, idx) => (
						<MenuItem key={month} value={idx}>
							{month}
						</MenuItem>
					))}
				</Select>
			</Grid>

			<Grid item>
				<Select
					value={getYear(date)}
					onChange={handleYearChange}
					MenuProps={{ disablePortal: true }}>
					{generateYears(date, 30).map(year => (
						<MenuItem key={year} value={year}>
							{year}
						</MenuItem>
					))}
				</Select>

				{/* <Typography>{format(date, "MMMM YYYY")}</Typography> */}
			</Grid>
			<IconContainer item>
				<StyledIconButton disabled={nextDisabled} onClick={onClickNext}>
					<ChevronRight color={nextDisabled ? "disabled" : "action"} />
				</StyledIconButton>
			</IconContainer>
		</Grid>
	);
};