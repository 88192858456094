import { CheckOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import requests from "api/requests";
import { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { DateConverter, Deserializer, JsonProperty } from "ts-serializer-core";

interface IProps{
  carID: number;
}

class DocumentSerializer {
  @JsonProperty('id')
  public id:number;

  @JsonProperty('type')
  public type:number;

  @JsonProperty('data')
  public data?:string;

  @JsonProperty('comment')
  public comment?:string;

  @JsonProperty({
    name: 'date_issue',
    type: Date,
    customConverter: DateConverter
  })
  public date_issue?:Date;

  @JsonProperty({
    name: 'expiration',
    type: Date,
    customConverter: DateConverter
  })
  public expiration?:Date;

  @JsonProperty('removed')
  public removed:boolean;
}

interface IDocumentType{
  id: number;
  name: string;
}

type IDocumentTypeByID = {[id: number]: string};

export default function Documents(props:IProps){
  const {
    carID,
  } = props;

  const [ documentTypes, setDocumentTypes ] = useState<IDocumentType[]|null>(null);
  const [ documents, setDocuments ] = useState<DocumentSerializer[]|null>(null);

  useEffect(()=>{
    requests.get('/base/document-type/').then((r) => setDocumentTypes(r.body))
  }, [])

  const DocumentTypeByID = useMemo(()=>{
    let res:IDocumentTypeByID = {};
    if(!documentTypes) return res;
    
    documentTypes.forEach((i)=>{
      res[i.id] = i.name
    })
    return res;
  }, [documentTypes])

  useEffect(()=>{
    requests.get(`/base/car-card/${carID}/documents/`).then((r) => {
      const deserializer = new Deserializer();
      return r.body.map((i:any)=>deserializer.deserialize(DocumentSerializer, i));
    }).then((res) => setDocuments(res))
  }, [carID])

  if(documentTypes === null || documents === null){
    return (
      <Box sx={{textAlign: 'center'}}>
        <CircularProgress/>
      </Box>
    )
  }

  if(!documents.length){
    return (
      <Typography variant="h6" color="textSecondary" align="center" sx={{py: 3}}>
        Документи відсутні
      </Typography>
    )
  }

  return (
    <Box sx={{mx: -2, mb: -2}}>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Тип</TableCell>
              <TableCell>Дані</TableCell>
              <TableCell>Примітка</TableCell>
              <TableCell sx={{ width: '1px' }}>Дата видачі</TableCell>
              <TableCell sx={{ width: '1px' }}>Дата закінчення</TableCell>
              <TableCell sx={{ width: '1px' }}>Видалено</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow
                key={document.id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0
                  },
                  '& td, & th': {
                    opacity: document.removed ? 0.5: 1
                  }
                }}
                hover
              >
                <TableCell component="th" scope="row">
                  {DocumentTypeByID[document.type]}
                </TableCell>
                <TableCell sx={{whiteSpace: "pre-wrap"}}>{document.data}</TableCell>
                <TableCell sx={{whiteSpace: "pre-wrap"}}>{document.comment}</TableCell>
                <TableCell>
                  {!!document.date_issue && (
                    <Moment
                      date={document.date_issue}
                      format="DD.MM.YYYY"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color={(!!document.expiration && document.expiration < new Date()) ? "error": "default"}
                  >
                    {!!document.expiration && (
                      <Moment
                        date={document.expiration}
                        format="DD.MM.YYYY"
                      />
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {document.removed && <CheckOutlined/>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}