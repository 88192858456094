import React, { useState, useEffect } from 'react';
import CardContext from './CardContext';
import { CardProviderInterface } from './types';
import { useHistory, useLocation } from 'react-router-dom';
import { CardItem, CardType } from 'cards/types';
import { CardsConfig } from 'cards/config';


const regexp_match = `(${Object.keys(CardsConfig).join("|")})-([0-9]+)`

interface ProgressPercentProviderProps{
  children: React.ReactNode;
}

export default function ProgressPercentProvider(props: ProgressPercentProviderProps) {
  const { hash } = useLocation();
  let history = useHistory();
  const {
    children
  } = props;

  const [ card, setCard ] = useState<CardItem|null>(null);

  useEffect(()=>{
    const match = hash.match(regexp_match);
    if(match){
      setCard({
        type: match[1] as CardType,
        id: Number(match[2]),
      })
    }else{
      setCard(null)
    }
  }, [hash])

  const open = (type:CardType, id:number)=>{
    history.push(`#${type}-${id}`);
  }
  

  const context:CardProviderInterface = {
    openCard: open
  }

  return (
    <CardContext.Provider value={context}>
        {children}
        {Object.entries(CardsConfig).map(([key, Component])=>{
          let id:number|undefined = undefined
          if(card && card?.type === key){
            id = card.id
          }
          return (
            <Component
              key={key}
              id={id}
              onClose={()=>history.push("#")}
            />
          )
        })}
    </CardContext.Provider>
  );
}