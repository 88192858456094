import { Grid, Hidden, InputAdornment, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import requests from 'api/requests';
import CustomTablePagination from 'components/customTablePagination';
import React, { useCallback, useEffect, useState } from 'react';
import ItemsTable from './itemsTable';
import { ICollateBlanance } from './types';
import { PaginationGridBox, TablePaperBox } from 'components/styled';


export default function CollateBalance() {
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState({
    first_loaded: false,
    items: [] as ICollateBlanance[],
    items_count: 0,
    page: 0,
    limit: 30,
  })

  const filters = useCallback(() => {
    let new_filter:any = {
    };
    if(searchString){
      new_filter.search = searchString;
    }
    return new_filter;
  }, [searchString])

  const load = useCallback(() => {
    const abortController = new AbortController();
    window.scrollTo(0, 0);
    
    requests.get('/corp-admin/collate/balance/', {
      ...filters(),
      limit: page.limit,
      offset: page.page * page.limit
    }, abortController.signal).then((r) => {
      setPage(page => ({
        ...page,
        first_loaded: true,
        items: r.body.results,
        items_count: r.body.count
      }));
    })

    return () => {
      abortController.abort();
    };
  }, [page.page, page.limit, filters])
  useEffect(() => load(), [load]);

  return (
    <div>
      { page.first_loaded && (
        <div>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <TextField
                    fullWidth
                    placeholder="Пошук"
                    value={ searchString }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value);
                      setPage({
                        ...page,
                        page: 0,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined color={'action'} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <PaginationGridBox item sm={4}>
                <CustomTablePagination
                  count={page.items_count}
                  limit={page.limit}
                  page={page.page}
                  onChange={(page_number:number) => {
                    setPage({
                      ...page,
                      page: page_number
                    });
                  }}
                />
              </PaginationGridBox>
            </Hidden>
          </Grid>
          <TablePaperBox>
            <ItemsTable
              items={page.items}
            />
          </TablePaperBox>
          <CustomTablePagination
            count={page.items_count}
            limit={page.limit}
            page={page.page}
            onChange={(page_number:number) => {
              setPage({
                ...page,
                page: page_number
              });
            }}
          />
        </div>
      )}
    </div>
  );
}