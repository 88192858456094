import { Grid, Paper, styled, Typography } from "@mui/material";

export const Root = styled("div")(({ theme }) => ({
  '& > div': {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
  }
}));

export const BalanceGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'right',
  [theme.breakpoints.down('xs')]: {
    textAlign: 'left',
  },
}));

export const PaperBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const TextTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  verticalAlign: 'middle',
  display: 'inline-flex',
}));