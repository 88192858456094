import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useMemo } from "react";
import { CardProps } from "../types";
import { StyledDialogTitle } from "./styles";
import OrderHistory from "./orderHistory";

export default function OrderHistoryCard(props:CardProps){
  const {
    id,
    onClose
  } = props
  

  const title = useMemo(()=>{
    let title_list = [
      "Історія замовлення",
      id
    ];
    return title_list.join(": ")
  }, [id])


  return (
    <Dialog
      open={!!id}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent dividers sx={{padding: 0}}>
        {!!id && <OrderHistory id={id}/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрити</Button>
      </DialogActions>
    </Dialog>
  )
}