import React, { useState, useEffect, useCallback } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import requests from 'api/requests';
import { useRouteMatch } from 'react-router-dom';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import Tiles from './tiles';
import Moment from 'react-moment';



export default function Offset() {
  const match:any = useRouteMatch();
  
  const [driver, setDriver] = useState(null as any)
  const [periods, setPeriods] = useState(null as any)
  const [period, setPeriod] = useState(null as any)
  const [carIDs, setCarIDs] = useState([] as any)

  const loadDriver = useCallback(() => {
    requests.get(`/base/driver/${match.params.id}/`).then((r) => {
      setDriver(r.body);
    })
  }, [match.params.id])

  const loadPeriods = useCallback(() => {
    if(!carIDs.length) return;

    requests.get('/driver/offset/period/', {
      car_id__in: carIDs,
      limit: 5
    }).then((r) => {
      setPeriods(r.body.results);
      if(r.body.results.length){
        setPeriod(r.body.results[0]);
      }
    })
  }, [carIDs])

  useEffect(() => {
    if(!driver) return;
    const car_ids = driver.car_ids;
    setCarIDs(car_ids);
  }, [driver]);
  
  useEffect(() => loadDriver(), [loadDriver]);
  useEffect(() => loadPeriods(), [loadPeriods]);

  if(carIDs === null || periods === null){
    return null;
  }

  if(!carIDs.length){
    return (
      <div>
        <Typography variant="h1" color="textSecondary" align="center">
          <ErrorOutlineOutlined fontSize="large" />
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          Розділ для обраного водія не доступний
        </Typography>
      </div>
    )
  }

  if(period === null){
    return (
      <div>
        <Typography variant="h1" color="textSecondary" align="center">
          <ErrorOutlineOutlined fontSize="large" />
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          Відсутні розрахункові періоди
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <FormControl fullWidth>
            <InputLabel>Період</InputLabel>
            <Select
              value={period}
              displayEmpty
              onChange={(e: any, q: any) => {
                setPeriod(e.target.value)
              }}
            >
              {periods.map((i:any, index:number) => (
                <MenuItem key={index} value={i}>
                  <Moment
                    date={i.date_from}
                    format="DD.MM"
                  />
                  -
                  <Moment
                    date={i.date_to}
                    format="DD.MM/YYYY"
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br/><br/>

      <Tiles
        carIDs={carIDs}
        periodID={period.id}
      />
    </div>
  );
}