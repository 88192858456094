import React from 'react';
import { IconButton, Typography, List, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { ExtraField, IStatus } from './types';
import { EditOutlined } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from 'utils/reorder';
import { DraggableListItem } from './styles';


interface IProps{
  statuses: IStatus[],
  extraFields?: ExtraField[];
  onEdit:(status:IStatus) => void;
  onReorder:(statuses:IStatus[]) => void;
}

export default function StatusList(props:IProps) {

  const onDragEnd = (result:any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const reorder_statuses:IStatus[] = reorder(
      props.statuses,
      result.source.index,
      result.destination.index
    );

    props.onReorder(reorder_statuses);
  }

  const renderSecondaryText = (status:IStatus) => {
    let items = [];
    if(status.is_final){
      items.push("Кінцевий");
    }

    (props.extraFields || []).map((i)=>{
      if(!!(status as any)[i.field_name]){
        items.push(`${i.title}: ${(status as any)[i.field_name]}`);
      };
      return i;
    })

    if(items.length > 0){
      return items.join(", ");
    }
    return null;
  }

  if(!props.statuses.length){
    return (
      <Typography color="textSecondary" align="center">Статуси відсутні</Typography>
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            { props.statuses.map((status, index) => {
              return (
                <Draggable
                  key={status.id}
                  draggableId={`id-${status.id}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <DraggableListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        draggable={snapshot.isDragging}
                      >
                        <div>
                          <ListItemText
                            primary={status.name}
                            secondary={renderSecondaryText(status)}
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end"
                              onClick={(e)=>{
                                e.preventDefault();
                                props.onEdit(status);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </div>
                      </DraggableListItem>
                    )
                  }}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}