import { Rating } from "@mui/material";
import CopyText from "components/copyText";
import { FilteredTableColumn } from "components/FilteredTable/types";
import NumberFormat from "react-number-format";
import { ICar } from "../types";

interface DriverFilteredTableColumn extends FilteredTableColumn{
  disable_for_atp?: boolean
}

const columns: DriverFilteredTableColumn[] = [
  {
    title: 'ID',
    items: [
      {
        title: 'ID',
        style: {
          whiteSpace: "nowrap"
        },
        render: (row:ICar) => (
          <CopyText text={row.id}>{row.id}</CopyText>
        )
      }
    ],
    width: 1
  },
  {
    title: 'Держ. номер',
    items: [
      {
        title: 'Держ. номер',
        render: (row:ICar) => row.state_number
      },
    ]
  },
  {
    title: 'Марка',
    items: [
      {
        title: 'Марка',
        render: (row:ICar) => row.brand.name
      },
    ]
  },
  {
    title: 'Клас',
    items: [
      {
        title: 'Клас',
        render: (row:ICar) => row.tariff_class.name
      },
    ]
  },
  {
    title: 'Рік',
    items: [
      {
        title: 'Рік',
        render: (row:ICar) => row.year
      },
    ]
  },
  {
    title: 'Середня оцінка',
    width: 300,
    items: [
      {
        title: 'Оцінка',
        render: (row:ICar) => (
          <div style={{marginLeft: -5}}>
            <Rating
              name="read-only"
              value={row.rating}
              readOnly
              size='small'
              precision={0.5}
              sx={{
                verticalAlign: 'middle'
              }}
            />
            &nbsp;(<NumberFormat value={row.rating} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />)
          </div>
        ),
      },
    ]
  },
  {
    title: 'АТП',
    items: [
      {
        title: 'АТП',
        render: (row:ICar) => row.auto_group?.name,
      },
    ],
    disable_for_atp: true
  },
  {
    title: 'Позивний',
    items: [
      {
        title: 'Позивний',
        render: (row:ICar) => row.call_sign,
      },
    ],
  }
];

export default columns;