import React, { useState } from 'react';
import { TableRow, TableCell, Popover, Typography, styled, popoverClasses } from '@mui/material';
import { IMessage } from './types';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

const MessageBox = styled("span")(({ theme }) => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  cursor: 'pointer',
}));

const PopoverBox = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    padding: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  }
}));


interface IInstanceRow {
  instance: IMessage;
}

export default function InstanceRow(props:IInstanceRow) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    instance
  } = props

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);

  const renderPhone = (number:string|null) => {
    if(!number) return "";

    return (
      <NumberFormat value={number} displayType={'text'} format="+## (###) ### ## ##" />
    )
  }

  return (
    <TableRow hover>
      <TableCell style={{whiteSpace: 'nowrap', width: 1}}>
        <Moment
          date={instance.created}
          format="DD.MM.YY HH:mm"
        />
      </TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{instance.customer?.firstname}</TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{instance.customer ? renderPhone(instance.customer.phone) : ""}</TableCell>
      <TableCell style={{maxWidth: 0}}>

        <MessageBox
          onClick={handlePopoverOpen}
        >
          {instance.message}
        </MessageBox>

        <PopoverBox
          open={popoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
        >
          <Typography variant="body2">{instance.message}</Typography>
        </PopoverBox>
      </TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{instance.os}</TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{instance.device}</TableCell>
      <TableCell style={{whiteSpace: 'nowrap'}}>{instance.version}</TableCell>
    </TableRow>
  );
}