import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import requests from 'api/requests';
import { IMenuRoute } from 'routes/types';
import { LogoBox, MenuLoadingBox } from '../styles';
import { IMainLayoutProps } from '../types';
import { IDriver } from 'components/driver/types';
import MenuItems from './menuItems';



export default function DriverLayoutMenu(props:IMainLayoutProps) {
  const driver_id = +props.startRouteProps.match.params.id;

  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [driver, setDriver] = useState(null as any);
  const [drivers, setDrivers] = useState([]);

  const makeUrl = (item:IMenuRoute) => {
    return (props.startRoute.start_path + item.path).replace(":id", `${driver_id}`)
  }

  useEffect(() => {
    setLoading(true);
    requests.getAll('/base/driver/', {limit: 0, removed: false}).then((r) => {
      setDrivers(r.body.results);
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    if(drivers.length){
      let driver = null;
      if(driver_id){
        drivers.forEach((i:any) => {
          if(i.id === driver_id){
            driver = i;
          }
        });
      }
      if(driver){
        setDriver(driver);
      }else{
        const new_url = props.startRoute.start_path.replace("/:id", "/");
        history.push(new_url);
      }
    }
  }, [drivers, driver_id, history, props.startRoute.start_path])

  const goToDriver = (new_driver_id:number) => {
    const new_url = props.startRouteProps.match.url.replace(`/${driver_id}/`, `/${new_driver_id}/`);
    history.push(new_url);
  }

  const driverName = (obj:IDriver):string => {
    let vars = [
      obj.lastname,
      obj.firstname ? (obj.firstname[0].toUpperCase() + ".") : null,
      obj.middlename ? (obj.middlename[0].toUpperCase() + ".") : null,
    ];
    vars = vars.filter(v => !!v);
    let name = vars.join(" ");
    return name ? name : '-/-'
  }

  const driverSubTitle = (obj:IDriver):string => {
    let subtitle = [];
    
    let call_signs = obj.call_signs.map(i => i.call_sign);
    if(call_signs.length){
      subtitle.push(call_signs.join(", "));
    }

    if(obj.auto_group && obj.auto_group.name !== "ФОП"){
      subtitle.push(obj.auto_group.name);
    }

    return subtitle.join(". ") + ` (ID: ${obj.id})`
  }

  const renderAutocompleteOption = (props:any, option:IDriver) => {
    let subtitle = driverSubTitle(option);

    return (
      <div {...props} key={option.id}>
        <div>
          <div>{driverName(option)}</div>
          {subtitle && (
            <Typography variant="caption" color="textSecondary">{subtitle}</Typography>
          )}
        </div>
      </div>
    )
  }

  const filterAutocompleteOptions = createFilterOptions({
    stringify: (option:IDriver) => [driverName(option), driverSubTitle(option)].join(" "),
  });

  let list = null;

  if(!loading && driver){
    let subtitle = driverSubTitle(driver);
    list = (
      <List>
        { drivers && drivers[0] && (
          <ListItem>
            <div style={{width: '100%'}}>
              { (drivers && drivers.length > 1) ? (
                <Autocomplete
                  options={drivers}
                  value={driver}
                  fullWidth
                  filterOptions={filterAutocompleteOptions}
                  renderOption={renderAutocompleteOption}
                  getOptionLabel={driverName}
                  isOptionEqualToValue={(option, value) => {
                    return option && value && option.id === value.id;
                  }}
                  renderInput={(params:any) => <TextField {...params} label="Водій" />}
                  disableClearable={true}
                  onChange={(e, driver) => {
                    goToDriver(driver.id);
                  }}
                />
              ) : (
                <Typography variant="body1">{driverName(drivers[0])}</Typography>
              )}
              { subtitle && (<Typography variant="caption" color="primary">{subtitle}</Typography>)}
            </div>
          </ListItem>
        )}
        <MenuItems
          items={props.startRoute.children}
          makePath={(item:IMenuRoute) => (props.startRoute.start_path + item.path)}
          makeUrl={makeUrl}
          onClick={() => { props.closeMobileMenu() }}
          isHideParam={driver}
        />
      </List>
    );
  }

  return (
    <div>
      <LogoBox className={"MuiPaper-elevation4"}>
        <img src={ process.env.PUBLIC_URL + '/img/logoHead.svg' } alt="logo" />
      </LogoBox>
      
      { loading ? (
        <MenuLoadingBox>
          <CircularProgress />
        </MenuLoadingBox>
      ) : list }
    </div>
  );
}