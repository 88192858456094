import { Feature, Map, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import { MAP_HOST } from 'config';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { CarLocation, Point as IPoint } from './types';
import {
  defaults as defaultInteractions
} from 'ol/interaction';
import Geometry from 'ol/geom/Geometry';
import CurrentLocationModel from './CurrentLocationModel';
import LineString from 'ol/geom/LineString';
import { Extent } from 'ol/extent';

const current_location_style = new Style({
  image: new Circle({
    radius: 6,
    stroke: new Stroke({
        color: '#fff',
        width: 2,
    }),
    fill: new Fill({
        color: "#346CF0"
    })
  })
})

export class MapClass{
  locationModel:CurrentLocationModel|null;
  iconsSource:VectorSource<any>;
  vectorLayer:Vector<any>;
  currentLocationSource:VectorSource<any>;
  currentLocationLayer:Vector<any>;
  tileLayer:TileLayer<any>;
  mapPopup:Overlay;
  map:Map;
  targetMap:string;
  targetPopUp:string;
  forceBigMarkers:boolean;
  PopUpFunc:null|((item:Feature<any>|null) => void);

  color_standard = '#ffc10e';
  color_business = '#56c4cf';
  color_premium = '#009574';
  color_busy = '#ff547c';
  zoom_for_big_marker = 13.0;

  constructor(targetMap:string, targetPopUp:string) {
    this.locationModel = null;
    this.PopUpFunc = null;
    this.targetMap = targetMap;
    this.targetPopUp = targetPopUp;
    this.forceBigMarkers = false;

    this.iconsSource = new VectorSource();
    this.vectorLayer = new Vector({
      source: this.iconsSource
    });
    this.tileLayer = new TileLayer({
      source: new XYZ({
        url: `${MAP_HOST}/tile/{z}/{x}/{y}.png`
      })
    });

    this.currentLocationSource = new VectorSource();
    this.currentLocationLayer = new Vector({
      source: this.currentLocationSource,
      updateWhileAnimating: true,
    });

    this.mapPopup = new Overlay({
      element: document.getElementById(this.targetPopUp) as HTMLElement,
      positioning: 'bottom-center',
      stopEvent: true,
      offset: [0, -20],
    });

    this.map = new Map({
      target: this.targetMap,
      interactions: defaultInteractions({
        altShiftDragRotate:false,
        pinchRotate:false
      }),
      layers: [
        this.tileLayer,
        this.vectorLayer,
        this.currentLocationLayer,
      ],
      overlays: [
        this.mapPopup
      ],
      view: new View({
        center: fromLonLat([30.5035, 50.4548]),
        zoom: 12,
        maxZoom: 20,
      })
    });

    
    this.map.on('moveend', this.drawIcons);
    this.pointermoveEvent();
  }

  setPopUpCallBack = (func: (item:Feature<any>|null) => void) => {
    this.PopUpFunc = func;
  }

  _callPopUpFunc = (item:Feature<any>|null) => {
    if(this.PopUpFunc){
      this.PopUpFunc(item)
    }
  }

  pointermoveEvent = () => {
    const event = (evt:any) => {
      var feature = this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });
      if (feature) {
        var coordinates = feature.getGeometry() as any;
        if(feature.getProperties().info){
          this.mapPopup.setPosition(coordinates.getCoordinates());
          this._callPopUpFunc(feature as Feature<any>);
        }else{
          this.mapPopup.setPosition(undefined);
          this._callPopUpFunc(null);
        }
      } else {
        this.mapPopup.setPosition(undefined);
        this._callPopUpFunc(null);
      }
    }
    // this.map.on('pointermove', event);
    this.map.on('singleclick', event);
  }

  drawIcons = () => {
    this.iconsSource.getFeatures().map((feature) => {
      const info:CarLocation = feature.getProperties().info;
      let color = "#000000";
      if(info.status !== 0){
        color = this.color_busy.toString();
      }else if(info.class === 2){
        color = this.color_standard.toString();
      }else if(info.class === 1){
        color = this.color_business.toString();
      }else if(info.class === 5){
        color = this.color_premium.toString();
      }
      const style = this.IconStyle(color, info.call_sign);
      feature.setStyle(style);

      return feature;
    })
  }

  ifBigMarker = () => {
    const zoom = this.map.getView().getZoom();
    return (!!zoom && zoom >= this.zoom_for_big_marker) || this.forceBigMarkers;
  }

  IconStyle = (color:string, text:string):Style => {
    let size = 6;
    let font_scale = 0;
    if(this.ifBigMarker()){
      size = 10;
      font_scale = 1;
    }
  
    const markerStyle = new Style({
        image: new Circle({
            radius: size,
            stroke: new Stroke({
                color: '#fff'
            }),
            fill: new Fill({
                color: color
            })
        }),
        text: new Text({
            font: 'bold 9px sans-serif',
            scale: font_scale,
            textAlign: 'center',
            text: text,
            fill: new Fill({
                color: '#fff'
            })
        })
    });
    return markerStyle;
  }

  updateCallSigns = (call_signs:CarLocation[]) => {
    const call_sign_ids = call_signs.map((i) => i.call_sign);
    this.iconsSource.getFeatures().map((feature) => {
      if(call_sign_ids.indexOf(feature.getId() as string) < 0){
        this.iconsSource.removeFeature(feature);
      }
      return feature;
    })
  
    call_signs.map((call_sign) => {
      let icon = this.iconsSource.getFeatureById(call_sign.call_sign);
      if(!icon){
        icon = new Feature();
      }
      icon.setId(call_sign.call_sign);
      const geometry = new Point(fromLonLat([call_sign.coordinates[1], call_sign.coordinates[0]]));
      icon.setProperties({
        info: call_sign
      })
      icon.setGeometry(geometry);
      this.iconsSource.addFeature(icon);
      return call_sign;
    })
    this.drawIcons();
  }

  get currentLocationFeature():Feature<Geometry>|null {
    const feature = this.currentLocationSource.getFeatureById("currentLocation");
    return feature?feature:null
  }

  protected updateCurrentLocation = () => {
    if(!!this.currentLocationFeature && this.locationModel?.point){
      const geometry = new Point(fromLonLat([
        this.locationModel?.point.coordinates[1],
        this.locationModel?.point.coordinates[0]]
      ));
      this.currentLocationFeature.setGeometry(geometry);
    }
  }

  showCurrentLocation = (locationModel:CurrentLocationModel) => {
    this.hideCurrentLocation();
    const feature = new Feature();
    feature.setId("currentLocation");
    feature.setStyle(current_location_style.clone())
    this.currentLocationSource.addFeature(feature);
    this.updateCurrentLocation();
    
    this.locationModel = locationModel;
    if(!!this.locationModel){
      this.locationModel.subscribe(this.updateCurrentLocation);
    }
  }

  hideCurrentLocation = () => {
    if(!!this.currentLocationFeature){
      this.currentLocationSource.removeFeature(this.currentLocationFeature);
    }

    if(!!this.locationModel){
      this.locationModel.unsubscribe(this.updateCurrentLocation);
      this.locationModel = null;
    }
  }

  setFit = (_extent:Extent) => {
    let new_extent:Extent = [..._extent];
    this.map.getView().fit(new_extent, {
      size: this.map.getSize(),
      padding: [25, 25, 25, 25]
    });
  }

  setCenter = (_point:IPoint, fixPointFit:number=0.0007) => {
    const geometry = new LineString([
      fromLonLat([_point.coordinates[1]-fixPointFit, _point.coordinates[0]-fixPointFit]),
      fromLonLat([_point.coordinates[1]+fixPointFit, _point.coordinates[0]+fixPointFit]),
    ]);
    this.setFit(geometry.getExtent());
  }
}