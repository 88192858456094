import React, { useCallback, useEffect, useState } from 'react';
import { IDriverRating } from './types';
import requests from 'api/requests';
import { FormControl, FormControlLabel, Grid, InputAdornment, Switch, TableContainer, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { dataRangeItem, defaultRangesWithAll } from 'components/daterange/defaults';
import { format } from 'date-fns';
import { useProgress } from 'components/progress';
import { IChoice } from './types';
import DataTable from './DataTable';
import { DefinedRange } from 'components/daterange/types';
import { TablePaperBox } from 'components/styled';

interface IProps{
  atp?: number;
  hideSubtitle?: boolean;
  hideRank?: boolean;
  DateRangeProps?: {
    definedRanges?: DefinedRange[];
    maxDate?: Date 
  };
}

export default function DriverRating(props:IProps) {
  const { showProgress, hideProgress } = useProgress();
  const [searchString, setSearchString] = useState('');
  const [searchStringData, setSearchStringData] = useState('');
  const [dateRange, setDateRange] = useState<IDateRange>(dataRangeItem.all(new Date()));
  const [showZero, setShowZero] = useState(false);
  const [allItems, setAllItems] = useState<IDriverRating[]|null>(null);
  const [orderedItems, setOrderedItems] = useState<IDriverRating[]>([]);
  const [choices, setChoices] = useState<IChoice[]>([]);

  const {
    atp,
    hideSubtitle,
    hideRank,
    DateRangeProps
  } = props

  const getFilter = useCallback(()=>{
    let filter:any = {}
    if(dateRange.startDate){
      filter.start = format(dateRange.startDate as Date, "yyyy-MM-dd");
    }
    if(dateRange.endDate){
      filter.end = format(dateRange.endDate as Date, "yyyy-MM-dd");
    }

    if(atp){
      filter.atp = atp;
    }
    return filter;
  }, [dateRange, atp])

  useEffect(()=>{
    showProgress();
    requests.get('/admin/webcab/rating/driver/', getFilter()).then((r) => {
      let _items:IDriverRating[] = r.body.map((i:any)=>{
        let driver_items:any = {};
        i.items.forEach((element:any) => {
          driver_items[element.item] = element.count
        });
        return {
          ...i,
          items: driver_items
        }
      });

      setAllItems(_items);
      hideProgress();
    })
  }, [getFilter, showProgress, hideProgress])

  useEffect(()=>{
    requests.get('/admin/webcab/rating/item/').then((r) => {
      setChoices(r.body)
    })
  }, [])

  useEffect(()=>{
    let _items:IDriverRating[] = [...(allItems||[])];

    if(!showZero){
      _items = _items.filter((i)=>i.rating.count)
    }

    if(searchStringData){
      _items = _items.filter((i)=>{
        if(/^[0-9]+$/.test(searchStringData)){
          const callsigns = i.call_signs.filter((c)=>c.call_sign===searchStringData)
          return callsigns.length > 0;
        }

        const names = [
          i.driver.lastname,
          i.driver.firstname,
          i.driver.middlename,
        ].filter((i)=>!!i);
        const driver_name = names.join(" ");
        return driver_name.toLocaleLowerCase().includes(searchStringData.toLocaleLowerCase()) || i.atp?.name.toLocaleLowerCase().includes(searchStringData.toLocaleLowerCase())
      })
    }

    setOrderedItems(_items)

  }, [allItems, showZero, searchStringData])
  

  useEffect(()=>{
    const timeout = setTimeout(()=>{
      setSearchStringData(searchString)
    }, 1000)

    return ()=>{
      clearTimeout(timeout)
    }
  }, [searchString])

  if(allItems===null){
    return null;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <Grid container spacing={3} alignItems="flex-start" justifyContent="flex-start" direction="row" wrap="nowrap">
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  placeholder="Пошук"
                  value={ searchString }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchString(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined color={'action'} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={3} xl={2}>
          <DateRangePicker
            dateRange={dateRange}
            onChange={(range) => setDateRange(range)}
            definedRanges={defaultRangesWithAll}
            {...DateRangeProps}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <FormControlLabel
            control={
              <Switch
                checked={showZero}
                onChange={(e)=>{
                  setShowZero(e.target.checked)
                }}
              />
            }
            label="Відображати порожні"
          />
        </Grid>
      </Grid>
      <TablePaperBox>
        <TableContainer>
          <DataTable
            items={orderedItems || []}
            choices={choices}
            hideSubtitle={hideSubtitle}
            hideRank={hideRank}
          />
        </TableContainer>
      </TablePaperBox>
    </div>
  )
}