import { FlipCameraAndroidOutlined } from "@mui/icons-material";
import { Checkbox, FormControl, List, ListItem, ListItemIcon, ListItemText, Switch, Typography } from "@mui/material";
import React from "react";
import { SelectFilterProps } from "./SelectFilter";

export interface ExcludeCheckboxListValue{
  exclude: boolean;
  value: (number|string)[];
}

export interface ExcludeCheckboxListFilterProps extends SelectFilterProps{
  value: ExcludeCheckboxListValue;
  onChange: (value:ExcludeCheckboxListValue)=>void;
  hideLabel?: boolean;
}

export default function ExcludeCheckboxListFilter(props:ExcludeCheckboxListFilterProps){
  const {
    items = [],
    hideLabel
  } = props

  const handleToggle = (value: number|string) => () => {
    const currentIndex = (props.value?.value || []).indexOf(value);
    const newChecked = [...(props.value?.value || [])];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    props.onChange({
      ...props.value,
      value: newChecked
    });
  };
  
  return (
    <FormControl fullWidth>
      {!hideLabel && (
        <Typography variant='caption' color='textSecondary'>{props.label}</Typography>
      )}

      <List disablePadding={!!hideLabel}>
        <ListItem dense>
          <ListItemIcon>
            <FlipCameraAndroidOutlined />
          </ListItemIcon>
          <ListItemText id="switch-list-label-exclude" primary="Відсутні" />
          <Switch
            edge="end"
            onChange={(e)=>{
              props.onChange({
                ...props.value,
                exclude: e.target.checked,
              });
            }}
            checked={!!props.value?.exclude}
            inputProps={{
              'aria-labelledby': 'switch-list-label-exclude',
            }}
          />
        </ListItem>
        {items.map((i) => {
          const labelId = `checkbox-list-label-${i.value}`;

          return (
            <ListItem
              key={i.value}
              role={undefined}
              dense
              button
              onClick={handleToggle(i.value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={(props.value?.value || []).indexOf(i.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={i.name} />
            </ListItem>
          );
        })}
      </List>
    </FormControl>
  )
}