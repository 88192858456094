import { TextField } from "@mui/material";
import React from "react";
import { FilteredTableFilterProps } from "../types";

export default function TextFilter(props:FilteredTableFilterProps){
  return (
    <TextField
      label={props.label}
      value={props.value || ""}
      onChange={(e)=>{
        props.onChange(e.target.value)
      }}
      fullWidth
    />
  )
}