import React, { useEffect, useRef, useState } from 'react';
import FilteredTable from 'components/FilteredTable';
import columns from './columns';
import filters from './filters';
import { IExtendData, IRating } from './types';
import InstanceModal from './instanceModal';
import requests from 'api/requests';
import { IStatus } from 'pages/settings/statuses/base/types';
import { Button } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';


const DEFAULT_INSTANCE:IRating = {
  call_sign: null,
  car: null,
  comment: "",
  driver: null,
  items: [],
  order: null,
  orderid: null,
  rating: 0,
  laststatus: null
}


export default function AppRating() {
  const tableRef = useRef(null)
  const [instance, setInstance] = useState<null|IRating>(null);
  const [extendData, setExtendData] = useState<IExtendData|undefined>(undefined);

  useEffect(()=>{
    Promise.all([
      requests.get('/settings/statuses/webcab-rating/').then((r) => r.body),
      requests.get('/admin/webcab/rating/item/').then((r) => r.body)
    ]).then((r)=>{
      const _statuses = r[0];
      let _statusDict = {} as any;
      _statuses.map((status:IStatus)=>{
        if(status.id !== undefined){
          _statusDict[status.id] = status;
        }
        return status;
      })

      const choices = r[1]
      let choicesDict = {} as any;
      choices.map((i:any)=>{
        choicesDict[i.id] = i;
        return i;
      })
      
      setExtendData({
        statuses: _statuses,
        statusDict: _statusDict,

        choices: choices,
        choicesDict: choicesDict,
      })
    })
    
  }, [])

  return (
    <div>
      <FilteredTable
        dataURL="/admin/webcab/rating/"
        columns={columns}
        filters={filters}
        userSettingsKey="webcab-rating"
        onRowClick={setInstance}
        extendData={extendData}
        tableRef={tableRef}
        leftSide={(
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddOutlined />}
            onClick={()=>{
              setInstance(DEFAULT_INSTANCE)
            }}
          >
            Додати
          </Button>
        )}
      />
      { instance !== null ? (
        <InstanceModal
          instance={instance}
          statusTypes={extendData?.statuses || []}
          ratingChoices={extendData?.choices || []}
          onClose={()=>setInstance(null)}
          onStatusChange={()=>{
            (tableRef.current as any).reload()
          }}
          onAdded={()=>{
            setInstance(null)
          }}
        />
      ): null }
    </div>
  )
}