import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthModel from "models/AuthModel";

function AuthRoute(data:any) {
  const { render, ...rest } = data;

  return (
    <Route
      {...rest}
      render={props =>
        AuthModel.if_auth() ? (
          <Redirect to="/" />
        ) : (
            render(props)
        )
      }
    />
  );
}

export default AuthRoute;