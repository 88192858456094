import React from "react";
import { DateRangePicker } from "components/daterange";
import { defaultRangesWithAll } from "components/daterange/defaults";
import { FilteredTableFilterProps } from "../types";
import { DefinedRange } from "components/daterange/types";

export interface DateRangePickerFilterProps extends FilteredTableFilterProps{
  definedRanges?: DefinedRange[];
}

export default function DateRangePickerFilter(props:DateRangePickerFilterProps){
  const definedRanges = props.definedRanges || defaultRangesWithAll;
  return (
    <DateRangePicker
      dateRange={props.value}
      onChange={(range) => props.onChange(range)}
      definedRanges={definedRanges}
    />
  )
}