import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { FilteredTableFilterProps } from "../types";

export interface SelectFilterItemsProps{
  name: string;
  value: string|number;
}

export interface SelectFilterProps extends FilteredTableFilterProps{
  items: SelectFilterItemsProps[]
}

export default function SelectFilter(props:SelectFilterProps){
  const {
    items = []
  } = props
  
  return (
    <FormControl fullWidth>
      <InputLabel shrink>{props.label}</InputLabel>
      <Select
        value={props.value || ''}
        displayEmpty
        onChange={(e)=>{
          props.onChange(e.target.value)
        }}
      >
        {items.map((i, index)=>(
          <MenuItem
            key={index}
            value={i.value}
          >{i.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}