import React, { useState } from 'react';
import { Menu } from '@mui/icons-material';
import { Route } from "react-router-dom";
import { IMenuCollapse, IMenuRoute, IStartRoute } from 'routes/types';
import ToolbarMenu from './ToolbarMenu';
import { AppBar, DrawerBox, FakeToolBarBox, MainBox, MainContentGrid, MenuButton, NavBox, RootBox, TitleBox } from './styles';
import { Grid, Hidden, Toolbar, Typography } from '@mui/material';

interface IMainLayoutProps {
  startRoute: IStartRoute;
  startRouteProps: any;
  children: JSX.Element;
}

export default function MainLayout(props:IMainLayoutProps) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <props.startRoute.layoutMenu
      startRoute={props.startRoute}
      startRouteProps={props.startRouteProps}
      closeMobileMenu={()=>{
        setMobileOpen(false);
      }}
    />
  );

  const headerRouteMaker = (items:(IMenuRoute|IMenuCollapse)[], parents:(IMenuRoute|IMenuCollapse)[]=[]) => {
    return (
      <React.Fragment>
        {items.map((item, index) => {
          if((item as any).items){
            return (
              <React.Fragment key={index}>
                {headerRouteMaker((item as IMenuCollapse).items, [...parents, item])}
              </React.Fragment>
            )
          }else{
            return (
              <Route
                key={index}
                path={props.startRoute.start_path + (item as IMenuRoute).path}
                exact={true}
                render={() => {
                  let titles = parents.map((i) => i.name);
                  return (
                    <React.Fragment>
                      {titles.length ? (
                        <Typography variant="caption" display="block" gutterBottom>{titles.join(" > ")}</Typography>
                      ) : null}
                      {item.name}
                    </React.Fragment>
                  );
                }}
              />
            )
          }
        })}
      </React.Fragment>
    )
  }

  return (
    <RootBox>
      <AppBar position="fixed" sx={{
        backgroundColor: 'primary.main',
        backgroundImage: 'none'
      }}>
        <Toolbar>
            <MenuButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </MenuButton>
            <TitleBox>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
              >
                {headerRouteMaker(props.startRoute.children)}
              </Typography>
            </TitleBox>
            
            <ToolbarMenu/>
            
        </Toolbar>
      </AppBar>
      <NavBox>
        <Hidden lgUp implementation="js">
          <DrawerBox
            mobile
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
          >
            {drawer}
          </DrawerBox>
        </Hidden>
        <Hidden mdDown implementation="js">
          <DrawerBox
            variant="permanent"
            open
          >
            {drawer}
          </DrawerBox>
        </Hidden>
      </NavBox>
      <MainBox>
        <Grid container spacing={0} direction="column" style={{minHeight: "100%"}}>
          <Grid item>
            <FakeToolBarBox />
          </Grid>
          <MainContentGrid item>
            <div>{props.children}</div>
          </MainContentGrid>
        </Grid>
      </MainBox>
    </RootBox>
  );
}