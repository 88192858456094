import React from 'react';
import { Container, Grid, Link, Typography } from '@mui/material';
import nav1 from 'static/drivercab/nav1.jpg';
import panel1_1 from 'static/drivercab/panel1.1.png';
import ic_connect from 'static/drivercab/ic_connect.png';
import no_server_working from 'static/drivercab/no_server_working.png';
import no_gprs_working from 'static/drivercab/no_gprs_working.png';
import gps_working from 'static/drivercab/gps_working.png';
import gps_working1 from 'static/drivercab/gps_working1.png';
import no_gps_working from 'static/drivercab/no_gps_working.png';
import driver_green from 'static/drivercab/driver_green.png';
import driver_orange from 'static/drivercab/driver_orange.png';
import balance_green from 'static/drivercab/balance_green.png';
import full_order_numered from 'static/drivercab/full_order_numered.png';
import web_order from 'static/drivercab/web_order.png';
import no_cash from 'static/drivercab/no_cash.png';
import joker from 'static/drivercab/joker.png';
import edditional from 'static/drivercab/edditional.png';
import ListItem from './listItem';



export default function InfoDriver() {

  return (
    <Container maxWidth="md">
      <Typography variant="h5"><Link href="https://play.google.com/store/apps/details?id=com.innotech.imap_taxi3&hl=RU" target="_blank">Driver Cab (посилання)</Link></Typography>
      <br/><br/>

      <Typography variant="h5">Інструкція</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <img src={ nav1 } alt="" width="100%" />
        </Grid>
        <Grid item xs={12} lg={4}>
          <img src={ panel1_1 } alt="" width="100%" />
          <br/><br/>

          <Typography gutterBottom>1. Cтатус підключення</Typography>
          <ListItem
            name="Підключений"
            image={ic_connect}
          />
          <ListItem
            name="Немає зв'язку з сервером. Перепідключіться."
            image={no_server_working}
          />
          <ListItem
            name="Немає інтернету"
            image={no_gprs_working}
          />

          <br/>
          <Typography gutterBottom>2. Відображення на карті</Typography>
          <ListItem
            name="GPS працює"
            image={gps_working}
          />
          <ListItem
            name="Неточна координата"
            image={gps_working1}
          />
          <ListItem
            name="GPS відключений"
            image={no_gps_working}
          />

          <br/>
          <Typography gutterBottom>3. Статус водія</Typography>
          <ListItem
            name="Вільний"
            image={driver_green}
          />
          <ListItem
            name="Перерва/На замовленні"
            image={driver_orange}
          />

          <br/>
          <Typography gutterBottom>4. Баланс</Typography>
          <ListItem
            name="Відображає баланс при натисканні"
            image={balance_green}
          />
        </Grid>
      </Grid>
      <br/><br/>

      <Typography variant="h5">Приклад згорнутого замовлення</Typography>
      <img src={ full_order_numered } alt="" width="100%" />
      <br/><br/>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom>1. Колір вертикальної смуги зліва відображає клас автомобіля на замовлення. значення:</Typography>
          <ListItem
            name="Без класу"
            color="#ff00ff"
          />
          <ListItem
            name="Мінівен"
            color="#000000"
          />
          <ListItem
            name="Стандарт"
            color="#0000ff"
          />
          <ListItem
            name="Бізнес"
            color="#008000"
          />
          <ListItem
            name="Преміум"
            color="#ff0000"
          />
          <br/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom>2. Час попереднього замовлення [поки не доступно].</Typography>
          <Typography gutterBottom>3. Кілометраж до адреси подачі [поки недоступний].</Typography>
          <Typography gutterBottom>4. Адреса подачі.</Typography>
          <Typography gutterBottom>5. Додаткова інформація на замовлення:</Typography>

          <ListItem
            name="Інтернет замовлення"
            image={web_order}
          />
          <ListItem
            name="Безготівкове замовлення (корпоратнивний рахунок кліента)"
            image={no_cash}
          />
          <ListItem
            name="Джокер (агентське замовлення)"
            image={joker}
          />
          <ListItem
            name="У замовленні є додаткові послуги"
            image={edditional}
          />
        </Grid>
      </Grid>
    </Container>
  );
}