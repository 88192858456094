import { LocalTaxiOutlined, PeopleAltOutlined, AssessmentOutlined, AttachMoneyOutlined, SupervisorAccountOutlined } from '@mui/icons-material';
import Trips from 'pages/corp/trips';
import Staff from 'pages/corp/staff/staff';
import Monitoring from 'pages/corp/monitoring';
import Payment from 'pages/corp/payment';
import Users from 'pages/corp/users';
import { ICompany } from 'pages/corp/payment/types';
import { IMenuRoute } from './types';

export const routes__corp = {
  trips: "/",
  staff: "/staff/",
  monitoring: "/monitoring/",
  payment: "/payment/",
  users: "/users/",
}

export const menu_routes__corp:IMenuRoute[] = [
  {
    path: routes__corp.trips,
    name: "Поїздки",
    component: Trips,
    icon: LocalTaxiOutlined,
  },{
    path: routes__corp.staff,
    name: "Співробітники",
    component: Staff,
    icon: PeopleAltOutlined,
  }
  ,{
    path: routes__corp.monitoring,
    name: "Моніторинг",
    component: Monitoring,
    icon: AssessmentOutlined,
  }
  ,{
    path: routes__corp.payment,
    name: "Оплата",
    component: Payment,
    icon: AttachMoneyOutlined,
    isHide: (company:ICompany):boolean => {
      return company.provider === null
    }
  }
  ,{
    path: routes__corp.users,
    name: "Адміністратори",
    component: Users,
    icon: SupervisorAccountOutlined,
  }
];