import { Box, Button, Typography } from '@mui/material';
import requests from 'api/requests';
import FilteredTable from 'components/FilteredTable';
import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import columns from './columns';
import filters from './filters';
import { ICar, IExtendData } from './types';

export default function Wialon() {
  const location = useLocation();
  const [extendData, setExtendData] = useState<IExtendData|undefined>(undefined);
  const [needWialonAuth, setNeedWialonAuth] = useState(false);

  const getExtendDate = useCallback(()=>{
    Promise.all([
      requests.get('/admin/wialon/car/').then((r) => r.body),
    ]).then((r)=>{
      const _cars = r[0];
      let _carsDict = {} as any;
      _cars.map((status:ICar)=>{
        if(status.id !== undefined){
          _carsDict[status.id] = status;
        }
        return status;
      })
      
      setExtendData({
        cars: _cars,
        carsDict: _carsDict,
      })
    }).catch((e)=>{
      if(e.status === 400 && e.body.auth === 'error'){
        setNeedWialonAuth(true);
      }
    })
  }, [])

  useEffect(()=>{
    const parsed = parse(location.search);
    if(!!parsed.access_token){
      setNeedWialonAuth(false);
      requests.post('/admin/wialon/token/', {
        'token': parsed.access_token
      }).then(getExtendDate)
    }else{
      getExtendDate();
    }
  }, [getExtendDate, location])

  if(needWialonAuth){
    return (
      <Box textAlign="center">
        <Typography variant='h5'>Необхідно авторизуватися на wialon</Typography><br/>
        <Button variant='contained' color='primary' href={`https://local.gpsm.com.ua/login.html?redirect_uri=${window.location.href}`}>Авторизуватися</Button>
      </Box>
    )
  }

  if(extendData === undefined){
    return null;
  }

  return (
    <div>
      <FilteredTable
        dataURL="/admin/wialon/parking/"
        columns={columns}
        filters={filters}
        userSettingsKey="wialon-parking"
        extendData={extendData}
        withoutPagination
      />
    </div>
  )
}