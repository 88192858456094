import { AccessTimeOutlined } from "@mui/icons-material";
import { Alert, InputAdornment, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import requests from 'api/requests';
import { format, parse } from 'date-fns';
import ukLocale from "date-fns/locale/uk";
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';


export default function Settings() {
  const match:any = useRouteMatch();
  const atp_id = match.params.id;
  
  const { enqueueSnackbar } = useSnackbar();
  const [ inputs, setInputs ] = useState({} as any);

  const handleChangeValue = (name:string) => (value:any) => {
    inputs[name] = value;
    setInputs({...inputs});
  }

  const load = useCallback(() => {
    requests.get(`/atp/atp/${atp_id}/`).then((r) => {
      setInputs(r.body);
    })
  }, [atp_id]);

  const save = (params:any) =>{
    requests.patch(`/atp/atp/${atp_id}/`, params).then((r) => {
      setInputs(r.body);
      enqueueSnackbar("Збережено", {variant: 'success'});
    })
  }

  useEffect(() => load(), [load]);
  if(!inputs.id){
    return null;
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
        <TimePicker
          label="Час перезмінки"
          renderInput={(params) => <TextField variant="standard" margin="normal" {...params} />}
          ampm={false}
          value={parse(inputs.replacement_time || '00:00:00', 'HH:mm:ss', new Date())}
          minutesStep={5}
          onChange={(v)=>{
            if(v){
              handleChangeValue('replacement_time')(format(v, 'HH:mm:ss'));
              save({
                'replacement_time': format(v, 'HH:mm:ss')
              })
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccessTimeOutlined color={'action'} />
              </InputAdornment>
            ),
          }}
        />
      </LocalizationProvider>

      <br/>
      <br/>
      <Typography variant="h5">Анкетні дані куратора</Typography>
      <br/>
      <b>ФІО:</b> {inputs.owner.last_name} {inputs.owner.first_name} {inputs.owner.middle_name}<br/>
      <b>Телефон:</b> {inputs.owner.phone || '-'}<br/>
      <b>Додатковий телефон:</b> {inputs.owner.second_phone || '-'}<br/>
      <b>Офісній телефон:</b> {inputs.owner.office_phone || '-'}<br/>
      <b>E-mail:</b> {inputs.owner.email || '-'}<br/>
      <br/>
      <Alert severity="info">
        Якщо Ви помітили помилку в анкетних даних - зверніться, будь ласка, до служби для їх зміни.
      </Alert>
    </div>
  );
}