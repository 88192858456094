import Login from 'pages/auth/login';
import Password from 'pages/auth/password';
import SetPassword from 'pages/auth/set_password';
import { IAuthRoute, IStartRoute } from './types';
import { menu_routes__corp_admin } from './adminCorp';
import { menu_routes__corp } from './corp';
import { menu_routes__driver } from './driver';
import CorpLayoutMenu from 'layout/main/menu/CorpLayoutMenu';
import DriverLayoutMenu from 'layout/main/menu/DriverLayoutMenu';
import corpRedirect from './redirect_page/corp_redirect';
import driverRedirect from './redirect_page/driver_redirect';
import BaseLayoutMenu from 'layout/main/menu/BaseLayoutMenu';
import { BusinessCenterOutlined, SettingsOutlined, AssignmentOutlined, DriveEtaOutlined, GavelOutlined } from '@mui/icons-material';
import { menu_routes__settings } from './settings';
import { red, cyan, green, blueGrey, yellow, orange, grey, lightBlue, brown } from '@mui/material/colors';
import ATPRedirect from './redirect_page/atp_redirect';
import { menu_routes__atp } from './atp';
import ATPLayoutMenu from 'layout/main/menu/ATPLayoutMenu';
import { EmojiTransportationOutlined, ExtensionOutlined, MonetizationOnOutlined } from '@mui/icons-material';
import { menu_routes__admin } from './admin';
import { menu_routes__finance } from './finance';
import defaultComponentsProps from 'defaultComponentsProps';
import { menu_routes__lawyer } from './lawyer';


export const routes = {
  login: "/login",
  password: "/password",
  set_password: "/set-password/:hash",
}

export const auth_routes:IAuthRoute[] = [
  {
    path: routes.login,
    name: "Вхід",
    component: Login
  },{
    path: routes.password,
    name: "Відновлення пароля",
    component: Password
  },{
    path: routes.set_password,
    name: "Відновлення пароля",
    component: SetPassword
  }
];


export const start_routes:IStartRoute[] = [
  {
    start_path: "/admin/admin",
    key: "admin",
    name: "Адміністратори",
    icon: ExtensionOutlined,
    firstPage: {
      path: "/admin/admin/"
    },
    children: menu_routes__admin,
    layoutMenu: BaseLayoutMenu,
    permissions: ["admin"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: grey[800]
          },
          secondary: {
            main: red[700]
          },
        } : {
          primary: {
            main: grey[700]
          },
          secondary: {
            main: red[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/finance",
    key: "finance",
    name: "Фінанси",
    icon: MonetizationOnOutlined,
    firstPage: {
      path: "/finance/"
    },
    children: menu_routes__finance,
    layoutMenu: BaseLayoutMenu,
    permissions: ["finance"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: lightBlue[800],
          },
          secondary: {
            main: blueGrey[700]
          },

        } : {
          primary: {
            main: lightBlue[900]
          },
          secondary: {
            main: blueGrey[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/admin/corp",
    key: "admin_corp",
    name: "Корпоративний відділ",
    icon: AssignmentOutlined,
    firstPage: {
      path: "/admin/corp/"
    },
    children: menu_routes__corp_admin,
    layoutMenu: BaseLayoutMenu,
    permissions: ["admin_corp"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: green[800]
          },
          secondary: {
            main: red[700]
          },
        } : {
          primary: {
            main: green[900]
          },
          secondary: {
            main: red[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/lawyer",
    key: "lawyer",
    name: "Юридичний відділ",
    icon: GavelOutlined,
    firstPage: {
      path: "/lawyer/"
    },
    children: menu_routes__lawyer,
    layoutMenu: BaseLayoutMenu,
    permissions: ["lawyer"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: brown[700]
          },
          secondary: {
            main: red[700]
          },
        } : {
          primary: {
            main: brown[400]
          },
          secondary: {
            main: red[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/atp/:id",
    key: "atp",
    name: "Кабінет АТП",
    icon: EmojiTransportationOutlined,
    firstPage: {
      path: "/atp/",
      component: ATPRedirect
    },
    children: menu_routes__atp,
    layoutMenu: ATPLayoutMenu,
    permissions: ["atp"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: orange[800]
          },
          secondary: {
            main: red[800]
          },
        } : {
          primary: {
            main: orange[900]
          },
          secondary: {
            main: red[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/driver/:id",
    key: "driver",
    name: "Кабінет водія",
    icon: DriveEtaOutlined,
    firstPage: {
      path: "/driver/",
      component: driverRedirect
    },
    children: menu_routes__driver,
    layoutMenu: DriverLayoutMenu,
    permissions: ["driver"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: yellow[800]
          },
          secondary: red,
        } : {
          primary: {
            main: yellow[900]
          },
          secondary: {
            main: red[900]
          },
        }),
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/corp/:company",
    key: "corp",
    name: "Кабінет клієнта",
    icon: BusinessCenterOutlined,
    firstPage: {
      path: "/corp/",
      component: corpRedirect
    },
    children: menu_routes__corp,
    layoutMenu: CorpLayoutMenu,
    permissions: ["corp"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: cyan[800]
          },
          secondary: red,
        } : {
          primary: {
            main: cyan[900]
          },
          secondary: {
            main: red[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  },
  {
    start_path: "/admin/settings",
    key: "settings",
    name: "Налаштування",
    icon: SettingsOutlined,
    firstPage: {
      path: "/admin/settings/"
    },
    children: menu_routes__settings,
    layoutMenu: BaseLayoutMenu,
    permissions: ["settings"],
    theme: (mode) => ({
      palette: {
        mode,
        ...(mode === "light" ? {
          primary: {
            main: red[800]
          },
          secondary: {
            main: blueGrey[800]
          },
        } : {
          primary: {
            main: red[900]
          },
          secondary: {
            main: blueGrey[900]
          },
        })
      },
      components: defaultComponentsProps,
    })
  }
]