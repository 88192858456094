import { AccessTimeOutlined } from "@mui/icons-material";
import {
	Grid,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	styled
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, getDate, isSameMonth, isToday, isWithinInterval } from "date-fns";
import ukLocale from "date-fns/locale/uk";
import { WEEK_DAYS } from "../defaults";
import { DateRange, NavigationAction } from "../types";
import {
	chunks,
	getDaysInMonth,
	inDateRange,
	isEndOfRange,
	isRangeSameDay,
	isStartOfRange
} from "../utils";
import Day from "./Day";
import Header from "./Header";


const WeekDaysContainer = styled(Grid)(({ theme }) => ({
	marginTop: 10,
	paddingLeft: 30,
	paddingRight: 30
}))

const DaysContainer = styled(Grid)(({ theme }) => ({
	paddingLeft: 15,
	paddingRight: 15,
	marginTop: 15,
	marginBottom: 20
}))

const TimeContainer = styled(Grid)(({ theme }) => ({
	paddingLeft: 25,
	paddingRight: 25,
	paddingBottom: 15,
	marginTop: 'auto'
}))

interface MonthProps{
	dateValue: Date|undefined;
	value: Date;
	marker: symbol;
	dateRange: DateRange;
	minDate: Date;
	maxDate: Date;
	withTime: boolean;
	navState: [boolean, boolean];
	setValue: (date: Date) => void;
	onChange: (date: Date|undefined) => void;
	helpers: {
		inHoverRange: (day: Date) => boolean;
	};
	handlers: {
		onDayClick: (day: Date) => void;
		onDayHover: (day: Date) => void;
		onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
	};
}

export default function Month(props:MonthProps){
	const {
		helpers,
		handlers,
		dateValue,
		value: date,
		dateRange,
		marker,
		setValue: setDate,
		onChange,
		minDate,
		maxDate,
		withTime
	} = props;

	const [back, forward] = props.navState;

	const handleTimeChange = (date: any) => {
		if(date instanceof Date && !isNaN(date.getTime())){
			onChange(date as Date);
		}else{
			onChange(undefined);
		}
	};

	return (
		<Paper square elevation={0} sx={{width: 290, background: "transparent"}}>
			<Grid container direction="column" style={{height:"100%"}}>
				<Header
					date={date}
					setDate={setDate}
					nextDisabled={!forward}
					prevDisabled={!back}
					onClickPrevious={() =>
						handlers.onMonthNavigate(marker, NavigationAction.Previous)
					}
					onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
				/>

				<WeekDaysContainer
					item
					container
					direction="row"
					justifyContent="space-between"
				>
					{WEEK_DAYS.map(day => (
						<Typography color="textSecondary" key={day} variant="caption">
							{day}
						</Typography>
					))}
				</WeekDaysContainer>

				<DaysContainer
					item
					container
					direction="column"
					justifyContent="space-between"
				>
					{chunks(getDaysInMonth(date), 7).map((week, idx) => (
						<Grid key={idx} container direction="row" justifyContent="center">
							{week.map(day => {
								const isStart = isStartOfRange(dateRange, day);
								const isEnd = isEndOfRange(dateRange, day);
								const isRangeOneDay = isRangeSameDay(dateRange);
								const highlighted =
									inDateRange(dateRange, day) || helpers.inHoverRange(day);
								
								return (
									<Day
										key={format(day, "MM-dd-yyyy")}
										filled={isStart || isEnd}
										outlined={isToday(day)}
										highlighted={highlighted && !isRangeOneDay}
										disabled={
											!isSameMonth(date, day) ||
											!isWithinInterval(day, {start: minDate, end: maxDate})
										}
										startOfRange={isStart && !isRangeOneDay}
										endOfRange={isEnd && !isRangeOneDay}
										onClick={() => handlers.onDayClick(day)}
										onHover={() => handlers.onDayHover(day)}
										value={getDate(day)}
									/>
								);
							})}
						</Grid>
					))}
				</DaysContainer>

				{ withTime ? (
					<TimeContainer item>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
							<TimePicker
								renderInput={(params) => <TextField variant="standard" {...params} />}
								ampm={false}
								value={dateValue || null}
								onChange={handleTimeChange}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<AccessTimeOutlined color={'action'} />
										</InputAdornment>
									),
								}}
							/>
						</LocalizationProvider>
					</TimeContainer>
				):null}
			</Grid>
		</Paper>
	);
};