import MapPageDriver from 'components/mapPageDriver';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export default function MapPage() {
  const match:any = useRouteMatch();

  return (
    <MapPageDriver
      atpID={match.params.id}
      availableFilteringOrders={true}
    />
  );
}