import * as React from "react";
import {
	IconButton,
	styled,
	Typography
} from "@mui/material";
import { combine } from "../utils";

interface DayProps{
	filled?: boolean;
	outlined?: boolean;
	highlighted?: boolean;
	disabled?: boolean;
	startOfRange?: boolean;
	endOfRange?: boolean;
	onClick?: () => void;
	onHover?: () => void;
	value: number | string;
}


const Root = styled("div")(({ theme }) => ({
	display: "flex",
  
  "&.leftBorderRadius": {
		borderRadius: "50% 0 0 50%"
	},
  "&.rightBorderRadius": {
		borderRadius: "0 50% 50% 0"
	},
  "&.highlighted": {
		backgroundColor: theme.palette.action.hover
	},
}));

const DayButton = styled(IconButton)(({ theme }) => ({
	height: 36,
	width: 36,
	padding: 0,
  
  "&.outlined": {
		border: `1px solid ${theme.palette.primary.dark}`
	},
  "&.filled": {
		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		},
		backgroundColor: theme.palette.primary.dark
	},
}));

const DayText = styled(Typography)(({ theme }) => ({
	lineHeight: 1.6,
  
  "&.contrast": {
		color: theme.palette.primary.contrastText,
		"&.secondary": {
			color: theme.palette.primary.contrastText,
		},
	},
  "&.secondary": {
		color: theme.palette.text.secondary
	},
}));

export default function Day(props:DayProps) {
	return (
		<Root
			className={combine(
				props.startOfRange && "leftBorderRadius",
				props.endOfRange && "rightBorderRadius",
				!props.disabled && props.highlighted && "highlighted"
			)}>
			<DayButton
				className={combine(
					!props.disabled && props.outlined && "outlined",
					!props.disabled && props.filled && "filled"
				)}
				disabled={props.disabled}
				onClick={props.onClick}
				onMouseOver={props.onHover}>
				<DayText
					className={combine(
						!props.disabled && props.filled && "contrast",
						!props.disabled && "secondary",
					)}
					variant="body2">
					{props.value}
				</DayText>
			</DayButton>
		</Root>
	);
};
