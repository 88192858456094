import { ArrowRightAlt, CloseOutlined } from "@mui/icons-material";
import {
	Container,
	Divider,
	Grid,
	Hidden,
	IconButton,
	Paper,
	TextField,
	Typography,
	styled
} from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { differenceInCalendarMonths, format } from "date-fns";
import ukLocale from "date-fns/locale/uk";
import { MARKERS } from "..";
import { TEXTS } from "../defaults";
import { DateRange, DefinedRange, NavigationAction, Setter } from "../types";
import DefinedRanges from "./DefinedRanges";
import Month from "./Month";


interface MenuProps{
	dateRange: DateRange;
	ranges: DefinedRange[];
	minDate: Date;
	maxDate: Date;
	withTime: boolean;
	firstMonth: Date;
	secondMonth: Date;
	setFirstMonth: Setter<Date>;
	setSecondMonth: Setter<Date>;
	setDateRange: Setter<DateRange>;
	helpers: {
		inHoverRange: (day: Date) => boolean;
	};
	handlers: {
		onDayClick: (day: Date) => void;
		onDayHover: (day: Date) => void;
		onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
	};
	closeDateRange: () => void;
}

const StyledHeader = styled(Grid)(({ theme }) => ({
	padding: "20px 70px",
	"& .headerItem": {
		flex: 1,
		textAlign: "center"
	}
}))

const StyledDivider = styled("div")(({ theme }) => ({
	borderLeft: `1px solid ${theme.palette.action.hover}`
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: 0
}))

export default function Menu(props:MenuProps){
	const {
		ranges,
		dateRange,
		minDate,
		maxDate,
		withTime,
		firstMonth,
		setFirstMonth,
		secondMonth,
		setSecondMonth,
		setDateRange,
		helpers,
		handlers
	} = props;
	const { startDate, endDate } = dateRange;
	const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
	const commonProps = { dateRange, minDate, maxDate, withTime, helpers, handlers };
	
	const renderDesktop = () => {
		return (
			<Grid container direction="row" wrap="nowrap">
				<Grid>
					<StyledHeader container alignItems="center">
						<Grid item className="headerItem">
							<Typography variant="subtitle1">
								{startDate ? format(startDate, "dd.MM.yyyy") : TEXTS.start}
							</Typography>
						</Grid>
						<Grid item className="headerItem">
							<ArrowRightAlt color="action" />
						</Grid>
						<Grid item className="headerItem">
							<Typography variant="subtitle1">
								{endDate ? format(endDate, "dd.MM.yyyy") : TEXTS.end}
							</Typography>
						</Grid>
					</StyledHeader>
					<Divider />
					<Grid container direction="row" justifyContent="center" wrap="nowrap">
						<Month
							{...commonProps}
							dateValue={dateRange.startDate}
							value={firstMonth}
							setValue={setFirstMonth}
							navState={[true, canNavigateCloser]}
							marker={MARKERS.FIRST_MONTH}
							onChange={(date)=>{
								setDateRange({
									...dateRange,
									startDate: date as Date
								})
							}}
						/>
						<StyledDivider />
						<Month
							{...commonProps}
							dateValue={dateRange.endDate}
							value={secondMonth}
							setValue={setSecondMonth}
							navState={[canNavigateCloser, true]}
							marker={MARKERS.SECOND_MONTH}
							onChange={(date)=>{
								setDateRange({
									...dateRange,
									endDate: date as Date
								})
							}}
						/>
					</Grid>
				</Grid>
				<StyledDivider />
				<Grid>
					<DefinedRanges
						selectedRange={dateRange}
						ranges={ranges}
						setRange={setDateRange}
					/>
				</Grid>
			</Grid>
		)
	}
	const renderMobile = () => {
		return (
			<Container sx={{p: 3}}>
				<Grid container direction="row" wrap="nowrap" spacing={3}>
					<Grid item>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
							<DatePicker
								renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
								inputFormat="dd.MM.yyyy"
								label={TEXTS.from}
								value={dateRange.startDate}
								onChange={(date)=>{
									setDateRange({
										...dateRange,
										startDate: date as Date
									})
								}}
							/>

							{ withTime ? (
								<TimePicker
									renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
									ampm={false}
									value={dateRange.startDate}
									onChange={(date)=>{
										setDateRange({
											...dateRange,
											startDate: date as Date
										})
									}}
								/>
							):null}
						</LocalizationProvider>
					</Grid>

					<Grid item>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
							<DatePicker
								renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
								inputFormat="dd.MM.yyyy"
								label={TEXTS.to}
								value={dateRange.endDate}
								onChange={(date)=>{
									setDateRange({
										...dateRange,
										endDate: date as Date
									})
								}}
							/>

							{ withTime ? (
								<TimePicker
									renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
									ampm={false}
									value={dateRange.endDate}
									onChange={(date)=>{
										setDateRange({
											...dateRange,
											endDate: date as Date
										})
									}}
								/>
							):null}
						</LocalizationProvider>
					</Grid>
				</Grid>
				<Grid container direction="row" wrap="nowrap" spacing={0} sx={{mx: -2}}>
					<Grid>
						<DefinedRanges
							selectedRange={dateRange}
							ranges={ranges}
							setRange={setDateRange}
						/>
					</Grid>
				</Grid>
				<CloseButton
					color="default"
					onClick={props.closeDateRange}
				>
          <CloseOutlined />
        </CloseButton>
			</Container>
		);
	}
	return (
		<Paper elevation={0} sx={{background: 'transparent'}}>
			<Hidden mdDown>{renderDesktop()}</Hidden>
			<Hidden mdUp>{renderMobile()}</Hidden>
		</Paper>
	);
};