import AuthModel from "models/AuthModel";
import { API_CONFIG } from "config";
const download = require("downloadjs");

export interface IResponse{
    status: number,
    body: any|null
}

class _requests{
    _with_error_message = true;
    _with_loader = true;

    public withoutErrorMessage = () => {
        let r = new _requests()
        r._with_error_message = false;
        return r;
    }

    public withoutLoader = () => {
        let r = new _requests()
        r._with_loader = false;
        return r;
    }

    private _response = async (url:string, response:Response):Promise<IResponse> => {
        let body:object|string|null = null;
        try {
            body = await response.clone().json()
        }
        catch (e) {
            body = await response.text();
        }

        if(url !== '/auth/session/' && (response.status === 403 || response.status === 401)){
            AuthModel.logout();
        }

        return {
            status: response.status,
            body: body
        }
    }

    private _auth_headers = () => {
        let headers:Record<string, string> = {
            'API-KEY': API_CONFIG.api_key
        }
        let session = AuthModel.session();
        if(session){
            headers['Authorization'] = `Session ${session}`;
        }
        return headers;
    }

    private _headers = () => {
        let headers:Record<string, string> = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        return {...this._auth_headers(), ...headers};
    }

    private _get_full_url = (url:string, params:any|null):string => {
        let _url = `${API_CONFIG.api_base_url}${url}`;
        if(params !== null){
            let str_params = Object.keys(params).filter((key)=>params[key]!==undefined).map(function(key) {
                return key + '=' + params[key];
            }).join('&');
            _url += "?" + str_params
        }
        return _url;
    }

    private _do_fetch = async (url:string, f:Promise<Response>):Promise<IResponse|null> => {
        let promise = new Promise<IResponse>(async (resolve, reject) => {
            let response:Response = new Response(null);
            try{
                response = await f;
            } catch (e:any) {
              if(e.name === "AbortError"){
                reject(null)
                return;
              }
            }

            // @ts-ignore
            response._with_error_message = this._with_error_message
            // @ts-ignore
            response._with_loader = this._with_loader

            if(response.status >= 200 && response.status < 300){
                resolve(response);
            }else{
                reject(await this._response(url, response));
            }
        })
        return await promise;
    }

    private _fetch = async (url:string, params:any|null):Promise<any> => {
        const res = fetch(url, params);
        const r = this._do_fetch(url, res)
        var evt = new CustomEvent("StartRequest", {detail: {
          response: r,
          requests: this,
        }});
        window.dispatchEvent(evt);
        return await r;
    }

    
    public download = async (url:string, params:any|null=null):Promise<Response> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            headers: this._headers()
        });
        const blob = await res.blob();
        const disposition = res.headers.get('content-disposition');
        let filename = 'no-name';

        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        
        download(blob, filename, blob.type);
        return res;
    }
    
    public blob = async (url:string, params:any|null=null):Promise<Blob> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            headers: this._headers()
        });
        return await res.blob();
    }
    
    public get = async (url:string, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            headers: this._headers(),
            signal: signal
        })
        return await this._response(url, res)
    }
    public getAll = async (url:string, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        let res = await this.get(url, {
            ...params,
            'offset': 0
        }, signal);
        if(res?.body?.count && res?.body?.results){
            let count = res.body.results.length;
            while(res.body.count > count){
                const _res = await this.get(url, {
                    ...params,
                    'offset': count
                }, signal);
                res.body.results = res.body.results.concat(_res.body.results)
                count = res.body.results.length;
            }
        }
        return await Promise.resolve(res);
    }

    public post = async (url:string, body:object|null=null, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'POST',
            body: body ? JSON.stringify(body) : null,
            headers: this._headers(),
            signal: signal
        })
        return await this._response(url, res)
    }
    public form = async (url:string, body:any, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        let formData = new FormData();
        if(body){
            for (let key in body) {
                formData.append(key, body[key]);
            }
        }
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'POST',
            body: formData,
            headers: this._auth_headers(),
            signal: signal
        })
        return await this._response(url, res)
    }
    public put = async (url:string, body:object|null=null, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'PUT',
            body: body ? JSON.stringify(body) : null,
            headers: this._headers(),
            signal: signal
        })
        return await this._response(url, res)
    }
    public patch = async (url:string, body:object|null=null, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'PATCH',
            body: body ? JSON.stringify(body) : null,
            headers: this._headers(),
            signal: signal
        })
        return await this._response(url, res)
    }
    public delete = async (url:string, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'DELETE',
            headers: this._headers(),
            signal: signal
        })
        return await this._response(url, res)
    }
}

const requests = new _requests();
export default requests