import React from 'react';
// import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

export default function PrintTable(props: { items: any}) {
  const { items } = props;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>№ Зам.</th>
            <th>Адреса</th>
            <th>Дата</th>
            <th>Час</th>
            <th>Клас</th>
            <th>Позивний</th>
            <th>Водій</th>
            {/* <th>Штраф, грн</th> */}
          </tr>
        </thead>
        <tbody>
          { items.map((item:any, index:number) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.addresses.length ? item.addresses[0] : null}</td>
              <td>
                <Moment
                  date={item.date}
                  format="DD.MM.YY"
                />
              </td>
              <td>
                <Moment
                  date={item.date}
                  format="HH:mm"
                />
              </td>
              <td>{item.tariff_class}</td>
              <td>{item.call_sign}</td>
              <td>{item.driver}</td>
              {/* <td align="right" style={{whiteSpace: 'nowrap'}}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} /></td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}