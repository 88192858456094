import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { PaperBox } from './styles';
import requests from 'api/requests';
import { Container, Grid, FormControl, TextField, IconButton, Divider } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import StatusList from './status_list';
import { useSnackbar } from 'notistack';
import { ExtraField, IStatus } from './types';
import InstanceModal, { InstanceModalProps } from './instanceModal';

interface IProps{
  apiUrl: string;
  extraFields?: ExtraField[];
  instanceModelComponent?: (props: InstanceModalProps) => JSX.Element;
}

export default function BaseStatusDict(props:IProps) {
  const [newStatus, setNewStatus] = useState("");
  const [instance, setInstance] = useState<IStatus|null>(null);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const RealInstanceModelComponent = useMemo(()=>props.instanceModelComponent || InstanceModal, [props.instanceModelComponent])

  const load = useCallback(() => {
    requests.get(props.apiUrl).then((r:any) => {
      setStatuses(r.body);
    })
  }, [props.apiUrl])
  useEffect(() => load(), [load]);

  const Add = () => {
    let data:IStatus = {
      name: newStatus,
      is_final: false,
    };
    requests.post(props.apiUrl, data).then((r:any) => {
      setNewStatus("");
      enqueueSnackbar(`Статус "${newStatus}" додано`, {variant: 'success'});
      load();
    })
  }

  const saveOrdering = (_statuses:IStatus[]) => {
    let queries:Promise<any>[] = [];
    _statuses.map((s, index) => {
      queries.push(requests.patch(`${props.apiUrl}${s.id}/`, {
        sort: index
      }))
      return s;
    })
    Promise.all(queries).then(()=>{
      enqueueSnackbar("Порядок збережено", {variant: 'success'});
    })
  }

  return (
    <div>
      <Container maxWidth="sm">
        <PaperBox>
          <form
            onSubmit={(e:any) => {
              e.preventDefault();
              Add();
            }}
          >
            <Grid container alignItems="flex-start" wrap="nowrap">
              <Grid item style={{flexGrow: 1}}>
                <FormControl fullWidth>
                  <TextField
                    label="Назва статуса"
                    placeholder="Вкажіть назву нового статуса"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={newStatus}
                    onChange={(event) => {
                      setNewStatus(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton type="submit" color="secondary">
                  <AddOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </form>
          
          <br/>
          <Divider/>
          <br/>
          <StatusList
            statuses={statuses}
            extraFields={props.extraFields}
            onEdit={(status) => setInstance(status)}
            onReorder={(statuses) => {
              setStatuses(statuses);
              saveOrdering(statuses);
            }}
          />

          
        </PaperBox>
      </Container>

      { instance !== null ? (
        <RealInstanceModelComponent
          apiUrl={props.apiUrl}
          extraFields={props.extraFields}
          instance={instance}
          onClose={()=>setInstance(null)}
          onDelete={()=>{
            setInstance(null);
            load();
          }}
          onSave={()=>{
            setInstance(null);
            load();
          }}
        />
      ): null }
    </div>
  );
}