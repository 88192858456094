import { styled, Tooltip, Typography, TypographyProps } from "@mui/material";

interface IProps{
  value: number|null
}

interface RankTypographyProps extends TypographyProps {
  warning?: boolean;
  error?: boolean;
}
const RankColor = styled(Typography, {
  shouldForwardProp: (prop) => !['warning', 'error'].includes(prop.toString()),
})<RankTypographyProps>(({ theme, warning, error }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.success.main : theme.palette.success.main,
  fontSize: 'inherit',
  display: 'inline',

  ...(warning&&{
    color: theme.palette.mode === 'light' ? theme.palette.warning.main : theme.palette.warning.light
  }),
  ...(error&&{
    color: theme.palette.mode === 'light' ? theme.palette.error.main : theme.palette.error.light,
    fontWeight: "bold"
  }),
}));

export default function Rank(props:IProps){
  const {
    value
  } = props;
  if(value === null || value === undefined){
    return (
      <Tooltip title="Рейтинг">
        <Typography color='textSecondary' sx={{
          fontSize: '0.7em',
          display: 'inline',
        }}>N/A</Typography>
      </Tooltip>
    )
  }
  return (
    <Tooltip title="Рейтинг">
      <RankColor
        error={value<75}
        warning={value>=75 && value<85 }
      >{value}</RankColor>
    </Tooltip>
  );
}