import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import OrderIDLink from "components/OrderIDLink";
import { forwardRef, useMemo } from "react";
import NumberFormat from "react-number-format";
import { OnlineTotalBillSerializer } from "./types";
import { format } from "date-fns";

interface IProps{
  items: OnlineTotalBillSerializer[];
}

interface ITotalProps{
  count: number;
  total: number;
}

function Total(props:ITotalProps){
  const {
    count,
    total,
  } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={3}>Всього ({count})</TableCell>
        <TableCell align="right">
          <NumberFormat
            value={total}
            displayType={'text'}
            thousandSeparator={' '}
          />
        </TableCell>
        <TableCell colSpan={3}></TableCell>
      </TableRow>
    </TableHead>
  )
}

function TableItems(props:IProps, ref: any){
  const {
    items,
  } = props

  const total:number|null = useMemo(()=>{
    if(!items.length){
      return null;
    }
    return items.map((i)=>(i.paysum + i.drivertip)).reduce((a,b)=>a+b);
  }, [items])

  if(total === null){
    return (
      <Typography variant="h6" color="textSecondary" align="center" sx={{py: 3}}>
        Замовлення відсутні
      </Typography>
    )
  }

  return (
    <TableContainer sx={{mt: 3, whiteSpace: "nowrap"}}>
      <Table size="small" ref={ref} sx={{
        [`@media print`]: {
          "*":{
            color: "#000",
          },
          "& button": {
            display: "none",
          }
        }
      }}>
        <TableHead>
          <TableRow>
            <TableCell>Замовлення</TableCell>
            <TableCell>Дата сплати</TableCell>
            <TableCell>Дата закриття</TableCell>
            <TableCell align="right">Вартість(з чайовими)</TableCell>
            <TableCell>Позивний</TableCell>
            <TableCell>Водій</TableCell>
            <TableCell>ФОП</TableCell>
          </TableRow>
        </TableHead>
        <Total count={items.length} total={total || 0}/>
        <TableBody>
          {items.map((item) => (
            <TableRow hover key={item.id}>
              <TableCell><OrderIDLink OrderID={item.id} /></TableCell>
              <TableCell>{format(item.getresultdatetime, "dd.MM.yyyy HH:mm:ss")}</TableCell>
              <TableCell>{format(item.lastfolderchangedatetime, "dd.MM.yyyy HH:mm:ss")}</TableCell>
              <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                <NumberFormat
                  value={item.paysum + item.drivertip}
                  displayType={'text'}
                  thousandSeparator={' '}
                />
              </TableCell>
              <TableCell sx={{whiteSpace: "nowrap"}}>{item.callsign.name}</TableCell>
              <TableCell>
                {[
                  item.driver.lastname,
                  item.driver.firstname,
                  item.driver.middlename,
                ].filter((i)=>!!i).join(" ")}
              </TableCell>
              <TableCell>{item.corrs_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        {!!items.length && (
          <Total count={items.length} total={total || 0}/>
        )}
      </Table>
    </TableContainer>
  )
}

export default forwardRef(TableItems)