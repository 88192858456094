export const getTotal = (items:any[]) => {
  let _total = {} as any;
    if(items.length){
      items.map((item:any) => {
        Object.keys(item).map((key:string) => {
          if(typeof item[key] == "number"){
            if(!_total[key]){
              _total[key] = 0;
            }
            _total[key] += item[key];
          }else{
            _total[key] = item[key];
          }
          return key;
        })
        return item;
      })
    }
    return _total;
}