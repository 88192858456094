import { Stepper, styled, Table, TableCell, TableCellProps, Typography, TypographyProps } from "@mui/material";
import { TablePaperBox } from "components/styled";


export const PaperBox = styled(TablePaperBox)(({ theme }) => ({
  "& td, & th": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));


interface TableCellBorderProps extends TableCellProps {
  BRight?: boolean;
}

export const TableCellBorder = styled(TableCell, {
  shouldForwardProp: (prop) => !['BRight'].includes(prop.toString()),
})<TableCellBorderProps>(({ theme, BRight }) => ({
  ...(BRight && {
      borderRightWidth: 1,
      borderRightStyle: "solid",
      borderRightColor: theme.palette.divider
  }),
}));

export const TableBox = styled(Table)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: theme.palette.divider
}));

export const RouteBox = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  position: 'relative',
  paddingBottom: theme.spacing(6),
  pageBreakAfter: 'always',
  "& .deleteButton": {
    position: 'absolute',
    right: 0,
    top: 0,
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "&:hover": {
    "& .deleteButton": {
      opacity: 1
    }
  }
}));

export const StepperBox = styled(Stepper)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(),
}));

interface PriceTypographyProps extends TypographyProps {
  diffPrice?: boolean;
}

export const PriceTypography = styled(Typography, {
  shouldForwardProp: (prop) => !['diffPrice'].includes(prop.toString()),
})<PriceTypographyProps>(({ theme, diffPrice }) => ({
  lineHeight: 1,
  opacity: 0.5,
  ...(diffPrice && {
    fontWeight: 'bold',
    opacity: 1
  }),
}));

interface ColorTypographyProps extends TypographyProps {
  red?: boolean;
  green?: boolean;
}

export const ColorTypography = styled(Typography, {
  shouldForwardProp: (prop) => !['red', 'green'].includes(prop.toString()),
})<ColorTypographyProps>(({ theme, red, green }) => ({
  ...(red && {
    color: theme.palette.error.dark
  }),
  ...(green && {
    color: theme.palette.success.dark
  }),
}));