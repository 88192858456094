import { Link, LinkProps } from "@mui/material";

interface IProps extends LinkProps{
  driverID?: number|null;
}

export default function DriverLink(props:IProps){
  const {
    driverID,
    onClick,
    ...otherProps
  } = props
  
  return (
    <Link
      {...otherProps}
      href={`#driver-${driverID}`}
      onClick={(e)=>{
        if(!!onClick) onClick(e);
        e.stopPropagation();
      }}
    />
  )
}