import { FilteredTableFilter } from "components/FilteredTable/types"
import { TextFilter } from "components/FilteredTable/filters";

const filters:FilteredTableFilter[] = [
  {
    title: 'Позивний',
    key: 'callsign',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        callsignid__name: value
      } : null
    ),
  },
  {
    title: 'Водій',
    key: 'driver',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        driverid__peopleid__lastname__icontains: value
      } : null
    ),
  },
  {
    title: 'Телефон пасажира',
    key: 'client_phone',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        telephoneid__number__icontains: value
      } : null
    ),
  },
  {
    title: 'ID Замовлення',
    key: 'orderid',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        orderid_id: value
      } : null
    ),
  },
]

export default filters;