import { Link, Step, StepLabel, TableBody, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { TariffClass } from "components/map/types";
import { format, setMinutes } from "date-fns";
import React, { useCallback } from "react";
import ClassPrices from "./classPrices";
import { RouteBox, StepperBox, TableBox, TableCellBorder } from "./styles";
import { IData, IRoute, IWeekDay } from "./types";

interface IProps{
  weekDays: IWeekDay[];
  carClasses: TariffClass[];
  routes: IRoute[];
  minutes: number[];
  data: IData;
  onDeleteRoute: (route:IRoute)=>void;
  getDataKey: (date:Date)=>string;
  onPriceCellClick: (route:IRoute, key:string)=>void;
}

export default function DataTable(props:IProps){
  const {
    weekDays,
    carClasses,
    routes,
    minutes,
    data,
    onDeleteRoute,
    getDataKey,
    onPriceCellClick
  } = props;

  const getPriceData = useCallback((routeID:number, dateTime:Date, minute:number)=>{
    return data?.[routeID]?.[getDataKey(setMinutes(dateTime, minute))]
  }, [data, getDataKey])

  return (
    <div>
      {routes.map((route)=>(
        <RouteBox key={route.id}>
          <StepperBox activeStep={-1} alternativeLabel className="stepper">
            {route.routepoints.map((route_point, index) => (
              <Step key={index} active>
                <StepLabel>
                  <div className="stepLabel">
                    <Typography variant="caption" component="div">
                      {route_point.title}
                    </Typography>
                    {route_point.description?(
                      <Typography variant="caption" color="textSecondary">
                        {route_point.description}
                      </Typography>
                    ):null}
                  </div>
                </StepLabel>
              </Step>
            ))}
          </StepperBox>
          
          <Typography
            variant="caption"
            align="center"
            component="p"
            className="printHide"
          >
            <Link href="#" color="inherit" onClick={(e:any)=>{
              e.preventDefault();
              onDeleteRoute(route);
            }}>Видалити маршрут</Link>
            <br/><br/>
          </Typography>

          <TableBox>
            <TableHead>
              <TableRow>
                <TableCellBorder BRight rowSpan={2}>Час</TableCellBorder>
                {weekDays.map((i)=>(
                  <TableCellBorder
                    BRight
                    key={i.title}
                    colSpan={carClasses.length}
                  >{i.title} <Typography variant="caption" color="textSecondary">({format(i.dateTime, "dd.MM")})</Typography></TableCellBorder>
                ))}
              </TableRow>
              <TableRow>
                {weekDays.map((weekDay)=>(
                  <React.Fragment key={weekDay.title}>
                    {carClasses.map((car_class, car_class_index)=>(
                      <Tooltip key={car_class.id} title={car_class.name}>
                        <TableCellBorder
                          BRight={car_class_index===(carClasses.length-1)}
                        >
                            {car_class.name.slice(0, 3)}
                        </TableCellBorder>
                      </Tooltip>
                    ))}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {minutes.map((minute, minute_index)=>(
                <TableRow key={minute} hover>
                  <TableCellBorder BRight>
                    {format(setMinutes(weekDays[0].dateTime, minute), "HH:mm")}
                  </TableCellBorder>
                  {weekDays.map((weekDay)=>(
                    <ClassPrices
                      key={weekDay.title}
                      prices={getPriceData(route.id, weekDay.dateTime, minute)}
                      carClasses={carClasses}
                      onClick={()=>{
                        onPriceCellClick(route, getDataKey(setMinutes(weekDay.dateTime, minute)))
                      }}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </TableBox>
        </RouteBox>
      ))}
    </div>
  )
}