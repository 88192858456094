import React from 'react';
import { Redirect } from "react-router-dom";
import { IMainLayoutProps } from 'layout/main/types';
import { LockOutlined } from '@mui/icons-material';
import { start_routes } from 'routes';
import AuthModel from 'models/AuthModel';
import { Button, Typography } from '@mui/material';
import LastLocationModel from 'models/LastLocationModel';


export default function MainRedirect(props:IMainLayoutProps) {
  let redirect_to = LastLocationModel.getPath();
  
  if(!redirect_to && AuthModel.permissions.length){
    const filtered_route = start_routes.filter((f) => {
      return AuthModel.check_all_permissions([f.key]);
    })
    if(filtered_route.length){
      redirect_to = filtered_route[0].firstPage.path;
    }
  }

  if(redirect_to){
    return (<Redirect to={redirect_to} />);
  }else{
    return (
      <div>
        <Typography variant="h1" color="textSecondary" align="center">
          <LockOutlined fontSize="large" />
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          {AuthModel.info.email}<br/>
          Ваш акаунт не має доступу до кабінету.
        </Typography>
        <Typography align="center">
          <br/>
          <Button
            onClick={()=>{
              AuthModel.logout();
            }}
            variant="contained"
            color="primary"
            component="span"
          >
            Вийти
          </Button>
        </Typography>
      </div>
    );
  }
}
