import React, { useState } from 'react';
import { TableRow, TableCell, TextField, Typography } from '@mui/material';
import { IMapStreet } from './types';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';


interface IRowProps{
  item:IMapStreet,
}

export default function Row(props: IRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [prevItem, setPrevItem] = useState<IMapStreet>({...props.item})
  const [item, setItem] = useState<IMapStreet>({...props.item})

  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    let _inputs = item as any;
    _inputs[name] = value;
    setItem({..._inputs});
  }

  const onUpdate = (itemName:string) => {
    if((prevItem as any)[itemName] === (item as any)[itemName]){
      return;
    }

    let params:any = {};
    params[itemName] = (item as any)[itemName];

    requests.patch(`/settings/map/street/${item.id}/`, params).then((r)=>{
      const name_to_message = params[itemName] ? ` "${params[itemName]}"` : "";
      enqueueSnackbar(`Збережено${name_to_message}`, {variant: 'success'});

      let _inputs = prevItem as any;
      _inputs[itemName] = params[itemName];
      setPrevItem({..._inputs});
    })
  }

  const renderInput = (itemName:string) => {
    return (
      <TextField
        fullWidth
        variant="outlined"
        size='small'
        label={(item as any)[itemName] !== (prevItem as any)[itemName] ? (
          <Typography color='error' component='span'>*</Typography>
        ) : undefined}
        value={(item as any)[itemName] || ''}
        onChange={handleChange(itemName)}
        onBlur={()=>onUpdate(itemName)}
      />
    )
  }

  return (
    <TableRow hover>
      <TableCell>
        {renderInput('name_ua')}
      </TableCell>
      <TableCell>
        {renderInput('name_ru')}
      </TableCell>
      <TableCell>
        {renderInput('name_en')}
      </TableCell>
    </TableRow>
  );
}