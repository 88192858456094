import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { ICollateBlanance } from './types';
import NumberFormat from 'react-number-format';

interface CompaniesTableProps {
  items: ICollateBlanance[];
}

export default function ItemsTable(props:CompaniesTableProps) {

  const money = (value:number) => (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
  );
  
  return (
    <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Код</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>Назва</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Баланс згідно історії</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Фактичний баланс</TableCell>
              <TableCell align="right" style={{whiteSpace: 'nowrap'}}>Різниця</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { props.items.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item.company?.code || (<Typography variant="inherit" color="textSecondary">-</Typography>)}</TableCell>
                <TableCell style={{whiteSpace: 'nowrap'}}>{item.company?.name || (<Typography variant="inherit" color="textSecondary">-</Typography>)}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.history_balance)}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.fact_balance)}</TableCell>
                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.diff_balance)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}