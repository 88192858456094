import AdminPage from 'components/adminPage';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export default function Users() {
  const match:any = useRouteMatch();

  return (
    <AdminPage
      apiUrl="/corp/company-permission/"
      parentID={match.params.company}
      parentFieldName="company"
      inputNames={{
        company: 'Компанія'
      }}
    />
  );
}