import { Button, IconButton, IconButtonProps, Popover } from "@mui/material";
import React from "react";

interface IProps extends IconButtonProps{
  onConfirm: ()=>void;
}

export default function ConfirmIconButton(props:IProps){
  const {
    onConfirm,
    onClick,
    ...buttonProps
  } = props;

  const [ confirmEl, setConfirmEl] = React.useState<HTMLButtonElement | null>(null);

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setConfirmEl(event.currentTarget);
    if(!!onClick){
      onClick(event);
    }
  }

  return (
    <React.Fragment>
      <IconButton
        {...buttonProps}
        onClick={onButtonClick}
      />

      <Popover
        open={!!confirmEl}
        anchorEl={confirmEl}
        onClose={()=>setConfirmEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={()=>{
            onConfirm();
            setConfirmEl(null);
          }}
        >Підтвердити</Button>
      </Popover>
    </React.Fragment>
  )
}