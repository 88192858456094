import { RatingFilter, SelectFilter, TextFilter } from "components/FilteredTable/filters";
import AccordionExcludeCheckboxListFilter from "components/FilteredTable/filters/AccordionExcludeCheckboxListFilter";
import AutocompleteFilter, { AutocompleteFilterItemProps } from "components/FilteredTable/filters/AutocompleteFilter";
import BooleanFilter from "components/FilteredTable/filters/BooleanFilter";
import { ExcludeCheckboxListValue } from "components/FilteredTable/filters/ExcludeCheckboxListFilter";
import FromToFilter from "components/FilteredTable/filters/FromToFilter";
import { FilteredTableFilter } from "components/FilteredTable/types"
import ArchiveFilter from "./archiveFilter";
import { IExtendData } from "./types";

interface DriverFilteredTableFilter extends FilteredTableFilter{
  disable_for_atp?: boolean
}

const filters:DriverFilteredTableFilter[] = [
  {
    title: 'Позивний',
    key: 'call_sign',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        call_sign: value
      } : null
    ),
  },
  {
    title: 'Без позивного',
    key: 'call_sign__isnull',
    component: BooleanFilter,
    filter: (value:boolean)=>(
      value ? {
        call_sign__isnull: true
      } : null
    ),
  },
  {
    title: 'Клас',
    key: 'tariff_class',
    component: SelectFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: "Всі",
          value: "",
        },
        ...(extendData?.tariff_class || [])
      ]
    }),
    filter: (value:number)=>(
      value ? {
        tariff_class: value
      } : null
    ),
  },
  {
    title: 'АТП',
    key: 'auto_group',
    component: AutocompleteFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: "Без АТП",
          option: (<b>Без АТП</b>),
          value: 0
        } as AutocompleteFilterItemProps,
        ...(extendData?.atp || [])
      ]
    }),
    filter: (value:number)=>{
      if(value === 0) return {
        auto_group__isnull: true
      };
      return value ? {
        auto_group__id: value
      } : null
    },
    disable_for_atp: true,
  },
  {
    title: 'Оцінка',
    key: 'rating',
    component: RatingFilter,
    filter: (value:number[])=>(
      value && value.length ? {
        rating_floor__in: value
      } : null
    ),
  },
  {
    title: 'Державний номер',
    key: 'state_number',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        state_number__icontains: value
      } : null
    ),
  },
  {
    title: 'Марка',
    key: 'brand',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        brand__name__icontains: value
      } : null
    ),
  },
  {
    title: 'Рік',
    key: 'year',
    component: FromToFilter,
    filter: (value:[number,number])=>{
      if(!value) return null;
      let res:any = {};
      if(value[0] !== null) res.year__gte = value[0]
      if(value[1] !== null) res.year__lte = value[1]
      return res
    },
  },
  {
    title: 'Додаткові послуги',
    key: 'status',
    component: AccordionExcludeCheckboxListFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: extendData?.services
    }),
    filter: (value:ExcludeCheckboxListValue)=>{
      if(!value) return null;
      const ids = value?.value || [];
      const exclude = !!value?.exclude;
      if(!ids.length) return null;
      
      if(exclude){
        return {
          service__not_in: ids.join(",")
        }
      }
      return {
        service__in: ids.join(",")
      }
    },
    sx: {
      mx: -2,
      mb: '1px'
    },
  },
  {
    title: 'Документи',
    key: 'documents',
    component: AccordionExcludeCheckboxListFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: extendData?.document_types
    }),
    filter: (value:ExcludeCheckboxListValue)=>{
      if(!value) return null;
      const ids = value?.value || [];
      const exclude = !!value?.exclude;
      if(!ids.length) return null;
      
      if(exclude){
        return {
          document__not_in: ids.join(",")
        }
      }
      return {
        document__in: ids.join(",")
      }
    },
    sx: {
      mx: -2,
      mt: '1px'
    }
  },
  {
    title: 'Архів',
    key: 'removed',
    component: ArchiveFilter,
    filter: (value:boolean)=>(
      {
        removed: !!value
      }
    ),
    excludeFilterCoount: true
  },
]

export default filters;