import { Components } from "@mui/material";

const defaultComponentsProps:Components = {
  MuiFormControl: {
    defaultProps: {
      variant: "standard"
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: "standard"
    }
  },
  MuiSelect: {
    defaultProps: {
      variant: "standard"
    }
  },
  MuiButton: {
    defaultProps: {
      color: "inherit"
    }
  },
  MuiIconButton: {
    defaultProps: {
      color: "inherit"
    }
  },
}

export default defaultComponentsProps