import React, { useMemo } from 'react';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, styled, TextField, Typography } from '@mui/material';
import { IDriverRequestStatus } from './types';
import { AddOutlined } from '@mui/icons-material';
import { IStatus } from 'pages/settings/statuses/base/types';

interface IProps {
  requestID?: number;
  statusTypes: IStatus[];
  onCreated: (instance:IDriverRequestStatus)=>void;

  newStatusInstance: IDriverRequestStatus|null;
  onChangeNewStatusInstance: (statusInstance:IDriverRequestStatus|null)=>void
  addNewStatus: (statusInstance:IDriverRequestStatus)=>Promise<any>;
}

const DividerBox = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
}));


export default function RequestStatusForm(props:IProps) {
  const {
    statusTypes,
    newStatusInstance,
    onChangeNewStatusInstance,
    addNewStatus
  } = props;

  const instance:IDriverRequestStatus = useMemo(() => {
    if(newStatusInstance !== null) return newStatusInstance;

    return {
      created: '',
      message: '',
      status: null,
      request: props.requestID,
    }
  }, [props.requestID, newStatusInstance])


  const handleChange = (name:keyof IDriverRequestStatus) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:keyof IDriverRequestStatus) => (value:any) => {
    instance[name] = value as never;
    const copy_instance = {...instance};
    if(copy_instance.message || copy_instance.status){
      onChangeNewStatusInstance(copy_instance);
    }else{
      onChangeNewStatusInstance(null);
    }
  }


  const add = () => {
    addNewStatus(instance).then((data)=>{
      props.onCreated(data);
      onChangeNewStatusInstance(null);
    })
  }

  if(!statusTypes.length){
    return null;
  }

  return (
    <div>
      <DividerBox/>
      
      <FormControl fullWidth>
        <InputLabel>Статус</InputLabel>
        <Select
          value={instance.status || ""}
          onChange={handleChange('status')}
        >
          {statusTypes.map((i)=>(
            <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin='normal'>
        <TextField
          label="Довільний коментар"
          multiline
          maxRows={4}
          value={instance.message}
          onChange={handleChange('message')}
        />
      </FormControl>
      
      {props.requestID ? (
        <Typography
          component="div"
          align="right"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddOutlined />}
            onClick={(e:any) => {
              e.preventDefault();
              add();
            }}
          >
            Додати
          </Button>
        </Typography>
      ):null}
    </div>
  );
}