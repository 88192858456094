import { InputLabel, Slider } from "@mui/material";
import React from "react";
import { FilteredTableFilterProps } from "../types";

export interface SliderFilterProps extends FilteredTableFilterProps{
  step?: number;
}

export default function SliderFilter(props:SliderFilterProps){
  return (
    <React.Fragment>
      <InputLabel shrink>
        {props.label}
      </InputLabel>
      <Slider
        value={props.value || [0, 100]}
        onChange={(event: any, newValue: number | number[]) => {
          props.onChange(newValue as number[]);
        }}
        valueLabelDisplay="auto"
        step={props.step}
      />
    </React.Fragment>
  )
}