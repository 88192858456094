import React, { useCallback, useMemo, useState } from "react";
import { Icon, IconProps, styled, Table, TableBody, TableCell, TableCellProps, TableHead, TableRow, Typography } from '@mui/material';
import { IChoice, IDriverRating } from "./types";
import NumberFormat from "react-number-format";
import DriverLink from "components/driverLink";
import Rank from "components/driver/rank";

interface IProps{
  items: IDriverRating[];
  choices: IChoice[];
  hideSubtitle?: boolean;
  hideRank?: boolean;
}


const HeadRow = styled(TableRow)(({ theme }) => ({
  "&>th": {
    verticalAlign: 'bottom',
    textAlign: 'center',
    lineHeight: 1,
    "& .material-icons": {
      fontSize: '1em'
    },
    "&>span": {
      textAlign: 'left'
    }
  }
}));


interface TableCellStyledProps extends TableCellProps {
  headStar?: boolean;
  vertical?: boolean;
  ordering?: boolean;
}

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => !['headStar', 'vertical', 'ordering'].includes(prop.toString()),
})<TableCellStyledProps>(({ theme, headStar, vertical, ordering }) => ({
  ...(headStar&&{
    textAlign: 'center',
  }),
  ...(vertical&&{
    "&>*": {
      display: 'inline',
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)',
      whiteSpace: 'nowrap',
    }
  }),
  ...(ordering&&{
    cursor: 'pointer'
  }),
}));

const StarsBox = styled("span")(({ theme }) => ({
  display: 'block',
  "&>span": {
    writingMode: 'horizontal-tb'
  },
  "&~br": {
    display: 'none'
  },
  "&>*": {
    display: 'inline',
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    whiteSpace: 'nowrap',
  }
}));

interface RotateIconProps extends IconProps {
  rotate?: boolean;
}

const RotateIcon = styled(Icon, {
  shouldForwardProp: (prop) => !['rotate'].includes(prop.toString()),
})<RotateIconProps>(({ theme, rotate }) => ({
  ...(rotate&&{
    transform: 'rotate(180deg)',
  }),
}));

type IOrderingFields = "rank"|"average"|"count"|"star"|"item";

interface IOrdering{
  field: IOrderingFields;
  desc?: boolean;
  value?: number;
}

export default function DataTable(props:IProps){
  const [ordering, setOrdering] = useState<IOrdering>({field: "average"});

  const {
    items,
    choices,
    hideSubtitle,
    hideRank
  } = props;

  const orderedItems:IDriverRating[] = useMemo(()=>{
    let _items:IDriverRating[] = [...items];

    if(ordering.field==="rank"){
      _items = _items.sort((n1,n2) => {
        if(n1.rank === null) return ordering.desc ? -1 : 1;
        if(n2.rank === null) return ordering.desc ? 1 : -1;
        return n1.rank - n2.rank;
      })
    }else if(ordering.field==="average"){
      _items = _items.sort((n1,n2) => n1.rating.average - n2.rating.average)
    }else if(ordering.field==="count"){
      _items = _items.sort((n1,n2) => n1.rating.count - n2.rating.count)
    }else if(ordering.field==="star"){
      _items = _items.sort((n1,n2) => (n1.rating.stars[(ordering.value || 0)-1] || 0) - (n2.rating.stars[(ordering.value || 0)-1] || 0))
    }else if(ordering.field==="item"){
      _items = _items.sort((n1,n2) => (n1.items[ordering.value || 0] || 0) - (n2.items[ordering.value || 0] || 0))
    }

    if(ordering.desc){
      _items = _items.reverse()
    }

    return _items

  }, [items, ordering])

  const total:IDriverRating|null = useMemo(()=>{
    if(items === null) return null;

    let _items:any = {};
    (choices || []).forEach((i)=>{
      _items[i.id] = 0;
    })
    
    let _total:IDriverRating = {
      driver: {
        firstname: "",
        lastname: "",
        middlename: "",
        id: 0
      },
      rank: 0,
      call_signs: [],
      rating: {
        average: 0,
        count: 0,
        stars: [
          0,0,0,0,0
        ]
      },
      items: _items
    }

    let rating_count = 0;
    let rank_count = 0;
    items.forEach((i)=>{
      _total.rank = (_total.rank || 0) + (i.rank || 0);
      _total.rating.average += i.rating.average;
      _total.rating.count += i.rating.count;
      _total.rating.stars = _total.rating.stars.map((s, index)=>s+i.rating.stars[index]);
      Object.keys(i.items).forEach((s:any)=>{
        _total.items[s] += i.items[s] || 0;
      })

      if(i.rating.count){
        rating_count += 1;
      }
      if(i.rank !== null){
        rank_count += 1;
      }
    })

    _total.rating.average /= rating_count;
    _total.rank = Math.round((_total.rank || 0) / rank_count);

    return _total
  }, [items, choices])

  const totalRow = useCallback(()=>{
    if(total === null) return null;
    
    return (
      <TableRow>
        <TableCell></TableCell>
        {!hideRank?(
          <TableCell>
            <Rank value={total.rank} />
          </TableCell>
        ):null}
        <TableCell align="center">
          <NumberFormat
            value={total.rating.average}
            displayType={'text'}
            thousandSeparator={' '}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </TableCell>
        <TableCell align="center">{total.rating.count}</TableCell>
        {[1,2,3,4,5].map((i)=>(
          <TableCell
            key={i}
            align="center"
          >
            {total.rating.stars[i-1]}
          </TableCell>
        ))}
        {choices.map((choice)=>(
          <TableCell
            key={choice.id}
            align="center"
          >
            {total.items[choice.id]}
          </TableCell>
        ))}
      </TableRow>
    )
  }, [choices, total, hideRank])

  const driverName = useCallback((item:IDriverRating) => {
    let names = [
      item.driver.lastname,
      item.driver.firstname,
      item.driver.middlename,
    ]
    names = names.filter((i)=>!!i);
    return names.join(" ");
  }, [])

  const driverSubTitle = useCallback((item:IDriverRating):string|null => {
    let subtitle:string|null = item.atp?.name || null;
    
    let call_signs = item.call_signs.map(i => i.call_sign);
    if(call_signs.length && item.atp?.name === "ФОП"){
      subtitle = call_signs.join(", ");
    }

    return subtitle
  }, [])

  const renderOrdering = useCallback((field:IOrderingFields, value?:number) => {
    if(ordering.field === field && ordering.value === value){
      return (
        <React.Fragment>
          <br/>
          <RotateIcon
            rotate={ordering.desc}
            color="disabled"
          >keyboard_backspace</RotateIcon>
        </React.Fragment>
      )
    }
    return null;
  }, [ordering])

  const orderingClick = useCallback((field:IOrderingFields, value?:number) => {
    if(ordering.field === field && ordering.value === value){
      setOrdering({
        ...ordering,
        desc: !ordering.desc
      })
    }else{
      setOrdering({
        field: field,
        value: value,
        desc: true
      })
    }
  }, [ordering])
  
  const r = useMemo(()=>{

  
  return (
    <Table size="small">
      <TableHead>
        <HeadRow>
          <TableCell style={{textAlign: 'left'}}>Водій/АТП</TableCell>
          {!hideRank?(
            <TableCellStyled
              vertical
              ordering
              onClick={()=>orderingClick('rank')}
            >
              <span>Рейтинг{renderOrdering('rank')}</span>
            </TableCellStyled>
          ):null}
          <TableCellStyled
            vertical
            ordering
            onClick={()=>orderingClick('average')}
          >
            <span>Середня{renderOrdering('average')}</span>
          </TableCellStyled>
          <TableCellStyled
            vertical
            ordering
            onClick={()=>orderingClick('count')}
          >
            <span>Кількість{renderOrdering('count')}</span>
          </TableCellStyled>
          {[1,2,3,4,5].map((i)=>{
            let stars = [];
            for(let c=0; c<i; c++){
              stars.push(c)
            }
            return (
              <TableCellStyled
                key={i}
                headStar
                vertical
                ordering
                onClick={()=>orderingClick('star', i)}
              >
                <span>
                  <StarsBox>
                    {stars.map((c=>(
                      <Icon key={c}>star</Icon>
                    )))}
                  </StarsBox>
                  {renderOrdering('star', i)}
                </span>
              </TableCellStyled>
            )
          })}
          {choices.map((choice)=>(
            <TableCellStyled
              key={choice.id}
              vertical
              ordering
              onClick={()=>orderingClick('item', choice.id)}
            >
              <span>{choice.name}{renderOrdering('item', choice.id)}</span>
            </TableCellStyled>
          ))}
        </HeadRow>
        {totalRow()}
      </TableHead>
      <TableBody>
        {orderedItems.map((item)=>{
          return (
            <TableRow
              hover
              key={item.driver.id}
            >
              <TableCell>
                <DriverLink driverID={item.driver.id} color="inherit">{driverName(item)}</DriverLink>
                {!hideSubtitle && driverSubTitle(item)?(
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                  >{driverSubTitle(item)}</Typography>
                ):null}
              </TableCell>
              {!hideRank?(
                <TableCell align="center">
                  <Rank value={item.rank} />
                </TableCell>
              ):null}
              <TableCell align="center">
                {item.rating.average?(
                  <NumberFormat
                    value={item.rating.average}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ):null}
              </TableCell>
              <TableCell align="center">{item.rating.count || null}</TableCell>
              {[1,2,3,4,5].map((i)=>(
                <TableCell
                  key={i}
                  align="center"
                >
                  {item.rating.stars[i-1] || null}
                </TableCell>
              ))}
              {choices.map((choice)=>(
                <TableCell
                  key={choice.id}
                  align="center"
                >
                  {item.items[choice.id] || null}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
      <TableHead>
        {totalRow()}
      </TableHead>
    </Table>
  )
}, [
  choices,
  driverName,
  orderedItems,
  orderingClick,
  renderOrdering,
  totalRow,
  driverSubTitle,
  hideSubtitle,
  hideRank
])
return r;
}