import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { FilteredTableFilterProps } from "../types";

export interface AutocompleteFilterItemProps{
  name: string;
  option?: JSX.Element;
  value: string|number;
}

export interface AutocompleteFilterProps extends FilteredTableFilterProps{
  items: AutocompleteFilterItemProps[]
}

export default function AutocompleteFilter(props:AutocompleteFilterProps){
  const {
    items = []
  } = props
  const [ prevValue, setPrevValue ] = useState(props.value);
  const [ focusNow, setFocusNow ] = useState(false);
  const inputRef = useRef();
  
  const realValue = useMemo(()=>{
    for(let i=0; i<items.length; i++){
      if(items[i].value === props.value){
        return items[i]
      }
    }
    return undefined;
  }, [props.value, items])

  useEffect(()=>{
    if(props.value!==prevValue){
      // Это извращение нужно чтобы Autocomplete не терял фокус при смене значение
      // в undefined или с undefined в значение
      // При таких изменениях компонент рендерится заново и соответственно фокус пропадает
      // вот здесь мы его руками ставим обратно
      // Причина рендеринга заново описана ниже
      setPrevValue(props.value)
      if(!!inputRef && !!inputRef.current && focusNow){
        (inputRef.current as any).focus()
      }
    }
  }, [props.value, prevValue, focusNow])

  return (
    <Autocomplete

      // Нужно менять key если realValue становиться undefined или на оборот
      // так как рукается что компонет Autocomplete не может переходить с котролироемого
      // состояния в не контролируемое и на оборот. А это определяется пропсом value
      // Соответственно при 
      key={ realValue===undefined ? "uncontroled" : "controled" }

      fullWidth
      disablePortal
      selectOnFocus
      clearOnBlur
      options={items}
      value={realValue}
      getOptionLabel={(option) => option.name || '(Порожньо)'}
      renderOption={((props, option)=>(
        <li {...props} key={option.value}>{option.option || option.name || '(Порожньо)'}</li>
      ))}
      isOptionEqualToValue={(option, value) => {
        return option && value!==undefined && option.value === value.value;
      }}
      renderInput={(params:any) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={props.label}
          onFocus={()=>{
            setFocusNow(true)
          }}
          onBlur={()=>{
            setFocusNow(false)
          }}
        />
      )}
      onChange={(e, option) => {
        props.onChange(option?.value)
      }}
    />
  )
}