import { CssBaseline, PaletteMode, Theme, ThemeOptions, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { red } from '@mui/material/colors';
import { ukUA } from '@mui/material/locale';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RequestsProvider from 'api/requests/RequestsProvider';
import { CardProvider } from 'cards/provider';
import { ProgressProvider } from 'components/progress';
import { ProgressPercentProvider } from 'components/progressPercent';
import { DEFAULT_PRIMARY_COLOR } from 'config';
import ukLocale from "date-fns/locale/uk";
import defaultComponentsProps from 'defaultComponentsProps';
import MainLayout from 'layout/main';
import { ConfirmProvider } from 'material-ui-confirm';
import AuthModel from 'models/AuthModel';
import LastLocationModel from 'models/LastLocationModel';
import { SnackbarProvider, WithSnackbarProps } from 'notistack';
import AuthNotFound from 'pages/not_found/AuthNotFound';
import GenericNotFound from 'pages/not_found/GenericNotFound';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { auth_routes } from 'routes';
import MainRedirect from 'routes/redirect_page/main_redirect';
import { IMenuCollapse, IMenuRoute, IStartRoute } from 'routes/types';
import AuthRoute from 'utils/AuthRoute';
import PrivateRoute from 'utils/PrivateRoute';


const getDefaultTheme = (mode: PaletteMode):ThemeOptions => ({
  palette: {
    mode: mode,
    primary: {
      main: DEFAULT_PRIMARY_COLOR
    },
    secondary: red,
  },
  components: defaultComponentsProps,
});

function Providers(props:any) {
   
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
      <ConfirmProvider>
        <ProgressProvider>
          <ProgressPercentProvider>
            <SnackbarProvider maxSnack={3}>
              <RequestsProvider>
                {props.children}
              </RequestsProvider>
            </SnackbarProvider>
          </ProgressPercentProvider>
        </ProgressProvider>
      </ConfirmProvider>
    </LocalizationProvider>
  );
}

function AuthProviders(props:any) {
   
  return (
      <Providers>
        <CardProvider>
          {props.children}
        </CardProvider>
      </Providers>
  );
}


export interface IAppProps extends WithSnackbarProps {
  classes: any,
  theme: Theme,
  location: any,
  mode: PaletteMode
}

class AppBase extends React.Component<IAppProps> {
  state = {
    auth: null
  };

  componentDidMount = () => {
    AuthModel.subscribe(this);
    AuthModel.firstch_check();
  }
  componentWillUnmount() {
    AuthModel.unsubscribe(this);
  }

  privateRouteMaker = (start_route:IStartRoute, start_route_props:any, items:(IMenuRoute|IMenuCollapse)[]) => {
    return (
      <React.Fragment>
        {items.map((child_route, child_index) => {
          if((child_route as any).items){
            return (
              <React.Fragment key={child_index}>
                {this.privateRouteMaker(start_route, start_route_props, (child_route as IMenuCollapse).items)}
              </React.Fragment>
            )
          }else{
            const Component = (child_route as IMenuRoute).component;
            return (
              <PrivateRoute
                key={child_index}
                exact
                path={start_route.start_path + (child_route as IMenuRoute).path}
                render={(props:any) =>  (
                  <Component
                    startRouteProps={start_route_props}
                    {...props}
                  />
                )}
              />
            )
          }
        })}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        { AuthModel.if_first_check() ?
        (
          <React.Fragment>
            { AuthModel.if_auth() ? (
              <Switch>
                {AuthModel.start_routes.map((start_route, index) => (
                  <PrivateRoute
                    key={`start_route_${index}`}
                    path={start_route.start_path+"/**"}
                    exact
                    children={(start_route_props:any) => {
                      if(start_route_props.match === null){
                        return "";
                      }
                      const theme = createTheme(start_route.theme(this.props.mode), ukUA);
                      
                      return (
                        <React.Fragment>
                          <HistorySaver/>
                          <ThemeProvider theme={theme}>
                            <AuthProviders>
                              <MainLayout
                                key={index}
                                startRoute={start_route}
                                startRouteProps={start_route_props}
                              >
                                <Switch>
                                  {this.privateRouteMaker(start_route, start_route_props, start_route.children)}
                                  <Route path='*' exact={true} component={AuthNotFound} />
                                </Switch>
                              </MainLayout>
                            </AuthProviders>
                          </ThemeProvider>
                        </React.Fragment>
                      )
                    }}
                  />
                ))}
                {AuthModel.start_routes.map((start_route, index) => {
                  if(start_route.firstPage === undefined || start_route.firstPage.component === undefined){
                    return "";
                  }
                  return (
                    <PrivateRoute
                      key={`first_page_${index}`}
                      exact
                      path={start_route.firstPage.path}
                      render={(props:any) =>  {
                        if(start_route.firstPage === undefined){
                          return "";
                        }else{
                          return (
                            <React.Fragment>
                              <HistorySaver/>
                              <start_route.firstPage.component
                                key={index}
                                startRoute={start_route}
                                {...props}
                              />
                            </React.Fragment>
                          )
                        }
                      }}
                    />
                  )
                })}
                <Route path='/' exact={true} component={MainRedirect} />
                <Route path='*' exact={true} component={AuthNotFound} />
              </Switch>
            ) : null}
            
            <Switch>
              {auth_routes.map((route, index) => (
                <AuthRoute
                  key={index}
                  exact
                  path={route.path}
                  render={(props:any) => (
                    <Providers>
                      <route.component
                        {...props}
                      />
                    </Providers>
                  )}
                />
              ))}
              {!AuthModel.if_auth() && (<Route path='*' exact={true} component={GenericNotFound} />)}
            </Switch>
          </React.Fragment>
        )
        : null }
      </div>
    )
    
  }
}


function HistorySaver(props:any) {
  let location = useLocation();
  useEffect(
    () => {
      LastLocationModel.setPath(location.pathname)
    },
    [location]
  )
   
  return null;
}

export default function App(props:any) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const mode:PaletteMode = React.useMemo(
    () => prefersDarkMode ? 'dark' : 'light',
    [prefersDarkMode],
  );
  const theme = React.useMemo(
    () => createTheme(getDefaultTheme(mode), ukUA),
    [mode],
  );;

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBase mode={mode} {...props} />
      </ThemeProvider>
    </BrowserRouter>
  );
}