import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OLMap from 'components/map';
import { ATP, CarLocation, Status, TariffClass } from 'components/map/types';
import requests from 'api/requests';
import Controls from './map/controls';
import { API_CONFIG } from 'config';
import { styled } from '@mui/material';


const statuses:Status[] = [
  {
    id: 0,
    name: 'Вільні'
  },
  {
    id: 1,
    name: 'Зайняті'
  }
]

const Root = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-3),
  right: theme.spacing(-3),
  left: theme.spacing(-3),
  bottom: theme.spacing(-3),
}));

interface IMapPageDriverProps {
  atpID?:number;
  availableFilteringOrders?:boolean;
}

interface ATPWithCallSigns extends ATP{
  call_signs: string[];
}

export default function MapPageDriver(props:IMapPageDriverProps) {
  const [ callSigns, setCallSigns ] = useState([] as CarLocation[]);
  const [ tariffClasses, setTariffClasses ] = useState([] as TariffClass[]);
  const [ atp, setAtp ] = useState(null as ATPWithCallSigns|null|undefined);
  const [ onlyATP, setOnlyATP ] = useState(true);
  const [ statusFilter, setStatusFilter ] = useState([] as number[]);
  const [ orderClassFilter, setOrderClassFilter ] = useState([] as number[]);
  const [ callsignFilter, setCallsignFilter ] = useState("");
  const [ filteringOrdersFilter, setFilteringOrdersFilter ] = useState(false);

  const availableFilteringOrders = useMemo(() => {
    if(!!atp && !onlyATP){
      // Для АТП при просмотре всех машин на керте выключен фильтр
      // по позывным, которые фильтруют заказы
      return false;
    }
    return !!props.availableFilteringOrders;
  }, [atp, onlyATP, props.availableFilteringOrders])

  const ws = useCallback(() => {
    var ws:WebSocket;
    var reconnectTimeout:NodeJS.Timeout;

    function connect() {
      requests.get("/car/shift/ws-token/").then((r)=>{
        ws = new WebSocket(`${API_CONFIG.car_location_ws}?token=${r.body.token}`);

        ws.onmessage = function(e) {
          var data = JSON.parse(e.data)
          if(data.type === "car"){
            setCallSigns(data.data.map((i:any)=>new CarLocation(i)))
          }
        };

        ws.onclose = function(e) {
          if(e.code !== 1000){
            // reconnect
            reconnectTimeout = setTimeout(function() {
              connect();
            }, 1000);
          }
        };

        ws.onerror = function(err) {
          ws.close();
        };
      })
		}

    connect()

    return () => {
      clearTimeout(reconnectTimeout)
      if(!!ws){
        ws.close()
      }
    }
  }, [])

  const loadClasses = useCallback(() => {
    requests.get('/car/class/').then((r) => {
        setTariffClasses(r.body);
    })
  }, [])

  const loadAtp = useCallback(() => {
    if(props.atpID){
      Promise.all([
        requests.get(`/atp/atp/${props.atpID}/`).then((r)=>r.body),
        requests.getAll(`/car/car/`, {
          atp: props.atpID,
          removed: false,
          call_sign__isnull: false
        }).then((r)=>r.body.results.map((i:any)=>i.call_sign))
      ]).then((r)=>{
        setAtp({
          ...r[0],
          call_signs: r[1]
        })
      })
    }else{
      setAtp(undefined);
    }
  }, [props.atpID])

  const filterByCallsign = useCallback((items:CarLocation[]) => {
    // Фильтруем по строке поиска
    if(!callsignFilter){
      return items;
    }
    return items.filter((item:CarLocation) => item.call_sign===callsignFilter)
  }, [callsignFilter])

  const filterByClass = useCallback((items:CarLocation[]) => {
    // Фильтруем по классу
    if(!orderClassFilter.length){
      return items;
    }
    return items.filter((item:CarLocation) => {
      for(var i=0; i<orderClassFilter.length; i++){
        if(item.available_classes.includes(orderClassFilter[i])){
          return true;
        }
      }
      return false;
    })
  }, [orderClassFilter])

  const filterByStatus = useCallback((items:CarLocation[]) => {
    // Фильтруем по статусу
    if(!statusFilter.length || statusFilter.length === statuses.length){
      return items;
    }
    return items.filter((item:CarLocation) => {
      if(statusFilter.includes(0)){
        return item.status === 0;
      }
      
      return item.status !== 0;
    })
  }, [statusFilter])

  const filterByFilteringOrders = useCallback((items:CarLocation[]) => {
    // Фильтруем по позывным, которые фильтруют заказы
    if(!availableFilteringOrders || (availableFilteringOrders && !filteringOrdersFilter)){
      return items;
    }

    const arrToStr = (arr:number[]):string => [...arr].sort((a, b) => a - b).join(",")

    let availables:any = {};
    (tariffClasses || []).map((i)=>{
      availables[i.id] = arrToStr(i.available);
      return i;
    })
    
    return items.filter((item:CarLocation) => {
      return availables[item.class]!==arrToStr(item.available_classes)
    })
  }, [filteringOrdersFilter, tariffClasses, availableFilteringOrders])

  const filterByATP = useCallback((items:CarLocation[]) => {
    // Фильтруем АТП
    if(!props.atpID || !onlyATP){
      return items;
    }
    
    return items.filter((item:CarLocation) => {
      return atp?.call_signs.includes(item.call_sign)
    })
  }, [props.atpID, onlyATP, atp])

  const getCallSigns = useCallback(() => {
    let call_signs = callSigns;
    call_signs = filterByCallsign(call_signs);
    call_signs = filterByClass(call_signs);
    call_signs = filterByStatus(call_signs);
    call_signs = filterByFilteringOrders(call_signs);
    call_signs = filterByATP(call_signs);
    return call_signs;

  }, [callSigns, filterByCallsign, filterByClass, filterByStatus, filterByFilteringOrders, filterByATP])

  
  useEffect(() => ws(), [ws]);
  useEffect(() => loadClasses(), [loadClasses]);
  useEffect(() => loadAtp(), [loadAtp]);

  return (
    <Root>
      <OLMap
        call_signs={getCallSigns()}
        tariff_classes={tariffClasses}
        statuses={statuses}
        forceBigMarkers={!!atp && !!onlyATP}
      >
        <Controls
          atp={atp || undefined}
          tariffClasses={tariffClasses}
          statuses={statuses}
          availableFilteringOrders={availableFilteringOrders}
          
          tariffClassesCheck={orderClassFilter}
          statusesCheck={statusFilter}
          callsign={callsignFilter}
          filteringOrders={filteringOrdersFilter}
          onlyATP={onlyATP}
          
          onChangeTariffClasses={setOrderClassFilter}
          onChangeStatuses={setStatusFilter}
          onChangeCallsign={setCallsignFilter}
          onChangeFilteringOrders={setFilteringOrdersFilter}
          onChangeOnlyATP={setOnlyATP}
        />
      </OLMap>
    </Root>
  );
}