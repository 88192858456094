
import CarList from 'components/car/carList';


export default function Cars() {
  return (
    <CarList
      userSettingsKey="admin-cars"
    />
  )
}