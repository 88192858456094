import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, styled } from '@mui/material';


const FormControlBox = styled(FormControl)(() => ({
  minWidth: 120,
}));

const PlaceholderBox = styled("span")(({ theme }) => ({
  color: theme.palette.grey[500],
}));

interface IMultipleSelectProps {
  label?:string;
  placeholder?:string;
  items:any[];
  value:any[];
  itemName:string;
  itemID:string;
  maxDisplayItems:number;
  onChange:(values:any[])=>void;
}

export default function MultipleSelect(props: IMultipleSelectProps) {
  const [ valueDict, setValueDict ] = useState({} as any);

  useEffect(() => {
    let dict = {} as any;
    props.items.map((i:any) => {
      dict[i[props.itemID]] = i[props.itemName];
      return i;
    })
    setValueDict(dict);
  }, [props.items, props.itemID, props.itemName]);

  return (
    <FormControlBox fullWidth>
      {props.label && (
        <InputLabel>{props.label}</InputLabel>
      )}
      <Select
        value={props.value}
        onChange={(e: any) => {
          props.onChange(e.target.value)
        }}
        multiple
        displayEmpty
        renderValue={(selected) => {
          const selected_names = (selected as number[]).map((i) => valueDict[i]);
          const selected_names_trim = selected_names.slice(0, props.maxDisplayItems);
          if(selected_names.length !== selected_names_trim.length){
            selected_names_trim.push("+"+(selected_names.length-selected_names_trim.length).toString());
          }
          let value = selected_names_trim.join(', ');
          if(value){
            return value;
          }else{
            if(props.placeholder){
              return <PlaceholderBox>{props.placeholder}</PlaceholderBox>;
            }
          }
          return "";
        }}
      >
        {props.items.map((i)=>(
          <MenuItem key={i[props.itemID]} value={i[props.itemID]}>
            <Checkbox checked={props.value.indexOf(i[props.itemID]) > -1} />
            <ListItemText primary={i[props.itemName]} />
          </MenuItem>
        ))}
      </Select>
    </FormControlBox>
  );
}

MultipleSelect.defaultProps = {
  itemID: 'id',
  itemName: 'name',
  maxDisplayItems: 2
}