import React, { useMemo, useState } from 'react';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Route, Link, useRouteMatch, matchPath } from "react-router-dom";
import { IMenuCollapse, IMenuRoute } from 'routes/types';
import { Collapse, List, ListItemButton, listItemButtonClasses, styled } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface IMenuItemsProps {
  items:(IMenuRoute|IMenuCollapse)[];
	makeUrl: (item:IMenuRoute) => string;
	makePath: (item:IMenuRoute) => string;
	onClick: (item:IMenuRoute) => void;
  isHideParam?: any;
}


interface ListItemBoxProps extends ListItemProps {
  nested?: boolean;
}

export const ListItemBox = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'nested',
})<ListItemBoxProps>(({ theme, nested }) => ({
  [`& .${listItemButtonClasses.root}`]: {
    ...(nested && {
      paddingLeft: theme.spacing(6),
    }),
  }
}));

export default function MenuItems(props:IMenuItemsProps) {
  const {
    isHideParam,
    makeUrl,
    makePath,
    onClick
  } = props;

  const match:any = useRouteMatch();
  const default_opens = useMemo(() => {
    let _default_opens = {} as any;
    const mapItems = (items:(IMenuRoute|IMenuCollapse)[], parent_indexes:number[]=[]) => {
      items.map((item, index) => {
        if((item as any).items){
          mapItems((item as IMenuCollapse).items, [...parent_indexes, index])
        }else{
          const z = matchPath(match.url, {
            path: makeUrl(item as IMenuRoute),
            exact: true,
            strict: false
          });
          if(z !== null){
            const opener_key = parent_indexes.join("-");
            _default_opens[opener_key] = true;
          }
        }
        return item;
      })
    }
    mapItems(props.items);
    return _default_opens
  }, [match, props.items, makeUrl])
  const [ opens, setOpens ] = useState(default_opens as any);
  
  const itemsMaker = (items:(IMenuRoute|IMenuCollapse)[], parent_indexes:number[]=[]) => {
    return (
      <React.Fragment>
        {items.map((item, index) => {
          if(item.isHide && item.isHide(isHideParam)){
            return null;
          }
          if((item as any).items){
            const opener_key = [...parent_indexes, index].join("-");
            const is_open = !!opens[opener_key];
            return (
              <React.Fragment key={index}>
                <ListItem button onClick={() => {
                  opens[opener_key] = !is_open;
                  setOpens({...opens});
                }}>
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={ item.name } />
                  {is_open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={is_open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {itemsMaker((item as IMenuCollapse).items, [...parent_indexes, index])}
                  </List>
                </Collapse>
              </React.Fragment>
            )
          }else{
            return (
              <Route
                key={index}
                path={makePath(item as IMenuRoute)}
                exact={true}
                children={({ match }) => (
                  <ListItemBox
                    selected={ !!match }
                    nested={parent_indexes.length > 0}
                    disablePadding
                  >
                    <ListItemButton
                      component={Link}
                      to={makeUrl(item as IMenuRoute)}
                      onClick={()=>{
                        onClick(item as IMenuRoute)
                      }}
                    >
                      { item.icon ? (
                        <ListItemIcon>
                          <item.icon />
                        </ListItemIcon>
                      ) : null}
                      <ListItemText primary={ item.name } />
                    </ListItemButton>
                  </ListItemBox>
                )}
              />
            )
          }
        })}
      </React.Fragment>
    )

    
  }

  return (
    <React.Fragment>
      {itemsMaker(props.items)}
    </React.Fragment>
  );
}