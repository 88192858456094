import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import requests from "api/requests";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CardProps } from "../types";
import { StyledAdminPage } from "./styles";
import { DriverSerializer } from "./types";
import { ReactComponent as ManPlaceholder } from 'static/man-placeholder.svg';
import { Deserializer } from "ts-serializer-core";
import Moment from 'react-moment';
import Rank from "components/driver/rank";
import Services from "./services";

export default function DriverCard(props:CardProps){
  const {
    id,
    onClose
  } = props
  const [ data, setData ] = useState<DriverSerializer|null>(null);
  const [ photo, setPhoto ] = useState<string|null|undefined>(undefined);

  const loadPhoto = useCallback((_id:number)=>{
    requests.get(`/base/driver-card/${_id}/photo/`).then((r)=>{
      setPhoto(r.body.photo)
    })
  }, [])

  useEffect(()=>{
    const abortController = new AbortController();

    if(!id){
      abortController.abort();
    }else{
      setData(null);
      setPhoto(undefined);
      requests.get(`/base/driver-card/${id}/`, {}, abortController.signal).then((r)=>{
        const deserializer = new Deserializer();
        const instance = deserializer.deserialize(DriverSerializer, r.body);
        setData(instance);
        loadPhoto(id);
      })
    }

    return () => {
      abortController.abort();
    };
  }, [id, loadPhoto])

  const title = useMemo(()=>{
    let title_list = ["Водій"];
    let driver_list = [];
    if(data?.lastname) driver_list.push(data?.lastname)
    if(data?.firstname) driver_list.push(data?.firstname[0].toUpperCase() + ".")
    if(data?.middlename) driver_list.push(data?.middlename[0].toUpperCase() + ".")
    const driver_name = driver_list.join(" ");
    if(driver_name){
      title_list.push(driver_name);
    }
    return title_list.join(": ")
  }, [data])


  return (
    <Dialog
      open={!!id}
      onClose={onClose}
      maxWidth="md"
      fullWidth={!!data}
    >
      {!data ? (
        <DialogContent>
          <CircularProgress sx={{display: "block"}} />
        </DialogContent>
      ):(
        <React.Fragment>
          <DialogTitle sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Box sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>{title}</Box>
            <Box
              sx={{
                paddingLeft: 2
              }}
            >
              <Rank value={data.rank} />
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container direction={{
              xs: "column-reverse",
              sm: "row"
            }}>
              <Grid item xs={9}>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography color="textSecondary" component="span">Прізвище: </Typography>
                    {data.lastname}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Ім'я: </Typography>
                    {data.firstname}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">По батькові: </Typography>
                    {data.middlename}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Телефон: </Typography>
                    {data.phone}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">E-mail: </Typography>
                    {data.email}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Статус: </Typography>
                    {data.status_value}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Дата народження: </Typography>
                    <Moment
                      date={data.birthday}
                      format="DD.MM.YYYY"
                    />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Прийнятий: </Typography>
                    <Moment
                      date={data.hiring_date}
                      format="DD.MM.YYYY"
                    />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Авто група: </Typography>
                    {data.atp?.name}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" component="span">Адреса проживання: </Typography>
                    {data.living_address}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} sx={{
                textAlign: 'center',
                mb: 1,
                "&>*": {
                  borderRadius: 1,
                  maxWidth: "100%",
                  height: "auto",
                }
              }}>
                {photo===undefined ? (
                  <CircularProgress />
                ) : (photo ? (
                  <img
                    src={photo}
                    alt={title}
                  />
                ):(<ManPlaceholder/>))}
              </Grid>
            </Grid>
            {data.note && (<Alert severity="info" sx={{mt: 2, whiteSpace: "pre-wrap"}}>{data.note}</Alert>)}

            <Box sx={{mt: 2, mb: 1}}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Додаткові послуги</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Services
                    driverID={data.id}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                >
                  <Typography>Доступ до кабінету</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <StyledAdminPage
                    apiUrl="/driver/driver-permission/"
                    parentID={data.id}
                    parentFieldName="driver"
                    inputNames={{
                      driver: 'Водій'
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Закрити</Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  )
}