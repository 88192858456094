import { PeopleAltOutlined, FontDownloadOutlined, FormatListBulletedOutlined, RoomOutlined } from '@mui/icons-material';
import PaymentErrorDirectory from 'pages/settings/directory/payment_error';
import MapPOI from 'pages/settings/map/poi';
import MapStreet from 'pages/settings/map/street';
import DriverRequestStatus from 'pages/settings/statuses/DriverRequestStatus';
import WebcabRatingStatus from 'pages/settings/statuses/WebcabRatingStatus';
import SettingsUser from 'pages/settings/users';
import { IMenuCollapse, IMenuRoute } from './types';

export const routes__settings = {
  invoices: "/",
  driver_request_status: "/statuses/driver-request",
  webcab_rating_status: "/statuses/webcab-rating",
  map_poi: "/map/poi",
  map_street: "/map/street",
  directory_payment_error: "/directory/payment-error",
}

export const menu_routes__settings:(IMenuRoute|IMenuCollapse)[] = [
  {
    path: routes__settings.invoices,
    name: "Користувачі",
    component: SettingsUser,
    icon: PeopleAltOutlined,
  },
  {
    name: "Статуси",
    icon: FormatListBulletedOutlined,
    items: [
      {
        path: routes__settings.driver_request_status,
        name: "Заявки водіїв",
        component: DriverRequestStatus,
      },
      {
        path: routes__settings.webcab_rating_status,
        name: "WebCab рейтинг",
        component: WebcabRatingStatus,
      },
    ]
  } as IMenuCollapse,
  {
    name: "Редагування адрес",
    icon: RoomOutlined,
    items: [
      {
        path: routes__settings.map_poi,
        name: "POI",
        component: MapPOI,
      },
      {
        path: routes__settings.map_street,
        name: "Вулиці",
        component: MapStreet,
      },
    ]
  } as IMenuCollapse,
  {
    name: "Довідник",
    icon: FontDownloadOutlined,
    items: [
      {
        path: routes__settings.directory_payment_error,
        name: "Помилки платіжної системи",
        component: PaymentErrorDirectory,
      },
    ]
  } as IMenuCollapse,
];