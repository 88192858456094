import { Grid, styled, TableRow } from "@mui/material";


export const AddressListOL = styled("ol")(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyle: "inside decimal"
}));

export const TableRowBox = styled(TableRow)(({ theme }) => ({
  '& > *': {
    borderBottom: 'unset',
  },
}));

export const CollapseGrid = styled(Grid)(({ theme }) => ({
  '& b': {
    opacity: 0.6,
  },
  '& > div': {
    maxWidth: 500
  }
}));

export const PrintBox = styled("div")(({ theme }) => ({
  width: '100%',
  '& table': {
    width: '100%',
    fontSize: 10,
    fontFamily: 'Arial, sans-serif',
    borderCollapse: 'collapse',
    border: '1px solid black',
    textAlign: 'left'
  },
  '& th': {
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '3px 5px'
  },
  '& td': {
    border: '1px solid black',
    padding: '3px 5px',
    verticalAlign: 'top'
  }
}));