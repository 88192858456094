import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TableCellBorderRight, TableCellDark, TableRowBox } from './styles';
import NumberFormat from 'react-number-format';
import requests from 'api/requests';


interface ITableOffsetComponent {
  carIDs:number[];
  periodID:number;
}

export default function TableOffset(props:ITableOffsetComponent) {
  const [items, setItems] = useState(null as any)
  const [total, setTotal] = useState(null as any)

  const money = (value:number) => {
    return (
      <NumberFormat
        value={value || 0}
        displayType={'text'}
        thousandSeparator={' '}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    )
  }

  const load = useCallback(() => {
    setItems(null);
    requests.getAll('/atp/offset/', {
      car_id__in: props.carIDs,
      period: props.periodID
    }).then((r) => {
      const _items = r.body.results;
      let _total = {} as any;
      if(_items.length){
        _items.map((item:any) => {
          item.sum = (item.order_sum_cash || 0) + (item.order_sum_non_cash_ch || 0) + (item.order_sum_non_cash_rc || 0)
          Object.keys(item).map((key:string) => {
            if(typeof item[key] == "number"){
              if(!_total[key]){
                _total[key] = 0;
              }
              _total[key] += item[key];
            }else{
              _total[key] = item[key];
            }
            return key;
          })
          return item;
        })
      }

      setItems(_items);
      setTotal(_total);
    })
  }, [props.carIDs, props.periodID])

  useEffect(() => load(), [load]);

  if(items === null || total === null){
    return (<div style={{textAlign: 'center'}}><CircularProgress /></div>)
  }

  return (
    <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellBorderRight align="center" colSpan={6}>Загальна інформація по замовленнях</TableCellBorderRight>
              <TableCell align="center" colSpan={9}>Деталізація по здачі</TableCell>
              <TableCellDark align="right" rowSpan={2}>Всього на здачу</TableCellDark>
            </TableRow>
            <TableRow>
              <TableCell>Позивний</TableCell>
              <TableCell align="right">Кіл-ть виконаних замовлень</TableCell>
              <TableCell align="right">Готівка</TableCell>
              <TableCell align="right">б/г кеш</TableCell>
              <TableCell align="right">б/г р/с</TableCell>
              <TableCellBorderRight align="right">Загальна сумма по замовленням</TableCellBorderRight>
              <TableCell align="right">Комісія</TableCell>
              <TableCell align="right">Бонус</TableCell>
              <TableCell align="right">Джокер</TableCell>
              <TableCell align="right">Оренда р/с</TableCell>
              <TableCell align="right">Телефон (3Мob)</TableCell>
              <TableCell align="right">Штрафи</TableCell>
              <TableCell align="right">Інші нарахування</TableCell>
              <TableCell align="right">Від кліента</TableCell>
              <TableCell align="right">Борг</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item:any, index:number) => (
              <TableRowBox key={index} hover nowrap>
                <TableCell>{item.call_sign}</TableCell>
                <TableCell align="right">{item.order_num || 0}</TableCell>
                <TableCell align="right">{money(item.order_sum_cash)}</TableCell>
                <TableCell align="right">{money(item.order_sum_non_cash_ch)}</TableCell>
                <TableCell align="right">{money(item.order_sum_non_cash_rc)}</TableCell>
                <TableCellBorderRight align="right">{money(item.sum)}</TableCellBorderRight>
                <TableCell align="right">{money(item.commission)}</TableCell>
                <TableCell align="right">{money(item.bonus)}</TableCell>
                <TableCell align="right">{money(item.order_sum_joker)}</TableCell>
                <TableCell align="right">{money(item.sum_radio)}</TableCell>
                <TableCell align="right">{money(item.sum_phone)}</TableCell>
                <TableCell align="right">{money(item.order_fine)}</TableCell>
                <TableCell align="right">{money(item.other)}</TableCell>
                <TableCell align="right">{money(item.order_sum_client)}</TableCell>
                <TableCell align="right">{money(item.order_debt)}</TableCell>
                <TableCellDark align="right">{money(item.remainder)}</TableCellDark>
              </TableRowBox>
            ))}
          </TableBody>
          <TableHead>
              <TableRowBox hover nowrap dark>
                <TableCell>Всього</TableCell>
                <TableCell align="right">{total.order_num || 0}</TableCell>
                <TableCell align="right">{money(total.order_sum_cash)}</TableCell>
                <TableCell align="right">{money(total.order_sum_non_cash_ch)}</TableCell>
                <TableCell align="right">{money(total.order_sum_non_cash_rc)}</TableCell>
                <TableCellBorderRight align="right">{money(total.sum)}</TableCellBorderRight>
                <TableCell align="right">{money(total.commission)}</TableCell>
                <TableCell align="right">{money(total.bonus)}</TableCell>
                <TableCell align="right">{money(total.order_sum_joker)}</TableCell>
                <TableCell align="right">{money(total.sum_radio)}</TableCell>
                <TableCell align="right">{money(total.sum_phone)}</TableCell>
                <TableCell align="right">{money(total.order_fine)}</TableCell>
                <TableCell align="right">{money(total.other)}</TableCell>
                <TableCell align="right">{money(total.order_sum_client)}</TableCell>
                <TableCell align="right">{money(total.order_debt)}</TableCell>
                <TableCellDark
                  align="right"
                  success={total.remainder <= 0}
                  warning={total.remainder > 0}
                >{money(total.remainder)}</TableCellDark>
              </TableRowBox>
          </TableHead>
        </Table>
      </TableContainer>
  );
}