import React, { useState } from 'react';
import { Dialog, DialogContent, FormControl, DialogActions, Button, Checkbox, TextField, Card, Divider, List, ListItem, ListItemIcon, ListItemText, IconButton, Popover } from '@mui/material';
import requests from 'api/requests';
import { IUser, IPermission } from './types';
import { useProgress } from 'components/progress';
import { useSnackbar } from 'notistack';
import SubPermissions from './SubPermissions';
import { DeleteOutlined } from '@mui/icons-material';


interface IUserDialogProps {
  item: IUser|null;
  permissionsDict:any;
  onClose: () => void;
  onDone: () => void;
}

export default function UserDialog(props:IUserDialogProps) {
  const _item = JSON.stringify(props.item || {});
  const __item = JSON.parse(_item) as IUser;
  
  const { isProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const [ inputs, setInputs ] = useState(__item);
  const [ permissions, setPermissions ] = useState((__item.permissions || []).map((i)=>i.permission));
  const [ deleteConfirmEl, setDeleteConfirmEl] = React.useState<HTMLButtonElement | null>(null);
  
  const handleChange = (name:string) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:string) => (value:any) => {
    let _inputs = inputs as any;
    _inputs[name] = value;
    setInputs({..._inputs});
  }

  const togglePermission = (code:string) => () => {
    const index = permissions.indexOf(code);
    if(index > -1){
      permissions.splice(index, 1)
    }else{
      permissions.push(code)
    }
    setPermissions([...permissions]);
  }

  const save_permissions = (user:IUser) => {
    requests.getAll('/settings/permission/', {
      user__in: user.id
    }).then((r) => {
      const current_permissions = r.body.results;
      let promises:Promise<any>[] = [];
      let current_permissions_codes = current_permissions.map((p:IPermission) => p.permission);
      current_permissions.map((p:IPermission) => {
        if(permissions.indexOf(p.permission) === -1){
          promises.push(requests.delete(`/settings/permission/${p.id}/`))
        }
        return p;
      })
      permissions.map((code:string) => {
        if(current_permissions_codes.indexOf(code) === -1){
          promises.push(requests.post(`/settings/permission/`, {
            user: user.id,
            permission: code,
          }))
        }
        return code;
      })
      Promise.all(promises).then((r) => {
        enqueueSnackbar("Збережено", {variant: 'success'});
        props.onDone();
      })
    })
  }

  const save = () => {
    let promises:Promise<any>[] = [];
    if(inputs.id){
      promises.push(requests.put(`/settings/user/${inputs.id}/`, inputs))
    }else{
      promises.push(requests.post(`/settings/user/`, inputs))
    }

    Promise.all(promises).then((r) => {
      let user_response = null;
      if(r.length){
        user_response = r[0];
      }else{
        save_permissions(inputs);
      }

      if(user_response !== null){
        save_permissions(user_response.body);
      }
    })
  }

  const remove = () =>{
    requests.delete(`/settings/user/${inputs.id}/`).then((r) => {
      enqueueSnackbar("Видалено", {variant: 'success'});
      props.onDone();
    })
  }

  return (
    <div>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="xs"
        onClose={()=>{
          if(isProgress) return;
          props.onClose();
        }}
      >
        <form
          onSubmit={(e:any) => {
            e.preventDefault();
            save();
          }}
        >
          <DialogContent>

            <FormControl fullWidth>
              <TextField
                autoFocus
                label="E-mail"
                margin="normal"
                value={inputs.email || ''}
                onChange={handleChange('email')}
                disabled={!!inputs.id || isProgress}
              />
            </FormControl>
            
            <br/><br/>
            <Card variant="outlined">
              <List dense component="div" role="list">
                <ListItem
                  role="listitem"
                  button
                  disabled={isProgress}
                  onClick={()=>{
                    handleChangeValue("is_superuser")(!inputs.is_superuser)
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={!!inputs.is_superuser}
                      disabled={isProgress}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Суперкористувач"} />
                </ListItem>
              </List>
              <Divider />
              <List dense component="div" role="list">
                {Object.keys(props.permissionsDict).map((code: string) => {
                  const labelId = `list-item-${code}-label`;

                  return (
                    <ListItem
                      key={code}
                      role="listitem"
                      button
                      onClick={togglePermission(code)}
                      disabled={!!inputs.is_superuser || isProgress}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={permissions.indexOf(code) > -1 || !!inputs.is_superuser}
                          disabled={!!inputs.is_superuser || isProgress}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={props.permissionsDict[code]} />
                    </ListItem>
                  );
                })}
                <ListItem />
              </List>
            </Card>
            
            {!!inputs.id && (
              <SubPermissions
                userID={inputs.id}
              />
            )}
          </DialogContent>
          <DialogActions>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setDeleteConfirmEl(event.currentTarget);
              }}
              color="secondary"
              disabled={isProgress}
              style={{marginRight: 'auto'}}
            >
              <DeleteOutlined />
            </IconButton>
            <Button
              onClick={props.onClose}
              disabled={isProgress}
            >
              Відмінити
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isProgress}
            >
              Зберегти
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Popover
        open={!!deleteConfirmEl}
        anchorEl={deleteConfirmEl}
        onClose={()=>setDeleteConfirmEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={()=>{
            remove();
            setDeleteConfirmEl(null);
          }}
        >Підтвердити</Button>
      </Popover>
    </div>
  );
}