import { Card, Divider, IconButton, Paper, styled } from "@mui/material";


export const Root = styled("div")(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const MapBox = styled("div")(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  "&>#map": {
    width: '100%',
    height: '100%',
    
    "& .ol-zoom": {
      top: 'auto',
      left: 'auto',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    "& .ol-control button": {
      height: '22px',
      width: '22px',
      lineHeight: '22px'
    },
    "& .ol-touch .ol-control button": {
      fontSize: '18px'
    }
  },
}));

export const MyLocationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(10),
  right: theme.spacing(3),
  marginRight: "-6px",
}));

export const ControlsBox = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
}));

export const ControlsOnPageBox = styled(ControlsBox)(({ theme }) => ({
  left: theme.spacing(3),
}));

export const PopUpBox = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap"
}));

export const InfoBox = styled("div")(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  whiteSpace: "nowrap"
}));

export const InfoTable = styled("table")(({ theme }) => ({
  width: '100%',
  '& td': {
    padding: 0,
    whiteSpace: "nowrap",
  },
  '& td:last-child': {
    textAlign: "right",
    paddingLeft: theme.spacing()
  }
}));

export const InfoRoot = styled("div")(({ theme }) => ({
  "&.mobileInfo": {
    display: 'flex',
    '& >div': {
      flexGrow: 1,
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderRight: "1px solid red",
      borderRightColor: theme.palette.divider,
      '&:last-child':{
        borderRight: 'none',
      },
      '& hr': {
        display: 'none'
      }
    }
  }
}));

export const PaperBox = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

export const DividerBox = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: -theme.spacing(-3),
  marginRight: -theme.spacing(-3),
}));