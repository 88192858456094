
import { useRouteMatch } from 'react-router-dom';
import DriverList from 'components/driver/driverList';


export default function Drivers() {
  const match:any = useRouteMatch();
  const atp = match.params.id;

  return (
    <DriverList
      userSettingsKey="atp-drivers"
      atp={atp}
    />
  )
}