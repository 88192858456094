import React, { useCallback, useEffect, useRef } from 'react';
import requests from 'api/requests';
import { IInvoice } from './types';
import { Dialog, DialogContent, DialogActions, Button, IconButton, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import printJS from 'print-js';


const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

interface IInvoiceComponent {
  invoice:IInvoice;
  stamp?:boolean;
  onClose: () => void;
}

export default function Invoice(props:IInvoiceComponent) {
  const frameContainerEl = useRef(null);

  const withStamp:boolean = props.stamp ? true : false;

  const load = useCallback(() => {
    requests.blob(`/corp/invoice/${props.invoice.id}/html/`, {
      'download': false,
      'stamp': withStamp
    }).then((blob) => {
      var frame = document.createElement("iframe") as any;
      frame.style.width = "800px"
      frame.style.border = "0"
      frame.onload = () => {
        const height = frame.contentWindow.document.body.scrollHeight as number;
        frame.style.height = `${height}px`
      }
      frame.src = URL.createObjectURL(blob);
      const el = frameContainerEl.current as any;
      el.appendChild(frame)
    })
  }, [props.invoice, withStamp])
  useEffect(() => load(), [load]);

  const Download = (e:any) => {
    e.preventDefault();
    requests.download(`/corp/invoice/${props.invoice.id}/pdf/`, {
      'download': true,
      'stamp': withStamp
    })
  }

  const Print = (e:any) => {
    e.preventDefault();
    requests.blob(`/corp/invoice/${props.invoice.id}/pdf/`, {
      'download': true,
      'stamp': withStamp
    }).then((blob) => {
      var objectURL = URL.createObjectURL(blob);
      printJS(objectURL);
    })
  }

  return (
    <Dialog
      open={true}
      maxWidth="md"
      scroll={'paper'}
      onClose={props.onClose}
    >
      <DialogContent dividers={true} sx={{ background: '#ffffff' }}>
        <div ref={frameContainerEl} style={{textAlign: 'center'}}></div>
        <CloseButton onClick={props.onClose}>
          <Close />
        </CloseButton>
      </DialogContent>
      <DialogActions>
          <Button onClick={Print}>
            Друкувати
          </Button>
          <Button onClick={Download}>
            Завантажити
          </Button>
        </DialogActions>
    </Dialog>
  );
}