import React, { useRef, useState } from 'react';
import FilteredTable from 'components/FilteredTable';
import columns from './columns';
import filters from './filters';
import { IItem } from './types';
import requests from 'api/requests';
import { Button } from '@mui/material';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import InstanceModal from './instanceModal';


export default function BlackListDriver() {
  const tableRef = useRef(null)
  const confirm = useConfirm();
  const [instance, setInstance] = useState<undefined|null|IItem>(undefined);

  const deleteItem = (row:IItem) => {
    requests.delete(`/admin/black-list/driver/${row.id}/`).then(()=>{
      setInstance(undefined);
      (tableRef.current as any).reload();
    })
  }

  const onDelete = (row:IItem) => {
    confirm({
      title: "Ви впевнені?",
      description: 'Подумайте ще раз і підтвердіть свої наміри',
      cancellationText: 'Ні',
      confirmationText: 'Так'
    }).then(() => {
      deleteItem(row)
    });
  }


  return (
    <div>
      <FilteredTable
        dataURL="/admin/black-list/driver/"
        columns={columns}
        filters={filters}
        userSettingsKey="black-list-driver"
        tableRef={tableRef}
        onRowClick={setInstance}
        leftSide={(
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddOutlined />}
            onClick={()=>{
              setInstance(null);
            }}
          >
            Додати
          </Button>
        )}
        actions={[{
          icon: DeleteOutline,
          onClick: onDelete
        }]}
      />
      {instance !== undefined ?(
        <InstanceModal
          instance={instance}
          onClose={()=>setInstance(undefined)}
          onSaved={()=>{
            setInstance(undefined);
            (tableRef.current as any).reload()
          }}
          onDeleteClick={deleteItem}
        />
      ):null}
    </div>
  )
}