import React from 'react';
import { Typography, Button } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import LastLocationModel from 'models/LastLocationModel';


export default function AuthNotFound() {
  let location = useLocation();
  if(location.pathname === LastLocationModel.getPath()){
    LastLocationModel.setPath(null);
  }

  return (
    <div>
        <Typography variant="h1" color="textSecondary" align="center">
          <ErrorOutlineOutlined fontSize="large" />
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          Сторінку не знайдено

          <Typography align="center">
            <br/>
            <Button
              component={RouterLink}
              to="/"
              variant="contained"
              color="primary"
            >
              На головну
            </Button>
          </Typography>
        </Typography>
      </div>
  )
}