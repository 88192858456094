import { Checkbox, FormControlLabel } from "@mui/material";
import { FilteredTableFilterProps } from "../types";

export default function BooleanFilter(props:FilteredTableFilterProps){
  return (
    <FormControlLabel control={<Checkbox
      checked={props.value || false}
      onChange={(e)=>{
        props.onChange(e.target.checked)
      }}
    />} label={props.label} />
  )
}