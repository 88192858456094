import { FilteredTableFilter } from "components/FilteredTable/types"
import { DateRangePickerFilter, TextFilter, SelectFilter } from "components/FilteredTable/filters";
import { dataRangeItem } from "components/daterange/defaults";
import { IDateRange } from "components/daterange";
import { format } from 'date-fns';
import { IExtendData } from "./types";
import CheckboxListFilter from "components/FilteredTable/filters/CheckboxListFilter";
import { REQUEST_TYPE_LIST } from "./config";

const filters:FilteredTableFilter[] = [
  {
    title: 'Дата',
    key: 'created',
    defaultValue: dataRangeItem.all(new Date()),
    component: DateRangePickerFilter,
    filter: (value:IDateRange)=>{
      if(!value.startDate && !value.endDate){
        return null;
      }
      let filter:any = {};
      if(value.startDate){
        filter.created__gte = format(value.startDate, "yyyy-MM-dd");
      }
      if(value.endDate){
        filter.created__lte = format(value.endDate, "yyyy-MM-dd");
      }
      return filter
    },
  },
  {
    title: 'Пошук',
    key: 'search',
    component: TextFilter,
    filter: (value:string)=>(
      value ? {
        search: value
      } : null
    ),
  },
  {
    title: 'Стан',
    key: 'state',
    component: SelectFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: "Всі стани",
          value: "",
        },
        {
          name: 'Нові',
          value: 'new',
        },
        {
          name: 'В роботі',
          value: 'in_progress',
        },
        {
          name: 'Завершені',
          value: 'final',
        }
      ]
    }),
    filter: (value:string)=>{
      if(value === 'new'){
        return {
          last_status_request__status__isnull: true
        };
      }
      if(value === 'in_progress'){
        return {
          last_status_request__status__is_final: false
        };
      }
      if(value === 'final'){
        return {
          last_status_request__status__is_final: true
        };
      }
      return null;
    },
  },
  {
    title: 'Статус',
    key: 'status',
    component: CheckboxListFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: ((extendData || {}).statuses || []).map((i)=>({
        name: i.name,
        value: i.id
      }))
    }),
    filter: (value:number[])=>{
      if(!value) return null;
      return {
        last_status_request__status__in: value.join(",")
      };
    },
  },
  {
    title: 'Тип',
    key: 'type',
    component: SelectFilter,
    componentProps: (value:string, filters:any, extendData?:IExtendData)=>({
      items: [
        {
          name: "Всі",
          value: "",
        },
        ...REQUEST_TYPE_LIST.map((i)=>({
          name: i.title,
          value: i.val
        }))
      ]
    }),
    filter: (value:string)=>(
      value ? {
        type: value
      } : null
    ),
  },
]

export default filters;