import { Badge, Box, Button, Container, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { ChevronRight, DeleteSweepOutlined, FilterListOutlined, PublishOutlined, SaveOutlined, SettingsBackupRestoreOutlined } from '@mui/icons-material';
import requests from 'api/requests';
import FilterDrawer from 'components/filterDrawer';
import { useSnackbar } from 'notistack';

import React, { MutableRefObject, Reducer, useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useState } from 'react';
import { FilterContainer, FilterControls, FilterItem, FooterDivider, PaperBox, RootBox } from './styles';
import { FilteredTableColumn, FilteredTableColumnItem, FilteredTableFilter, IActions, IDataActions } from './types';

interface IProps{
  dataURL: string;
  exportURL?: string;
  columns: FilteredTableColumn[];
  filters?: FilteredTableFilter[];
  defaultFilters?: {[name: string]: any};
  userSettingsKey?: string;
  onRowClick?: (item:any)=>void;

  extendData?: any;
  tableRef?: MutableRefObject<any>;
  withoutPagination?: boolean;

  leftSide?: JSX.Element;
  actions?: IActions[];
  dataActions?: IDataActions[];
}

interface IPage{
  limit: number;
  page: number;
  size: number|null;
}

interface IValueReducerAction{
  action: 'set'|'update';
  data: {
    key: string;
    value: any;
  } | any;
}

function filterValueReducer(state:any, action:IValueReducerAction) {
  if(action.action === 'set') return action.data;

  let _state = {...state};
  _state[action.data.key] = action.data.value
  return _state;
}


export default function FilteredTable(props:IProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<IPage>({
    limit: 30,
    page: 0,
    size: null
  })
  const {
    columns,
    filters,
    defaultFilters,
    userSettingsKey,
    extendData,
    tableRef,
    withoutPagination,
    actions=[],
    dataActions=[],
    exportURL,
  } = props;
  
  const ifFilterPersistent = useMediaQuery(theme.breakpoints.up('lg'));
  const [filterOpen, setFilterOpen] = useState(ifFilterPersistent);

  const [filterValues, setFilterValues] = useReducer<Reducer<any, IValueReducerAction>>(filterValueReducer, null);
  const [defaultValues, setDefaultValues] = useState({} as any)

  useEffect(()=>{
    setFilterOpen(ifFilterPersistent);
  }, [ifFilterPersistent])

  useImperativeHandle(tableRef, () => ({

    reload() {
      load(false);
    }

  }));
  

  const handleFilterClose = () => {
    setFilterOpen(false);
  };
  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
  };

  const makeFilters = useCallback((forceWithoutPagination?:boolean)=>{
    let filter:any = {
      ...(defaultFilters || {})
    };
    (filters || []).map((f)=>{
      let _f = f.filter(filterValues[f.key], filterValues, extendData);
      if(_f !== null){
        filter = {
          ...filter,
          ..._f,
        }
      }
      return f;
    })

    if(!withoutPagination && !forceWithoutPagination){
      filter = {
        ...filter,
        limit: page.limit,
        offset: page.page * page.limit,
      }
    }
    return filter
  }, [page.limit, page.page, withoutPagination, filterValues, filters, extendData, defaultFilters])

  const filtersCount = useCallback(()=>{
    let count:number = 0;
    (filters || []).map((f)=>{
      let _f = f.filter(filterValues[f.key], filterValues, extendData);
      if(!f.excludeFilterCoount && _f !== null){
        count++;
      }
      return f;
    })
    return count
  }, [filterValues, filters, extendData])

  const load = useCallback((scroll_up=true) => {
    if(userSettingsKey !== undefined && filterValues === null){
      return
    }
    const abortController = new AbortController();
    let timer = setTimeout(() => {
      if(scroll_up){
        window.scrollTo(0, 0);
      }
      requests.get(props.dataURL, {
        ...makeFilters(),
      }).then((r) => {
        if(!withoutPagination){
          setRows(r.body.results)
          setPage({
            limit: page.limit,
            page: page.page,
            size: r.body.count
          });
        }else{
          setRows(r.body)
          setPage({
            limit: page.limit,
            page: page.page,
            size: r.body.length
          });
        }
      })
    }, 500)

    return () => {
      clearTimeout(timer);
      abortController.abort();
    };
  }, [page.limit, page.page, withoutPagination, makeFilters, props.dataURL, userSettingsKey, filterValues])

  const patchDefaultValue = useCallback((data:any):any => {
    let _defaultValues:any = {...data};
    (filters || []).map((f)=>{
      if(f.defaultValue !== undefined){
        _defaultValues[f.key] = f.defaultValue;
      }
      return f;
    })
    return _defaultValues;
  }, [filters])

  const loadUserSettings = useCallback(()=>{
    requests.get(`/user/settings/${userSettingsKey}/`).then((r)=>{
      let _filters = r.body.filters || {};
      const _defaultValues = patchDefaultValue(_filters);
      setDefaultValues(_defaultValues);
      setFilterValues({
        action: 'set',
        data: _defaultValues
      });
    })
  }, [userSettingsKey, patchDefaultValue])

  const saveUserSettings = () => {
    let filter:any = {};
    (filters || []).map((f)=>{
      let _f = f.filter(filterValues[f.key], filterValues, extendData);
      if(_f !== null && f.defaultValue === undefined){
        filter[f.key] = filterValues[f.key]
      }
      return f;
    })

    requests.put(`/user/settings/${userSettingsKey}/`, {
      filters: filter
    }).then((r) => {
      setDefaultValues(patchDefaultValue(filter));
      enqueueSnackbar("Збережено", {variant: 'success'});
    })
  }

  useEffect(() => load(), [load]);
  useEffect(() => loadUserSettings(), [loadUserSettings]);

  const ifFilterList = useMemo(()=>{
    return !!filters && filters.length > 0
  }, [filters])

  const itemStyle = (item:FilteredTableColumnItem):React.CSSProperties => {
    let style:React.CSSProperties = {...item.style};
    if(item.align){
      style.textAlign = item.align
    }
    return style
  }

  const renderPagination = () => (
    <TablePagination
        component="div"
        count={page.size || 0}
        rowsPerPage={page.limit}
        page={page.page}
        rowsPerPageOptions={[page.limit]}
        onPageChange={(e, page_number) => {
          setPage({
            ...page,
            page: page_number
          });
        }}
      />
  )

  const handlerChangeFilter = (filter_field:string) => (filter_value:any) => {
    setFilterValues({
      action: 'update',
      data: {
        key: filter_field,
        value: filter_value
      }
    });
    setPage({
      ...page,
      page: 0
    });
  }

  const exportData = useCallback((filter:any) => {
    if(!!exportURL){
      requests.download(exportURL, filter);
    }
  }, [exportURL])

  const dataActionsList = useMemo(()=>{
    let res:IDataActions[] = [...dataActions];
    if(exportURL){
      res.push({
        tooltip: "Експорт",
        icon: PublishOutlined,
        onClick: exportData
      })
    }
    return res;
  }, [exportURL, dataActions, exportData])

  const dataActionClick = (action:IDataActions) => {
    action.onClick(makeFilters(true))
  }

  if(page.size === null) return null;

  return (
    <div style={{display: 'flex'}}>
      <RootBox>
        <Container maxWidth={false}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              flexGrow={1}
              flexShrink={1}
            >{props.leftSide||null}</Box>
            {dataActionsList.map((action, index)=>(
              <Box key={index} sx={{ px: 1 }}>
                <Tooltip title={action.tooltip}>
                  <IconButton onClick={()=>{
                    dataActionClick(action)
                  }}><action.icon/></IconButton>
                </Tooltip>
              </Box>
            ))}
            {ifFilterList ? (
              <Box sx={{ pl: 1 }}>
                <Tooltip title={filterOpen ? "Сховати фільтр" : "Фільтр"}>
                  <IconButton onClick={handleFilterToggle}>
                    <Badge badgeContent={filterOpen ? 0 : filtersCount()} color="secondary">
                      {filterOpen ? (<ChevronRight />) : (<FilterListOutlined />)}
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Box>
            ):null}
          </Box>
        </Container>
        
        <PaperBox>
          <TableContainer>
            <Table size="small" ref={tableRef}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index)=>(
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{
                        width: column.width !== undefined ? column.width : 'auto'
                      }}
                    >{column.title}</TableCell>
                  ))}
                  {actions.map((action, index)=>(
                    <TableCell key={index}></TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {rows.map((row, row_index)=>(
                <TableRow
                  key={row_index}
                  hover
                  sx={{
                    cursor: props.onRowClick !== undefined ? 'pointer' : 'default'
                  }}
                  onClick={()=>{
                    if(props.onRowClick !== undefined){
                      props.onRowClick(row)
                    }
                  }}
                >
                  {columns.map((column, column_index)=>(
                    <TableCell
                      key={column_index}
                      align={column.align}
                    >
                      {column.items.map((item, item_index)=>(
                        <div
                          key={item_index}
                          style={itemStyle(item)}
                        >
                          {item.render(row, extendData) || null}
                        </div>
                      ))}
                    </TableCell>
                  ))}
                  {actions.map((action, index)=>(
                    <TableCell
                      key={index}
                      align="center"
                      style={{
                        width: 1
                      }}
                    >
                      <IconButton
                        onClick={(e)=>{
                          e.preventDefault();
                          e.stopPropagation();
                          action.onClick(row);
                        }}
                      >
                        <action.icon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PaperBox>

        
        {!withoutPagination?(
          <Container maxWidth={false}>
            {renderPagination()}
          </Container>
        ):null}
      </RootBox>
      {ifFilterList ? (
        <FilterDrawer
          open={filterOpen}
          onClose={handleFilterClose}
          variant={ifFilterPersistent ? "persistent" : "temporary"}
        >
          <FilterContainer>

            {(filters || []).map((filter, index)=>{
              const filterProps = filter.componentProps ? filter.componentProps(filterValues[filter.key], filterValues, extendData) : {}
              return (
                <FilterItem key={filter.key} sx={filter.sx}>
                  <filter.component
                    label={filter.title}
                    value={filterValues[filter.key]}
                    onChange={handlerChangeFilter(filter.key)}
                    {...filterProps}
                  />
                </FilterItem>
              )
            })}
          </FilterContainer>
          <FilterControls>
            <FooterDivider />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<DeleteSweepOutlined />}
                  onClick={()=>{
                    setFilterValues({
                      action: 'set',
                      data: patchDefaultValue({})
                    })
                  }}
                >
                  Очистити
                </Button>
              </Grid>
              {userSettingsKey !== undefined ? (
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid>
                      <Tooltip title="Застосувати останні збережені налаштування">
                        <IconButton
                          onClick={()=>{
                            setFilterValues({
                              action: 'set',
                              data: defaultValues
                            })
                          }}
                          size="small"
                        >
                          <SettingsBackupRestoreOutlined/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid>
                      <Tooltip title="Зберегти налаштування">
                        <IconButton
                          onClick={saveUserSettings}
                          size="small"
                        >
                          <SaveOutlined/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              ):null}
            </Grid>
          </FilterControls>
        </FilterDrawer>
      ):null}
    </div>
  );
}