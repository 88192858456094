import React from 'react';
import { Grid } from '@mui/material';


interface IListItemComponent {
  name:string;
  image?:string;
  color?:string;
}

export default function ListItem(props:IListItemComponent) {

  return (
    <Grid container spacing={1} alignItems="center" alignContent="stretch" wrap="nowrap">
      <Grid item style={{flex: "0 0 50px"}}>
        {props.image && (
          <img src={props.image} alt={props.name} width="100%" style={{display: 'block'}} />
        )}
        {props.color && (
          <div style={{width: '100%', paddingBottom: "100%", backgroundColor: props.color}}></div>
        )}
      </Grid>
      <Grid item style={{flex: "0 0 0px"}}></Grid>
      <Grid item style={{flexGrow: 1}}>{props.name}</Grid>
    </Grid>
  );
}