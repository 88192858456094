import { Icon, InputLabel, Link, MenuItem, Select, TextField } from "@mui/material";
import { MaskedField } from "components/fields";
import { format } from "date-fns";
import React from "react";
import { parseINN } from "utils/inn";
import { REQUEST_TYPE_LIST } from "./config";
import { IDriverRequest } from "./types";

type IFields = { [type in (keyof IDriverRequest)] : {
  title: string;
  field: (v:any, onChange:(v:any)=>void)=>JSX.Element|null
}}

export const FIELDS:IFields = {
  id: {
    title: 'ID',
    field: (v, onChange) => null
  },
  created: {
    title: 'Дата створення',
    field: (v, onChange) => null
  },
  updated: {
    title: 'Дата останнього оновлення',
    field: (v, onChange) => null
  },
  type: {
    title: 'Тип',
    field: (v, onChange) => (
      <React.Fragment>
          <InputLabel>{FIELDS.type.title}</InputLabel>
          <Select
            value={v}
            onChange={onChange}
          >
            {REQUEST_TYPE_LIST.map((i)=>(
              <MenuItem key={i.val} value={i.val}>{i.title}</MenuItem>
            ))}
          </Select>
      </React.Fragment>
    )
  },
  full_name: {
    title: 'ПІБ',
    field: (v, onChange) => (
      <TextField
        label={`${FIELDS.full_name.title}*`}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  phone: {
    title: 'Телефон',
    field: (v, onChange) => (
      <MaskedField
        label={`${FIELDS.phone.title}*`}
        mask="+38 (099) 999 99 99"
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  email: {
    title: 'E-mail',
    field: (v, onChange) => (
      <TextField
        label={FIELDS.email.title}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  inn: {
    title: 'ІПН',
    field: (v, onChange) => {
      let helpText = null;
      if(v){
        const inn = parseINN(v);
        if(inn){
          helpText = `Дата народження: ${format(inn.date_of_birth, "dd.MM.yyyy")} (${inn.age})`;
        }
      }
      return (
        <TextField
          label={FIELDS.inn.title}
          margin="none"
          value={v || ''}
          helperText={helpText}
          onChange={onChange}
        />
      )
    }
  },
  car_model: {
    title: 'Марка та модель авто',
    field: (v, onChange) => (
      <TextField
        label={FIELDS.car_model.title}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  car_state_number: {
    title: 'Держ. номер авто',
    field: (v, onChange) => {
      let helpText = (
        <Link
          href={"https://ua.carplates.app/number/" + (v||'').replace(/ /g, "")}
          target="_blank"
          rel="noreferrer"
        >
          Перевірити на carplates.app <Icon fontSize="inherit">open_in_new</Icon>
        </Link>
      )
      return (
        <TextField
          label={FIELDS.car_state_number.title}
          margin="none"
          value={v || ''}
          onChange={onChange}
          helperText={v ? helpText : null}
        />
      )
    }
  },
  car_year: {
    title: 'Рік випуску авто',
    field: (v, onChange) => (
      <TextField
        label={FIELDS.car_year.title}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  driving_experience: {
    title: 'Водійський досвід',
    field: (v, onChange) => (
      <TextField
        label={FIELDS.driving_experience.title}
        margin="none"
        value={v || ''}
        onChange={onChange}
      />
    )
  },
  last_status_request: {
    title: 'Статус',
    field: (v, onChange) => null
  },
  utm_source: {
    title: 'utm_source',
    field: (v, onChange) => null
  },
  utm_medium: {
    title: 'utm_medium',
    field: (v, onChange) => null
  },
  utm_campaign: {
    title: 'utm_campaign',
    field: (v, onChange) => null
  },
  referrer: {
    title: 'referrer',
    field: (v, onChange) => null
  },
  source: {
    title: 'Джерело',
    field: (v, onChange) => null
  },
  cv: {
    title: 'Резюме',
    field: (v, onChange) => null
  },
}