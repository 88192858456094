import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, FormControl, DialogActions, Button, TextField, Card, List, ListItem, ListItemText, IconButton, Typography, ListItemSecondaryAction } from '@mui/material';
import requests from 'api/requests';
import { IRoutePoint } from './types';
import { DeleteOutlined } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';


interface IProps {
  onClose: () => void;
  onDone: () => void;
}

export default function AddRouteDialog(props:IProps) {

  const [value, setValue] = useState<IRoutePoint | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<IRoutePoint[]>([]);
  const [routePoints, setRoutePoints] = useState<IRoutePoint[]>([]);

  const {
    onClose,
    onDone
  } = props;
  
  const save = () => {
    if(routePoints.length < 2) return;

    requests.post("/admin/tariff/check/route/", {}).then((r)=>{
      const route_id = r.body.id;
      let promises:Promise<any>[] = [];

      routePoints.forEach((i, index)=>{
        promises.push(
          requests.post("/admin/tariff/check/routepoint/", {
              ...i,
              "route": route_id,
              "sort": index,
          })
        )
      })
      Promise.all(promises).then(()=>{
        onDone();
      })
    })
  }

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    const abortController = new AbortController();
    const timeout = setTimeout(()=>{
      requests.get(
        `/base/address/search/`,
        {
          q: inputValue
        },
        abortController.signal
      ).then((r)=>{
        setOptions(r.body.results)
      })
    }, 500)

    return () => {
      abortController.abort();
      clearTimeout(timeout);
    };
  }, [inputValue]);

  return (
    <div>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="xs"
        onClose={()=>{
          props.onClose();
        }}
      >
        <form
          onSubmit={(e:any) => {
            e.preventDefault();
            save();
          }}
        >
          <DialogContent>

            <FormControl fullWidth>
              <Autocomplete
                getOptionLabel={(option) => option.label || ""}
                options={options}
                filterOptions={(x) => x}
                autoComplete
                value={value}
                inputValue={inputValue}
                onChange={(event, newValue: IRoutePoint | null) => {
                  if(!newValue || (newValue.street_id && !newValue.building)){
                    setValue(newValue);
                    if(!newValue){
                      setInputValue("");
                    }
                  }else{
                    setValue(null);
                    setInputValue("");
                    setRoutePoints((v)=>{
                      return [
                        ...v,
                        newValue
                      ];
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Адреса"
                    fullWidth
                    autoFocus
                  />
                )}
                renderOption={(props:any, option) => {
                  return (
                    <div {...props} key={`${option.poi_id}-${option.street_id}-${option.building}`}>
                      <div>
                        <Typography>
                          {option.title}
                        </Typography>
                        {option.description ? (
                          <Typography variant="body2" color="textSecondary">
                            {option.description}
                          </Typography>
                        ):null}
                      </div>
                    </div>
                  )
                }}
              />
            </FormControl>
            
            <br/><br/>
            {routePoints.length ? (
              <Card variant="outlined">
                <List dense component="div" role="list">
                  {routePoints.map((i, index)=>(
                    <ListItem
                      role="listitem"
                      key={index}
                    >
                      <ListItemText primary={i.title} secondary={i.description} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={()=>{
                          setRoutePoints((v)=>{
                            v.splice(index, 1);
                            return [...v]
                          })
                        }}>
                          <DeleteOutlined />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Card>
            ):null}
            
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
            >
              Відмінити
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={routePoints.length<2}
            >
              Зберегти
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}