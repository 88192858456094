import { IMenuCollapse, IMenuRoute } from './types';
import { AccountBoxOutlined, BlockOutlined, DesignServicesOutlined, DirectionsCarOutlined, EmojiTransportationOutlined, HowToRegOutlined, LocalOfferOutlined, LocalParkingOutlined, MapOutlined, PhoneIphoneOutlined, TimelineOutlined } from '@mui/icons-material';
import MapPage from 'pages/admin/mapPage';
import Stat from 'pages/admin/stat';
import DriverRequest from 'pages/admin/driverRequest';
import DriverRequestEmails from 'pages/admin/driverRequest/driverRequestEmails';
import ATPRequest from 'pages/admin/ATPRequest';
import ATPRequestEmails from 'pages/admin/ATPRequest/ATPRequestEmails';
import AppRating from 'pages/admin/app/rating';
import AppMessages from 'pages/admin/app/messages';
import Wialon from 'pages/admin/wialon';
import TariffLoyaltyTime from 'pages/admin/tariff/loyaltyTime';
import AppDriverRating from 'pages/admin/app/driverRating';
import BlackListDriver from 'pages/admin/blackList/driver';
import TariffCheck from 'pages/admin/tariff/check';
import Drivers from 'pages/admin/drivers';
import Cars from 'pages/admin/cars';
import ServiceUpdateRequest from 'pages/admin/serviceUpdateRequest';

export const routes__admin = {
  map: "/map/",
  stat: "/",
  driver_request: "/driver-request/",
  driver_request_emails: "/driver-request/emails/",
  atp_request: "/atp-request/",
  atp_request_emails: "/atp-request/emails/",
  app_rating: "/app/rating/",
  app_driver_rating: "/app/driver-rating/",
  app_message: "/app/message/",
  wialon: "/wialon/",
  tariff_loyalty_time: "/tariff/loyalty-time/",
  tariff_check: "/tariff/check/",
  black_list_driver: "/black-list/driver/",
  drivers: "/drivers/",
  cars: "/cars/",
  service_update_request: "/service-update-request/"
}

export const menu_routes__admin:(IMenuRoute|IMenuCollapse)[] = [
  {
    path: routes__admin.stat,
    name: "Статистика",
    component: Stat,
    icon: TimelineOutlined,
  },
  {
    path: routes__admin.map,
    name: "Карта",
    component: MapPage,
    icon: MapOutlined,
  },
  {
    name: "Заявки водіїв",
    icon: HowToRegOutlined,
    items: [
      {
        path: routes__admin.driver_request,
        name: "Заявки",
        component: DriverRequest,
      },
      {
        path: routes__admin.driver_request_emails,
        name: "E-mail для повідомлень",
        component: DriverRequestEmails,
      },
    ]
  } as IMenuCollapse,
  {
    name: "Заявки АТП",
    icon: EmojiTransportationOutlined,
    items: [
      {
        path: routes__admin.atp_request,
        name: "Заявки",
        component: ATPRequest,
      },
      {
        path: routes__admin.atp_request_emails,
        name: "E-mail для повідомлень",
        component: ATPRequestEmails,
      },
    ]
  } as IMenuCollapse,
  {
    name: "Мобільний додаток",
    icon: PhoneIphoneOutlined,
    items: [
      {
        path: routes__admin.app_rating,
        name: "Оцінки",
        component: AppRating,
      },
      {
        path: routes__admin.app_driver_rating,
        name: "Рейтинг по водіям",
        component: AppDriverRating,
      },
      {
        path: routes__admin.app_message,
        name: "Повідомлення розробникам",
        component: AppMessages,
      },
    ]
  } as IMenuCollapse,
  {
    path: routes__admin.wialon,
    name: "Аналіз зупинок",
    component: Wialon,
    icon: LocalParkingOutlined,
  },
  {
    name: "Тариф",
    icon: LocalOfferOutlined,
    items: [
      {
        path: routes__admin.tariff_loyalty_time,
        name: "Лояльність по часу",
        component: TariffLoyaltyTime,
      },
      {
        path: routes__admin.tariff_check,
        name: "Перевірка",
        component: TariffCheck,
      },
    ]
  } as IMenuCollapse,
  {
    name: "Чорний список",
    icon: BlockOutlined,
    items: [
      {
        path: routes__admin.black_list_driver,
        name: "Водії для пасажирів",
        component: BlackListDriver,
      },
    ]
  } as IMenuCollapse,
  {
    path: routes__admin.drivers,
    name: "Водії",
    component: Drivers,
    icon: AccountBoxOutlined,
  },
  {
    path: routes__admin.cars,
    name: "Автопарк",
    component: Cars,
    icon: DirectionsCarOutlined,
  },
  {
    path: routes__admin.service_update_request,
    name: "Заявки на зміни послуг",
    component: ServiceUpdateRequest,
    icon: DesignServicesOutlined,
  },
];