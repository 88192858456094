import React from 'react';
import NumberFormat from 'react-number-format';
import { Grid, Card, Typography, Badge, Skeleton, styled, badgeClasses } from '@mui/material';


const TotalItemCard = styled(Card)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  "& > *": {
    textAlign: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    "& p": {
      textAlign: 'left',
      margin: 0,
      flexGrow: 1
    }
  }
}));

const BadgeBox = styled(Badge)(({ theme, badgeContent }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [`& .${badgeClasses.badge}`]: {
    ...(!badgeContent && {
      backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[400] : theme.palette.grey[600]
    })
  },
}));


interface ITotalTilesItemProps {
  title: string,
  value: number|undefined,
  badge: number|null,
  number_format: boolean,
  hidden?: boolean,
}

interface ITotalTilesProps {
  items: ITotalTilesItemProps[],
}

export default function TotalTiles(props: ITotalTilesProps) {

  return (
    <div>
      <Grid container spacing={3} alignItems="flex-start" justifyContent="flex-start" direction="row">

        {props.items.map((item, index) => !item.hidden ? (
          <Grid item key={index} xs={12} sm={"auto"}>
            <BadgeBox
              badgeContent={item.badge || 0}
              color="secondary"
              showZero={item.badge!==null && item.badge!==undefined}
              max={99999}
            >
              <TotalItemCard>
                  <Typography color="textSecondary" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {item.value!==undefined ? (
                      <div>{item.number_format ? (
                        <NumberFormat value={item.value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
                      ) : item.value}</div>
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Typography>
              </TotalItemCard>
            </BadgeBox>
          </Grid>
        ): null)}

      </Grid>
    </div>
  );
}