import React from 'react';
import { IColumn, IType, IItem } from "./types";
import NumberFormat from "react-number-format";
import Moment from 'react-moment';
import { Typography } from '@mui/material';

export const types:Array<IType> = [
  {
    name: "Попередні замовлення",
    initOpen: true,
    folders: [18]
  },
  {
    name: "Замовлення на оформленні",
    initOpen: true,
    folders: [19]
  },
  {
    name: "Направлені замовлення",
    initOpen: true,
    folders: [20, 21]
  },
  {
    name: "Виконуються",
    initOpen: true,
    folders: [22]
  },
  {
    name: "Завершені замовлення",
    initOpen: false,
    folders: [23]
  },
  {
    name: "Невиконані замовлення",
    initOpen: false,
    folders: [24]
  },
];

export const columns:Array<IColumn> = [
  {
    name: "№ замов.",
    nowrap: true,
    width: 1,
    onRender: (item:IItem) => (
      <NumberFormat
        value={item.id}
        displayType={'text'}
        thousandSeparator={' '}
      />
    )
  },
  {
    name: "Дата та час",
    nowrap: true,
    width: 1,
    onRender: (item:IItem) => (
      <Moment
        date={item.date}
        format="DD.MM.YY HH:mm"
      />
    )
  },
  {
    name: "ПІБ",
    onRender: (item:IItem) => item.fio
  },
  {
    name: "Адреса подачі",
    onRender: (item:IItem) => {
      if(item.addresses && item.addresses.length){
        return item.addresses[0];
      }
      return '';
    }
  },
  {
    name: "Клас",
    onRender: (item:IItem) => item.tariff_class
  },
  {
    name: "Авто",
    onRender: (item:IItem) => {
      let items:{
        title: string,
        value: any
      }[] = [];

      if(item.callsign !== null){
        items.push({
          'title': 'позивний',
          'value': item.callsign
        })
      }

      if(item.car !== null){
        items.push({
          'title': 'номер',
          'value': item.car.number
        })
        items.push({
          'title': 'марка',
          'value': item.car.brand
        })
        items.push({
          'title': 'колір',
          'value': item.car.color
        })
      }

      if(item.arrived_datetime !== null){
        items.push({
          'title': 'на місці',
          'value': (
            <Moment
              date={item.arrived_datetime}
              format="HH:mm"
            />
          )
        })
      }


      if(!items.length){
        return '';
      }

      return (
        <React.Fragment>
          {items.map((i, index)=>(
            <div key={index}>
              <Typography color='textSecondary' component='span' variant='body2'>{i.title}:</Typography>
              &nbsp;{i.value}
            </div>
          ))}
        </React.Fragment>
      )
    }
  },
  {
    name: "Тариф, грн.",
    align: "right",
    onRender: (item:IItem) => (
      <NumberFormat
        value={item.price}
        displayType={'text'}
        thousandSeparator={' '}
      />
    )
  },
]