import { DateConverter, JsonProperty } from "ts-serializer-core";


class CallsignSerializer {
  @JsonProperty('id')
  public id: number;

  @JsonProperty('name')
  public name: string;
}
class DriverSerializer {
  @JsonProperty('id')
  public id: number;

  @JsonProperty('lastname')
  public lastname: string;

  @JsonProperty('firstname')
  public firstname: string;

  @JsonProperty('middlename')
  public middlename: string;
}

export class OnlineTotalBillSerializer {
  @JsonProperty('id')
  public id: number;

  @JsonProperty({
    name: 'getresultdatetime',
    type: Date,
    customConverter: DateConverter
  })
  public getresultdatetime: Date;

  @JsonProperty({
    name: 'lastfolderchangedatetime',
    type: Date,
    customConverter: DateConverter
  })
  public lastfolderchangedatetime: Date;

  @JsonProperty('paysum')
  public paysum: number;

  @JsonProperty('drivertip')
  public drivertip: number;

  @JsonProperty({
    name: 'callsign',
    type: CallsignSerializer,
  })
  public callsign: CallsignSerializer;

  @JsonProperty({
    name: 'driver',
    type: DriverSerializer,
  })
  public driver: DriverSerializer;

  @JsonProperty('corrs_name')
  public corrs_name: string;
}