import { DateConverter, JsonProperty } from "ts-serializer-core";


export class OrderHistorySerializer {
  @JsonProperty({
    name: 'date',
    type: Date,
    customConverter: DateConverter
  })
  public date:Date;

  @JsonProperty('user')
  public user:string;

  @JsonProperty('changes')
  public changes:string;
}