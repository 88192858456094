import React, { useState, useCallback, useEffect } from 'react';
import { PaperBox } from './styles';
import requests from 'api/requests';
import { Container, Grid, FormControl, TextField, IconButton, Divider } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import UsersList from './emails_list';
import { useSnackbar } from 'notistack';
import { IEmail } from './types';
import { useConfirm } from 'material-ui-confirm';


export default function CompanyRequestEmails() {
  const confirm = useConfirm();
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const load = useCallback(() => {
    let data = {} as any;
    requests.getAll('/corp-admin/company/emails/', data).then((r:any) => {
      setEmails(r.body.results);
    })
  }, [])
  useEffect(() => load(), [load]);

  const Delete = (email:IEmail) => {
    confirm({
      title: "Ви впевнені?",
      description: 'На обрану адресу більше не будуть приходити сповіщення',
      cancellationText: 'Ні',
      confirmationText: 'Так'
    }).then(() => {
      requests.delete(`/corp-admin/company/emails/${email.id}/`).then(()=>{
        enqueueSnackbar("Видалено", {variant: 'success'});
        load();
      })
    });
  }

  const Add = () => {
    let data:IEmail = {
      email: email
    };
    requests.post('/corp-admin/company/emails/', data).then((r:any) => {
      setEmail("");
      enqueueSnackbar("Адресу додано", {variant: 'success'});
      load();
    })
  }

  return (
    <div>
      <Container maxWidth="sm">
        <PaperBox>
          <form
            onSubmit={(e:any) => {
              e.preventDefault();
              Add();
            }}
          >
            <Grid container alignItems="flex-start" wrap="nowrap">
              <Grid item style={{flexGrow: 1}}>
                <FormControl fullWidth>
                  <TextField
                    label="E-mail"
                    placeholder="E-mail для повідомлень"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton type="submit" color="secondary">
                  <AddOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </form>
          
          <br/>
          <Divider/>
          <br/>
          <UsersList
            emails={emails}
            onDelete={Delete}
          />
        </PaperBox>
      </Container>
    </div>
  );
}