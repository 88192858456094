import { styled, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import React from "react";
import { FilteredTableFilterProps } from "../types";


const Root = styled("div")(({ theme }) => ({
  display: 'flex',
  marginLeft: -3,
}));

export default function RatingFilter(props:FilteredTableFilterProps){

  const value:number[] = (props.value || []);
  const count = 5;

  const handlerChange = (value_number:number) => (val:boolean) => {
    let _value = value.filter((i)=>i!==value_number);
    if(val){
      _value.push(value_number);
    }
    props.onChange(_value);
  }

  return (
    <div>
      <Typography variant='caption'>{props.label}</Typography>
      <Root>
        {Array.from(Array(count).keys()).map((_i)=>(
          <Rating
            name={`_rating_filter_${_i}`}
            key={_i}
            max={1}
            value={Number(value.includes(_i+1))}
            onChange={(e, newValue)=>{
              handlerChange(_i+1)(newValue===1)
            }}
          />
        ))}
      </Root>
    </div>
  )
}