import { styled, TableCell, TableCellProps } from "@mui/material";


interface BalabceTableCellProps extends TableCellProps {
  error?: boolean;
  alarm?: boolean;
}
export const BalabceTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => !['error', 'alarm'].includes(prop.toString()),
})<BalabceTableCellProps>(({ theme, error, alarm }) => ({
  ...(error&&{
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }),
  ...(alarm&&{
    color: theme.palette.warning.main,
    fontWeight: 'bold'
  }),
}));