import {
	startOfWeek,
	startOfMonth,
	endOfWeek,
	endOfMonth,
	isBefore,
	addDays,
	isSameDay,
	isWithinInterval,
	parse,
	isValid,
	isSameMinute,
	format
} from "date-fns";
import { TEXTS } from "./defaults";
import { DateRange } from "./types";

export const identity = <T>(x: T) => x;

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] => {
	return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
		array.slice(i * size, i * size + size)
	);
};

export const combine = (...args: any[]): string => args.filter(identity).join(" ");

// Date
export const getDaysInMonth = (date: Date) => {
	const startWeek = startOfWeek(startOfMonth(date));
	const endWeek = endOfWeek(endOfMonth(date));
	const days = [];
	for (let curr = startWeek; isBefore(curr, endWeek); ) {
		days.push(curr);
		curr = addDays(curr, 1);
	}
	return days;
};

export const isStartOfRange = ({ startDate }: DateRange, day: Date) =>
	(startDate && isSameDay(day, startDate)) as boolean;

export const isEndOfRange = ({ endDate }: DateRange, day: Date) =>
	(endDate && isSameDay(day, endDate)) as boolean;

export const inDateRange = ({ startDate, endDate }: DateRange, day: Date) =>
	(startDate &&
		endDate &&
		(isWithinInterval(day, {start: startDate, end: endDate}) ||
			isSameDay(day, startDate) ||
			isSameDay(day, endDate))) as boolean;

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
	if (startDate && endDate) {
		return isSameDay(startDate, endDate);
	}
	return false;
};

type Falsy = false | null | undefined | 0 | "";

export const optionalDate = (date: Date | Falsy, defaultValue: Date):Date => {
	if (date instanceof Date) {
		return date;
	}
	return defaultValue;
};

export const isSameRange = (first: DateRange, second: DateRange) => {
	const { startDate: fStart, endDate: fEnd } = first;
	const { startDate: sStart, endDate: sEnd } = second;
	if (fStart && sStart && fEnd && sEnd) {
		return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
	}else{
		return ((!fStart && !sStart) || (!!fStart && !!sStart && isSameMinute(fStart, sStart)))
			&& ((!fEnd && !sEnd) || (!!fEnd && !!sEnd && isSameMinute(fEnd, sEnd)));
	}
};

export const parseDate = (date:string):Date|undefined => {
	const parsed = parse(date, "yyyy-MM-dd", new Date());
	if (isValid(parsed)) return parsed;
	else return undefined
}

export const getDateRangeWithTimeText = (dateRange:DateRange):string => {
	const yStart = Boolean(dateRange.startDate);
	const yEnd = Boolean(dateRange.endDate);

	if(yStart && yEnd){
		return format(dateRange.startDate as Date, "dd.MM.yyyy HH:mm") + " - " + format(dateRange.endDate as Date, "dd.MM.yyyy HH:mm");
	}
	
	if(!yStart && yEnd){
		return TEXTS.to + " " + format(dateRange.endDate as Date, "dd.MM.yyyy HH:mm");
	}
	if(yStart && !yEnd){
		return TEXTS.from + " " + format(dateRange.startDate as Date, "dd.MM.yyyy HH:mm");
	}

	return TEXTS.no_range;
}