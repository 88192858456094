import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import OrdersDriver from 'components/orderDriver';
import requests from 'api/requests';
import { parse } from 'date-fns';
import { getDateDefinedRanges } from './utils';


export default function Orders() {
  const match:any = useRouteMatch();
  const atp_id = match.params.id;

  const [ atp, setAtp ] = useState({} as any);

  const replacement_time = useMemo(()=>{
    return parse(atp.replacement_time || '00:00:00', 'HH:mm:ss', new Date());
  }, [atp])
  
  const dateDefinedRanges = useMemo(()=> getDateDefinedRanges(replacement_time), [replacement_time])

  const load = useCallback(() => {
    requests.get(`/atp/atp/${atp_id}/`).then((r) => {
      setAtp(r.body);
    })
  }, [atp_id]);

  useEffect(() => load(), [load]);

  if(!atp.id){
    return null;
  }

  return (
    <OrdersDriver
      listURL="/atp/order/"
      identificatorID={atp_id}
      identificatorName="atp"
      dateDefinedRanges={dateDefinedRanges}
      dateRange={dateDefinedRanges[1]}
      startTime={replacement_time}
    />
  )
}