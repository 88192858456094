import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(process.env['REACT_APP_SERVICEWORKER'] === '1'){
  const conf = {
    onUpdate: (registration:ServiceWorkerRegistration) => {
      if(registration.waiting !== null){
        registration.waiting.addEventListener('statechange', (event:any) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  }
  serviceWorkerRegistration.register(conf);
}else{
  serviceWorkerRegistration.unregister();
}