import { alpha, darken, lighten, styled, TableCell, TableCellProps, TableRow, TableRowProps } from "@mui/material";

export const TableCellBorderRight = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid
    ${
      theme.palette.mode === 'light'
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
    }`
}));


interface TableCellDarkProps extends TableCellProps {
  success?: boolean;
  warning?: boolean;
}
export const TableCellDark = styled(TableCell, {
  shouldForwardProp: (prop) => !['success', 'warning'].includes(prop.toString()),
})<TableCellDarkProps>(({ theme, success, warning }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],

  ...(success&&{
    color: theme.palette.mode === 'light' ? theme.palette.success.main : theme.palette.success.light
  }),
  ...(warning&&{
    color: theme.palette.mode === 'light' ? theme.palette.error.main : theme.palette.error.light
  }),
}));

interface TableRowBoxProps extends TableRowProps {
  nowrap?: boolean;
  dark?: boolean;
}
export const TableRowBox = styled(TableRow, {
  shouldForwardProp: (prop) => !['nowrap', 'dark'].includes(prop.toString()),
})<TableRowBoxProps>(({ theme, nowrap, dark }) => ({
  "& td, & th": {
    ...(nowrap&&{
        whiteSpace: "nowrap"
    }),
    ...(dark&&{
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    }),
  }
}));