import { Card, styled, Typography, TypographyProps } from "@mui/material";


interface ValueTypographyProps extends TypographyProps {
  success?: boolean;
  warning?: boolean;
}

export const ValueTypography = styled(Typography, {
  shouldForwardProp: (prop) => !['success', 'warning'].includes(prop.toString()),
})<ValueTypographyProps>(({ theme, success, warning }) => ({
  ...(success && {
    color: theme.palette.success.main
  }),
  ...(warning && {
    color: theme.palette.error.main
  }),
}));

export const TileCard = styled(Card)(({ theme }) => ({
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  height: "100%",
  "& > *": {
    textAlign: 'center'
  }
}));