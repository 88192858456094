import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, IconButton, Popover, DialogTitle, List, ListItem, ListItemIcon, Checkbox, ListItemText, Card } from '@mui/material';
import requests from 'api/requests';
import { useSnackbar } from 'notistack';
import { IEmail } from './types';
import { REQUEST_TYPE, REQUEST_TYPE_LIST } from '../config';
import { DeleteOutlined } from '@mui/icons-material';

interface InstanceModalProps {
  instance: IEmail;
  onClose?: ()=>void;
  onSave?: (instance:IEmail)=>void;
  onDelete?: (instance:IEmail)=>void;
}

export default function InstanceModal(props:InstanceModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [ deleteConfirmEl, setDeleteConfirmEl] = React.useState<HTMLButtonElement | null>(null);
  const _instance:IEmail = {
    id: props.instance.id,
    email: props.instance.email,
    type: props.instance.type ? [...props.instance.type] : []
  }
  const [instance, setInstance] = useState(_instance);

  const {
    onSave,
    onClose,
    onDelete
  } = props;

  const remove = () => {
    requests.delete(`/admin/driver/emails/${instance.id}/`).then((r) => {
      enqueueSnackbar("Видалено", {variant: 'success'});
      if(onDelete){
        onDelete(instance);
      }
    })
  }

  const update = () => {
    requests.patch(`/admin/driver/emails/${instance.id}/`, instance).then((r) => {
      enqueueSnackbar("Збережено", {variant: 'success'});
      if(onSave){
        onSave(r.body);
      }
    });
    
  }

  const toggleType = (type:(keyof typeof REQUEST_TYPE)) => () => {
    if(instance.type === undefined){
      instance.type = []
    }
    const index = instance.type.indexOf(type);
    if(index > -1){
      instance.type.splice(index, 1)
    }else{
      instance.type.push(type)
    }
    setInstance({...instance});
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
    >
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          update();
        }}
      >
        <DialogTitle>{instance.email}</DialogTitle>
        <DialogContent>

        <Card variant="outlined">
          <List dense component="div" role="list">
            {REQUEST_TYPE_LIST.map((type) => {
              const labelId = `list-item-${type.val}-label`;
              const val = type.val as (keyof typeof REQUEST_TYPE);

              return (
                <ListItem
                  key={type.val}
                  role="listitem"
                  button
                  onClick={toggleType(val)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={(instance.type || []).indexOf(val) > -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={type.title} />
                </ListItem>
              );
            })}
          </List>
        </Card>
          
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setDeleteConfirmEl(event.currentTarget);
            }}
            color="secondary"
            style={{marginRight: 'auto'}}
          >
            <DeleteOutlined />
          </IconButton>
          <Button
            onClick={onClose}
          >
            Відмінити
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            Зберегти
          </Button>
        </DialogActions>
      </form>

      <Popover
        open={!!deleteConfirmEl}
        anchorEl={deleteConfirmEl}
        onClose={()=>setDeleteConfirmEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="primary"
          onClick={()=>{
            remove();
            setDeleteConfirmEl(null);
          }}
        >Підтвердити</Button>
      </Popover>
    </Dialog>
  );
}